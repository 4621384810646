import { IconButton } from "@proag/sprout";
import {
  IPoliciesCrops,
  IPoliciesUnits,
  useRmaAdmCountiesQuery,
} from "features/managed-acres";
import { useEffect, useRef, useState } from "react";
import styles from "./producers-units-sidebar-coverage-card.module.scss";
import _lodash from "lodash";
import { colors } from "@proag/sprout";
import { useAppStateStore } from "../../../../app/state-management/use-app-state-store";

interface ProducersUnitsSidebarCoverageCardProps {
  isSelected: boolean;
  setSelectedUnit(unitId: number): void;
  coverage: IPoliciesCrops | undefined;
  units: Array<IPoliciesUnits>;
}

export const ProducersUnitsSidebarCoverageCard: React.FC<
  ProducersUnitsSidebarCoverageCardProps
> = ({
  isSelected,
  units,
  setSelectedUnit,
  coverage,
}) => {
  const [showUnits, setShowUnits] = useState(false);
  const [countyName, setCountyName] = useState("???");

  const scrollbar = useRef<any>();
  const setStoreCoverageId = useAppStateStore((s) => s.setSelectedCoverageId);
  const selectedCoverageId = useAppStateStore((s) => s.selectedCoverageId);
  const selectedUnitId = useAppStateStore((s) => s.selectedUnitId);

  const currentYear: number =
    coverage?.reinsuranceYear === undefined
      ? new Date().getFullYear()
      : _lodash.toInteger(coverage?.reinsuranceYear);
  const { rmaAdmCounties, isRmaAdmCountiesFetching } =
    useRmaAdmCountiesQuery(currentYear); // get our counties.

  useEffect(() => {
    if (!!rmaAdmCounties) {
      let matchedCountiesById = rmaAdmCounties.filter((c) => {
        return (
          c.countyCode === coverage?.countyCode &&
          c.stateCode === coverage?.stateCode &&
          c.reinsuranceYear.toString() === coverage?.reinsuranceYear
        );
      });

      setCountyName(matchedCountiesById[0].countyName);
    } else {
      setCountyName("???");
    }
  }, [rmaAdmCounties]);

  // let's build out the coverage summary slugs
  const cty = `${countyName} County`;
  const ipa = `${coverage!.insurancePlanAbbrev}`;
  const pef = `${Math.round(coverage!.priceElectionFactor * 100)}%`;

  /* prettier-ignore */
  const lev = coverage!.coverageByPracticeChecked
      ? `${Math.round(coverage!.irrCoverageLevel * 100)}%/${Math.round(coverage!.coverageLevel * 100)}%`
      : `${Math.round(coverage!.coverageLevel * 100)}%`

  /* prettier-ignore */
  const cropCoverageSummary = `${cty} | ${ipa} ${lev} - ${pef}`;

  useEffect(() => {
    if (
      !showUnits &&
      units &&
      units.findIndex((u) => u.unitId == selectedUnitId) != -1
    ) {
      setShowUnits(true);
    }
  }, [selectedUnitId]);

  const onSelectedUnit = (unitId: number) => {
    setSelectedUnit(unitId);
  };

  const onSelectedCoverage = (coverage: IPoliciesCrops | undefined) => {
    if ((!selectedCoverageId || !selectedUnitId) && coverage) {
      setStoreCoverageId(coverage.coverageId);
    }
    setShowUnits((current: boolean) => !current);
  };

  return (
    <>
      <div
        ref={scrollbar}
        data-testid={"producers-units-sidebar-coverage-card"}
        className={"mb-2"}
      >
        <div>
          <div
            data-testid={
              "producers-units-sidebar-coverage-" +
              coverage?.coverageId.toString()
            }
            onClick={() => onSelectedCoverage(coverage)}
            className={`p-3 px-3 pt-2 pb-3 ${styles.hoverClick} w-100 ${
              coverage?.coverageId == selectedCoverageId ? styles.selected : ""
            }`}
          >
            <div className="title-lg bold d-flex justify-content-between">
              {coverage?.commodityName}
              <IconButton
                data-testid={
                  "producers-units-sidebar-coverage-card-button-" +
                  coverage?.coverageId.toString()
                }
                className="right-align"
                iconName={showUnits ? "ChevronUp" : "ChevronDown"}
              />
            </div>
            <div className="label-sm" style={{ color: colors.neutral40 }}>
              {cropCoverageSummary}
            </div>
          </div>

          {showUnits &&
            units?.map((unit) => {
              return (
                <div
                  data-testid={
                    "producers-units-sidebar-unit-card-" +
                    unit?.unitId.toString()
                  }
                  className={`p-3 px-3 pt-2 pb-3 ${styles.hoverClick} w-100 ${
                    unit.unitId == selectedUnitId ? styles.selected : ""
                  }`}
                  key={unit.unitId}
                  onClick={() => onSelectedUnit(unit.unitId)}
                >
                  <div className="title-sm bold">{unit.unitNumber}</div>
                  {coverage ? (
                    <div
                      className="label-sm"
                      style={{ color: colors.neutral40 }}
                    >
                      <div>
                        {coverage.commodityName +
                          " " +
                          coverage.insurancePlanAbbrev +
                          ` - ${Math.round(
                            coverage.coverageLevel * 100
                          )}% - 100%`}
                      </div>
                      <div>
                        {unit.practiceName +
                          " - " +
                          unit.typeName +
                          " - Share " +
                          Math.round(unit.share * 100) +
                          "%"}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
        </div>
        <div className="mb-3"></div>
      </div>
    </>
  );
};
