import { all, fork } from "redux-saga/effects";
import * as NotificationSaga from "./notification";

export const Reducers = {
  notificationState: NotificationSaga.reducer,
};

export const RootSaga = function* root() {
  const allSagas = [...NotificationSaga.Sagas];
  yield all(allSagas.map((saga) => fork(saga)));
};
