import { Typography } from "@proag/sprout";

export const NoRowsOverlay = () => {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: "lightgrey", height: "9%" }}
    >
      <Typography>No records found.</Typography>
    </div>
  );
};
