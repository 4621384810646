import {
  LabelStyle,
  LayerStyle,
  MapStyle,
} from "./mapTypes";
import {
  SimpleRenderer,
  SimpleFillSymbol,
  Color,
  Graphic,
  GraphicsLayer,
  PictureMarkerSymbol,
  SimpleMarkerSymbol,
  MapView,
  FeatureLayer,
  WebMap,
  GeoJSONLayer,
  LabelClass
} from "./arcgis";
import {
  defaultMapDefinition,
} from "./mapConstants";
import { RefObject } from "react";
import { PoliciesFarmsShapesQueryResult } from "features/managed-acres/hooks/policies/models/policies-farms-shapes-query-result";

export function zoomToLayer(featureLayer: FeatureLayer, view: MapView) {
  return featureLayer.queryExtent().then((response) => {
    view.goTo(response.extent).catch((error) => {
      // TODO: improve error handling
    });
  });
}

export function createMapSymbol(style: LayerStyle) {
  const symbol = new SimpleFillSymbol({
    color: style.color,
    style: style.style,

    outline: {
      color: style.outlineColor,
      width: style.outlineWidth,
      style: style.outlineStyle,
    },
  });

  return symbol;
}


export function createLabelClass(style: LabelStyle) {
  const label = new LabelClass({
      labelExpressionInfo: { expression: "$feature.fieldLabel" },
      symbol: {
        type: style.type,
        color: style.color,
        haloSize: style.haloSize,
        haloColor: style.haloColor
      }
  });

  return label;
}

export function createMapMarker(style: MapStyle) {
  if (style.marker.style === "picture") {
    return new PictureMarkerSymbol({
      url: style.marker.url,
    });
  }

  return new SimpleMarkerSymbol({
    style: style.marker.style,
    outline: {
      color: style.marker.outlineColor,
    },
    color: new Color(style.marker.color),
  });
}

export const isOnFeatureLayer = (item: any, featureLayer: FeatureLayer) => {
  return item?.layer?.layerId == featureLayer.layerId;
};

export const propagateWithItem = (
  view: MapView,
  featureLayer: FeatureLayer,
  event: any,
  eventHandler: any
) => {
  view.hitTest(event).then(function (response) {
    if (response.results.length) {
      const item = response.results.find((a) =>
        isOnFeatureLayer(a, featureLayer)
      );
      if (item) {
        const graphic = (item as any).graphic as Graphic;
        const attributes = graphic.attributes;
        event.mapItem = attributes;
      }
    }
    if (eventHandler) {
      eventHandler(event);
    }
  });
};

export const createGeoJsonMap = (
  ref: RefObject<HTMLDivElement>,
  style: MapStyle,
  url: string
) => {
  const mapDefinition = defaultMapDefinition;
  const defaultLayerStyle = createMapSymbol(style.defaultLayer);
  const renderer = new SimpleRenderer({ symbol: defaultLayerStyle });

  const geoJsonLayer = new GeoJSONLayer({
    url: url,
    outFields: mapDefinition.featureOutfields,
    renderer: renderer,
    labelingInfo: [ createLabelClass(style.labelStyle)],
  });

  const interactionLayer = new GraphicsLayer();

  const webmap = new WebMap({
    basemap: mapDefinition.basemap,
    layers: [geoJsonLayer, interactionLayer],

    // TODO: this background is rendered as soon as the map starts rendering
    //    which might improve the sense of "something is happening"
    //    - determine if this has any value?

    // initialViewProperties: {
    //   background: {
    //     color: new Color( "rgba(0, 240, 240, 1)")
    //   },
    // }
  });

  const view = new MapView({
    map: webmap,
    container: ref.current ?? undefined, //MapView expects undefined for any falsy value
    zoom: 8,
    center: [-98.5984483, 39.8313285], // Geographic center of USA
    highlightOptions: {
      color: new Color(style.highlight?.fillColor),
      fillOpacity: style.highlight?.fillOpacity,
      haloColor: new Color(style.highlight?.haloColor),
    },
  });

  geoJsonLayer.when(() => {
    view.when(() => {
      return geoJsonLayer.queryExtent().then((response) => {
        view.goTo(response.extent).catch((error) => {
          // TODO: improve error handling
          console.log("error", error);
        });
      });
    });
  });

  return { view, geoJsonLayer };
};

export const createCluIdFeatureQuery = (cluIds: string[]) => {
  const inClause = cluIds.map((id) => `\'${id}\'`).join(", ");

  const query = `clu_identifier in (${inClause})`;

  return query;
};


export const UpdateMapEffect = (farmsShapes: PoliciesFarmsShapesQueryResult[] | undefined, updateStateMethod: any) => {
  let features = farmsShapes?.map((s) => {
    let f = `{"type" : "Feature", "geometry" : {{GEO}}, "properties" : {{PROPS}} }`;

    f = f.replace("{{GEO}}", s.shape);
    f = f.replace(
      "{{PROPS}}",
      `{ "fieldLabel": "` + s.fieldNumber + "\\n (" + s.acreage + `)"}`
    );

    return f;
  });
  if (features && features.length > 0) {
    //Leaving this here for whomever picks up 128022. for policy 1065389 you can see the map errors with too many features.
    //When Limiting to top 200 shapes you at least get to see some of what is displayed rather than erroring
    //console.log(features.length)
    //let fc = `{  "type": "FeatureCollection",  "features": [${features.slice(0,200).join(",")}] }`; //prettier-ignore
    let fc = `{  "type": "FeatureCollection",  "features": [${features.join(",")}] }`; //prettier-ignore
    updateStateMethod(fc);
  } else {
    //Adding an empty if undefined
    let fc = `{"features":[{"geometry":{"coordinates":[[[[0,0]]]],"type": "MultiPolygon"},"properties":{"fieldLabel": "-0"},"type": "Feature"}],"type": "FeatureCollection"}`; //prettier-ignore
    updateStateMethod(fc);
  }
}
