import { round } from "lodash";
import { format, isValid } from "date-fns";
import { FieldDataType } from "features/managed-acres/components/tables/FarmFields/FieldDataType";
import {
  validateSplitFieldHasDataEntered,
  validateAcresEnteredIfCropAndPracticeSelected,
  validateAcresEnteredAreNotMoreThanAvailable,
  validateAcresEnteredArePositive,
  validateAcresEnteredIfPlantDateEntered,
  validateAcresEnteredIfPreventPlantIsSelected,
} from "./validations-reportedacres";
import {
  hasEnteredAcres,
  hasSelectedCrop,
  hasSelectedUnit,
  hasCheckedPreventPlant,
  hasEnteredPlantedDate,
} from "./validation-helpers";

export const validateIfCropSelectedPracticeTypeIsAlsoSelected = (
  record: FieldDataType
): void => {
  if (hasSelectedCrop(record) && !hasSelectedUnit(record)) {
    record.unitId = {
      value: record.unitId.value,
      validationMessage: `Practice/Type must be selected when Crop is selected.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.unitId = {
      value: record.unitId.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};

export const validatePracticeSelectedIfAcresEntered = (
  record: FieldDataType
): void => {
  if (hasEnteredAcres(record) && !hasSelectedUnit(record)) {
    record.unitId = {
      value: record.unitId.value,
      validationMessage: `Practice/Type must be selected when acres are reported.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.unitId = {
      value: record.unitId.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};

export const validatePracticeIfPreventPlantIsSelected = (
  record: FieldDataType
): void => {
  if (!record.unitId.isValid) {
    return;
  }
  if (hasCheckedPreventPlant(record) && !hasSelectedUnit(record)) {
    record.unitId = {
      value: record.unitId.value,
      validationMessage: `Practice/Type must be selected when prevent plant is checked.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.unitId = {
      value: record.unitId.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};
