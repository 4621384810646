import { ICellEditorParams } from "ag-grid-community";
import { FieldDataType, dateEntryParser } from "features/managed-acres";
import { useState, forwardRef, useImperativeHandle, memo, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";

export const DatePicker = memo(
  forwardRef((props: ICellEditorParams<FieldDataType>, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const flatpickrRef = useRef<any>();
    const valueRef = useRef<string>();
    valueRef.current = props.value.value;

    if (flatpickrRef && flatpickrRef.current) {
      flatpickrRef.current.flatpickr?.calendarContainer?.classList?.add(
        "ag-custom-component-popup"
      );
    }

    if (isOpen && flatpickrRef) {
      flatpickrRef.current.flatpickr.open();
      flatpickrRef.current.flatpickr.altInput.focus();
    }

    const setDate = (dateStr: string): void => {
      const nextDate = dateEntryParser(dateStr);
      if (!dateStr) {
        valueRef.current = undefined;
      } else if (nextDate) {
        valueRef.current = format(nextDate, "MM/dd/yyyy");
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          setDate(flatpickrRef.current.flatpickr.altInput.value);
          return valueRef.current;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          setIsOpen(true);
          return false;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          return false;
        },

        //this exposes the handler for testing
        onChangeHandler: setDate,
      };
    });

    return (
      <div className="w-100 h-100">
        <Flatpickr
          className="w-100 h-100"
          value={valueRef.current}
          ref={flatpickrRef}
          options={{
            maxDate: new Date(),
            allowInput: true,
            dateFormat: "m/d/Y",
            altInput: true,
            altFormat: "m/d/Y",
            parseDate: (datestr: string, format: any) =>
              dateEntryParser(datestr),
          }}
        />
      </div>
    );
  })
);
