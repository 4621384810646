import { routePaths } from "services/routing/routes";
import styles from "./Sidebar.module.scss";
import { Link, Icon } from "@proag/sprout";

export const Sidebar = () => (
  <div className={styles.sidebar}>
    <div className={styles.sidebarHeader}></div>
    <Link className={styles.sidebarItem} to={routePaths.home}>
      <Icon iconName="Home" /> Home
    </Link>
    <Link className={styles.sidebarItem} to={routePaths.home}>
      <Icon iconName="Search" /> Search
    </Link>
    <Link className={styles.sidebarItem} to={routePaths.contact}>
      <Icon iconName="Email" /> Contact US
    </Link>
  </div>
);
