import { DatePicker } from "components/grid";
import { FieldDataType } from "features/managed-acres";
import { FieldTextEditor } from "features/managed-acres/components/tables/FarmFields/field-text-editor";
import {
  GridValidator,
  preventedPlantEffect,
  valueSetterEffect,
} from "features/managed-acres/components/tables/FarmFields/field-validators";
import "flatpickr/dist/themes/airbnb.css";
import {
  PlantDateCellClassRules,
  PlantDateEditable,
  PlantDateValueSetter,
  PlantedAcresCellClassRules,
  PlantedAcresValueSetter,
  PreventPlantOnCellClicked,
  SubFieldValueSetter,
  cellStyleLeftAlign,
  cellStyleRightAlign,
} from "./column-definition-helper";
import {
  ActionColumn,
  FieldAcresColumn,
  FNTractColumn,
  PlantDateColumn,
  PreventedPlantColumn,
  ReportedAcresColumn,
  SubFieldColumn,
  PreviouslyReportedColumn,
  FieldNumberColumn,
} from "./units-fields-table-columns";
import {
  ColDef,
  NewValueParams,
  SuppressKeyboardEventParams,
} from "ag-grid-community";

export const UnitFieldsTableColumnDefinition_GroupColumnDefinition: ColDef<FieldDataType> =
  {
    headerName: "FN/Tract - Field",

    cellRendererParams: {
      suppressCount: true,
      innerRenderer: FieldNumberColumn,
    },
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    headerCheckboxSelection: true,
    sortable: false,
    suppressMenu: true,
  };

export const UnitFieldsTableColumnDefinition_FarmNumber: ColDef<FieldDataType> =
  {
    headerName: "FarmNumber",
    field: "fsnTract",
    cellRenderer: FNTractColumn,
    colId: "fsnTract",
    hide: true,
    suppressMovable: true,
    rowGroup: true,
    cellClass: "span-display-block",
  };

export const UnitFieldsTableColumnDefinition_PreviouslyReported: ColDef<FieldDataType> =
  {
    headerName: "Previously Reported",
    field: "previouslyReported",
    colId: "previouslyReported",
    cellRenderer: PreviouslyReportedColumn,
    suppressAutoSize: false,
    cellEditor: FieldTextEditor,
    type: ["lockoutDisabled", "rightAligned"],
    cellStyle: cellStyleRightAlign,
    flex: 1,
    suppressMovable: true,
    sortable: false,
    suppressMenu: true,
    editable: false,
  };

export const UnitFieldsTableColumnDefinition_SubField: ColDef<FieldDataType> = {
  headerName: "Sub Field",
  field: "subField",
  colId: "subField",
  cellRenderer: SubFieldColumn,
  suppressAutoSize: false,
  cellEditor: FieldTextEditor,
  type: ["lockoutDisabled", "rightAligned"],
  cellStyle: cellStyleRightAlign,
  flex: 1,
  suppressMovable: true,
  sortable: false,
  suppressMenu: true,
  onCellValueChanged: (newValueParams: NewValueParams<FieldDataType>) =>
    GridValidator("unit", "partial", undefined, newValueParams),
  valueSetter: SubFieldValueSetter,
};

export const UnitFieldsTableColumnDefinition_FieldAcres: ColDef<FieldDataType> =
  {
    headerName: "Field Acres",
    field: "acres",
    colId: "acres",
    suppressAutoSize: false,
    cellRenderer: FieldAcresColumn,
    cellStyle: cellStyleRightAlign,
    flex: 1,
    suppressMovable: true,
    sortable: false,
    suppressMenu: true,
    type: ["rightAligned"],
  };

export const UnitFieldsTableColumnDefinition_PlantedAcres: ColDef<FieldDataType> =
  {
    headerName: "Reported Acres",
    field: "plantedAcres",
    colId: "plantedAcres",
    suppressAutoSize: false,
    cellRenderer: ReportedAcresColumn,
    cellEditor: FieldTextEditor,
    type: ["lockoutDisabled", "rightAligned"],
    cellStyle: cellStyleRightAlign,
    cellEditorParams: {
      type: "number",
    },
    cellClassRules: PlantedAcresCellClassRules,
    valueSetter: PlantedAcresValueSetter,
    onCellValueChanged: (newValueParams: NewValueParams<FieldDataType>) =>
      GridValidator("unit", "partial", undefined, newValueParams),
    flex: 1,
    suppressMovable: true,
    sortable: false,
    suppressMenu: true,
    suppressKeyboardEvent: (
      params: SuppressKeyboardEventParams<FieldDataType>
    ) => {
      if (params.event.key != "Tab") {
        return false;
      }
      var isPlantDateEditable: boolean;

      if (params?.context.isRoleUnderwriter) {
        isPlantDateEditable = true;
      } else if (params?.data?.pastAcreageReportDate) {
        isPlantDateEditable = !params?.data?.pastAcreageReportDate;
      }
      isPlantDateEditable = !params?.data?.preventedPlant.value;

      if (!isPlantDateEditable) {
        params.api.stopEditing();
      }
      return false;
    },
  };

export const UnitFieldsTableColumnDefinition_PlantDate: ColDef<FieldDataType> =
  {
    headerName: "Plant Date",
    field: "plantDate",
    colId: "plantDate",
    suppressAutoSize: false,
    cellRenderer: PlantDateColumn,
    cellEditor: DatePicker,
    editable: PlantDateEditable,
    cellClassRules: PlantDateCellClassRules,
    valueSetter: PlantDateValueSetter,
    onCellValueChanged: (newValueParams: NewValueParams<FieldDataType>) =>
      GridValidator("unit", "partial", undefined, newValueParams),
    cellStyle: cellStyleRightAlign,
    flex: 1,
    suppressMovable: true,
    sortable: false,
    suppressMenu: true,
    suppressKeyboardEvent: (
      params: SuppressKeyboardEventParams<FieldDataType>
    ) => {
      // Tabbing thru focuses only on editable cell and skips non-editable cell
      // Stop editing cell so that tab works on all columns
      if (params.event.key === "Tab") params.api.stopEditing();
      return false;
    },
  };

export const UnitFieldsTableColumnDefinition_PreventPlant: ColDef<FieldDataType> =
  {
    headerName: "PP",
    field: "preventedPlant",
    colId: "preventedPlant",
    suppressAutoSize: false,
    cellRenderer: PreventedPlantColumn,
    type: "lockoutDisabled",
    valueSetter: valueSetterEffect(preventedPlantEffect),
    cellStyle: cellStyleLeftAlign,
    editable: false,
    onCellValueChanged: (newValueParams: NewValueParams<FieldDataType>) =>
      GridValidator("unit", "partial", undefined, newValueParams),
    onCellClicked: PreventPlantOnCellClicked,
    suppressMovable: true,
    sortable: false,
    suppressMenu: true,
    width: 60,
  };

export const UnitFieldsTableColumnDefinition_Action: ColDef<FieldDataType> = {
  hide: false,
  headerName: "Action",
  field: "action",
  colId: "action",
  resizable: true,
  cellRenderer: ActionColumn,
  width: 86,
  suppressMovable: true,
  sortable: false,
  suppressMenu: true,
};

export const UnitFieldsTableColumnDefinitions: Array<ColDef<FieldDataType>> = [
  UnitFieldsTableColumnDefinition_FarmNumber,
  UnitFieldsTableColumnDefinition_PreviouslyReported,
  UnitFieldsTableColumnDefinition_SubField,
  UnitFieldsTableColumnDefinition_FieldAcres,
  UnitFieldsTableColumnDefinition_PlantedAcres,
  UnitFieldsTableColumnDefinition_PlantDate,
  UnitFieldsTableColumnDefinition_PreventPlant,
  UnitFieldsTableColumnDefinition_Action,
];
