import { Flex, Typography } from "@proag/sprout";

interface ProducersCountyHeaderCardProps {
    title: string;
    amount: number | undefined;
  }
  export const ProducersCountyHeaderCard: React.FC<
    ProducersCountyHeaderCardProps
  > = ({ title, amount }) => {
    return (
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{
          marginLeft: 0,
          marginRight: 6,
          padding: 1,
          border: "none",
          width: "150px",
        }}
      >
        <Typography
          className="body-sm"
          style={{ marginBottom: 0, fontSize: "60%" }}
        >
          {title} County
        </Typography>
        <Typography
          className="body-sm"
          style={{ marginBottom: 0, marginRight: 3, fontSize: "60%" }}
        >
          {amount?.toFixed(2)} Acres
        </Typography>
      </Flex>
    );
  };