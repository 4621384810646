import { NavigateFunction, useNavigate } from "react-router";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";

import { config } from "./config";
import { useRestoreOriginalUri } from "./useRestoreOriginalUri";

export const OktaAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const oktaAuth = new OktaAuth(config.oidc);
  const restoreOriginalUri = useRestoreOriginalUri(oktaAuth);

  // Start auth service (token auto renew, token auto remove, cross-tab sync. See: https://github.com/okta/okta-auth-js#running-as-a-service)
  oktaAuth.start();

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
