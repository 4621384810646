import { ApplicationRolesQueryResultItem } from "features/managed-acres/hooks";
import { useMemo } from "react";

export const mapApplicationRolesQueryResults = (applicationRoles: Array<ApplicationRolesQueryResultItem> | undefined) => {
  let isRoleAgent: boolean = false;
  let isRoleUnderwriter: boolean = false;
  let isRoleProducer: boolean = false;

  if (applicationRoles && applicationRoles.length > 0) {
    applicationRoles.forEach((e) => {
      if (e.roleName.trim() === "simplified-ar-agent") {
        isRoleAgent = true;
      }
      if (e.roleName.trim() === "simplified-ar-underwriter") {
        isRoleUnderwriter = true;
      }
      if (e.roleName.trim() === "simplified-ar-producer") {
        isRoleProducer = true;
      }
    });
  }

  var userRole = {
    isRoleAgent: isRoleAgent,
    isRoleUnderwriter: isRoleUnderwriter,
    isRoleProducer: isRoleProducer,
  };

  return userRole;
};
