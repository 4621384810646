import { getAwsRum } from "./awsRumWrapper";

interface ComponentStackInfo {
  componentStack: string;
}

export const handleErrorBoundaryError = (
  error: Error,
  componentStack: ComponentStackInfo
) => {
  awsRumErrorHandler(error, componentStack);
};

export const awsRumErrorHandler = (
  error: Error,
  info: ComponentStackInfo | undefined
) => {
  const awsRum = getAwsRum();
  if (awsRum) {
    if (info) {
      awsRum.addSessionAttributes({
        info_component_stack: info.componentStack,
      });
    }
    awsRum.recordError(error);
  }
};
