import { FC, useEffect, useRef } from "react";
import { defaultMapStyle } from "./mapConstants";
import {
  createGeoJsonMap,
} from "./mapUtils";

import styles from "./Map.module.scss";

export type GeoJsonMapProps = {
  geoData: string;
  onClick?: Function;
};

export const GeoJsonMap: FC<GeoJsonMapProps> = ({ geoData, onClick }) => {
  const landMap = useRef<HTMLDivElement>(null);
  const style = defaultMapStyle;

  // create a new blob from geojson featurecollection
  const blob = new Blob([geoData], {
    type: "application/json",
  });

  // URL reference to the blob
  const url = URL.createObjectURL(blob);

  useEffect(() => {
    if (landMap.current) {
      const { view, geoJsonLayer } = createGeoJsonMap(landMap, style, url);
      /*
      if (view.on) {
        view.on("click", (e) => {
          const mapEvent = e as MapMouseEvent;
          propagateWithItem(view, geoJsonLayer, mapEvent, onClick);
        });
      } */
    }
  }, [geoData]);

  return <div className={styles.landMap} ref={landMap}></div>;
};
