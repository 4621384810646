import { FieldDataType } from "features/managed-acres";

/**
 * Helper functions
 * */
export const hasSelectedCrop = (r: FieldDataType): boolean => {
  return !!r.coverageId.value;
};

export const hasSelectedUnit = (r: FieldDataType): boolean => {
  return !!r.unitId.value;
};

export const hasEnteredAcres = (r: FieldDataType): boolean => {
  return !!r.plantedAcres.value && r.plantedAcres.value > 0;
};

export const hasEnteredPlantedDate = (r: FieldDataType): boolean => {
  return !!r.plantDate.value;
};

export const hasCheckedPreventPlant = (r: FieldDataType): boolean => {
  // this is kind of pointless, but it gives consistency with the other helpers, and provides a means of
  // changing things in the future more easily if we need to.
  return !!r.preventedPlant.value;
};

export const isSplitField = (r: FieldDataType): boolean => {
  // this is kind of pointless, but it gives consistency with the other helpers, and provides a means of
  // changing things in the future more easily if we need to.
  return !!r.splitParentId;
  // if (r.splitParentId === null) return false;
  // else if (r.splitParentId === 0) return false;
  // else return true;
};
