import { LoadingSpinner } from "components/common/LoadingSpinner";
import {
  IPoliciesCrops,
  IPoliciesUnits,
  usePoliciesCropUnitsQuery,
} from "features/managed-acres";
import React, { useState } from "react";
import { useParams } from "react-router";
import { ProducersUnitsSidebarCoverageCard } from ".";
import styles from "./producers-units-sidebar.module.scss";
import {
  IProducersFilterData,
  ProducersFilter,
} from "components/search/producers-filter";
import { useAppStateStore } from "../../../../app/state-management/use-app-state-store";

interface ProducersUnitsSidebarProps {
  onFilterChange: (filterData: IProducersFilterData) => void;
  units: Array<IPoliciesUnits> | undefined;
  crops: Array<IPoliciesCrops> | undefined;
  setSelectedUnit(unitId: number): void;
}

export const ProducersUnitsSidebar: React.FC<ProducersUnitsSidebarProps> = ({
  onFilterChange,
  units,
  crops,
  setSelectedUnit,
}) => {
  let { pawPolicyOid } = useParams();

  const [sideBarStyle, setSideBarStyle] = useState<any>({
    style: { height: `calc(100vh - ${210}px)` },
  });

  const { isCropUnitsFetching } = usePoliciesCropUnitsQuery(+pawPolicyOid!);
  const selectedUnitId = useAppStateStore((s) => s.selectedUnitId);
  const showSpinner = isCropUnitsFetching;

  const onSelectedUnit = (selectedUnitId: number) => {
    setSelectedUnit(selectedUnitId);
  };

  return (
    <>
      {showSpinner ? (
        <LoadingSpinner />
      ) : (
        <div
          className={styles.leftColumnContent}
          style={{
            ...sideBarStyle.style,
          }}
          data-testid="producers-units-fields-sidebar"
        >
          <ProducersFilter
            onFilterChange={onFilterChange}
            showHideEmpty={true}
          />
          <div className="px-3 pt-2 pb-3">
            {crops?.map((crop, index) => {
              return (
                <ProducersUnitsSidebarCoverageCard
                  isSelected={false}
                  units={
                    units
                      ? units.filter(
                          (unit) => unit.coverageId == crop.coverageId
                        )
                      : []
                  }
                  key={index}
                  setSelectedUnit={onSelectedUnit}
                  coverage={crop}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
