import { Typography } from "@proag/sprout";
import { usePoliciesProducerQuery } from "features/managed-acres";
import { startCase, toLower } from "lodash";
import React from "react";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { useParams } from "react-router";

export const ProducersNamePolicyNumber: React.FC<{}> = () => {
  const policyId = useAppStateStore((s) => s.policyId);

  const { producer } = usePoliciesProducerQuery(policyId);

  return (
    <>
      <div className="mt-2">
        {!!producer && (
          <Typography className="head-sm" style={{ marginBottom: "0px" }}>
            {startCase(toLower(producer.displayName))}
          </Typography>
        )}
        {!!producer && (
          <Typography
            className="head-sm"
            style={{ marginBottom: "0px", fontSize: "90%" }}
          >
            {producer.policyNumber}
          </Typography>
        )}
      </div>
    </>
  );
};
