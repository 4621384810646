import { isEmpty } from "lodash";
import { FieldDataType } from "./FieldDataType";

const cellStyle = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#f1f2f2",
  paddingLeft: ".75rem",
};

export interface AcreageSummary {
  [x: string]: any;
  totalAcres: number;
  reportedAcres: number;
  PPAcres: number;
}

export const calculateAcreageTotals = (
  data?: FieldDataType[]
): AcreageSummary => {
  let nextAverageData: AcreageSummary = {} as AcreageSummary;
  if (data) {
    nextAverageData = [...data].reduce(
      (acreage: AcreageSummary, current: FieldDataType) => {
        let currentPlantedAcres = current.plantedAcres?.value
          ? parseFloat(current.plantedAcres.value?.toString() ?? "")
          : 0;
        if (!acreage.reportedAcres) {
          acreage.reportedAcres = 0;
        }
        if (!acreage.totalAcres) {
          acreage.totalAcres = 0;
        }
        if (!acreage.PPAcres) {
          acreage.PPAcres = 0;
        }

        if (
          current.splitParentId === undefined ||
          current.splitParentId === null ||
          current.splitParentId === 0
        ) {
          acreage.totalAcres += current.acres;
        }

        acreage.reportedAcres += currentPlantedAcres;
        if (current.preventedPlant?.value) {
          acreage.PPAcres += currentPlantedAcres;
        }

        if (acreage.hasOwnProperty(current.coverageId.value ?? 0)) {
          acreage[current.coverageId.value ?? 0] += !current.preventedPlant
            ?.value
            ? currentPlantedAcres
            : 0;
        } else {
          if (current.coverageId?.value) {
            acreage[current.coverageId.value] = !current.preventedPlant?.value
              ? currentPlantedAcres
              : 0;
          }
        }

        return { ...acreage };
      },
      {} as AcreageSummary
    );
  }
  return nextAverageData;
};

export const setColumns = (nextAcreageData: AcreageSummary) => {
  const nextAverageDataColumnDef = [
    {
      field: "totalAcres",
      cellStyle: cellStyle,
      flex: 1,
    },
    {
      field: "reportedAcres",
      cellStyle: cellStyle,
      flex: 1,
    },
    {
      field: "PPAcres",
      cellStyle: cellStyle,
      flex: 1,
    },
  ];
  if (!isEmpty(nextAcreageData)) {
    for (let [key, value] of Object.entries(nextAcreageData)) {
      const hasColumn = nextAverageDataColumnDef.findIndex(
        (col) => col.field === key
      );

      if (hasColumn < 0) {
        nextAverageDataColumnDef.push({
          field: key,
          cellStyle: cellStyle,
          flex: 1,
        });
      }
    }
  }
  return nextAverageDataColumnDef;
};
