import {Button, Flex, Typography} from "@proag/sprout";
import {PrivacyStatementsFooter} from "../../../../components/ui/privacy-statements-footer";

interface ProducersUnitsFieldsBodyFooterProps {
  isFieldsSaving: boolean | undefined;
  previousUnitId: number;
  nextUnitId: number;
  doNav: any;
}

export const ProducersUnitsFieldsBodyFooter: React.FC<
  ProducersUnitsFieldsBodyFooterProps
> = ({ isFieldsSaving, previousUnitId, nextUnitId, doNav }) => {
  return (
    <>
      <Flex className="mb-1" justifyContent="space-between">
        <Flex className="align-flex-start" direction="column"></Flex>
        <Flex direction="column" alignItems="flex-end">
          <Flex direction="row" alignItems="flex-end">
            {!isFieldsSaving && previousUnitId > 0 &&
              <Button size="sm"
                variant="neutral-outlined"
                leftIcon="ArrowLeft"
                className="mr-2"
                data-testid="SaveAndPrevious"
                onClick={() => {
                  doNav(previousUnitId);
                }}
              >
                Previous
              </Button>
            }

            {!isFieldsSaving && nextUnitId > 0 &&
              <Button size="sm"
                variant="neutral-outlined"
                rightIcon="ArrowRight"
                data-testid="SaveAndNext"
                onClick={() => {
                  doNav(nextUnitId);
                }}
              >
                Next
              </Button>
            }
            {isFieldsSaving && <Button leftIcon="Activity">Saving ...</Button>}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
