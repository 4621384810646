import { Button, Flex } from "@proag/sprout";

interface ProducersUnitsEditFooterProps {
  onSave: () => void;
  onCancel: () => void;
  isSaveDisabled: boolean;
}

export const ProducersUnitsEditFooter: React.FC<
  ProducersUnitsEditFooterProps
> = ({ onSave, onCancel, isSaveDisabled }) => {
  return (
    <>
      <Flex className="mb-1" justifyContent="space-between">
        <Flex className="align-flex-start" direction="column"></Flex>
        <Flex direction="column" alignItems="flex-end">
          <Flex direction="row" alignItems="flex-end">
            <Button
              size="sm"
              variant="neutral-outlined"
              className="mr-2"
              data-testid="unit-edit-cancel"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              data-testid="unit-edit-save"
              disabled={isSaveDisabled}
              variant={isSaveDisabled ? "neutral-tonal" : "brand-solid"}
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
