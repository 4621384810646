import { FC, useEffect, useState } from "react";
import { GeoJsonMap } from "components/maps/geo-json-map";
import { usePoliciesAllFarmsShapesQuery } from "features/planted-acres/review/hooks/policies/policies-queries";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { UpdateMapEffect } from "components/maps/mapUtils";

export interface ProducersAllFarmsFieldsMapProps {}

export const ProducersAllFarmsFieldsMap: FC<
  ProducersAllFarmsFieldsMapProps
> = ({}) => {
  const policyId = useAppStateStore((s) => s.policyId);
  const [geoJsonCluShapes, setGeoJsonCluShapes] = useState("");
  const { farmsShapes, isAllFarmsShapesFetching } =
    usePoliciesAllFarmsShapesQuery(policyId);

  useEffect(() => {
    UpdateMapEffect(farmsShapes, setGeoJsonCluShapes)
  }, [farmsShapes]);

  return (
    <>
      <div
        style={{ width: "100%", height: "480px" }}
        data-testid="ppar-all-farms-map"
      >
        {!isAllFarmsShapesFetching && <GeoJsonMap geoData={geoJsonCluShapes} />}
      </div>
    </>
  );
};
