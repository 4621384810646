import { useQuery } from "@tanstack/react-query";
import { ApiGateway, FieldManagement, useApiGateway } from "app/apiGateway";

export type RmaAdmCountiesResponse = Array<RmaAdmCounty>;

export type RmaAdmCounty = {
  countyId: number;
  stateCode: string;
  countyCode: string;
  countyName: string;
  recordTypeCode: string;
  recordCategoryCode: string;
  reinsuranceYear: number;
  lastReleasedDate: Date;
  releasedDate: Date;
  deletedDate: Date;
  createdBy: string;
  createdDate: Date;
  modifiedBy: string;
  modifiedDate: Date;
};

const getRmaAdmCounties = async (apiGateway: ApiGateway, ry: number) => {
  let response = await apiGateway.get(
    `rma-adm/counties?reinsuranceYear=${ry}`,
    FieldManagement
  );

  let countiesResponse: RmaAdmCountiesResponse =
    response as RmaAdmCountiesResponse;

  return countiesResponse;
};

/*
    Purpose of this query is to provide a cached result
    set of the RmaAdm counties dataset.
*/
export const useRmaAdmCountiesQuery = (ry: number) => {
  const apiGateway = useApiGateway();

  const { data, isFetching, refetch } = useQuery<RmaAdmCountiesResponse>({
    queryKey: ["RmaAdmCounties", ry],
    staleTime: 100 * 60 * 1000, //100 minutes
    queryFn: async () => await getRmaAdmCounties(apiGateway, ry),
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: () => console.log("rma-adm counties success"),
  });

  return {
    rmaAdmCounties: data,
    isRmaAdmCountiesFetching: isFetching,
  };
};

export const rmaAdmCountiesQueryExportedForTesting = {
  getRmaAdmCounties,
};
