import { colors } from "@proag/sprout";
import { PoliciesAcresObserveQueryResult } from "features/planted-acres/review/hooks/policies/models/policies-acres-observed-query-result";
import { ProducersReviewAcreObservationDiscrepanciesCard } from "./producers-planted-acres-review-acre-observation-discrepancies-card";

interface ProducersReviewAcreObservationDiscrepanciesProps {
  acreObservationDiscrepancies: PoliciesAcresObserveQueryResult | undefined;
  setHasChanges: (isValid: boolean) => void;
  discrepancyCount: number;
  updateDiscrepancyCount: (hideDiscrepancyCount: number) => void;
}

export const ProducersReviewAcreObservationDiscrepancies: React.FC<
  ProducersReviewAcreObservationDiscrepanciesProps
> = ({ acreObservationDiscrepancies, setHasChanges, discrepancyCount, updateDiscrepancyCount }) => {
  return (
    <div data-testid="acre-observation-discrepencies">
      <div className="label-md mb-4" style={{ color: colors.neutral40 }}>
        Earth observations noticed some discrepancies between what was entered
        and what was observed
      </div>

      <div className="label-md mb-1">
        {discrepancyCount} unresolved discrepancies
      </div>
      {!!acreObservationDiscrepancies?.acresObserved.filter(
        (w) => w.hasDiscrepancies == true
      ) && (
        <div>
          {acreObservationDiscrepancies?.acresObserved
            .filter((w) => w.hasDiscrepancies == true)
            .map((detail, index) => {
              return (
                <div key={index}>
                  <ProducersReviewAcreObservationDiscrepanciesCard
                    acresObservedDetail={detail}
                    setHasChanges={setHasChanges}
                    updateDiscrepancyCount={updateDiscrepancyCount}
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
