import "@arcgis/core/assets/esri/themes/light/main.css";
import { LoginCallback } from "@okta/okta-react";
//import "@proag/sprout";
import { SproutStyleProvider } from "@proag/sprout";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorFallback, handleErrorBoundaryError } from "app/errorBoundary";
import { OktaAuthProvider } from "app/okta/OktaAuthProvider";
import { SecureRoutes } from "app/okta/SecureRoutes";
import { ReactQueryProvider } from "app/utils/ReactQueryProvider";
import { LoadingSpinner } from "components/common/LoadingSpinner";
import Layout from "components/layout/Layout";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes } from "react-router-dom";
import { routePaths } from "services/routing/routes";
import Contact from "pages/contact";
import Home from "pages/home";
import "./global.scss";
import { ProducersUnitsFields } from "pages/producers-units-fields/producers-units-fields";
import { ProducersFarmsFields } from "pages/producers-farms-fields/producers-farms-fields";
import { ProducersPlantedAcresReview } from "pages/producers-planted-acres-review/producers-planted-acres-review";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey((window as any).globalConfig?.AGGridLicenseKey);

export default () => {
  return (
    <ReactQueryProvider>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={handleErrorBoundaryError}
      >
        <ReactQueryDevtools initialIsOpen={false} />
        <OktaAuthProvider>
          <SproutStyleProvider>
            <Layout>
              <Routes>
                <Route
                  path={routePaths.login}
                  element={
                    <LoginCallback loadingElement={<LoadingSpinner />} />
                  }
                />
                <Route element={<SecureRoutes />}>
                  <Route path={routePaths.home} element={<Home />} />
                  <Route path={routePaths.contact} element={<Contact />} />
                  <Route path="*" element={<Navigate to={routePaths.home} />} />
                  <Route
                    path={routePaths.producerFarms}
                    element={<ProducersFarmsFields />}
                  />
                  <Route
                    path={routePaths.producerUnits}
                    element={<ProducersUnitsFields />}
                  />
                  <Route
                    path={routePaths.producerPlantedAcresReview}
                    element={<ProducersPlantedAcresReview />}
                  />
                </Route>
              </Routes>
            </Layout>
          </SproutStyleProvider>
        </OktaAuthProvider>
      </ErrorBoundary>
    </ReactQueryProvider>
  );
};
