export const FIELDDATA = {
  PLANTDATE: "plantDate",
  COVERAGEID: "coverageId",
  UNITID: "unitId",
  PREVENTEDPLANT: "preventedPlant",
  PLANTEDACRES: "plantedAcres",
  FIELDNUMBER: "fieldNumber",
  SUBFIELD: "subField",
  ACRES: "acres",
  PRIORINSURED: "priorInsured",
  PASTFINALPLANTDATE: "pastFinalPlantDate",
  UNITNUMBER: "unitNumber",
  UNINSUREDREASONCODE: "uninsuredReasonCode",
  ARTYPE: "arType",
  FARM: "farm",
  ACTION: "action",
  PREVIOUSLYREPORTED: "previouslyReported",
} as const;

export const PLANTINGSEASON = {
  SPRING: "S",
  FALL: "F",
} as const;

export const ACREAGEREPORTTYPES = {
  FARMBASED: "Farm Based",
  UNITBASED: "Unit Based",
} as const;

export const SUBFIELDPREFIX = {
  DEFAULT: "sub",
  AUTOGENERATED: "$@U_",
} as const;

export const ACREAGEVALIDATIONSTATUS = {
  DEFAULT: 0,
  PROCESSING: 1,
  ERROR: 2,
  SUCCESS: 3,
} as const;

export const REINSURANCE_YEAR = {
  RY2024: 2024,
  RY2025: 2025,
  RY2026: 2026
} as const;

export const WARNINGMESSAGES = {
  MUS_EOQUALIFICATION: "You do not qualify for EO on this crop",
  MUS_QUALIFICATION_ALERT: "You may not qualify for selected unit structure."
} as const;

export const INFO_MESSAGES = {
  MUS_EOREMOVE: "EO will be removed from the unit options."
} as const;

export const UNIT_OPTION_PRACTICE_CODES = {
  ORG: 1,
  NONORG: 2,
  IRR: 3,
  NIRR: 4,
  BOTH: 5
} as const;

export const BUTTON_TEXT = {
  ReviewUnitStructure: "Review Unit Structure",
  UnitStructure : "Unit Structure"
} as const

export const TOOLTIP_TEXT = {
  TEXT_1: "System defaulted unit structure to",
  TEXT_2: "Sections do not meet qualifying criteria for EO."
}

export const NUMERIC_INPUT ={
  MIN : 2,
  MAX : 99999
} as const

export const UNIT_STRUCTURE_CODES = {
  EU_by_Organic_Prac : "EO" 
} as const