import {
  Button as RBButton,
  ButtonProps as RBButtonProps,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { Button as SButton } from "@proag/sprout";

interface ButtonProps extends RBButtonProps {
  label: string;
  loading?: boolean;
  tooltipMessage?: string;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  loading,
  tooltipMessage,
  disabled,
  variant,
  ...props
}) => {
  return (
    <SButton disabled={disabled || loading} {...props}>
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          variant={variant == "primary" ? "white" : "primary"}
        />
      ) : (
        label
      )}
    </SButton>
  );
};
