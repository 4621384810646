import { useOktaAuth } from "@okta/okta-react";
import {
  AcresSummary,
  ProducersFarmsFieldsBody,
  ProducersFarmsSidebar,
  filterFarms,
  mapAcresResultsToAcresSummary,
  useApplicationRolesQuery,
  usePoliciesAcresQuery,
  usePoliciesCropUnitsQuery,
  usePoliciesFarmsQuery,
  useRmaAdmCountiesQuery,
  useRmaAdmStatesQuery,
} from "features/managed-acres";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMetricsCreateEventPublish } from "app/analytics";
import styles from "./producers-farms-fields.module.scss";
import { useQueryClient } from "@tanstack/react-query";
import {
  ProducersBaseFooter,
  ProducersPolicyHeader,
  SideBarDisplayTabs,
} from "components/ui";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { usePolicyIdFromRoute } from "app/utils/policy-utils";
import { IProducersFilterData } from "components/search/producers-filter";
import { PrivacyStatementsFooter } from "../../components/ui/privacy-statements-footer";
import { PoliciesFarmsQueryResult } from "features/managed-acres/hooks/policies/models/policies-farms-query-result";
export const ProducersFarmsFields: React.FC = ({ }) => {
  /*** Pre-loaded data sets  ***/
  const { rmaAdmStates, isRmaAdmStatesFetching } = useRmaAdmStatesQuery();
  const { rmaAdmCounties, isRmaAdmCountiesFetching } = useRmaAdmCountiesQuery(
    new Date().getFullYear()
  );
  const { ApplicationRolesData } = useApplicationRolesQuery();
  /*** End                   ***/

  usePolicyIdFromRoute();

  const policyId = useAppStateStore((s) => s.policyId);
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);
  const setSelectedFarmId = useAppStateStore((s) => s.setSelectedFarmId);
  const selectedFarmId = useAppStateStore((s) => s.selectedFarmId);
  const isFarmGridValid = useAppStateStore((s) => s.isFarmGridValid);
  const reinsuranceYear = useAppStateStore((s) => s.reinsuranceYear);
  const setReinsuranceYear = useAppStateStore((s) => s.setReinsuranceYear);
  const [isPageLoadEventSaved, setIsPageLoadEventSaved] = useState(false);
  const [pageLoadStartDateTime] = useState(new Date().toUTCString());
  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  const queryClient = useQueryClient();

  const onSelectedFarmHandler = (farmId: number, isDirty?: boolean) => {
    if (selectedFarmId != farmId) {
      if (!isFarmGridValid) {
        toast.error(
          "Please resolve errors in your data before switching farms.",
          {
            toastId: "SelectedFarmNavigateGridValidCheck",
          }
        );
      } else {
        // before we hit the next page, make sure we clear the cache so that we can get new data
        queryClient.invalidateQueries(["policiesFarmsFieldsQuery", policyId]); // this will clear ALL policies fields data, not just a single farms.
        queryClient.invalidateQueries([
          "policiesAcresQuery",
          policyId,
          plantingSeason,
        ]);

        setSelectedFarmId(farmId);
      }
    }
  };

  const [farmsFilterData, setFarmsFilterData] = useState({
    filterType: "",
    filterValue: "All",
    hideEmpty: false,
  } as IProducersFilterData);
  const [farmData, setFarmData] = useState<PoliciesFarmsQueryResult[]>([]);
  const { farms, isFarmsFetching } = usePoliciesFarmsQuery(policyId);
  useEffect(() => {
    if (!!farms) {
      setFarmData(farms);
    }
  }, [farms]);
  const { acres, isAcresFetching } = usePoliciesAcresQuery(policyId);

  const { cropUnits } = usePoliciesCropUnitsQuery(Number(policyId));

  let ry = Number(cropUnits?.crops[0]?.reinsuranceYear!);

  const onFilterChangeHandler = (filterData: IProducersFilterData) => {
    setFarmsFilterData(filterData);
  };

  let mappedAcres: AcresSummary | undefined = undefined;

  if (!!acres) {
    mappedAcres = mapAcresResultsToAcresSummary(acres);
  }

  useEffect(() => {
    if (reinsuranceYear != ry && cropUnits?.crops?.length! > 0) {
      setReinsuranceYear(ry);
    }
  }, [ry]);

  const filteredFarms = filterFarms(
    farmsFilterData,
    farmData || [],
    acres,
    cropUnits
  );

  var previousFarmId = 0;
  var nextFarmId = 0;

  if (filteredFarms) {
    var farmIndex = filteredFarms.findIndex((x) => x.farmId == selectedFarmId);

    previousFarmId =
      filteredFarms && farmIndex - 1 >= 0
        ? filteredFarms[farmIndex - 1].farmId
        : 0;
    nextFarmId =
      filteredFarms && farmIndex + 1 < filteredFarms.length
        ? filteredFarms[farmIndex + 1].farmId
        : 0;
  }

  const setPageLoadEndDateTime = (pageLoadEndDateTime: string) => {
    if (!isPageLoadEventSaved) {
      savePageLoadDuration(pageLoadEndDateTime);
    }
  };
  const onFarmDeleteHandler = (deletedFarmId: number) => {
    if (deletedFarmId > 0) {
      //Remove the deleted farm from the farm list
      var newFarmList = farmData.filter((f) => f.farmId != deletedFarmId);
      setFarmData(newFarmList);
      //Set the selected farm based on next and prev farm id
      if (newFarmList.length > 0) {
        if (selectedFarmId == deletedFarmId) {
          var newSelectedFarmId = 0;
          if (nextFarmId != 0) {
            var nextFarm = newFarmList.find((f) => f.farmId == nextFarmId);
            if (nextFarm) {
              newSelectedFarmId = nextFarm.farmId;
            }
          }
          if (newSelectedFarmId == 0 && previousFarmId != 0) {
            var prevFarm = newFarmList.find((f) => f.farmId == previousFarmId);
            if (prevFarm) {
              newSelectedFarmId = prevFarm.farmId;
            }
          }
          if (newSelectedFarmId == 0) {
            newSelectedFarmId = newFarmList[0].farmId;
          }
          onSelectedFarmHandler(newSelectedFarmId, false);
        }
      } else {
        setSelectedFarmId(0);
      }

      // clear the cache so that we can get new data
      queryClient.invalidateQueries(["policiesFarmsFieldsQuery", policyId]);

      queryClient.invalidateQueries([
        "policiesFarmsQuery",
        policyId,
        plantingSeason,
      ]);
      queryClient.invalidateQueries([
        "policiesCropUnitsQuery",
        policyId,
        plantingSeason,
      ]);
    }
  };

  const savePageLoadDuration = async (pageLoadEndDateTime: string) => {
    if (pageLoadStartDateTime && pageLoadEndDateTime) {
      recordMetric({
        name: "producer-farm-fields-page-load-time",
        data: {
          category: "ProducersFarmsFields",
          label: "Producers Farm Fields Page Load Time",
          pageLoadStartDateTime: pageLoadStartDateTime,
          pageLoadEndDateTime: pageLoadEndDateTime,
        },
      });
      setIsPageLoadEventSaved(true);
    }
  };

  useEffect(() => {
    recordMetric({
      name: "producers-farms-fields-view",
      data: {
        category: "ProducersFarmsFields",
        label: "Producers Farm Fields View",
        action: "View",
      },
    });
  }, [recordMetric]);

  return (
    <>
      <div className={styles.pageWrapper}>
        <div className={styles.pageHeader} id="producers-header">
          <ProducersPolicyHeader
            acresSummaryData={mappedAcres}
            acresSummaryDataIsFetching={isAcresFetching}
          />
          <div>
            <SideBarDisplayTabs />
          </div>
        </div>
        <div className={styles.pageContent}>
          <div className={styles.leftColumn}>
            <ProducersFarmsSidebar
              onSelectedFarm={onSelectedFarmHandler}
              acresDetails={acres}
              onFilterChange={onFilterChangeHandler}
              filteredFarms={filteredFarms}
              onFarmDeleted={onFarmDeleteHandler}
            />
          </div>
          <div className={`${styles.rightColumn} p-3`}>
            <ProducersFarmsFieldsBody
              nextFarmId={nextFarmId}
              previousFarmId={previousFarmId}
              onSelectedFarm={onSelectedFarmHandler}
              setPageLoadEndDateTime={setPageLoadEndDateTime}
            />
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <PrivacyStatementsFooter />
      </div>
      <ProducersBaseFooter />
    </>
  );
};
