import React from "react";
import {colors, Flex, getSproutColor} from "@proag/sprout";
import Switch from "react-switch";

interface CropTotalsProps {
  showCropTotals: boolean;
  toggleShowCropTotals: (newValue?: boolean | undefined) => void;
}

export const CropTotalsToggle : React.FC<CropTotalsProps> = ({showCropTotals, toggleShowCropTotals}) => {

  return (
    <>
      <Flex direction="column" alignItems="flex-end" justifyContent="space-between">
        <Flex direction="row" >
          <div className="label-md mb-3" style={{ color: colors.neutral40, paddingRight: 10 }}>
            Crop Totals
          </div>
          <Switch className="mb-3"
            data-testid="pff-crop-totals-switch"
            width={36}
            height={20}
            onChange={toggleShowCropTotals}
            checked={showCropTotals}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={getSproutColor("brandGreen")}
          />
        </Flex>

      </Flex>

    </>
  )
}
