import { Flex, Sheet } from "@proag/sprout"
import { ManageUnitStructureFooter } from "components/ui/manage-unit-structure-footer"
import { UnitStructureReviewCoveragesSidebar } from "./unit-structure-review-coverages-sidebar"
import { useEffect, useState } from "react"
import { PoliciesCoveragesUnitStructureQueryResult } from "features/managed-acres/hooks/policies/models/policies-coverages-unitstructure-query-result"
import { useAppStateStore } from "app/state-management/use-app-state-store"
import { usePoliciesCoveragesUnitStructurePost } from "features/managed-acres/hooks/policies/policiesCommands"
import { PoliciesCoveragesUnitStructureUpdate } from "features/managed-acres/hooks/policies/models/policies-coverages-unitstructure-update-command"
import { toast } from "react-toastify"
import styles from "./producers-planted-acres-review-units-sidebar-card.module.scss";
import { Feature147540, useFeatureFlag } from "../../../../app/feature-flag/useFeatureFlag";
import  { OptionCodeUpdate, PoliciesCoveragesOverrideUnitStructureQueryResult } from "features/managed-acres/hooks/policies/models/policies-coverages-unitstructure-override-query-result";

interface UnitStructureReviewSidebarSheetProps {
    show: boolean
    handleClose: () => void
    handleSave: (data:OptionCodeUpdate[]) => void
    coverageId?: number
    isCollapse?: boolean
    onSaveRefresh : ()=> void
 }


export const UnitStructureReviewSidebarSheet: React.FC<UnitStructureReviewSidebarSheetProps> =
    ({ show, handleClose,handleSave, coverageId, isCollapse,onSaveRefresh }) => {
        const policyId = useAppStateStore((s) => s.policyId);
        const [coveragesToUpdate, setCoveragesToUpdate] = useState<PoliciesCoveragesUnitStructureQueryResult[]>()
        const { mutate, isMutateUnitStructureSuccess, isMutateUnitStructureLoading, mutateUnitStructureResult } = usePoliciesCoveragesUnitStructurePost(policyId, onSaveSuccess, onSaveError)
        const { featureFlag: isEOUnitStructureEnabled } = useFeatureFlag(Feature147540);
        
        useEffect(() => {
            if (mutateUnitStructureResult) {
                onSaveRefresh()
                var result = mutateUnitStructureResult as PoliciesCoveragesOverrideUnitStructureQueryResult
                if (result && result.validationMessage) {
                    if (result.validationMessage.errorDetails && result.validationMessage.errorDetails.length > 0) {

                        result.validationMessage.errorDetails.forEach(error => {
                            var cov = coveragesToUpdate?.find(c => c.coverageID == error.id)
                            var err = "County :" + cov?.countyName + ", Commodity :" + cov?.commodityName + " -" + error.message
                            showErrorToast(err)
                        });
                    }
                    else {
                        showSuccessToast("Saved Successfully.")
                    }
                    if(result.optionCodeUpdateList && result.optionCodeUpdateList.length > 0){
                        handleSave(result.optionCodeUpdateList)
                    }
                }
            }
            if (mutateUnitStructureResult == null && isMutateUnitStructureSuccess) {
                onSaveRefresh()
                showSuccessToast("Saved Successfully.")
            }
            setCoveragesToUpdate([])
        }, [mutateUnitStructureResult])

        const CoveragesUnitStructureToUpdate = (updatedCov: PoliciesCoveragesUnitStructureQueryResult): void => {
            let coverages = coveragesToUpdate ?? []
            let coverage = coveragesToUpdate?.find(c => c.coverageID == updatedCov.coverageID)
            if (coverage) {
                coverages?.map(c => {
                    if (c.coverageID == updatedCov.coverageID) {
                        c = updatedCov;
                    }
                })
            }
            else {
                coverages.push(updatedCov)
            }
            setCoveragesToUpdate(coverages)
        }

        function onSaveSuccess() {
          
        }

        function onSaveError() {
            showErrorToast("Error while saving data.")
        }

        function showSuccessToast(msg: string) {
            toast.success(msg, {
                toastId: 'success',
                position: "top-right"
            })
        }
        function showErrorToast(msg: string) {
            toast.error(msg, {
                toastId: 'error',
                position: "top-right"
            })
        }
        const saveCoverages = (): void => {
            if (coveragesToUpdate) {
                let updatedCoverages: PoliciesCoveragesUnitStructureUpdate[] = []
                coveragesToUpdate.map((c, index) => {
                    if (c.coverageUnitStructureOptionOverride?.overrideSelection && c.coverageUnitStructureOptionOverride?.overrideSelection.length > 0) {
                        let updated: PoliciesCoveragesUnitStructureUpdate = {
                            coverageID: c.coverageID,
                            pawCoverageId: c.pawCoverageId,
                            overrideSelection: c.coverageUnitStructureOptionOverride?.overrideSelection ?? []
                        }
                        updatedCoverages.push(updated)
                    }

                })
                if (updatedCoverages.length > 0) {
                    mutate(updatedCoverages)
                    
                }

            }
        }

        return (
            <>
            {isEOUnitStructureEnabled &&
                <Sheet
                    setVisible={handleClose}
                    title="Unit Structure Review"
                    visible={show}
                    size="500px"
                    footer={
                        <ManageUnitStructureFooter onCancel={handleClose} onSave={() => { saveCoverages() }} />
                    }
                >
                    <div>
                        {(isMutateUnitStructureLoading && 
                        <Flex>
                            <div className={styles.loading_state}>
                                <div className={styles.loading}></div>
                            </div>
                        </Flex>)}
                        {isEOUnitStructureEnabled && <UnitStructureReviewCoveragesSidebar
                            isCollapse={show}
                            showAlert={show}
                            coverageId={coverageId}
                            CoveragesUnitStructureToUpdate={CoveragesUnitStructureToUpdate}
                            isSaved={isMutateUnitStructureSuccess}
                        />
                        }
                    </div>
                </Sheet>
                }
            </>
        )
    }



