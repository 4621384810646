import { Alert, Button, Dialog, Typography } from "@proag/sprout";
import { ApiGateway, FieldManagement, useApiGateway } from "app/apiGateway";
import {
  ACREAGEREPORTTYPES,
  ACREAGEVALIDATIONSTATUS,
} from "app/common/constants";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { usePoliciesCropUnitsQuery } from "features/managed-acres/hooks/policies/policiesQueries";
import { useEffect, useState } from "react";
import { InputCheckBox } from "./inputs/input-checkbox";
import { ReportType } from "./inputs/report-type";
import { Tooltip } from "react-tooltip";
import {
  usePoliciesAgencyAgentProducerQuery,
  usePoliciesFarmsAcreageStatus,
} from "../hooks/policies/policies-queries";
import { useAcreageReportDocumentEnvelopesQuery } from "../hooks/acreage-reports/document-envelope-queries";
import { LoadingSpinner } from "../../../../components/common/LoadingSpinner";
import { toast } from "react-toastify";
import { DocumentEnvelopeQuery } from "../hooks/acreage-reports/models/document-envelope-query";
import { useMetricsCreateEventPublish } from "app/analytics";
import { PollApiUntil, PollingOptions } from "app/utils/polling";
import { FileSaveAs } from "app/utils/file-save-as";
import { handleResponse } from "app/apiGateway/utils/apiUtils";

export interface ESignData {
  customFields: {
    agentEmail: string;
    growerEmail: string;
    pawAgentId: string;
    pawGrowerId: string;
    pawPolicyId: string;
    plantingSeason: string;
  };
  envelopeConfig: {
    AgentEmailAddress: string;
    AgentName: string;
    ProducerEmailAddress: string;
    ProducerName: string;
  };
}

export interface AcreageReportState {
  policyOid: number;
  acreageReportId: number;
  reinsuranceYear: number;
  levelOfDetail: number;
  reportType: number;
  displayUnmaskedTaxIds: boolean;
  commodityCodes: string[];
  includeExtraBlankArPage: boolean;
  omitZeroArLines: boolean;
  revisedAcreageReport: boolean;
  plantingSeason: string;
  eSignData?: ESignData;
}

export const ProducersReviewGenerateAcreageReport: React.FC = ({}) => {
  const apiGateway = useApiGateway();

  const policyId = useAppStateStore((s) => s.policyId);
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);
  let reinsuranceYear = "2024";
  const [alertVisible, setAlertVisible] = useState(false);
  const [showESignatureNotAllowed, setShowESignatureNotAllowed] =
    useState(false);
  const [eSignatureNotAllowedMessage, setESignatureNotAllowedMessage] =
    useState<string>("");
  const [eSignatureStatus, setESignatureStatus] = useState("");
  const [isESignDocumentSending, setIsEsignDocumentSending] = useState(false);

  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  const { cropUnits } = usePoliciesCropUnitsQuery(Number(policyId));
  const commodityCodes = cropUnits?.crops?.map((c) => c.commodityCode);

  const hasAllPastAcreageReportingDates =
    cropUnits?.units?.filter((unit) => unit.pastAcreageReportingDate)?.length ==
    cropUnits?.units?.length;

  const firstReinsuranceYear = cropUnits?.crops?.map(
    (c) => c.reinsuranceYear
  )[0];
  reinsuranceYear = firstReinsuranceYear
    ? firstReinsuranceYear
    : reinsuranceYear;

  const [myState, setMyState] = useState<AcreageReportState>({
    policyOid: policyId,
    acreageReportId: 0, // override on load
    reinsuranceYear: Number(reinsuranceYear),
    levelOfDetail: 3, //Hardcoded for now
    reportType: 0,
    commodityCodes: commodityCodes ?? [],
    displayUnmaskedTaxIds: false,
    includeExtraBlankArPage: false,
    omitZeroArLines: false,
    revisedAcreageReport: false,
    plantingSeason: plantingSeason ?? "S",
  });

  const handleSelected = (eventKey: any) => {
    setMyState((prevState) => ({
      ...prevState,
      reportType: eventKey == ACREAGEREPORTTYPES.FARMBASED ? 0 : 1,
      plantingSeason: plantingSeason ?? "S",
    }));
  };

  const generateDocument = async (eSignData?: ESignData): Promise<boolean> => {
    // first build our data model out.
    let model: AcreageReportState = myState;
    model.eSignData = eSignData;

    model.policyOid = policyId; //sometimes the policyId is not available in the state, override now just to be safe

    // then send message to /policies/1234/actions/generate-acreage-report
    let testLocal = false;
    let data: any;
    if (!testLocal) {
      data = await apiGateway.post(
        `policies/${policyId}/actions/generate-acreage-report`,
        FieldManagement,
        model
      );
    } else {
      let url = `https://localhost:7172/policies/${policyId}/actions/generate-acreage-report`;
      const fetchProps: RequestInit = {
        method: "GET",
        headers: { applicationId: "FieldManagement" },
      };

      const response = await fetch(url, fetchProps);
      data = await handleResponse(response);
    }

    // we should verify the data status before we continue
    if (data.type === "application/problem+json") {
      let txt = await data.text();
      toast.error("Error generating report.  " + txt);
      return false;
    }

    return true;
  };

  const handleDownloadPdf = async () => {
    let fileName: string = "AcreageReport";
    console.log("function policyId", policyId);

    // we should check if the report is already generated
    let arStatus = await PoliciesAcreageReportApi(
      apiGateway,
      policyId,
      acreageStatusData?.acreageReportId ?? 0
    );

    setReportProcessing(true);

    recordMetric({
      name: "producers-planted-acres-review-download-report",
      data: {
        category: "ProducersPlantedAcresReview",
        label: `Download Report Type: ${
          myState?.reportType == 0
            ? ACREAGEREPORTTYPES.FARMBASED
            : ACREAGEREPORTTYPES.UNITBASED ?? ""
        }`,
        action: "Download",
      },
    });

    setButtonText("Generating Report");
    let docGenSuccess = await generateDocument();

    if (docGenSuccess) {
      await PollForDocumentGenerationComplete(
        apiGateway,
        policyId,
        acreageStatusData?.acreageReportId ?? 0
      );
    }

    // requery the status
    arStatus = await PoliciesAcreageReportApi(
      apiGateway,
      policyId,
      acreageStatusData?.acreageReportId ?? 0
    );

    if (arStatus.reportGenerationStatus === 9) {
      setButtonText("Downloading Report");
      // we are good to go, we've got the answer we're looking for! Onward! Sontar-Ha!
      const fileData = await apiGateway.getFile(
        `policies/${policyId}/acreage-report/${acreageStatusData?.acreageReportId}/action/download`,
        FieldManagement
      );

      let blob = new Blob([fileData], { type: "application/pdf" });
      FileSaveAs(blob, fileName);
      toast.success("Report downloaded successfully.");
    }

    setButtonText("Download Report");
    setReportProcessing(false);
  };

  const handleESignatureClick = async () => {
    recordMetric({
      name: "producers-planted-acres-e-signature",
      data: {
        category: "ProducersPlantedAcresReview",
        label: "Producers Planted Acres E-Signature Click",
        action: "ESignature",
      },
    });

    if (!policyInfo?.producerEmailAddress) {
      setESignatureNotAllowedMessage(
        "No email address is available for the grower. Please add grower email address to their profile."
      );
      setShowESignatureNotAllowed(true);
      return;
    }

    if (hasAllPastAcreageReportingDates) {
      setESignatureNotAllowedMessage(
        "E-Signature is unavailable after Acreage Reporting Date."
      );
      setShowESignatureNotAllowed(true);
      return;
    }

    if (documentEnvelopes && documentEnvelopes.length > 0) {
      setShowDocusignExistsMessage(true);
      return;
    }

    await handleESignature();
  };

  const handleESignature = async () => {
    // if we made it this far, submit document to docusign

    setShowDocusignExistsMessage(false); // clear the dialog

    setIsEsignDocumentSending(true); // update the button text

    let eSignData = collectESignData();

    let docGenSuccess = await generateDocument(eSignData);

    if (docGenSuccess) {
      await PollForDocumentGenerationComplete(
        apiGateway,
        policyId,
        acreageStatusData?.acreageReportId ?? 0
      );

      handleDocumentSentSuccess();

      // refetches the document envelopes collection, which will update the disableESignature state
      await fetchDocumentEnvelopes();
    }
  };

  const collectESignData = (): ESignData => {
    /*     let arID = acreageStatusData != undefined ? acreageStatusData.acreageReportId : 0; */

    let agentEmailAddress =
      policyInfo != undefined && policyInfo.agentEmailAddress != undefined
        ? policyInfo.agentEmailAddress
        : "";

    let agentName =
      policyInfo != undefined && policyInfo.agentName != undefined
        ? policyInfo.agentName
        : "";

    let producerEmailAddress =
      policyInfo != undefined && policyInfo.producerEmailAddress != undefined
        ? policyInfo.producerEmailAddress
        : "";

    let producerName =
      policyInfo != undefined && policyInfo.producerName != undefined
        ? policyInfo.producerName
        : "";

    /*
    let arTypeConfig = {
      reportType: myState.reportType,
      levelOfDetail: myState.levelOfDetail,
      commodityCodes: myState.commodityCodes,
      displayUnmaskedTaxIds: myState.displayUnmaskedTaxIds,
      includeExtraBlankArPage: myState.includeExtraBlankArPage,
      revisedAcreageReport: myState.revisedAcreageReport,
      omitZeroArLines: myState.omitZeroArLines,
      plantingSeason: myState.plantingSeason,
    }; */

    let eSignData: ESignData;
    eSignData = {
      customFields: {
        agentEmail: agentEmailAddress,
        growerEmail: producerEmailAddress,
        pawAgentId:
          policyInfo != undefined ? policyInfo.pawAgentId.toString() : "",
        pawGrowerId:
          policyInfo != undefined ? policyInfo.pawGrowerId.toString() : "",
        pawPolicyId: policyId.toString(),
        plantingSeason: myState.plantingSeason,
      },
      envelopeConfig: {
        AgentEmailAddress: agentEmailAddress,
        AgentName: agentName,
        ProducerEmailAddress: producerEmailAddress,
        ProducerName: producerName,
      },
    };

    return eSignData;
  };

  const handleDocumentSentSuccess = () => {
    setDocumentExistsDialogButtonText("OK");
    setIsEsignDocumentSending(false); // update the button text back to normal
    toast.success("Document sent successfully.");
  };

  const handleTaxChange = () => {
    setMyState((prevState) => ({
      ...prevState,
      displayUnmaskedTaxIds: !prevState.displayUnmaskedTaxIds,
    }));
  };

  const handleBlankChange = () => {
    setMyState((prevState) => ({
      ...prevState,
      includeExtraBlankArPage: !prevState.includeExtraBlankArPage,
    }));
  };

  const handleOmitChange = () => {
    setMyState((prevState) => ({
      ...prevState,
      omitZeroArLines: !prevState.omitZeroArLines,
    }));
  };

  const handleRevisedChange = () => {
    setMyState((prevState) => ({
      ...prevState,
      revisedAcreageReport: !prevState.revisedAcreageReport,
    }));
  };

  const [fetchPawStatus, setFetchPawStatus] = useState(true);
  const [reportProcessing, setReportProcessing] = useState(false);
  const [reportStatus, setReportStatus] = useState<string | undefined>(
    "Processing not started"
  );

  const { acreageStatusData } = usePoliciesFarmsAcreageStatus(
    policyId,
    fetchPawStatus
  );

  const [buttonText, setButtonText] = useState("Download Report");

  useEffect(() => {
    if (acreageStatusData) {
      if (
        acreageStatusData &&
        acreageStatusData.acreageValidationStatus !== undefined
      ) {
        if (
          acreageStatusData.acreageValidationStatus ===
          ACREAGEVALIDATIONSTATUS.DEFAULT
        ) {
          setFetchPawStatus(true);
          setReportStatus("Processing not started");
        }
        if (
          acreageStatusData.acreageValidationStatus ===
          ACREAGEVALIDATIONSTATUS.PROCESSING
        ) {
          setFetchPawStatus(true);
          setReportStatus("Processing, please wait");
        }
        if (
          acreageStatusData.acreageValidationStatus ===
          ACREAGEVALIDATIONSTATUS.ERROR
        ) {
          setFetchPawStatus(true);
          setReportStatus("Error processing report");
        }
        if (
          acreageStatusData.acreageValidationStatus ===
          ACREAGEVALIDATIONSTATUS.SUCCESS
        ) {
          setFetchPawStatus(false);
          setReportStatus(undefined);
        }
      }

      setMyState((prevState) => ({
        ...prevState,
        acreageReportId: acreageStatusData.acreageReportId,
      }));
    }
  }, [acreageStatusData]);

  let docEnvQuery: DocumentEnvelopeQuery = {
    policyOid: policyId,
    plantingSeason: myState.plantingSeason,
  };

  const {
    documentEnvelopes,
    isDocumentEnvelopesFetching,
    fetchDocumentEnvelopes,
  } = useAcreageReportDocumentEnvelopesQuery(docEnvQuery);
  const { policyInfo, isPolicyInfoFetching, policyInfoIsFetched } =
    usePoliciesAgencyAgentProducerQuery(policyId);
  const [showDocusignExistsMessage, setShowDocusignExistsMessage] =
    useState<boolean>(false);
  const [disableESignature, setDisableESignature] = useState<boolean>(false);

  const [documentExistsDialogButtonText, setDocumentExistsDialogButtonText] =
    useState<string>("OK");
  const [eSignatureButtonText, setESignatureButtonText] = useState<string>();

  const mapEnvelopeStatus = (envelope: any) => {
    var status = "";
    switch (envelope.documentEnvelopeStatusId) {
      case "1":
        status = ""; // Show blank if Status is Created
        break;
      case "3":
        status = "Expired"; // Show status as Expired for Voided
        break;
      default:
        status = envelope.documentEnvelopeStatus;
    }
    return status;
  };
  const assignEnvelopeStatus = (documentEnvelopes: any) => {
    if (documentEnvelopes && documentEnvelopes.length > 0) {
      //get latest document envelope
      var latestEnvelope = documentEnvelopes
        .sort((a: any, b: any) =>
          a.documentEnvelopeId > b.documentEnvelopeId ? 1 : -1
        )
        .reverse()[0];

      var status = mapEnvelopeStatus(latestEnvelope);
      setESignatureStatus(status);
    } else {
      setESignatureStatus("");
    }
  };
  useEffect(() => {
    if (documentEnvelopes && documentEnvelopes.length > 0) {
      const isCompletedDocPresent = documentEnvelopes.some(
        (envelope) => envelope.documentEnvelopeStatusId === 4
      );
      setDisableESignature(isCompletedDocPresent);
    } else {
      setDisableESignature(false);
    }
    assignEnvelopeStatus(documentEnvelopes);
  }, [documentEnvelopes]);

  useEffect(() => {
    if (isPolicyInfoFetching || isDocumentEnvelopesFetching) {
      setESignatureButtonText("Loading...");
    } else {
      setESignatureButtonText("E-Signature");
    }
  }, [isPolicyInfoFetching || isDocumentEnvelopesFetching]);

  return (
    <>
      <div
        data-testid="producers-review-generate-acreage-report"
        className="h-100"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Alert
          data-testid="report-creation-failed"
          className="sprout__alert-docs__yqsSX"
          position="top-center"
          onClose={() => setAlertVisible(false)}
          titleText="Report creation failed!"
          visible={alertVisible}
          variant="error-solid"
        />
        <div>
          <Typography className="title-md">Generate Acreage Report</Typography>
        </div>
        <div className="flex-grow-1">
          <div>
            <ReportType
              label="Report Type"
              value={myState.reportType}
              onChange={handleSelected}
            />
          </div>
        </div>
        <div className="flex-grow-1">
          <div>
            <InputCheckBox
              label="Display Tax ID's"
              onChange={handleTaxChange}
              testId="generate-report-displayTaxIds"
            />
          </div>
          <div>
            <InputCheckBox
              label="Add Blank AR Page"
              testId="generate-report-addBlankARPage"
              onChange={handleBlankChange}
            />
          </div>
          <div>
            <InputCheckBox
              label="Omit zero acre line(s)"
              testId="generate-report-omitZero"
              onChange={handleOmitChange}
            />
          </div>
          <div>
            <InputCheckBox
              label="Flag as Revised AR"
              onChange={handleRevisedChange}
              testId="generate-report-flagAsReserverdAR"
            />
          </div>
        </div>
        <div className="d-flex">
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-html={reportStatus}
            data-tooltip-hidden={!fetchPawStatus}
            data-testid={"custom-header-icon-toolTip"}
            className="mr-2 flex-grow-1"
          >
            <Button
              width="100%"
              data-testid="producers-review-generate-acreage-report-download-button"
              variant={"brand-outlined"}
              size="sm"
              onClick={handleDownloadPdf}
              disabled={fetchPawStatus || reportProcessing}
              style={
                fetchPawStatus || reportProcessing ? { opacity: 0.35 } : {}
              }
            >
              {buttonText}
            </Button>
          </a>
          <div className="flex-grow-1">
            <Button
              width="100%"
              data-testid="producers-review-generate-acreage-report-esignature-button"
              variant="brand-solid"
              size="sm"
              onClick={handleESignatureClick}
              disabled={
                disableESignature ||
                isPolicyInfoFetching ||
                isDocumentEnvelopesFetching
              }
              style={
                isPolicyInfoFetching ||
                isDocumentEnvelopesFetching ||
                disableESignature
                  ? { opacity: 0.35 }
                  : {}
              }
            >
              {isESignDocumentSending ? "Sending..." : eSignatureButtonText}
            </Button>
            {eSignatureStatus && (
              <div
                data-testid="producers-review-generate-acreage-report-esignature-status"
                className="d-flex justify-content-start mt-2"
                style={{ fontSize: 12 }}
              >
                E-Signature {eSignatureStatus}
              </div>
            )}
          </div>
        </div>

        <Dialog
          data-testid="producers-review-generate-acreage-report-esignature-not-allowed"
          onClose={() => {
            setShowESignatureNotAllowed(false);
          }}
          onPrimaryButtonClick={() => {
            setShowESignatureNotAllowed(false);
          }}
          primaryButtonText={"Ok"}
          titleText="E-Signature"
          visible={showESignatureNotAllowed}
        >
          <>
            <span>{eSignatureNotAllowedMessage}</span>
            <br />
          </>
        </Dialog>
        <Tooltip id="my-tooltip" />
      </div>
      <Dialog
        onClose={() => setShowDocusignExistsMessage(false)}
        onPrimaryButtonClick={handleESignature}
        onSecondaryButtonClick={() => setShowDocusignExistsMessage(false)}
        primaryButtonText={documentExistsDialogButtonText}
        secondaryButtonText="Cancel"
        titleText="E-Signature"
        visible={showDocusignExistsMessage}
      >
        <>
          {isESignDocumentSending ? <LoadingSpinner /> : null}
          <span>
            An acreage report has already been sent for signature. Are you sure
            you want to resend?
          </span>
        </>
      </Dialog>
    </>
  );
};

export const PoliciesAcreageReportApi = (
  apiGateway: ApiGateway,
  policyId: number,
  acreageReportId: number
): any => {
  const p = policyId;
  const ar = acreageReportId;
  return apiGateway.get(`policies/${p}/acreage-report/${ar}`, FieldManagement);
};

export const PollForDocumentGenerationComplete = async (
  apiGateway: ApiGateway,
  policyId: number,
  acreageReportId: number
) => {
  // SETUP the polling function
  // ...what are we polling ?
  const pollApi = async () =>
    await PoliciesAcreageReportApi(apiGateway, policyId, acreageReportId);

  // SETUP the testCriteria function
  // ....when we should stop polling
  const testResults = (val: any) => {
    if (val.reportGenerationStatus === 9) {
      //fileName += "_" + val.reportFileName;
      //reportGenerationStatus = val.reportGenerationStatus; // success
      return true;
    }
    if (val.reportGenerationStatus === -1) {
      //reportGenerationStatus = val.reportGenerationStatus; // actually an error
      return true;
    }

    return false; // still processing, poll again
  };

  // SETUP:
  // ....how often should we poll ?
  const pollingOptions: PollingOptions = {
    timeout: 10 * 60 * 1000, // 10 minutes (in ms) == 600,000
    interval: 1.5 * 1000, // 1.5 seconds (in ms) == 1,500
  };

  // now we just wait until it passes our tests
  await PollApiUntil(pollApi, testResults, pollingOptions);
};
