/* map new `PoliciesFarmsFieldRecord`
       from existing `FieldDataType`
*/
import { mapDateStringToShortIsoFormat } from "features/managed-acres/functions/mappings/mapDateStringToIsoFormat";

import {
  FieldDataType,
  PoliciesFarmsFieldRecord,
} from "features/managed-acres";

export const mapPoliciesFarmsFieldRecordFromFieldDataType = (
  f: FieldDataType
): PoliciesFarmsFieldRecord => {
  // build the record PoliciesFarmsFieldRecord
  // convert all the UNDEFINED values to NULL for Transportation
  let policiesFarmsFieldRecordObject: PoliciesFarmsFieldRecord = {
    acreageReportFieldId: f.fieldId,
    farmId: f.farm!.farmId,
    fieldNumber: f.fieldNumber,
    cluIdentifier: f.cluIdentifier,
    cluCalculatedAcreage: f.acres,
    coverageId: f.coverageId.value === undefined ? null : f.coverageId.value,
    unitId: f.unitId.value === undefined ? null : f.unitId.value,
    plantedAcres: f.plantedAcres.value ? f.plantedAcres.value : null,
    plantedDate: mapDateStringToShortIsoFormat(f.plantDate.value) ?? null,
    subField: f.subField.value === undefined ? null : f.subField.value,
    share: f.share === undefined ? null : f.share,
    preventedPlant: f.preventedPlant.value,
    uninsuredReasonCode: f.uninsuredReasonCode,
    priorYearInsured: f.priorInsured === undefined ? null : f.priorInsured,
    splitParentId: f.splitParentId,
    acreageReportId: f.acreageReportId,
    isImportedFromPlanetWatchers: f.isImportedFromPlanetWatchers,
  };

  return policiesFarmsFieldRecordObject;
};
