import { QueryClient } from "@tanstack/react-query";
import { getPawURL } from "app/apiGateway/utils/apiUtils";
import { SUBFIELDPREFIX } from "app/common/constants";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { useEffect } from "react";
import { useParams } from "react-router";
import { randomString } from "./randomString";

export function usePolicyIdFromRoute() {
  const { pawPolicyOid } = useParams();
  const existingPolicyId = useAppStateStore((s) => s.policyId);
  const setPolicyId = useAppStateStore((s) => s.setPolicyId);

  useEffect(() => {
    if (existingPolicyId !== Number(pawPolicyOid)) {
      setPolicyId(!!pawPolicyOid ? +pawPolicyOid : -1);
    }
  }, [existingPolicyId, pawPolicyOid]);
}

export const routeToPaw = (policyId: number) => {
  const urlToPaw =
    getPawURL(window) +
    "/policy/MpciPolicy/Details?oid=" +
    policyId.toString() +
    "&selectedTab=1";
  window.location.href = urlToPaw;
};

export const refreshQueries = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(["policiesCropUnitsQuery"]);
  queryClient.invalidateQueries(["policiesAcresQuery"]);
  queryClient.invalidateQueries(["policiesFarmsQuery"]);
  queryClient.invalidateQueries(["policiesFarmsAcreageQuery"]);
  queryClient.invalidateQueries(["policiesFarmsFieldsQuery"]);
  queryClient.invalidateQueries(["policiesFarmsAcreageStatusQuery"]);
  queryClient.invalidateQueries(["policiesCropUnitsReviewQuery"]);
};

export function generateSubField(isAutogenerated: boolean | null | undefined) : string {
  return isAutogenerated
    ? SUBFIELDPREFIX.AUTOGENERATED + SUBFIELDPREFIX.DEFAULT + randomString(3)
    : SUBFIELDPREFIX.DEFAULT + randomString(3);
}
