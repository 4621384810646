import { Button, Flex, Typography, getSproutColor } from "@proag/sprout";
import { GridApi } from "ag-grid-community";
import {
  CropOptions,
  UnitOptions,
  dateEntryParser,
  mapApplicationRolesQueryResults,
  mapDateStringToShortIsoFormat,
} from "features/managed-acres/functions";
import {
  IPoliciesCrops,
  IPoliciesUnits,
  IPoliciesFarmUnits,
  PoliciesFarmsQueryResult,
  useApplicationRolesQuery,
  useFieldBulkUpdate,
} from "features/managed-acres/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import Select, { SingleValue } from "react-select";
import { FieldDataType } from "./FarmFields";
import Flatpickr from "react-flatpickr";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { mapPoliciesFarmsFieldRecordFromFieldDataType } from "features/planted-acres/functions/utility/map-policiesfarmsfieldrecord-from-fielddatatype";
import { useQueryClient } from "@tanstack/react-query";
import { BulkUpdate, option } from "./bulk-edit-unit-fields";
import { toast } from "react-toastify";

interface BulkEditFarmFieldsProps {
  gridApi?: GridApi<FieldDataType>;
  gridCount: number;
  coverages?: IPoliciesCrops[];
  units?: IPoliciesUnits[];
  farmUnits?: IPoliciesFarmUnits[];
  farm?: PoliciesFarmsQueryResult;
  selectedFarmId: number;
}

export function BulkEditFarmFields({
  gridApi,
  gridCount,
  coverages,
  units,
  farmUnits,
  farm,
  selectedFarmId,
}: BulkEditFarmFieldsProps): JSX.Element {
  const [bulkUpdate, setBulkUpdate] = useState<BulkUpdate>({} as BulkUpdate);
  const queryClient = useQueryClient();
  const policyId = useAppStateStore((s) => s.policyId);
  const [unitOptions, setUnitOptions] = useState<
    Array<{ value: number; label: string; isDisabled: boolean }>
  >([]);
  const { ApplicationRolesData } = useApplicationRolesQuery();
  const { isRoleUnderwriter } = useMemo(
    () => mapApplicationRolesQueryResults(ApplicationRolesData),
    [ApplicationRolesData]
  );

  const inputRef = useRef<any>();
  if (inputRef && inputRef.current && inputRef.current.flatpickr) {
    inputRef.current.flatpickr.altInput.disabled = bulkUpdate.isPreventPlant;
  }

  const onSuccess = (): void => {
    toast.success(`${gridCount} fields updated`);
    queryClient.invalidateQueries({
      queryKey: ["policiesFarmsFieldsQuery", policyId, selectedFarmId],
    });
    gridApi?.deselectAll();
  };

  const { mutate, isLoading } = useFieldBulkUpdate(onSuccess, policyId);

  useEffect(() => {
    setUnitOptions(
      UnitOptions(0, selectedFarmId, units, farmUnits, isRoleUnderwriter)
    );
  }, [selectedFarmId, units, farmUnits, isRoleUnderwriter]);

  let cropOptions = useMemo(
    () =>
      CropOptions(
        selectedFarmId,
        coverages,
        units,
        farmUnits,
        farm?.countyCode,
        isRoleUnderwriter
      ),
    [
      selectedFarmId,
      coverages,
      units,
      farmUnits,
      farm?.countyCode,
      isRoleUnderwriter,
    ]
  );

  const saveChanges = () => {
    const fieldsToUpdate = gridApi?.getSelectedRows();
    if (!fieldsToUpdate || fieldsToUpdate.length <= 0) {
      return;
    }

    const nextFields = fieldsToUpdate?.map((field) => {
      const nextField = {
        ...mapPoliciesFarmsFieldRecordFromFieldDataType(field),
      };

      if (bulkUpdate?.selectedCoverage && bulkUpdate.selectedCoverage.value) {
        nextField.coverageId = bulkUpdate.selectedCoverage.value;
        nextField.unitId = null;
      }

      if (
        bulkUpdate?.selectedUnitNumber &&
        bulkUpdate.selectedUnitNumber.value
      ) {
        nextField.unitId = bulkUpdate.selectedUnitNumber.value;
      }

      if (bulkUpdate?.plantDate) {
        const nextDate = mapDateStringToShortIsoFormat(bulkUpdate.plantDate);
        nextField.plantedDate = nextDate ?? null;
        nextField.preventedPlant = false;
      }

      if (bulkUpdate?.matchFieldAcres) {
        nextField.plantedAcres = nextField.cluCalculatedAcreage;
      }

      if (bulkUpdate?.isPreventPlant) {
        nextField.preventedPlant = true;
        nextField.plantedDate = null;
      }
      return nextField;
    });

    mutate(nextFields);
  };

  return (
    <div className="p-2" style={{ backgroundColor: getSproutColor("green90") }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="m-0">
          <Typography className="body-lg" style={{ margin: 0 }}>
            {`${gridCount} fields selected`}
          </Typography>
        </div>

        <div className="d-flex align-items-center">
          <div className="mr-2" style={{ minWidth: "200px" }}>
            <Select
              placeholder="Crop"
              value={bulkUpdate?.selectedCoverage}
              onChange={(event: SingleValue<option>) => {
                setBulkUpdate({
                  ...bulkUpdate,
                  selectedCoverage: event,
                  selectedUnitNumber: null,
                });
                setUnitOptions(
                  UnitOptions(
                    event?.value ?? 0,
                    selectedFarmId,
                    units,
                    farmUnits,
                    isRoleUnderwriter
                  )
                );
              }}
              options={cropOptions}
              isDisabled={isLoading}
            />
          </div>

          <div className="mr-2" style={{ minWidth: "200px" }}>
            <Select
              placeholder="Practice/Type"
              value={bulkUpdate?.selectedUnitNumber}
              onChange={(event: SingleValue<option>) => {
                setBulkUpdate({ ...bulkUpdate, selectedUnitNumber: event });
              }}
              options={unitOptions}
              isDisabled={isLoading}
            />
          </div>

          <Flex className="mr-2">
            <input
              className="mr-3"
              type="checkbox"
              data-testid="match-acres"
              onChange={(event) => {
                setBulkUpdate({
                  ...bulkUpdate,
                  matchFieldAcres: event.currentTarget.checked,
                });
              }}
              checked={!!bulkUpdate?.matchFieldAcres}
              disabled={isLoading}
            ></input>
            <Typography className="body-lg" style={{ margin: 0 }}>
              Match field acres
            </Typography>
          </Flex>

          <div data-testid="plant-date-col" className="mr-2">
            <Flatpickr
              ref={inputRef}
              disabled={bulkUpdate.isPreventPlant}
              data-testid="match-plant-date"
              placeholder="Plant Date"
              value={bulkUpdate?.plantDate}
              onClose={(selectedDates: Date[], dateStr: string) => {
                // handle user clicking outside of datePicker input
                if (!dateStr) {
                  setBulkUpdate({ ...bulkUpdate, plantDate: null });
                }
              }}
              onChange={(selectedDates: Date[], dateStr: string) => {
                if (selectedDates.length > 0) {
                  setBulkUpdate({ ...bulkUpdate, plantDate: dateStr });
                } else {
                  setBulkUpdate({ ...bulkUpdate, plantDate: null });
                }
              }}
              options={{
                maxDate: new Date(),
                allowInput: true,
                dateFormat: "m/d/Y",
                altInput: true,
                altFormat: "m/d/Y",
                parseDate: (datestr: string, format: any) =>
                  dateEntryParser(datestr),
              }}
            />
          </div>

          <Flex className="mr-2" style={{ minWidth: "55px" }}>
            <input
              className="mr-3"
              type="checkbox"
              data-testid="match-prevent-plant"
              onChange={(event) => {
                setBulkUpdate({
                  ...bulkUpdate,
                  isPreventPlant: event.currentTarget.checked,
                });
              }}
              checked={!!bulkUpdate?.isPreventPlant}
              disabled={isLoading || !!bulkUpdate.plantDate}
            ></input>
            <Typography className="body-lg" style={{ margin: 0 }}>
              PP
            </Typography>
          </Flex>

          <Button
            onClick={saveChanges}
            disabled={isLoading}
            data-testid="apply-updates"
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}
