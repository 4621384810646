import { Flex, Button } from "@proag/sprout";
import { Dropdown } from "react-bootstrap";
import React from "react";

interface ImportAcresProps {
    importDisabled: boolean | undefined
    setAcrsiImportAlertIsVisible: any
    setPlanetWatchersImportAlertIsVisible: any
    importPlanetWatchersDisabled: boolean | undefined
}

export const ImportAcres: React.FC<
    ImportAcresProps
> = ({
    importDisabled,
    setAcrsiImportAlertIsVisible,
    setPlanetWatchersImportAlertIsVisible,
    importPlanetWatchersDisabled
}) => {
        return (
            <>
              <Flex direction="column" alignItems="flex-end">
                  <Dropdown className="flex-end">
                      <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
                          Import
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                          <Dropdown.Item
                              disabled={importDisabled}
                              onClick={() => {
                                  setAcrsiImportAlertIsVisible(true);
                              }}
                          >
                              From ACRSI
                          </Dropdown.Item>
                          <Dropdown.Item disabled={importDisabled || importPlanetWatchersDisabled}
                            onClick={()=>{
                              setPlanetWatchersImportAlertIsVisible(true);
                            }}
                          >
                              From Earth Observations
                          </Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
              </Flex>
            </>
        );
    };

const CustomToggle = React.forwardRef(({ children, onClick }: any, ref) => (
    <Button
        leftIcon="DocumentImport"
        rightIcon="CaretDown"
        variant="neutral-outlined"
        ref={ref as any}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </Button>
));
