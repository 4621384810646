import {
  MapStyle,
} from "./mapTypes";

export const defaultMapStyle: MapStyle = {
  defaultLayer: {
    style: "solid",
    color: "rgba(0, 255, 0, 0.18)", //"rgba(0, 0, 0, 0)",
    opacity: 0.3,
    outlineColor: "rgba(0, 0, 0, 0.1)",
    outlineWidth: 2,
    outlineStyle: "solid",
  },

  marker: {
    // style: "picture",
    // url: "/map-pin.svg",
    style: "diamond",
    color: "white",
    outlineColor: "black",
  },

  plantLayer: {
    style: "solid",
    color: "rgba(0, 0, 0, 0.5)",
    opacity: 1,

    outlineColor: "rgba(255, 255, 255, 1)",
    outlineWidth: 1,
    outlineStyle: "solid",
  },

  preventPlantLayer: {
    style: "solid",
    color: "rgba(255, 0, 0, 0.2)",
    opacity: 0.3,

    outlineColor: "rgba(255, 0, 0, 1)",
    outlineWidth: 1,
    outlineStyle: "solid",
  },

  highlight: {
    fillColor: "rgba(0, 255, 0, 0.3)",
    haloColor: "rgba(0, 255, 0, 1)",
  },

  labelStyle: {
      type: "text",  
      color: "rgba(0, 0, 0, 1)",
      haloSize: 1,
      haloColor: "rgba(255, 255, 255, 1)"
  }
};

export const defaultMapDefinition = {
  basemap: "osm", // one of ["satellite", "hybrid", "terrain", "oceans", "osm", "dark - gray - vector", "gray - vector", "streets - vector", "topo - vector", "streets - night - vector", "streets - relief - vector", "streets - navigation - vector" ]
  featureUrl:
    "https://gisserver.nonprod-proag.com/arcgis/rest/services/clu_master_current/FeatureServer/0",
  featureOutfields: ["*"],
};