import { colors } from "@proag/sprout";
import styles from "./producers-planted-acres-review-acre-observation-discrepancies.module.scss";
import {
  FeaturePlanetWatchersIsActive,
  useFeatureFlag,
} from "app/feature-flag";

interface ProducersReviewAcreObservationDiscrepanciesProps {}

export const ProducersReviewAcreObservationNoDiscrepancies: React.FC<
  ProducersReviewAcreObservationDiscrepanciesProps
> = ({}) => {
  const { featureFlag: flag_FeaturePlanetWatchersIsActive } = useFeatureFlag(
    FeaturePlanetWatchersIsActive
  );
  return (
    <>
      <div data-testid="acre-observation-no-discrepencies">
        <>
          <div className="label-md mb-4" style={{ color: colors.neutral40 }}>
            No observations available.{" "}
            {flag_FeaturePlanetWatchersIsActive ? (
              <span>
                Currently this feature is only available for Corn, Soybeans,
                Cotton and Spring Wheat
              </span>
            ) : (
              <span>Please check back soon.</span>
            )}
          </div>
          <div>
            <img
              className={`${styles.noObservationsEarthImage} mt-4, mb-4`}
              src="/acre-observation.svg"
            />
          </div>
        </>
      </div>
    </>
  );
};
