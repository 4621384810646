import { Flex, Icon, colors } from "@proag/sprout";
import { PoliciesAcresObservedResult } from "features/planted-acres/review/hooks/policies/models/policies-acres-observed-query-result";

interface ProducersReviewAcreObservationDiscrepancyDetailProps {
  acresObservedDetail: PoliciesAcresObservedResult | undefined;
  discrepancyType: string;
}

export const ProducersReviewAcreObservationDiscrepancyDetail: React.FC<
  ProducersReviewAcreObservationDiscrepancyDetailProps
> = ({ acresObservedDetail, discrepancyType }) => {
  let discrepancyTitle: string, reported: string, suggested: string;

  switch (discrepancyType.toLowerCase()) {
    case "crop": {
      discrepancyTitle = "Crop";
      reported = displayCropName(acresObservedDetail?.cropName);
      suggested = displayCropName(
        acresObservedDetail?.observedCrops[0]?.cropName
      );
      break;
    }
    case "acres": {
      discrepancyTitle = "Acres";
      reported = acresObservedDetail?.plantedAcres?.toString() ?? "0";
      suggested =
        acresObservedDetail?.observedCrops[0]?.observedAcres?.toFixed(2).toString() ?? "";
      break;
    }
    case "date": {
      discrepancyTitle = "Plant Date";
      reported = acresObservedDetail?.plantedDate ?? "No Date";
      suggested = acresObservedDetail?.observedPlantedDate ?? "No Date";
      break;
    }
    default:
      discrepancyTitle = "";
      reported = "";
      suggested = "";
      break;
  }

  return (
    <>
      <div
        className="d-flex mr-2"
        data-testid="producers-review-acres-observation-discrepancies-detail"
      >
        <Flex
          direction="column"
          alignItems="flex-start"
          style={{
            backgroundColor: colors.neutral98,
            borderLeft: "3px solid",
            borderLeftColor: colors.orange60,
          }}
        >
          <div className="d-flex ml-2">
            <div style={{ color: colors.orange60 }}>{discrepancyTitle}</div>
          </div>
          <div className="d-flex ml-2 mb-2">
            <div className="mr-2">
              <div className="label-md">{reported}</div>
              <div className="body-sm" style={{ color: colors.neutral40 }}>
                Reported
              </div>
            </div>
            <div className="mr-2">
              <Icon style={{ color: colors.orange60 }} iconName="ArrowRight" />
            </div>
            <div className="mr-2">
              <div className="label-md">{suggested}</div>
              <div className="body-sm" style={{ color: colors.neutral40 }}>
                Suggest
              </div>
            </div>
          </div>
        </Flex>
      </div>
    </>
  );

  function displayCropName(cropName: string | undefined): string {
    return cropName?.trim().length === 0 ? "No Crop" : cropName ?? "No Crop";
  }
};
