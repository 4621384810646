import { IconButton, Icon } from "@proag/sprout";
import { ICellRendererParams } from "ag-grid-community";
import { FIELDDATA } from "app/common/constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FieldDataType,
  NumberValidationField,
  PreviouslyReportedField,
  StringValidationField,
} from "features/managed-acres";
import { colors } from "@proag/sprout";
import { FieldCellDisplay } from "features/managed-acres/components/tables/FarmFields/field-cell-display";
import { Overlay, Popover } from "react-bootstrap";
import { FC, useState } from "react";
import styles from "./units-fields-table-columns.module.scss";
import { isFieldEditable } from "./column-definition-helper";

const FNTractColumn = (prop: ICellRendererParams<FieldDataType, string>) => {
  // extract the values from our prop.value.
  let v: string = prop.value ?? "";

  let [fsn, tn, name, section] = v.split("|");

  return (
    <>
      <div className="d-flex flex-column">
        <p data-testid="farm-name-span" className="mb-0 mt-1">
          {name}
        </p>
        <p data-testid="fsnTract-span" className="m-0">
          {fsn}
          {!!tn && `/${tn}`}
          {!!section && `/${section}`}
        </p>
      </div>
    </>
  );
};
const FieldNumberColumn = (prop: ICellRendererParams<FieldDataType, string>) => {
  
    let satelliteIcon = (
      <Icon 
        data-testid="satellite-icon"
        iconName="Satellite"
        size={"sm"}
      ></Icon>
    );

    const renderTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        Automatically imported from Acre Observation
      </Tooltip>
    );

    if (prop.node.group) {
      return FNTractColumn(prop);
    } else {

      if (prop.data?.isImportedFromPlanetWatchers) {
        return (        
          <OverlayTrigger
            placement="bottom"
            overlay={renderTooltip}
          >
            <div>{prop.data?.fieldNumber} <span style={{ margin: 5 }}>{satelliteIcon}</span></div>
            </OverlayTrigger>        
        );
      }
      else {
        return <div>{prop.data?.fieldNumber}</div>;
      }
    
  }
};

const FieldColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.FIELDNUMBER]
  >
) => {
  return <div>{prop.value}</div>;
};

const PreviouslyReportedColumn: FC<
  ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PREVIOUSLYREPORTED]
  >
> = (prop) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event: any) => {
    setShow((current: boolean) => !current);
    setTarget(event.target);
  };

  const onHideToolTip = () => {
    setShow((current: boolean) => !current);
  };

  const getPreviouslyReportedText = (
    unitField?: PreviouslyReportedField
  ): string => {
    if (unitField) {
      return `${unitField.coverage?.value} ${unitField.unit?.value}`;
    } else {
      return "";
    }
  };

  const getPlantedInfo = (unitField?: PreviouslyReportedField): string => {
    if (!unitField) {
      return "";
    }
    if (unitField.plantedDate) {
      if (unitField.acres) {
        return `${unitField.acres} Acres - ${unitField.plantedDate}`;
      } else {
        return `${unitField.plantedDate}`;
      }
    } else {
      if (unitField.preventedPlant && unitField.acres) {
        return `${unitField.acres} Acres - PP`;
      } else if (unitField.acres) {
        return `${unitField.acres} Acres`;
      } else {
        return "";
      }
    }
  };

  if (prop.node.group || !prop.data?.previouslyReported) return <div></div>;
  else if (prop.data?.previouslyReported.length > 1)
    return (
      <div>
        <div className="col-12 p-1">
          <div
            className={`label-md ${styles.hover}`}
            data-testid={`${prop.node.rowIndex}-multiplecrops`}
            onClick={handleClick}
          >
            <u>Multiple Crops</u>
          </div>
          <Overlay
            show={show}
            target={target}
            placement="right"
            containerPadding={20}
            rootClose={true}
            onHide={onHideToolTip}
          >
            <Popover
              id="popover-previously-reported"
              data-testid="popover-previously-reported"
              style={{ width: 225 }}
            >
              <Popover.Body>
                {prop.data?.previouslyReported?.map((e, index) => {
                  return (
                    <div key={index} className="p-1">
                      <div className="row">
                        <div
                          className="col-12 p-0 label-md"
                          data-testid={`${prop.node.rowIndex}-${index}-prevreportedunit`}
                        >
                          <b>{getPreviouslyReportedText(e)}</b>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-12 label-sm p-0"
                          data-testid={`${prop.node.rowIndex}-${index}-prevreportedplantedinfo`}
                        >
                          {getPlantedInfo(e)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>
      </div>
    );
  else {
    return (
      <div>
        <div
          className="row p-0"
          data-testid={`${prop.node.rowIndex}-prevreportedunit`}
        >
          <div className="col-12 p-0 label-md text-end mb-1">
            {getPreviouslyReportedText(prop.data.previouslyReported[0])}
          </div>
        </div>
        <div className="row p-0">
          <div
            className="col-12 p-0 label-sm text-end"
            data-testid={`${prop.node.rowIndex}-prevreportedplantedinfo`}
            style={{ color: colors.neutral40 }}
          >
            {getPlantedInfo(prop.data.previouslyReported[0])}
          </div>
        </div>
      </div>
    );
  }
};
const SubFieldColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.SUBFIELD]
  >
) => {
  if (prop.node.group) return <div></div>;
  else {
    let cellValue = prop.data?.subField
      ? prop.data?.subField
      : ({} as StringValidationField);

    return FieldCellDisplay(
      cellValue,
      <span>{prop.data?.subField.value}</span>,
      "flex-end"
    );
  }
};

const FieldAcresColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.ACRES]
  >
) => {
  return <span>{prop.value}</span>;
};

const ReportedAcresColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PLANTEDACRES]
  >
) => {
  if (prop.node.group) return <div></div>;
  let cellValue = prop.node.data?.plantedAcres
    ? prop.node.data?.plantedAcres
    : ({} as NumberValidationField);
  return FieldCellDisplay(
    cellValue,
    <span>{cellValue.value}</span>,
    "flex-end"
  );
};

const PastFinalPlantDateColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PASTFINALPLANTDATE]
  >
) => {
  return <div>{prop.value ? "LP" : ""}</div>;
};

const PlantDateColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PLANTDATE]
  >
) => {
  if (prop.node.group) return <div></div>;

  let cellValue = prop.node.data?.plantDate
    ? prop.node.data?.plantDate
    : ({} as StringValidationField);
  return FieldCellDisplay(
    cellValue,
    <div className="d-flex">
      {prop.node.data?.pastFinalPlantDate && (
        <span style={{ color: "#317399" }} className="mr-1">
          LP
        </span>
      )}
      {cellValue.value}
    </div>
  );
};

const PreventedPlantColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PREVENTEDPLANT]
  >
) => {
  var isEditable: boolean = isFieldEditable(prop.data, prop?.context);

  if (prop.node.group) return <div></div>;
  else {
    return (
      <div className="d-flex">
        <input
          type="checkbox"
          checked={prop.data?.preventedPlant.value === true}
          disabled={!isEditable}
          readOnly
        ></input>
      </div>
    );
  }
};

const ActionColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.ACTION]
  >
) => {
  if (prop.node.group) return <div></div>;
  else {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <IconButton
          id={`${prop.node.rowIndex}-action`}
          data-testid={`${prop.node.rowIndex}-action`}
          iconName="OverflowMenuVertical"
          disabled={prop.data?.pastAcreageReportDate}
        />
      </div>
    );
  }
};

export {
  FNTractColumn,
  FieldColumn,
  SubFieldColumn,
  FieldAcresColumn,
  ReportedAcresColumn,
  PastFinalPlantDateColumn,
  PlantDateColumn,
  PreventedPlantColumn,
  ActionColumn,
  PreviouslyReportedColumn,
  FieldNumberColumn
};
