import { colors } from "@proag/sprout";
import { PoliciesFarmsQueryResult } from "features/managed-acres";
import React from "react";

interface IFarmLegalsRendererProps {
  farms: PoliciesFarmsQueryResult[] | undefined;
  farmId: number | undefined;
}

export const FarmLegalsRenderer: React.FC<IFarmLegalsRendererProps> = ({
  farms,
  farmId,
}) => {
  let farm = farms?.find((f) => f.farmId === +farmId!);

  return (
    <div className="mt-1">
      <div className="d-flex">
        <div className="mr-5">
          <div className="label-sm mb-2" style={{ color: colors.neutral40 }}>
            County
          </div>
          <div className="label-md">{farm?.countyName}</div>
        </div>

        <div className="mr-5">
          <div className="label-sm mb-2" style={{ color: colors.neutral40 }}>
            FN
          </div>
          <div className="label-md">{farm?.farmSerialNumber ?? ""}</div>
        </div>

        <div className="mr-5">
          <div className="label-sm mb-2" style={{ color: colors.neutral40 }}>
            Tract
          </div>
          <div className="label-md">{farm?.tractNumber ?? ""}</div>
        </div>

        <div className="mr-5">
          <div className="label-sm mb-2" style={{ color: colors.neutral40 }}>
            Section
          </div>
          <div className="label-md">{farm?.section ?? ""}</div>
        </div>

        <div className="mr-5">
          <div className="label-sm mb-2" style={{ color: colors.neutral40 }}>
            Township
          </div>
          <div className="label-md">{farm?.township ?? ""}</div>
        </div>

        <div className="mr-5">
          <div className="label-sm mb-2" style={{ color: colors.neutral40 }}>
            Range
          </div>
          <div className="label-md">{farm?.range ?? ""}</div>
        </div>
      </div>
    </div>
  );
};
