import { Flex, IconButton, colors } from "@proag/sprout";
import { toSentence } from "app/utils/isSentence";
import { PoliciesAcresObservedResult } from "features/planted-acres/review/hooks/policies/models/policies-acres-observed-query-result";
import { useState } from "react";
import { ProducersReviewAcreObservationDiscrepancyDetail } from "./producers-planted-acres-review-acre-observation-discrepancy-detail";
import {
  PoliciesFarmsFieldRecord,
  PoliciesFarmsFieldUpdateCommand,
  mapDateStringToShortIsoFormat,
  usePoliciesFarmsFieldUpdateMutation,
} from "features/managed-acres";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { useMetricsCreateEventPublish } from "app/analytics";

interface ProducersReviewAcreObservationDiscrepanciesCardProps {
  acresObservedDetail: PoliciesAcresObservedResult | undefined;
  setHasChanges: (isValid: boolean) => void;
  updateDiscrepancyCount: (hideDiscrepancyCount: number) => void;
}

export const ProducersReviewAcreObservationDiscrepanciesCard: React.FC<
  ProducersReviewAcreObservationDiscrepanciesCardProps
> = ({ acresObservedDetail, setHasChanges, updateDiscrepancyCount }) => {
  const [show, setShow] = useState(true);
  const policyId = useAppStateStore((s) => s.policyId);
  const [showDetail, setDetailShow] = useState(false);

  const { mutateFieldsUpdate: mutateFields } =
    usePoliciesFarmsFieldUpdateMutation();
  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  const onSave = () => {
    let policiesFarmsFieldRecordObject: PoliciesFarmsFieldRecord = {
      acreageReportFieldId: acresObservedDetail?.acreageReportFieldId ?? 0,
      acreageReportId: acresObservedDetail?.acreageReportId ?? 0,
      cluCalculatedAcreage: acresObservedDetail?.cluCalculatedAcreage ?? 0,
      cluIdentifier: acresObservedDetail?.cluIdentifier ?? "",
      coverageId:
        acresObservedDetail?.coverageId === undefined
          ? null
          : acresObservedDetail?.coverageId,
      farmId: acresObservedDetail?.farmId ?? 0,
      fieldNumber: acresObservedDetail?.fieldNumber ?? 0,
      plantedAcres: acresObservedDetail?.plantedAcres
        ? acresObservedDetail?.plantedAcres
        : null,
      plantedDate:
        mapDateStringToShortIsoFormat(acresObservedDetail?.plantedDate) ?? null,
      preventedPlant: acresObservedDetail?.preventedPlant ?? false,
      priorYearInsured:
        acresObservedDetail?.priorYearInsured === undefined
          ? null
          : acresObservedDetail?.priorYearInsured,
      share: acresObservedDetail?.share ?? 0,
      splitParentId: acresObservedDetail?.splitParentId ?? 0,
      subField:
        acresObservedDetail?.subField === undefined
          ? null
          : acresObservedDetail?.subField,
      uninsuredReasonCode: acresObservedDetail?.uninsuredReasonCode ?? "",
      unitId:
        acresObservedDetail?.unitId === undefined
          ? null
          : acresObservedDetail?.unitId,
     isImportedFromPlanetWatchers:true,//Set it to true, if user accepts the discrepancies
    };

    //crop
    if (acresObservedDetail?.discrepancies.includes("Crop")) {
      policiesFarmsFieldRecordObject.coverageId =
        acresObservedDetail?.observedCrops[0]?.coverageId ?? null;
      policiesFarmsFieldRecordObject.unitId =
        acresObservedDetail?.observedCrops[0]?.unitId ?? null;
    }
    //acres
    if (acresObservedDetail?.discrepancies.includes("Acres")) {
      policiesFarmsFieldRecordObject.plantedAcres =
        acresObservedDetail?.observedCrops[0]?.observedAcres ?? null;
    }

    //date
    if (acresObservedDetail?.discrepancies.includes("Date")) {
      policiesFarmsFieldRecordObject.plantedDate =
        mapDateStringToShortIsoFormat(
          acresObservedDetail?.observedPlantedDate
        ) ?? null;
    }
    // now attach the record to the command...
    let policiesFarmsFieldUpdateCommandObject: PoliciesFarmsFieldUpdateCommand =
      {
        policyId: policyId,
        farmId: acresObservedDetail?.farmId ?? 0,
        record: policiesFarmsFieldRecordObject,
      };

    //Save Stuff
    mutateFields(policiesFarmsFieldUpdateCommandObject); // on success / error ??

    recordMetric({
      name: "producers-planted-acres-review-field-update",
      data: {
        category: "ProducersPlantedAcresReview",
        label: "Producers Planted Acres Review Field Update",
        action: "Update",
      },
    });

    //close and refresh stuff
    handleCloseRefresh();
  };

  function handleCloseRefresh() {
    setHasChanges(true);
    setShow(!show);
    updateDiscrepancyCount(acresObservedDetail?.discrepancies?.length ?? 0);
  }
  const DisplayFarmName =
    acresObservedDetail?.farmName == ""
      ? "Untitled"
      : acresObservedDetail?.farmName;
  const DisplayFieldNumber =
    acresObservedDetail?.fieldNumber === 0
      ? ""
      : ` (Field ${acresObservedDetail?.fieldNumber})`;
  const DisplayCropUnit =
    acresObservedDetail?.cropName === ""
      ? ""
      : acresObservedDetail?.cropName +
        " " +
        acresObservedDetail?.unitNumberSummary;
  const DisplayFarmNumber =
    acresObservedDetail?.farmSerialNumber === "0"
      ? ""
      : `FN ${acresObservedDetail?.farmSerialNumber}`;
  const DisplayTract =
    acresObservedDetail?.tractNumber === "0"
      ? ""
      : `  |  Tract ${acresObservedDetail?.tractNumber}`;
  const DisplayCounty = `${acresObservedDetail?.countyName} County`;
  const DisplayLegals = !acresObservedDetail?.section?.trim()
    ? ""
    : "  -  (" +
      acresObservedDetail?.section +
      ") " +
      acresObservedDetail?.township +
      " " +
      acresObservedDetail?.range;
  const DisplayDiscrepancies = toSentence(
    acresObservedDetail?.discrepancies ?? []
  );

  return (
    <>
      {show && (
        <div>
          <div
            onClick={() => setDetailShow(!showDetail)}
            data-testid="producers-review-acres-observation-discrepancies-card"
            className="mr-4 ml-4"
          >
            <div className="row surface-card border-round shadow-3">
              <div className="col-6">
                <Flex direction="column" alignItems="flex-start">
                  <div className="title-sm">
                    {DisplayFarmName + DisplayFieldNumber}
                  </div>
                  <div className="label-sm" style={{ color: colors.neutral40 }}>
                    {DisplayCropUnit}
                  </div>
                  <div className="label-sm" style={{ color: colors.neutral40 }}>
                    {DisplayFarmNumber + DisplayTract}
                  </div>
                  <div className="label-sm" style={{ color: colors.neutral40 }}>
                    {DisplayCounty + DisplayLegals}
                  </div>
                </Flex>
              </div>
              <div className="col-3">
                <Flex justifyContent="flex-start">
                  <div
                    className="label-sm mb-1"
                    style={{ color: colors.orange60 }}
                  >
                    Discrepancies
                  </div>
                </Flex>
                <Flex justifyContent="flex-start">{DisplayDiscrepancies}</Flex>
              </div>
              <div className="col-3">
                <Flex
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  className={"mr-2 mb-5 ml-1"}
                >
                  <div className="label-md mb-2 mr-1">Manage</div>
                  <IconButton
                    data-testid={
                      "producers-review-acres-observation-discrepancies-card-button-" +
                      acresObservedDetail?.fieldNumber.toString()
                    }
                    className="right-align"
                    iconName={showDetail ? "ChevronUp" : "ChevronDown"}
                  />
                </Flex>
              </div>
            </div>
          </div>

          {showDetail && (
            <div className="row surface-card border-round shadow-1 mr-3 ml-3">
              <div className="col-9">
                <div className="d-flex">
                  {acresObservedDetail?.discrepancies?.map(
                    (discrepancy, index) => {
                      return (
                        <div key={index}>
                          <ProducersReviewAcreObservationDiscrepancyDetail
                            acresObservedDetail={acresObservedDetail}
                            discrepancyType={discrepancy}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="col-3">
                <Flex
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  className={"m-3"}
                >
                  <IconButton
                    data-testid="producers-review-acres-observation-discrepancies-card-detail-cancel"
                    iconName="Close"
                    variant="error-tonal"
                    className={"mr-2"}
                    onClick={handleCloseRefresh}
                  />
                  <IconButton
                    data-testid="producers-review-acres-observation-discrepancies-card-detail-save"
                    iconName="Checkmark"
                    variant="brand-tonal"
                    onClick={onSave}
                  />
                </Flex>
              </div>
            </div>
          )}
          <div className="mb-2"></div>
        </div>
      )}
    </>
  );
};
