import { dateEntryParser } from "../parsers/dateEntryParser";

export const mapDateStringToShortIsoFormat = (theDate: string | undefined) => {
  if (theDate === undefined) return undefined;

  if (theDate === "") return undefined;

  const parsedDate = dateEntryParser(theDate);
  return parsedDate?.toISOString().substring(0, 10);
};
