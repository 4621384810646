import { Flex, IconButton, Typography, Icon } from "@proag/sprout";
import { FIELDDATA } from "app/common/constants";
import { format, isValid } from "date-fns";
import { FieldDataType, IPoliciesFarmUnits } from "features/managed-acres";
import { ICellRendererParams } from "ag-grid-community";
import { isFieldEditable } from "./field-validators";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FNTractColumn = (
  prop: ICellRendererParams<FieldDataType, FieldDataType[typeof FIELDDATA.FARM]>
) => {
  return (
    <div>{prop.value?.farmSerialNumber + "/" + prop.value?.tractNumber}</div>
  );
};

const FieldColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.FIELDNUMBER]
  >
) => {
  let satelliteIcon = (
    <Icon data-testid="satellite-icon" iconName="Satellite" size={"sm"}></Icon>
  );
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Automatically imported from Acre Observation
    </Tooltip>
  );
  if (prop.data?.isImportedFromPlanetWatchers) {
    return (
      <OverlayTrigger placement="bottom" overlay={renderTooltip}>
        <div>
          {prop.data?.fieldNumber}
          <span style={{ margin: 5 }}>{satelliteIcon}</span>
        </div>
      </OverlayTrigger>
    );
  } else {
    return <div>{prop.value}</div>;
  }
};

const AcresColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.ACRES]
  >
) => {
  return <span>{prop.value}</span>;
};

const PriorInsuredColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PRIORINSURED]
  >
) => {
  if (prop.value === true) {
    return (
      <div>
        <svg
          enableBackground="new 0 0 24 24"
          height="14"
          width="14"
          viewBox="0 0 24 24"
        >
          <path
            fill="#2DC214"
            d="M21.652,3.211c-0.293-0.295-0.77-0.295-1.061,0L9.41,14.34  c-0.293,0.297-0.771,0.297-1.062,0L3.449,9.351C3.304,9.203,3.114,9.13,2.923,9.129C2.73,9.128,2.534,9.201,2.387,9.351  l-2.165,1.946C0.078,11.445,0,11.63,0,11.823c0,0.194,0.078,0.397,0.223,0.544l4.94,5.184c0.292,0.296,0.771,0.776,1.062,1.07  l2.124,2.141c0.292,0.293,0.769,0.293,1.062,0l14.366-14.34c0.293-0.294,0.293-0.777,0-1.071L21.652,3.211z"
          ></path>
        </svg>
      </div>
    );
  } else {
    return (
      <div>
        <svg
          enableBackground="new 0 0 24 24"
          height="14"
          width="14"
          viewBox="0 0 24 24"
        >
          <path
            fill="#CE1515"
            d="M22.245,4.015c0.313,0.313,0.313,0.826,0,1.139l-6.276,6.27c-0.313,0.312-0.313,0.826,0,1.14l6.273,6.272  c0.313,0.313,0.313,0.826,0,1.14l-2.285,2.277c-0.314,0.312-0.828,0.312-1.142,0l-6.271-6.271c-0.313-0.313-0.828-0.313-1.141,0  l-6.276,6.267c-0.313,0.313-0.828,0.313-1.141,0l-2.282-2.28c-0.313-0.313-0.313-0.826,0-1.14l6.278-6.269  c0.313-0.312,0.313-0.826,0-1.14L1.709,5.147c-0.314-0.313-0.314-0.827,0-1.14l2.284-2.278C4.308,1.417,4.821,1.417,5.135,1.73  L11.405,8c0.314,0.314,0.828,0.314,1.141,0.001l6.276-6.267c0.312-0.312,0.826-0.312,1.141,0L22.245,4.015z"
          ></path>
        </svg>
      </div>
    );
  }
};

// Renderer for the Composite "Crop-Plan-Level-Price" object
const CropColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.COVERAGEID]
  >
) => {
  // get the crop coverages data
  let coverages = prop.data?.coverages;
  // match the selected value:
  let selectedValue = prop.value?.value ?? 0;
  let coverage = coverages?.find((c) => c.coverageId === selectedValue);

  const contents = () => {
    if (!coverage) {
      return (
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Typography className="body-lg" style={{ marginBottom: 3 }}>
            Select
          </Typography>
        </Flex>
      );
    } else {
      let level = `${Math.round(coverage.coverageLevel * 100)}%`;
      if (coverage.coverageByPracticeChecked === true) {
        /* prettier-ignore */
        level = `IRR ${Math.round(coverage.irrCoverageLevel * 100)}% - NON IRR ${Math.round(coverage.coverageLevel * 100)}%`;
      }

      return (
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Typography className="body-lg" style={{ marginBottom: 3 }}>
            {coverage.commodityName}
          </Typography>
          <Typography className="body-sm" style={{ marginBottom: 3 }}>
            {
              /* prettier-ignore */
              `${coverage.insurancePlanAbbrev} | ${level} | ${Math.round(coverage.priceElectionFactor * 100)}%`
            }
          </Typography>
        </Flex>
      );
    }
  };

  return contents();
};

const PlantedAcresColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PLANTEDACRES]
  >
) => {
  return <div>{prop?.value?.value}</div>;
};

const PastFinalPlantDateColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PASTFINALPLANTDATE]
  >
) => {
  return <div>{prop.value ? "LP" : ""}</div>;
};

const PlantDateColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PLANTDATE]
  >
) => {
  let displayValue = "";
  let value = new Date(prop.value?.value ?? "");
  if (prop.value && isValid(value)) {
    displayValue = format(value, "M/d/y");
  }

  return <div>{displayValue}</div>;
};

const ArTypeColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.ARTYPE]
  >
) => {
  return <div>{prop.value}</div>;
};

// Renderer for the Composite "practice-type-unit-share" object
const PracticeTypeColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.UNITID]
  >
) => {
  // get the crop coverages data
  let units = prop.data?.units;

  const farmUnits = prop?.data?.farmUnits
    ?.filter((f: IPoliciesFarmUnits) => f.farmId == prop?.data?.farm?.farmId)
    .map((f: IPoliciesFarmUnits) => f.unitId);

  // get us only the units matching the currently selected coverage.
  const values = units?.filter(
    (u) =>
      (u.coverageId === prop?.data?.coverageId.value ?? 0) &&
      farmUnits?.includes(u.unitId)
  );

  let unit = values?.find((u) => u.unitId === prop.value?.value ?? 0);

  if (!unit) {
    return (
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Typography className="body-lg" style={{ marginBottom: 3 }}>
          Select
        </Typography>
      </Flex>
    );
  } else {
    return (
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Typography className="body-lg" style={{ marginBottom: 3 }}>
          {unit.practiceName}
          {" - "}
          {unit.typeName}
        </Typography>
        <Typography className="body-sm" style={{ marginBottom: 3 }}>
          {unit.unitNumber}
          {" - "}
          {Math.round(unit.share * 100)}%
        </Typography>
      </Flex>
    );
  }
};

const UninsuredColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.UNINSUREDREASONCODE]
  >
) => {
  return <div>{prop.value}</div>;
};

const UnitNumberColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.UNITNUMBER]
  >
) => {
  return <div>{prop.value}</div>;
};

const PreventedPlantColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.PREVENTEDPLANT]
  >
) => {
  var isEditable: boolean = isFieldEditable(prop.data, prop.context);

  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <input
        type="checkbox"
        checked={prop.data?.preventedPlant.value === true}
        readOnly
        disabled={!isEditable}
      ></input>
    </div>
  );
};

const ActionColumn = (
  prop: ICellRendererParams<
    FieldDataType,
    FieldDataType[typeof FIELDDATA.ACTION]
  >
) => {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <IconButton
        id={`${prop.node.rowIndex}-action`}
        data-testid={`${prop.node.rowIndex}-action`}
        iconName="OverflowMenuVertical"
        disabled={prop.data?.pastAcreageReportDate}
      />
    </div>
  );
};

export {
  AcresColumn,
  ArTypeColumn,
  CropColumn,
  FNTractColumn,
  FieldColumn,
  PastFinalPlantDateColumn,
  PlantDateColumn,
  PlantedAcresColumn,
  PracticeTypeColumn,
  PreventedPlantColumn,
  PriorInsuredColumn,
  UninsuredColumn,
  UnitNumberColumn,
  ActionColumn,
};
