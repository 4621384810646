import { CompareCoverageByCountyThenCommodityName, ComparePremiumLineByCoverageThenPremiumLine } from "components/functions/comparators/compare-functions";
import { IPolicyFarmUnitCoverage, reconstructPolicyFarmUnitCoverage } from "components/functions/filterConstructor";
import { IProducersFilterData } from "components/search/producers-filter";
import {
  IPoliciesCrops,
  IPoliciesUnits,
  PoliciesAcresQueryResult,
  PoliciesCropUnitsQueryResult,
  PoliciesFarmsQueryResult
} from "features/managed-acres";
import _ from "lodash";

export const filterUnits = (
  farmsFilterData: IProducersFilterData,
  farms: Array<PoliciesFarmsQueryResult>,
  acresDetails?: Array<PoliciesAcresQueryResult>,
  unitDetails?: PoliciesCropUnitsQueryResult
): UnitFilterResult => {
  let myResponse: UnitFilterResult = {
    units: unitDetails?.units,
    crops: unitDetails?.crops
  }

  const farmUnitCoverage = reconstructPolicyFarmUnitCoverage(unitDetails, farms);
  let responseFarmUnitCoverages: IPolicyFarmUnitCoverage[] = [];

  if (farmsFilterData.filterValue === "") {
    responseFarmUnitCoverages = farmUnitCoverage;
  } else {

    if (["Unit", "All"].includes(farmsFilterData.filterType)) {
      const matchunits = farmUnitCoverage.filter((f) => {
        return f.unit_unitNumber?.toLowerCase()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });
      matchunits.forEach(matchedUnit => responseFarmUnitCoverages.push(matchedUnit));
    }

    if (["Name", "All"].includes(farmsFilterData.filterType)) {
      let farmsByName = farmUnitCoverage.filter(f => f.name?.toLowerCase()
        .includes(farmsFilterData.filterValue.toLowerCase()));

      farmsByName.forEach(f => responseFarmUnitCoverages.push(f))
    }

    if (["Number", "All"].includes(farmsFilterData.filterType)) {
      let farmsByFsn = farmUnitCoverage.filter(f => f.farmSerialNumber?.toString()
        .toLowerCase()
        .includes(farmsFilterData.filterValue.toLowerCase()));

      farmsByFsn.forEach(f => responseFarmUnitCoverages.push(f))
    }

    if (["Tract", "All"].includes(farmsFilterData.filterType)) {
      let farmFound = farmUnitCoverage.filter(f => f.tractNumber?.toString()
        .toLowerCase()
        .includes(farmsFilterData.filterValue.toLowerCase()));

      farmFound.forEach(f => responseFarmUnitCoverages.push(f))
    }

    if (["Section", "All"].includes(farmsFilterData.filterType)) {
      let farmsBySection = farmUnitCoverage.filter(f => f.section?.toString()
        .toLowerCase()
        .includes(farmsFilterData.filterValue.toLowerCase()));

      farmsBySection.forEach(f => responseFarmUnitCoverages.push(f))
    }

    if (["Township", "All"].includes(farmsFilterData.filterType)) {
      let farmsByTownship = farmUnitCoverage.filter(f => f.township?.toLowerCase()
        .includes(farmsFilterData.filterValue.toLowerCase()));

      farmsByTownship.forEach(f => responseFarmUnitCoverages.push(f))
    }

    if (["Range", "All"].includes(farmsFilterData.filterType)) {
      let farmsByRange = farmUnitCoverage.filter(f => f.range?.toLowerCase()
        .includes(farmsFilterData.filterValue.toLowerCase()));

      farmsByRange.forEach(f => responseFarmUnitCoverages.push(f))
    }

    if (["Crop", "All"].includes(farmsFilterData.filterType)) {
      const matchCrops = farmUnitCoverage.filter((f) => {
        return f.coverage_commodityName?.toString()
          .toLowerCase()
          .trim()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });

      matchCrops.forEach(m => responseFarmUnitCoverages.push(m))
    }

    if (["Practice", "All"].includes(farmsFilterData.filterType)) {
      const matchPractice = farmUnitCoverage.filter((f) => {
        return f.unit_practiceName?.toString()
          .toLowerCase()
          .trim()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });

      matchPractice.forEach(m => responseFarmUnitCoverages.push(m))
    }

    if (["Type", "All"].includes(farmsFilterData.filterType)) {
      const matchType = farmUnitCoverage.filter((f) => {
        return f.unit_typeName?.toString()
          .toLowerCase()
          .trim()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });

      matchType.forEach(m => responseFarmUnitCoverages.push(m))
    }
  }

  if (farmsFilterData.hideEmpty) {
    if (acresDetails) {
      responseFarmUnitCoverages = responseFarmUnitCoverages.filter((f) => {
        let foundAcre = acresDetails.findIndex((a) => {
          return a.farmId === f.farmId;
        });

        return foundAcre >= 0;
      });
    }
  }
  let uniqueFarmUnitCoverages = _.uniq(responseFarmUnitCoverages)

  var unitList = uniqueFarmUnitCoverages.map(item => item.unitId)
  var newUnitlist = myResponse.units?.filter(r => unitList.includes(r.unitId))

  var coverageList = newUnitlist?.map(item => item.coverageId)
  var newCoverageList = myResponse.crops?.filter(crop => coverageList?.includes(crop.coverageId))

  myResponse.crops = newCoverageList?.sort((a, b) => CompareCoverageByCountyThenCommodityName(a, b));
  const sortOrder = new Map<number, number>();
  myResponse.crops?.forEach((Crop, index) => {
    sortOrder.set(Crop.coverageId, index);
  });
  myResponse.units = newUnitlist?.sort((a, b) => ComparePremiumLineByCoverageThenPremiumLine(a, b, sortOrder));

  return myResponse;
};

export interface UnitFilterResult {
  crops: Array<IPoliciesCrops> | undefined;
  units: Array<IPoliciesUnits> | undefined;
}
