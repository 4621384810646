import { Typography } from "@proag/sprout";
import { useFeatureFlag } from "app/feature-flag";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import {
  ProducersBaseFooter,
  ProducersPolicyHeader,
  SideBarDisplayTabs,
} from "components/ui";
import {
  AcresSummary,
  PoliciesCropUnitsQueryResult,
  RmaAdmCountiesResponse,
  mapAcresResultsToAcresSummary,
  useApplicationRolesQuery,
  usePoliciesAcresQuery,
  usePoliciesCropUnitsQuery,
  usePoliciesFarmsQuery,
  useRmaAdmCountiesQuery,
  useRmaAdmStatesQuery,
} from "features/managed-acres";
import { useEffect, useState } from "react";
import {
  ProducersUnitsFieldsBody,
  ProducersUnitsSidebar,
} from "features/planted-acres/units/components";
import { filterUnits } from "features/planted-acres/units/functions/filters/filter-Units-By-Many";
import styles from "./producers-units-fields.module.scss";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { usePolicyIdFromRoute } from "app/utils/policy-utils";
import { IProducersFilterData } from "components/search/producers-filter";
import { PrivacyStatementsFooter } from "../../components/ui/privacy-statements-footer";
import { useMetricsCreateEventPublish } from "app/analytics";

export const ProducersUnitsFields: React.FC = ({}) => {
  const { rmaAdmCounties, isRmaAdmCountiesFetching } = useRmaAdmCountiesQuery(
    new Date().getFullYear()
  );

  usePolicyIdFromRoute();
  const selectedUnitId = useAppStateStore((s) => s.selectedUnitId);

  const [farmsFilterData, setFarmsFilterData] = useState({
    filterType: "",
    filterValue: "All",
    hideEmpty: false,
  } as IProducersFilterData);
  const [previousUnitId, setPreviousUnitId] = useState(0);
  const [nextUnitId, setNextUnitId] = useState(0);

  const isUnitGridValid = useAppStateStore((s) => s.isUnitGridValid);
  const policyId = useAppStateStore((s) => s.policyId);

  const queryClient = useQueryClient();
  const setStoreUnitId = useAppStateStore((s) => s.setSelectedUnitId);
  const setStoreCoverageId = useAppStateStore((s) => s.setSelectedCoverageId);
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);
  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  const { farms, isFarmsFetching } = usePoliciesFarmsQuery(policyId!);

  const { acres, isAcresFetching } = usePoliciesAcresQuery(policyId);

  const { cropUnits } = usePoliciesCropUnitsQuery(Number(policyId));

  const filteredUnits = filterUnits(
    farmsFilterData,
    farms || [],
    acres,
    cropUnits
  );

  const onSelectedUnitHandler = (Id: number) => {
    if (selectedUnitId != Id) {
      if (!isUnitGridValid) {
        toast.error(
          "Please resolve errors in your data before switching units.",
          {
            toastId: "SelectedUnitNavigateGridValidCheck",
          }
        );
      } else {
        // before we hit the next page, make sure we clear the cache so that we can get new data
        queryClient.invalidateQueries(["policiesFarmsFieldsQuery", policyId]); // this will clear ALL policies fields data, not just a single farms.
        queryClient.invalidateQueries([
          "policiesAcresQuery",
          policyId,
          plantingSeason,
        ]);

        setStoreUnitId(Id);
        var selectedUnit = filteredUnits.units?.find((e) => e.unitId == Id);
        if (selectedUnit) {
          setStoreCoverageId(selectedUnit.coverageId);
        }
      }
    } else {
      //console.log("units are the same");
    }
  };

  const onFilterChangeHandler = (filterData: IProducersFilterData) => {
    setFarmsFilterData(filterData);
  };

  let mappedAcres: AcresSummary | undefined = undefined;

  if (!!acres) {
    mappedAcres = mapAcresResultsToAcresSummary(acres);
  }

  useEffect(() => {
    if (cropUnits && rmaAdmCounties) {
      mapCropUnits(cropUnits, rmaAdmCounties);
    }
  }, [cropUnits, rmaAdmCounties]);

  function mapCropUnits(
    cropUnits: PoliciesCropUnitsQueryResult,
    rmaAdmCounties: RmaAdmCountiesResponse
  ) {
    cropUnits.crops.forEach((crop) => {
      crop.countyName = rmaAdmCounties.filter(
        (counties) =>
          counties.countyCode == crop?.countyCode &&
          counties.stateCode == crop?.stateCode &&
          counties.reinsuranceYear.toString() == crop?.reinsuranceYear
      )[0]?.countyName;
    });
  }

  useEffect(() => {
    if (filteredUnits.units && filteredUnits.units.length > 1) {
      let index = filteredUnits.units.findIndex(
        (c) => c.unitId == selectedUnitId
      );
      if (index >= 0)
        setPreviousUnitId(filteredUnits.units[index - 1]?.unitId ?? 0);
      if (index != filteredUnits.units.length)
        setNextUnitId(filteredUnits.units[index + 1]?.unitId ?? 0);
    }
  }, [selectedUnitId]);

  useEffect(() => {
    recordMetric({
      name: "producers-units-fields-view",
      data: {
        category: "ProducersUnitsFields",
        label: "Producers Unit Fields View",
        action: "View",
      },
    });
  }, [recordMetric]);

  return (
    <>
      <div
        className={styles.pageWrapper}
        data-testid={"producers-units-fields"}
      >
        <div className={styles.pageHeader} id="producers-header">
          <ProducersPolicyHeader
            acresSummaryData={mappedAcres}
            acresSummaryDataIsFetching={isAcresFetching}
          />
          <div>
            <SideBarDisplayTabs />
          </div>
        </div>
        <div className={styles.pageContent}>
          <div className={styles.leftColumn}>
            <ProducersUnitsSidebar
              onFilterChange={onFilterChangeHandler}
              crops={filteredUnits?.crops}
              units={filteredUnits?.units}
              setSelectedUnit={onSelectedUnitHandler}
            />
          </div>
          <div className={`${styles.rightColumn} p-3`}>
            {selectedUnitId && (
              <ProducersUnitsFieldsBody
                selectedUnitId={selectedUnitId}
                units={cropUnits}
                previousUnitId={previousUnitId}
                nextUnitId={nextUnitId}
                setSelectedUnit={onSelectedUnitHandler}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <PrivacyStatementsFooter />
      </div>
      <ProducersBaseFooter />
    </>
  );
};
