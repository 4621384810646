import { useMemo } from "react";

interface IGlobalConfig {
  oktaAuth: {
    REACT_APP_OKTA_CLIENT_ID: string;
    REACT_APP_OKTA_ISSUER: string;
    REACT_APP_OKTA_REDIRECT_URL: string;
    REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK: boolean;
  };
  awsRum: {
    REACT_APP_AWSRUM_APPLICATION_ID: string;
    REACT_APP_AWSRUM_APPLICATION_VERSION: string;
    REACT_APP_AWSRUM_APPLICATION_REGION: string;
  };

  awsRumConfig: {
    sessionSampleRate: number;
    guestRoleArn: string;
    identityPoolId: string;
    endpoint: string;
    telemetries: Array<any>;
    allowCookies: boolean;
    enableXRay: boolean;
    sessionAttributes: {
      applicationVersion: string;
    };
  };

  Paw_Url: string;
  ApiGateway_URL: string;
  Logout_Url: string;
  FeatureFlagAppId: number;
  AGGridLicenseKey: string;
}

export function useGlobalConfigValues<T = IGlobalConfig[keyof IGlobalConfig]>(
  key: keyof IGlobalConfig
): T | undefined {
  return useMemo<T | undefined>(
    () =>
      ((window as any)?.globalConfig as IGlobalConfig)?.[key] as T | undefined,
    [key]
  );
}
