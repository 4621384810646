import { PLANTINGSEASON } from "app/common/constants";
import { create } from "zustand";

export interface AppState {
  // policy
  policyId: number;
  setPolicyId: (id: number) => void;

  // UnitGridValid
  isUnitGridValid: boolean;
  setIsUnitGridValid: (valid: boolean) => void;

  // FarmGridValid
  isFarmGridValid: boolean;
  setIsFarmGridValid: (valid: boolean) => void;

  //Coverage Id
  selectedCoverageId: number | undefined;
  setSelectedCoverageId: (id: number) => void;

  //Unit Id
  selectedUnitId: number | undefined;
  setSelectedUnitId: (id: number) => void;

  //Planting Season
  plantingSeason: string | undefined;
  setPlantingSeason: (plantingSeason: string) => void;

  //Farm Id
  selectedFarmId: number;
  setSelectedFarmId: (id: number) => void;

  //Reinsurance Year
  reinsuranceYear: number;
  setReinsuranceYear: (ry: number) => void;
}

// TODO: Someday, we'll probably want to implement persistence of the state:
// https://docs.pmnd.rs/zustand/integrations/persisting-store-data
// Also, there exists a devTools middleware that we might want in testing/debugging scenarios
export const useAppStateStore = create<AppState>((set) => ({
  // policy
  policyId: 0,
  setPolicyId: (id) => set((state) => ({ policyId: id })),

  // UnitGridValid
  isUnitGridValid: true,
  setIsUnitGridValid: (valid) => set((state) => ({ isUnitGridValid: valid })),

  // FarmGridValid
  isFarmGridValid: true,
  setIsFarmGridValid: (valid) => set((state) => ({ isFarmGridValid: valid })),

  //Coverage Id
  selectedCoverageId: undefined,
  setSelectedCoverageId: (coverageId) =>
    set((state) => ({ selectedCoverageId: coverageId })),

  //Unit Id
  selectedUnitId: undefined,
  setSelectedUnitId: (unitId) => set((state) => ({ selectedUnitId: unitId })),

  //Planting Season
  plantingSeason: PLANTINGSEASON.SPRING,
  setPlantingSeason: (plantingSeason) =>
    set((state) => ({ plantingSeason: plantingSeason })),

  //Farm Id
  selectedFarmId: 0,
  setSelectedFarmId: (farmId) => set((state) => ({ selectedFarmId: farmId })),

  //Reinsurance Year
  reinsuranceYear: 0,
  setReinsuranceYear: (ry) => set((state) => ({ reinsuranceYear: ry }))

}));

/** REFERENCE implementations for use in your components
 *
  const policyId = useAppStateStore((s) => s.policyId);
  const setPolicyId = useAppStateStore((s) => s.setPolicyId);

  const isUnitGridValid = useAppStateStore((s) => s.isUnitGridValid);
  const setIsUnitGridValid = useAppStateStore((s) => s.setIsUnitGridValid);

 */
