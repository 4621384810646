import { PoliciesAcresQueryResult } from "features/managed-acres";

export type AcresSummary = {
  calculatedAcres: number;
  plantedAcres: number;
  preventedAcres: number;
  unreportedAcres: number;
  cropTotals: Array<CropTotalsSummary>;
  countyTotals: Array<CountyTotalsSummary>;
  preventedCountyTotals: Array<CountyTotalsSummary>;
};

export type CropTotalsSummary = {
  cropName: string;
  plantedAcres: number;
  countyTotals: Array<CountyTotalsSummary>;
};

export type CountyTotalsSummary = {
  countyName: string;
  plantedAcres: number;
};

export const mapAcresResultsToAcresSummary = (
  acres: Array<PoliciesAcresQueryResult>
): AcresSummary => {
  // sum all the coverageAcres, plantedAcres, and preventedAcres.
  let summary: AcresSummary = {
    calculatedAcres: 0,
    plantedAcres: 0,
    preventedAcres: 0,
    unreportedAcres: 0,
    cropTotals: [],
    countyTotals: [],
    preventedCountyTotals: [],
  };

  summary.calculatedAcres = acres.reduce<number>((acc: number, item) => {
    if (item.splitFromParentFieldId === 0) {
      return acc + item.calculatedAcres;
    }
    return acc;
  }, 0 as number);

  summary.plantedAcres = acres.reduce<number>((acc: number, item) => {
    return acc + item.plantedAcres;
  }, 0 as number);

  summary.preventedAcres = acres.reduce<number>((acc: number, item) => {
    return acc + item.preventedAcres;
  }, 0 as number);

  // first, find the crops our data set has.
  let uniqueCountyName: string[] = [];
  acres.forEach((item) => {
    if (!uniqueCountyName.includes(item.countyName))
      uniqueCountyName.push(item.countyName);
  });

  // now... we've got to identify all the Unique Crops in the main array, and
  // create summary records for them .

  // first, find the crops our data set has.
  let uniqueCrops: string[] = [];
  acres.forEach((item) => {
    if (!uniqueCrops.includes(item.cropName)) uniqueCrops.push(item.cropName);
  });

  // now, build the summary records
  uniqueCrops.forEach((crop) => {
    let _countyTotals: Array<CountyTotalsSummary> = [];

    uniqueCountyName.forEach((county) => {
      _countyTotals.push({
        countyName: county,
        plantedAcres: acres.reduce<number>((acc: number, item) => {
          if (item.countyName === county && item.cropName === crop) {
            return acc + item.plantedAcres;
          } else {
            return acc;
          }
        }, 0 as number),
      });
    });

    summary.cropTotals.push({
      cropName: crop,
      countyTotals: _countyTotals,
      plantedAcres: acres.reduce<number>((acc: number, item) => {
        if (item.cropName === crop) {
          return acc + item.plantedAcres;
        } else {
          return acc;
        }
      }, 0 as number),
    });
  });

  // now, build the summary records
  uniqueCountyName.forEach((county) => {
    summary.countyTotals.push({
      countyName: county,
      plantedAcres: acres.reduce<number>((acc: number, item) => {
        if (item.countyName === county) {
          return acc + item.plantedAcres;
        } else {
          return acc;
        }
      }, 0 as number),
    });
  });

  uniqueCountyName.forEach((county) => {
    summary.preventedCountyTotals.push({
      countyName: county,
      plantedAcres: acres.reduce<number>((acc: number, item) => {
        if (item.countyName === county) {
          return acc + item.preventedAcres;
        } else {
          return acc;
        }
      }, 0 as number),
    });
  });

  summary.unreportedAcres =
    summary.calculatedAcres - summary.plantedAcres - summary.preventedAcres;

  return summary;
};

export const AcresResultsToMapTestData: Array<PoliciesAcresQueryResult> = [
  {
    farmId: 430968,
    fieldId: 2208245,
    fieldNumber: 1,
    subField: "",
    splitFromParentFieldId: 0,
    calculatedAcres: 5.32,
    plantedAcres: 5.0,
    preventedAcres: 0.0,
    cropName: "WHEAT",
    countyName: "",
    coverageId: 1,
    unitId: 1,
    acreageReportId: 1,
  },
  {
    farmId: 430968,
    fieldId: 2208246,
    fieldNumber: 2,
    subField: "a",
    splitFromParentFieldId: 0,
    calculatedAcres: 124.66,
    plantedAcres: 100.0,
    preventedAcres: 0.0,
    cropName: "WHEAT",
    countyName: "",
    coverageId: 1,
    unitId: 1,
    acreageReportId: 1,
  },
  {
    farmId: 430968,
    fieldId: 2208247,
    fieldNumber: 2,
    subField: "b",
    splitFromParentFieldId: 2,
    calculatedAcres: 124.66,
    plantedAcres: 0.0,
    preventedAcres: 24.6,
    cropName: "",
    countyName: "",
    coverageId: 1,
    unitId: 1,
    acreageReportId: 1,
  },
  {
    farmId: 430968,
    fieldId: 1,
    fieldNumber: 2,
    subField: "",
    splitFromParentFieldId: 0,
    calculatedAcres: 500,
    plantedAcres: 1.0,
    preventedAcres: 24.6,
    cropName: "CandyCorn",
    countyName: "",
    coverageId: 1,
    unitId: 1,
    acreageReportId: 1,
  },
  {
    farmId: 430968,
    fieldId: 1,
    fieldNumber: 2,
    subField: "asd",
    splitFromParentFieldId: 1,
    calculatedAcres: 500,
    plantedAcres: 499.0,
    preventedAcres: 0,
    cropName: "Pumpkin",
    countyName: "",
    coverageId: 1,
    unitId: 1,
    acreageReportId: 1,
  },
];
