import { AuthState } from "@okta/okta-auth-js";
import {
  blobContentTypes,
  contentDispositions,
  httpBearerPrefix,
  httpHeaderNames,
  httpStatusCodes,
  jsonContentTypes,
  textContentTypes,
  validResponseStates,
} from "../constants/http";
import { defaultApiUri } from "../constants/urls";
import {
  ApiRequestHeaders,
  ApiRequestInfo,
  ApiResponse,
  ApiResponseBody,
} from "../types";

export function formatContentTypeDispositionHeader(
  disposition: keyof typeof contentDispositions,
  filename?: string
) {
  if (filename) {
    return `${disposition}; filename=\"${filename}\"`;
  }

  return disposition;
}

export function getApiGatewayUrl(window: any) {
  return window?.globalConfig?.ApiGateway_URL ?? defaultApiUri;
}

export function getPawURL(window: any): string {
  return window?.globalConfig?.Paw_Url ?? "";
}

export function formatUrl(url: string) {
  return getApiGatewayUrl(window) + url;
}

export function getContentType(response: Response): string | null {
  if (!response.headers.has(httpHeaderNames.contentType)) {
    return null;
  }

  const contentType = response.headers.get(httpHeaderNames.contentType);
  if (!contentType) {
    return null;
  }

  return contentType;
}

export function isTextResponse(contentType: string) {
  return textContentTypes.includes(contentType);
}

export function isJsonResponse(contentType: string) {
  return jsonContentTypes.includes(contentType);
}

export function isBlobResponse(contentType: string) {
  return blobContentTypes.includes(contentType);
}

export async function getTypedResponseBody(
  response: Response
): Promise<ApiResponseBody> {
  if (response.status == httpStatusCodes.noContent) {
    return [];
  }

  const contentType = getContentType(response);
  if (!contentType) {
    return null;
  }

  if (isJsonResponse(contentType)) {
    return await response.json();
  }

  if (isTextResponse(contentType)) {
    return await response.text();
  }

  if (isBlobResponse(contentType)) {
    return await response.blob();
  }

  //in the unknown case, where a content-type is provided
  //default to blob
  return await response.blob();
}

export function shouldReturnData(response: Response) {
  return validResponseStates.includes(response.status);
}

export async function handleResponse(response: Response): Promise<ApiResponse> {
  let data: ApiResponseBody = null;
  try {
    data = await getTypedResponseBody(response);

    if (shouldReturnData(response)) {
      return {
        isError: false,
        error: undefined,
        headers: response.headers,
        status: response.status,
        statusText: response.statusText,
        data,
      };
    }

    return {
      isError: true,
      error: undefined,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      data,
    };
  } catch (error) {
    return {
      isError: true,
      error: error,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      data,
    };
  }
}

export function mapHeaders(headers: ApiRequestHeaders) {
  var result = new Headers();
  result.set(httpHeaderNames.applicationId, headers.applicationId);

  if (headers.authorization) {
    result.set(httpHeaderNames.authorization, headers.authorization);
  }

  if (headers.accept && headers.accept.length) {
    for (let type in headers.accept) {
      result.set(httpHeaderNames.accept, type);
    }
  }

  if (headers.contentType) {
    result.set(httpHeaderNames.contentType, headers.contentType);
  }

  return result;
}

export function mapRequestInfo(request: ApiRequestInfo): RequestInit {
  const requestInit: RequestInit = {
    method: request.method,
    body: request.body,
    signal: request.signal,
    cache: request.cache,
  };

  return requestInit;
}

export async function send(request: ApiRequestInfo): Promise<ApiResponse> {
  const requestInit = mapRequestInfo(request);
  requestInit.headers = mapHeaders(request.headers);

  const absoluteUrl = formatUrl(request.url);

  const response = await fetch(absoluteUrl, requestInit);
  return await handleResponse(response);
}
