import { useIsMutating } from "@tanstack/react-query";
import {
  CellValueChangedEvent,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  RowDataUpdatedEvent,
  TabToNextCellParams,
} from "ag-grid-community";
import { areValuesSame } from "app/utils/areValuesSame";
import { AgGridReact } from "ag-grid-react";
import { FIELDDATA } from "app/common/constants";
import { CustomHeader } from "components/grid/custom-headers/custom-header";
import { GenerateGridOption } from "components/grid/grid-options";
import {
  PoliciesFarmsFieldRecord,
  PoliciesFarmsFieldUpdateCommand,
  getGridValidationErrors,
  getPastFinalPlantDate,
  mapApplicationRolesQueryResults,
  useApplicationRolesQuery,
  usePoliciesFarmsFieldUpdateMutation,
} from "features/managed-acres";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { BooleanValidationField, FieldDataType } from "./FieldDataType";
import { FarmFieldsAcreageFooterTable } from "./farm-fields-acerage-footer-table";
import { FarmFieldsTableColumnDefinitions } from "./farm-fields-table-column-definitions";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { mapPoliciesFarmsFieldRecordFromFieldDataType } from "features/planted-acres/functions/utility/map-policiesfarmsfieldrecord-from-fielddatatype";
import {
  FarmFieldsTabToNextCellHook,
  useDeleteField,
  useSplitField,
} from "../../../hooks";
import { GridValidator, isFieldEditable } from "./field-validators";
import "ag-grid-community/styles/ag-grid.css";
import "@proag/sprout/themes/ag-theme-sprout.css";
import { useMetricsCreateEventPublish } from "app/analytics";

interface FarmFieldsTableProps {
  isLoading: boolean;
  data?: FieldDataType[];
  onGridApiReady(api: GridApi): any;
  onSelectRowChange(capi: GridApi): any;
}
/*
  Notes for future me:

  example showing "hierarchical" dropdowns, where each one is driven by earlier choices.
  https://stackblitz.com/edit/react-ag-grid-cascading-dropdowns-blog?file=index.js

  here's an example with custom picker and state driven columnDefs
  https://stackoverflow.com/questions/62629176/how-to-add-a-button-and-a-dropdown-in-aggrid-cell-in-react-functional-component
*/
export const FarmFieldsTable: React.FC<FarmFieldsTableProps> = ({
  isLoading,
  data,
  onGridApiReady,
  onSelectRowChange,
}) => {
  const policyId = useAppStateStore((s) => s.policyId);
  const farmId = useAppStateStore((s) => s.selectedFarmId);
  const setIsFarmGridValid = useAppStateStore((s) => s.setIsFarmGridValid);
  const gridOne = useRef<AgGridReact>(null);

  const [rowData, setRowData] = useState(data);
  const [acreageFooterData, setAcreageFooterData] = useState(data);
  const isImportMutating = useIsMutating({ mutationKey: ["importACRSI"] });
  const { SplitField } = useSplitField();
  const { DeleteField } = useDeleteField();
  const { mutate: recordMetric } = useMetricsCreateEventPublish();
  const { ApplicationRolesData } = useApplicationRolesQuery();
  const { isRoleAgent, isRoleUnderwriter, isRoleProducer } = useMemo(
    () => mapApplicationRolesQueryResults(ApplicationRolesData),
    [ApplicationRolesData]
  );

  const isMutatingBulkEdit = useIsMutating({ mutationKey: ["fieldsBulkEdit"] });

  useEffect(() => {
    if (rowData && rowData.length > 0) {
      GridValidator("farm", "full", gridOne.current?.api);
    }
  }, [rowData]);

  if (isLoading || isMutatingBulkEdit) {
    gridOne.current?.api?.showLoadingOverlay();
  }

  if (!isLoading && !isMutatingBulkEdit) {
    showHideOverlay();
  }

  useEffect(() => {
    if (gridOne && gridOne.current && gridOne.current.api) {
      if (isImportMutating) {
        gridOne.current?.api?.showLoadingOverlay();
      } else {
        showHideOverlay();
      }
    }
  }, [isImportMutating]);

  useEffect(() => {
    setRowData(data);
    setAcreageFooterData(data);
    updateGridOption();
  }, [data, isRoleUnderwriter]);

  const { mutateFieldsUpdate: mutateFields } =
    usePoliciesFarmsFieldUpdateMutation();

  const onCellValueChanged = (e: CellValueChangedEvent<FieldDataType>) => {
    /// OK, so we're going to do the save here, automatically, instead of in response to some button click.
    /// so, we need to remove the button actions,
    /// and implement the save here. :D

    console.log("cellValue changed handler on table....");
    const colId = e.column.getColId();
    // set isImportedFromPlanetWatchers to false if user overrides any value
    if (colId != FIELDDATA.SUBFIELD && e.oldValue.value != e.newValue.value) {
      if (!areValuesSame(e.oldValue.value, e.newValue.value)) {
        e.data.isImportedFromPlanetWatchers = false;
      }
    }
    let policiesFarmsFieldRecordObject: PoliciesFarmsFieldRecord =
      mapPoliciesFarmsFieldRecordFromFieldDataType(e.data);

    // now attach the record to the command...
    let policiesFarmsFieldUpdateCommandObject: PoliciesFarmsFieldUpdateCommand =
      {
        policyId: policyId,
        farmId: farmId,
        record: policiesFarmsFieldRecordObject,
      };

    mutateFields(policiesFarmsFieldUpdateCommandObject); // on success / error ??

    recordMetric({
      name: "producers-farm-field-update",
      data: {
        category: "ProducersFarmsFields",
        label: "Producers Farm Field Update",
        action: "Update",
      },
    });

    // ok, so we've (attempted to have) saved the data,
    // now we should inspect the grid to see if we have validation issues.

    const validationMessages: string[] = getGridValidationErrors(e.api);

    if (validationMessages.length > 0) {
      console.log("we have validation messages... set gridIsValid to False");
      setIsFarmGridValid(false);
    } else {
      console.log("NO validation messages... set gridIsValid to True");
      setIsFarmGridValid(true);
    }

    if (colId === FIELDDATA.UNITID || colId === FIELDDATA.PLANTDATE) {
      let unit = e.data?.units?.find(
        (x) => x.unitId === e.data.unitId.value ?? 0
      );
      e.data.pastFinalPlantDate = getPastFinalPlantDate(
        unit && unit.finalPlantDate ? unit.finalPlantDate : "",
        e.data.plantDate.value ? e.data.plantDate.value : ""
      ); //+ 'T00:00:00'
      e.api.refreshCells();
    }

    if (
      colId === FIELDDATA.PLANTEDACRES ||
      colId === FIELDDATA.COVERAGEID ||
      colId === FIELDDATA.PREVENTEDPLANT
    ) {
      const allRowData: FieldDataType[] = [];
      e.api.forEachNode((node) => {
        if (node.data) {
          allRowData.push(node.data);
        }
      });

      setAcreageFooterData(allRowData);
    }
  };

  const gridOptions = GenerateGridOption(
    isRoleUnderwriter,
    isRoleProducer,
    isRoleAgent
  );

  const onRowDataUpdated = (rowDataUpdatedEvent: RowDataUpdatedEvent) => {
    validateGrid(rowDataUpdatedEvent.api);
  };

  const validateGrid = (api: GridApi) => {
    const validationMessages: string[] = getGridValidationErrors(api);
    if (validationMessages.length > 0) {
      setIsFarmGridValid(false);
    } else {
      setIsFarmGridValid(true);
    }
  };

  const components = useMemo<{
    [p: string]: any;
  }>(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);

  const tabToNextCell = useCallback(
    (params: TabToNextCellParams<FieldDataType>) =>
      FarmFieldsTabToNextCellHook(params, gridOne),
    []
  );

  function showHideOverlay() {
    if (gridOne.current?.api?.getDisplayedRowCount()) {
      gridOne.current?.api.hideOverlay();
    } else {
      gridOne.current?.api?.showNoRowsOverlay();
    }
  }

  const onCellClicked = (params: any) => {
    if (params.colDef.field == "action") {
      params.api.contextMenuFactory.showMenu(
        params.node,
        params.column,
        params.value,
        params.event
      );
    }
  };

  //params: CellKeyDownEvent
  const onCellKeyDown = useCallback((params: any) => {
    if (params.event?.key != "Enter") {
      return;
    }

    // If enter key is pressed check or uncheck pp
    if (params.colDef.field == FIELDDATA.PREVENTEDPLANT) {
      if (params.data) {
        let newValue: BooleanValidationField = {
          value: !params.data.preventedPlant.value,
          isValid: false,
          warningMsg: false,
          validationMessage: "",
        };
        params.node?.setDataValue(FIELDDATA.PREVENTEDPLANT, newValue);
      }
    }

    if (params.colDef.field == "action") {
      var element = document.getElementById(`${params.rowIndex}-action`);
      // The method showMenu accepts mouse click event as param
      // but we have got the key down event which does not have x, y cordinates
      // so getting the position of the action button and set the
      // x,y cordinates to show the menu at the right place
      var position = element?.getBoundingClientRect();
      if (position) {
        params.event.clientX = position?.x;
        params.event.clientY = position?.y + 20;
      }
      params.api.contextMenuFactory.showMenu(
        params.node,
        params.column,
        params.value,
        params.event,
        element
      );
      params.event?.preventDefault();
    }
  }, []);

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      if (params.column?.getColId() != "action") {
        return [];
      }

      var isEditable: boolean = isFieldEditable(
        params.node?.data,
        params.context
      );
      var cellPos = params.api.getFocusedCell();
      var rowIndex: number = cellPos?.rowIndex ?? -1;
      if (rowIndex < 0) {
        return [];
      }

      var gridData: FieldDataType[] = [];
      params.api.forEachNode((e) => gridData.push(e.data));
      var rowData = gridData[rowIndex];

      var menuItems: (string | MenuItemDef)[] = [
        {
          name: `Split Field`,
          action: () => {
            SplitField(policyId, rowData, params.api, rowIndex);
          },
          disabled: !isEditable,
        },
      ];

      if (
        rowIndex >= 0 &&
        gridData &&
        gridData.length > 0 &&
        gridData[rowIndex]!.splitParentId > 0
      ) {
        var deleteMenu = {
          name: "Delete",
          action: () => {
            DeleteField(policyId, rowData, params.api);
          },
          disabled: !isEditable,
        };
        menuItems.push(deleteMenu);
      }

      return menuItems;
    },
    []
  );

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const [gridWrapperStyle, setGridWrapperStyle] = useState<any>({
    style: { height: `calc(100vh - ${500}px)` },
  });

  const updateGridOption = () => {
    const gridOptions = GenerateGridOption(
      isRoleUnderwriter,
      isRoleProducer,
      isRoleAgent
    );
    gridOne?.current?.api?.updateGridOptions(gridOptions);
  };

  return (
    <>
      <div
        className="ag-theme-sprout"
        style={{
          ...gridWrapperStyle.style,
        }}
      >
        <AgGridReact<FieldDataType>
          gridOptions={gridOptions}
          components={components}
          domLayout="normal"
          rowData={isLoading || isMutatingBulkEdit ? undefined : rowData}
          columnDefs={FarmFieldsTableColumnDefinitions}
          rowHeight={50}
          headerHeight={48}
          noRowsOverlayComponent={NoRowsOverlay}
          defaultColDef={{ resizable: true }}
          singleClickEdit={true}
          onGridReady={(params: GridReadyEvent) => {
            onGridApiReady(params.api);
          }}
          onSelectionChanged={(params: GridReadyEvent) => {
            onSelectRowChange(params.api);
          }}
          onCellValueChanged={onCellValueChanged}
          getRowId={(params: GetRowIdParams<FieldDataType>) =>
            params.data.fieldId.toString()
          }
          onRowDataUpdated={onRowDataUpdated}
          ref={gridOne}
          stopEditingWhenCellsLoseFocus={true}
          tabToNextCell={tabToNextCell}
          suppressDragLeaveHidesColumns={true}
          allowContextMenuWithControlKey={false}
          suppressContextMenu={false}
          getContextMenuItems={getContextMenuItems}
          onCellClicked={onCellClicked}
          onCellKeyDown={onCellKeyDown}
          popupParent={popupParent}
          suppressRowVirtualisation={true}
        />
      </div>

      <div style={{ flex: "none", height: "60px" }}>
        <FarmFieldsAcreageFooterTable
          parentGrid={gridOne}
          fieldData={acreageFooterData}
        />
      </div>
    </>
  );
};
