import { Flex, IconButton, colors, Icon, Button, Sheet } from "@proag/sprout";
import {
  IPoliciesCrops,
  IPoliciesUnits,
  useRmaAdmCountiesQuery,
} from "features/managed-acres";
import _lodash from "lodash";
import { useEffect, useState } from "react";
import { REINSURANCE_YEAR,BUTTON_TEXT,TOOLTIP_TEXT, UNIT_STRUCTURE_CODES } from "../../../../app/common/constants";
import { Feature147540, useFeatureFlag } from "../../../../app/feature-flag/useFeatureFlag";
import { ManageUnitStructureFooter } from "../../../../components/ui/manage-unit-structure-footer";
import styles from "./producers-planted-acres-review-units-sidebar-card.module.scss";
import { ProducersReviewUnitsSidebarUnit } from "./producers-planted-acres-review-units-sidebar-unit";
import { UnitStructureReviewCoveragesSidebar } from "./unit-structure-review-coverages-sidebar";
import { PoliciesCoveragesUnitStructureQueryResult} from "features/managed-acres/hooks/policies/models/policies-coverages-unitstructure-query-result";
import { UnitStructureReviewSidebarSheet } from "./unit-structure-review-sidebar-sheet";

interface ProducersReviewUnitSidebarCoverageCardProps {
  isSelected: boolean;
  units: Array<IPoliciesUnits>;
  coverage: IPoliciesCrops;
  coverageUnitStructure?: PoliciesCoveragesUnitStructureQueryResult;
  onReviewButtonClick(coverageId : number | undefined) : void;
}

export const ProducersReviewUnitsSidebarCoverageCard: React.FC<
  ProducersReviewUnitSidebarCoverageCardProps
> = ({ isSelected, units, coverage, coverageUnitStructure,onReviewButtonClick}) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  const [countyName, setCountyName] = useState("???");

  const { featureFlag: isEOUnitStructureEnabled } = useFeatureFlag(Feature147540);
  const isShowWarning = coverageUnitStructure && coverageUnitStructure.unitOptionCode 
                        && coverageUnitStructure.unitOptionCode.code == UNIT_STRUCTURE_CODES.EU_by_Organic_Prac
                        && coverageUnitStructure.systemUnitStructureOptionCode != coverageUnitStructure.unitOptionCode.code 
                        && !(coverageUnitStructure.coverageUnitStructureOptionOverride?.overrideSelection 
                        && coverageUnitStructure.coverageUnitStructureOptionOverride?.overrideSelection.length > 0)
  const currentYear: number =
    coverage.reinsuranceYear === undefined
      ? new Date().getFullYear()
      : _lodash.toInteger(coverage.reinsuranceYear);
  const { rmaAdmCounties, isRmaAdmCountiesFetching } =
    useRmaAdmCountiesQuery(currentYear); // get our counties.

  useEffect(() => {
    if (!!rmaAdmCounties && rmaAdmCounties.length > 0) {
      let matchedCountiesById = rmaAdmCounties.filter((c) => {
        return (
          c.countyCode === coverage.countyCode &&
          c.stateCode === coverage.stateCode &&
          c.reinsuranceYear.toString() === coverage.reinsuranceYear
        );
      });
      
      setCountyName(matchedCountiesById[0].countyName);
    } else {
      setCountyName("???");
    }
  }, [rmaAdmCounties]);

  // let's build out the coverage summary slugs
  const cty = `${countyName} County`;
  const ipa = `${coverage!.insurancePlanAbbrev}`;
  const pef = `${Math.round(coverage!.priceElectionFactor * 100)}%`;

  /* prettier-ignore */
  const lev = coverage!.coverageByPracticeChecked
    ? `${Math.round(coverage!.irrCoverageLevel * 100)}%/${Math.round(coverage!.coverageLevel * 100)}%`
    : `${Math.round(coverage!.coverageLevel * 100)}%`

  /* prettier-ignore */
  const cropCoverageSummary = `${ipa} ${lev} - ${pef}`;

  return (
    <>
      <div
        data-testid={"producers-review-units-sidebar-coverage-card"}
        className={`${styles.hoverClick} w-100 mb-2 ${isSelected ? styles.selected : ""
          }`}
      >
        <div>
          <div>
            <div onClick={() => setShow(!show)} data-testid={"producers-review-units-sidebar-card-" + coverage.coverageId.toString()}>
              <Flex direction="row" alignItems="flex-end" justifyContent="space-between">
                <Flex>
                  <div className="pt-3 pl-3 pb-3">
                    <div className="title-sm">{coverage.commodityName}
                      {isEOUnitStructureEnabled && Number(coverage.reinsuranceYear) >= REINSURANCE_YEAR.RY2025
                        && isShowWarning &&
                        <span>
                          <Icon iconName="WarningFilled" className={`${styles.tooltip_icon}`} size="sm" color={colors.orange70} />
                          <div className={`${styles.tooltip}`}>
                            <span className={`${styles.tooltiptext}`}>
                              <p className={`${styles.tooltippara}`}>{`${TOOLTIP_TEXT.TEXT_1} ${coverageUnitStructure.systemUnitStructureOptionCode ?? "Blank"}`}.</p>
                              <p className={`${styles.tooltippara}`}>{TOOLTIP_TEXT.TEXT_2}</p>
                              <Flex justifyContent="center">
                                <Button rightIcon="ArrowRight" size="sm" className={`${styles.buttoncss}`}
                                  onClick={()=>{onReviewButtonClick(coverageUnitStructure.coverageID)}}>{BUTTON_TEXT.ReviewUnitStructure}</Button>
                              </Flex>
                            </span>
                          </div>
                        </span>
                      }

                    </div>
                    <div
                      className="label-sm"
                      style={{ color: colors.neutral40 }}
                    >
                      {cty}
                    </div>
                    <div
                      className="label-sm"
                      style={{ color: colors.neutral40 }}
                    >
                      {cropCoverageSummary}
                    </div>
                  </div>
                </Flex>
                <Flex>
                  <Flex
                    direction="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                  >
                    <Flex
                      direction="column"
                      alignItems="flex-end"
                      justifyContent="space-between"
                      style={{
                        backgroundColor: colors.neutral98,
                      }}
                      className={"p-3 mr-2 mb-2 ml-2"}
                    >
                      <Flex direction="row" alignItems="baseline">
                        <div
                          className="label-sm  mb-1"
                          style={{ color: colors.neutral40 }}
                        >
                          {coverage.reportedAcres === 0 ? "No" : ""} Reported Acres
                        </div>
                      </Flex>
                      <Flex
                        direction="row"
                        alignItems="baseline"
                        justifyContent="flex-start"
                      >
                        {coverage.reportedAcres > 0 && (
                          <>
                            <div className="label-md mr-1">
                              {(coverage.reportedAcres
                              ).toFixed(2)}
                            </div>
                            <div
                              className="label-sm"
                              style={{ color: colors.neutral40 }}
                            >
                              / {coverage.totalAcres.toFixed(2)}
                            </div>
                          </>
                        )}
                      </Flex>
                    </Flex>
                    <Flex
                      direction="column"
                      alignItems="flex-end"
                      justifyContent="space-between"
                      className={"mr-2 mb-4 ml-1"}
                    >
                      <IconButton
                        data-testid={
                          "producers-review-units-sidebar-card-button-" +
                          coverage.coverageId.toString()
                        }
                        className="right-align"
                        iconName={show ? "ChevronUp" : "ChevronDown"}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </div>
            {show &&
              units?.map((unit) => {
                return (
                  <ProducersReviewUnitsSidebarUnit
                    key={unit.unitId}
                    coverage={coverage}
                    unit={unit}
                    isSelected={false}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
