import { FC, useEffect, useState } from "react";
import {
  createCluIdFeatureQuery, UpdateMapEffect,
} from "components/maps/mapUtils";
import {
  useCountyShapesQuery,
  usePoliciesFarmsFieldsQuery,
  usePoliciesFarmsShapesQuery,
  useRmaAdmStatesQuery,
} from "features/managed-acres";
import { GeoJsonMap } from "components/maps/geo-json-map";
import { useAppStateStore } from "app/state-management/use-app-state-store";

export interface ProducersFarmsFieldsMapProps {
  countyCode: string;
  stateCode: string;
}

export const ProducersFarmsFieldsMap: FC<ProducersFarmsFieldsMapProps> = ({
  countyCode,
  stateCode,
}) => {
  const policyId = useAppStateStore((s) => s.policyId);
  const farmId = useAppStateStore((s) => s.selectedFarmId);

  // inspect the farms clu shapes via hook
  // const {cluShapes} = useClueShapesApi(farmId);
  // this query should be executed on the parent element, in the hopes that the cacheing will speed things up for us

  // if no cluShapes.. then we fallback to countyShape
  // const {countyShapes} = useCountyShapesApi(countyCode,  stateCode);
  // this query should be executed on the parent element, in the hopes that the cacheing will speed things up for us

  const [mapQuery, setMapQuery] = useState("");
  const [cluCount, setCluCount] = useState(0);
  const [stateName, setStateName] = useState("");
  const [geoJsonCluShapes, setGeoJsonCluShapes] = useState("");

  const { farmsFields, isFarmsFieldsFetching } = usePoliciesFarmsFieldsQuery(
    policyId,
    farmId
  );

  const { farmsShapes, isFarmsShapesFetching } = usePoliciesFarmsShapesQuery(
    policyId,
    farmId
  );

  const { rmaAdmStates, isRmaAdmStatesFetching } = useRmaAdmStatesQuery();

  const { countyShapeData, isCountyShapeDataFetching } = useCountyShapesQuery(
    stateName,
    countyCode
  );

  useEffect(() => {
    if (!rmaAdmStates) return;
    const state =
      rmaAdmStates.find((s) => {
        return s.stateCode == stateCode;
      })?.stateName ?? "";

    setStateName(state);
  }, [rmaAdmStates]);

  useEffect(() => {
    UpdateMapEffect(farmsShapes, setGeoJsonCluShapes)
  }, [farmsShapes]);

  useEffect(() => {
    if (!!farmsFields) {
      let clus = farmsFields.map((f) => f.cluId);
      setCluCount(clus.length);
      setMapQuery(createCluIdFeatureQuery(clus));
    }
  }, [farmsFields]);

  return (
    <>
      <div
        style={{ width: "100%", height: "480px" }}
        data-testid="pff-land-map"
      >
        {cluCount > 0 && <GeoJsonMap geoData={geoJsonCluShapes} />}

        {cluCount === 0 &&
          !isCountyShapeDataFetching &&
          countyShapeData !== undefined && (
            <GeoJsonMap geoData={countyShapeData} />
          )}
      </div>
    </>
  );
};
