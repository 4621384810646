import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { AdminTool, useApiGateway } from "app/apiGateway";
import { useGlobalConfigValues } from "app/utils";

export const FeaturePlanetWatchersIsActive = "FeaturePlanetWatchersIsActive";
export const FeatureFarmUnitEditIsActive = "FeatureFarmUnitEditIsActive";
export const FeatureFarmUnitSkipRowEditIsActive = "FeatureFarmUnitSkipRowEditIsActive";
export const Feature147540 = "FEATURE 147540"; /*Feature 147540: Unit Structure Modernization(EO) - Simplified AR UI Changes*/
export const UnitStructureButtonFix_US147904 ="UnitStructureButtonFix_US147904";
export interface IFeatureFlagResponse {
  applicationId: number;
  featureFlagId: number;
  name: string;
  description: string;
  enabled: boolean;
}

export interface IUseFeatureFlagReturnValue {
  featureFlag: boolean | undefined;
  isFlagLoading: boolean;
  isSuccess: boolean;
}

export const useFeatureFlag = (
  flagName:
    | typeof FeaturePlanetWatchersIsActive
    | typeof FeatureFarmUnitEditIsActive
    | typeof FeatureFarmUnitSkipRowEditIsActive
    | typeof Feature147540
    | typeof UnitStructureButtonFix_US147904
): IUseFeatureFlagReturnValue => {
  const apiGateway = useApiGateway();

  const applicationId = useGlobalConfigValues<number>("FeatureFlagAppId");

  const { data, isLoading, ...rest } = useQuery<IFeatureFlagResponse[]>(
    ["feature-flags", applicationId],
    () =>
      apiGateway.get(`v1/applications/${applicationId}/featureflags`, AdminTool)
  );

  return {
    featureFlag: useMemo(
      () => data && (data.find((f) => f.name === flagName)?.enabled ?? false),
      [data, flagName]
    ),
    isFlagLoading: isLoading,
    ...rest,
  };
};
