import { HttpMethod } from "../types";

export const httpBearerPrefix = "Bearer ";

export const httpMethods = {
  get: "GET" as HttpMethod,
  post: "POST" as HttpMethod,
  put: "PUT" as HttpMethod,
  delete: "DELETE" as HttpMethod,
  patch: "PATCH" as HttpMethod,
};

export const httpHeaderNames = {
  accept: "Accept",
  applicationId: "ApplicationId",
  authorization: "Authorization",
  contentDisposition: "Content-Disposition",
  contentType: "Content-Type",
  setAuthorization: "Set-Authorization",
};

export const httpStatusCodes = {
  // response body may be useful
  ok: 200,
  created: 201, // synchronously adding new items
  accepted: 202, // asynchronously queuing requests or events
  noContent: 204, // empty array
  notChanged: 304, // avoid need to reload item
  unprocessable: 422, // validation errors
  badRequest: 400, // bad request or validation errors

  // response body, if any, should be ignored
  // may have ui implications
  unauthorized: 401, // unauthorized
  forbidden: 403, // forbidden
  notFound: 404, // not found
  conflict: 409, // conflict
  lengthRequired: 411, // length required
  payloadTooLarge: 413, // payload too large
  locked: 423, // locked

  // response body, if any, can be ignored
  // may have retry or ui implications
  requestTimedOut: 408, // request timed out
  tooManyRequests: 429, // too many requests

  // expected only during active development
  notImplemented: 501, // not implemented (return dummy data?)

  // loggable, but not informative
  serverError: 500, // server error
  badGateway: 502, // bad gateway
  serviceUnavailable: 503, // service unavailable
  gatewayTimeout: 504, // gateway timeout
};

export const validResponseStates = [
  httpStatusCodes.ok,
  httpStatusCodes.created,
  httpStatusCodes.accepted,
  httpStatusCodes.noContent,
  httpStatusCodes.notChanged,
  httpStatusCodes.unprocessable,
  httpStatusCodes.badRequest,
];

export const contentTypes = {
  //json
  json: "application/json",
  jsonUtf8: "application/json; charset=utf-8",

  //text
  plaintext: "text/plain",
  plaintextUtf8: "text/plain;charset=UTF-8",
  csv: "text/csv",

  //web text
  html: "text/html",
  formdata: "multipart/form-data",

  //blob
  pdf: "application/pdf",
  zip: "application/zip",
  octetStream: "application/octet-stream",
};

export const textContentTypes = [
  contentTypes.plaintext,
  contentTypes.plaintextUtf8,
  contentTypes.csv,
  contentTypes.html,
  contentTypes.formdata,
];

export const jsonContentTypes = [contentTypes.json, contentTypes.jsonUtf8];

export const blobContentTypes = [
  contentTypes.pdf,
  contentTypes.zip,
  contentTypes.octetStream,
];

export const contentDispositions = {
  inline: "inline",
  attachment: "attachment",
};
