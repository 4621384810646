import { useNavigate } from "react-router-dom";
import styles from "./producers-planted-acres-review-footer.module.scss";
import {Button, Flex} from "@proag/sprout";
import React from "react";
import { routeToPaw } from "app/utils/policy-utils";
import { useAppStateStore } from "app/state-management/use-app-state-store";


export interface ProducersPlantedAcresReviewFooterProps {}

export const ProducersPlantedAcresReviewFooter: React.FC<ProducersPlantedAcresReviewFooterProps> = () => {
  const navigate = useNavigate();

  const policyId = useAppStateStore((s) => s.policyId);

  const backToAcres = () => {
    //this handles going back to the farms or units using the built in "back" functionality
    navigate(-1);
  };

  return (
    <>
      <footer className={styles.footer} id="page-footer">
        <Button size={'sm'}
                data-testid="producers-planted-acres-footer-back-button"
                variant="neutral-open"
                leftIcon="ArrowLeft"
                onClick={backToAcres}
        >
          Back to Acres
        </Button>
        <Flex direction={'row'} justifyContent="flex-end" alignItems="flex-end">
          <Flex
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end">
            <Button size={'sm'}
                data-testid="producers-planted-acres-footer-detail-lines-button"
                    rightIcon="ArrowRight"
                    onClick={() => {
                      routeToPaw(policyId);
                    }}
            >
              Back to Detail Lines
            </Button>
          </Flex>
        </Flex>
      </footer>
    </>
  );
};
