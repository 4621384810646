import { useAppStateStore } from "app/state-management/use-app-state-store";
import { LoadingSpinner } from "components/common/LoadingSpinner";
import styles from "./producers-planted-acres-review-sidebar.module.scss";
import { UnitStructureReviewSidebarCoverageCard } from "./unit-structure-review-sidebar-coverage-card";
import { usePoliciesCoveragesUnitStructureQuery, usePoliciesCoveragesAcreageReportingDetail } from "features/managed-acres/hooks/policies/policiesQueries";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PoliciesCoveragesUnitStructureQueryResult } from "features/managed-acres/hooks/policies/models/policies-coverages-unitstructure-query-result";
import { UNIT_STRUCTURE_CODES } from "app/common/constants";

interface UnitStructureReviewCoveragesSidebarProps {
  coverageId?: number;
  showAlert?: boolean;
  isCollapse?: boolean;
  CoveragesUnitStructureToUpdate(cov: PoliciesCoveragesUnitStructureQueryResult | undefined): void;
  isSaved: boolean;

}

export const UnitStructureReviewCoveragesSidebar: React.FC<UnitStructureReviewCoveragesSidebarProps> = ({ coverageId, showAlert, isCollapse, CoveragesUnitStructureToUpdate, isSaved }) => {
  const policyId = useAppStateStore((s) => s.policyId);
  const { isCoverageUnitStructureFetching, coveragesUnitStructure, error, refetch } = usePoliciesCoveragesUnitStructureQuery(policyId);
  const [coveragesUnitStructureCurrent, setCoveragesUnitStructureCurrent] = useState<PoliciesCoveragesUnitStructureQueryResult[] | undefined>(undefined);
  //Get coverage acreage details
  const {coveragesAcreageReportingDetail, coveragesAcreageReportingDetailerror } = usePoliciesCoveragesAcreageReportingDetail(policyId);

  useEffect(() => {
    let coverageUnitStructure = coveragesUnitStructure?.filter((c) => c.unitOptionCode 
                                                                && c.unitOptionCode.code == UNIT_STRUCTURE_CODES.EU_by_Organic_Prac)
    setCoveragesUnitStructureCurrent(coverageUnitStructure);
  }, [coveragesUnitStructure]);

  useEffect(() => {
    if (coveragesUnitStructureCurrent) {
      var coverages: PoliciesCoveragesUnitStructureQueryResult[] = JSON.parse(JSON.stringify(coveragesUnitStructureCurrent));
      coverages?.map(c => c.isLocallyChanged = undefined);
      setCoveragesUnitStructureCurrent(coverages);
    }
  }, [isSaved === true]);
  const CoverageUnitStructureToUpdate = (cov: PoliciesCoveragesUnitStructureQueryResult): void => {

    var coverageUnitStructureUpdated = [...coveragesUnitStructureCurrent ?? []];
    coverageUnitStructureUpdated?.map(c => {
      if (c.coverageID == cov.coverageID) {
        c = cov;
      }
    })
    setCoveragesUnitStructureCurrent(coverageUnitStructureUpdated);
    CoveragesUnitStructureToUpdate(cov)
  }

  useEffect(() => {
    if (error != null && showAlert) {
      showTaost('Error in fetching coverage data!!!')
    }
    if (coveragesUnitStructureCurrent && coveragesUnitStructureCurrent.length == 0 && showAlert) {
      showTaost('No Data Available!!')
    }
    if (coveragesAcreageReportingDetailerror != null && showAlert) {
      showTaost('Error while fetching acreage reporting data for lockout!!!')
    }

  })

  useEffect(() => {
    if (isCollapse) {
      refetch()
    }
  }, [isCollapse])


  const showTaost = (message: string): void => {
    toast.error(message, {
      toastId: 'error',
      position: "top-right"
    })
  }  
  return (
    <>
      {isCoverageUnitStructureFetching ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.leftColumnContent}>
          <div className={`${styles.rightSidebarContent}`}>
            <div className="px-3 pt-2 pb-3">
              {coveragesUnitStructureCurrent?.map((crop, index) => {
                return (
                  <div key={index}>
                    <UnitStructureReviewSidebarCoverageCard 
                      isSelected={crop.coverageID === coverageId}
                      coverageUnitStructure={crop}
                      isCollapse={isCollapse}
                      CoverageUnitStructureToUpdate={CoverageUnitStructureToUpdate}
                      pastAcreageReportingDate={coveragesAcreageReportingDetail?.find(
                        (u) => u?.pawCoverageId === crop.pawCoverageId
                      )?.pastAcreageReportingDate}    
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
