import { FieldDataType } from "features/managed-acres";
import { GridOptions, IRowNode, RowClassParams } from "ag-grid-community";

export function GenerateGridOption(
  isRoleUnderwriter: boolean,
  isRoleProducer: boolean,
  isRoleAgent: boolean
): GridOptions<FieldDataType> {
  const rowIsEditable = (params: any) =>
    isRoleUnderwriter || !params.data?.pastAcreageReportDate;

  return {
    columnTypes: {
      lockoutDisabled: {
        editable: (params) => {
          return rowIsEditable(params);
        },
      },
    },
    getRowStyle: (params: RowClassParams<FieldDataType>) => {
      if (!rowIsEditable(params)) {
        return { opacity: ".5" };
      }
    },
    context: {
      isRoleAgent: isRoleAgent,
      isRoleUnderwriter: isRoleUnderwriter,
      isRoleProducer: isRoleProducer,
    },
    rowSelection: "multiple",
    suppressRowClickSelection: true,
    tabIndex: 0,
    icons: {
      groupExpanded:
        '<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true"><path d="M24 12L16 22 8 12z"></path></svg>',
      groupContracted:
        '<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true"><path d="M12 8L22 16 12 24z"></path></svg>',
    },
    isRowSelectable: (rowNode: IRowNode<FieldDataType>) => {
      if (rowNode.data?.isVisible) {
        if (isRoleUnderwriter) {
          return true;
        } else {
          if (rowNode.data) {
            return !rowNode.data.pastAcreageReportDate;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
  };
}
