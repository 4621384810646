import { useMetricsCreateEventPublish } from "app/analytics";
import _ from "lodash";
import { Flex, Typography } from "@proag/sprout";
import "react-accessible-accordion/dist/fancy-example.css";
import { useEffect } from "react";

export default () => {
  const { mutate: recordMetric } = useMetricsCreateEventPublish();
  useEffect(() => {
    recordMetric({
      name: "home-page-load",
      data: {
        category: "home",
        label: "Home Page Load",
      },
    });
  }, [recordMetric]);
  return (
    <>
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Typography className="head-lg">
          Acreage Reporting Producer Search
        </Typography>
        <div className="surface-card border-round shadow-3 p-4">
          <Typography className="title-md">Search</Typography>
          <div>Search functionality under construction</div>
        </div>

        {/*************************************************************************************************** */}
        {/****************************  Search Results Go Here **************************************** */}
        {/*************************************************************************************************** */}

        {/*************************************************************************************************** */}
        {/*************************************************************************************************** */}
        {/*************************************************************************************************** */}
      </Flex>
    </>
  );
};
