import { usePolicyIdFromRoute } from "app/utils/policy-utils";
import { ProducersNamePolicyNumber } from "components/ui";
import { ProducersReviewAcreObservation } from "features/planted-acres/review/components/producers-planted-acres-review-acre-observation";
import { ProducersAllFarmsFieldsMap } from "features/planted-acres/review/components/producers-planted-acres-review-all-farms-map";
import { ProducersReviewFarmsSidebar } from "features/planted-acres/review/components/producers-planted-acres-review-farms-sidebar";
import { ProducersReviewGenerateAcreageReport } from "features/planted-acres/review/components/producers-planted-acres-review-generate-acreage-report";
import { ProducersReviewPlantingSummary } from "features/planted-acres/review/components/producers-planted-acres-review-planting-summary";
import { ProducersReviewFarmsSideBarTabs } from "features/planted-acres/review/components/producers-planted-acres-review-sidebar-tabs";
import { ProducersReviewUnitsSidebar } from "features/planted-acres/review/components/producers-planted-acres-review-units-sidebar";
import { useEffect, useState } from "react";
import styles from "./producers-planted-acres-review.module.scss";
import { ProducersPlantedAcresReviewFooter } from "features/planted-acres/review/components/producers-planted-acres-review-footer";
import { PrivacyStatementsFooter } from "components/ui/privacy-statements-footer";
import { useMetricsCreateEventPublish } from "app/analytics";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { Button, Flex } from "@proag/sprout";
import { ReviewManageUnitStructureCard } from "../../features/planted-acres/review/components/review-manage-unit-structure-card";
import { Feature147540, useFeatureFlag, UnitStructureButtonFix_US147904 } from "../../app/feature-flag";
import { REINSURANCE_YEAR ,BUTTON_TEXT,UNIT_STRUCTURE_CODES} from "../../app/common/constants";
import { usePoliciesCoveragesUnitStructureQuery } from "../../features/managed-acres";
import { UnitStructureReviewSidebarSheet } from "features/planted-acres/review/components/unit-structure-review-sidebar-sheet";
import  { OptionCodeUpdate } from "features/managed-acres/hooks/policies/models/policies-coverages-unitstructure-override-query-result";


export const ProducersPlantedAcresReview: React.FC = ({ }) => {
  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  usePolicyIdFromRoute();
  const policyId = useAppStateStore((s) => s.policyId);
  const [showUnits, setShowUnits] = useState(true);
  const [show, setShow] = useState(false);  
  const { featureFlag: isEOUnitStructureEnabled } = useFeatureFlag(Feature147540);
  const { featureFlag: isUserStory147904Enabled } = useFeatureFlag(UnitStructureButtonFix_US147904);
  const {coveragesUnitStructure, refetch} = usePoliciesCoveragesUnitStructureQuery(policyId);

  const reinsuranceYearFromQueryData = (coveragesUnitStructure && coveragesUnitStructure.length > 0) ? Number(coveragesUnitStructure[0].reinsuranceYear) : null;
  const setReinsuranceYear = useAppStateStore((s) => s.setReinsuranceYear);
  
  let reinsuranceYear = useAppStateStore((s) => s.reinsuranceYear);
  if(isUserStory147904Enabled && reinsuranceYearFromQueryData != null && reinsuranceYear != reinsuranceYearFromQueryData) {
    setReinsuranceYear(reinsuranceYearFromQueryData);
  }
  reinsuranceYear = useAppStateStore((s) => s.reinsuranceYear);
  
  const coverageUnitstructures = isEOUnitStructureEnabled ? coveragesUnitStructure
  ?.filter((val)=> val.unitOptionCode && val.unitOptionCode.code == UNIT_STRUCTURE_CODES.EU_by_Organic_Prac) : [];
  
   const  attentionNeededColelction = coverageUnitstructures?.filter(c => c.systemUnitStructureOptionCode == null &&
     !(c.coverageUnitStructureOptionOverride?.overrideSelection && c.coverageUnitStructureOptionOverride?.overrideSelection.length > 0));
 
  const [coverageId,setCoverageId] = useState<number| undefined>(undefined)
  const [updatedUnitOptioncollection,setUpdatedUnitOptioncollection] = useState<OptionCodeUpdate[]>([])
  useEffect(() => {
    recordMetric({
      name: "producers-planted-acres-review-view",
      data: {
        category: "ProducersPlantedAcresReview",
        label: "Producers Planted Acres Review View",
        action: "View",
      },
    });
  }, [recordMetric]);
  

  const handleShow = () :void => {
    setShow(true);
  };

  function handleClose() {    
    refetch();
    setCoverageId(undefined)
    return setShow(false);
  }

   function handleSave(data:OptionCodeUpdate[]) {    
   setUpdatedUnitOptioncollection(data);
  }


  const onReviewButtonClick=(coverageId : number): void =>{
    setCoverageId(coverageId)
    handleShow()
  }
  
  const onSave =(): void => {
    refetch()
  }
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <ProducersNamePolicyNumber />
        {(isEOUnitStructureEnabled && reinsuranceYear >= REINSURANCE_YEAR.RY2025) && 
          <Flex direction={"row"} justifyContent="flex-end" alignItems="flex-end">
            <Flex
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                rightIcon="ChevronRight"
                variant="brand-outlined"
                onClick={handleShow}
              >
                {BUTTON_TEXT.UnitStructure}
              </Button>
            </Flex>
          </Flex>
        }

      </div>

      <UnitStructureReviewSidebarSheet 
        show={show} handleClose={handleClose} 
        isCollapse={show} 
        coverageId={coverageId} 
        handleSave ={(data)=>handleSave(data)}
        onSaveRefresh={()=>{onSave()}}></UnitStructureReviewSidebarSheet>
      
      <div className={`${styles.parentGrid}`}>
        <div className={`${styles.report}`}>
          <div className={`${styles.acreageReport} p-4`}>
            {(isEOUnitStructureEnabled && reinsuranceYear >= REINSURANCE_YEAR.RY2025 && attentionNeededColelction && attentionNeededColelction.length>0) ?
              <ReviewManageUnitStructureCard handleShow={handleShow}/> :
              <ProducersReviewGenerateAcreageReport />
            }
          </div>
        </div>
        <div className={`${styles.planting}`}>
          <div
            className={`${styles.topReviewSection} surface-card border-round shadow-3 p-4`}
          >
            <ProducersReviewPlantingSummary />
          </div>
        </div>
        <div className={`${styles.observation}`}>
          <div
            className={`${styles.topReviewSection} surface-card border-round shadow-3 p-4`}
          >
            <ProducersReviewAcreObservation />
          </div>
        </div>
        <div className={`${styles.farms}`}>
          <div className="surface-card border-round shadow-3 p-2">
            <div>{showUnits}</div>
            <ProducersReviewFarmsSideBarTabs
              showUnits={showUnits}
              setShowUnits={setShowUnits}
            />
            {!showUnits && <ProducersReviewFarmsSidebar />}
            {showUnits && <ProducersReviewUnitsSidebar onReviewButtonClick={onReviewButtonClick} updatedUnitOptioncollection={updatedUnitOptioncollection}/>}
          </div>
        </div>
        <div className={`${styles.farmsFieldsMap}`}>
          <div data-testid="ProducersFarmsFieldsBody" className="h-100">
            <ProducersAllFarmsFieldsMap />
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <PrivacyStatementsFooter />
      </div>
      <ProducersPlantedAcresReviewFooter />
    </>
  );
};
