export function toSentence(array: string[]) {
  let separator: string = ", ";
  let lastSeparator: string = " and ";
  let localArray = array.slice();
  let lastMember: string = localArray.pop() ?? "";

  if (array.length > 2) {
    lastSeparator = separator.trimEnd() + lastSeparator;
  }
  return localArray.length
    ? localArray.join(separator) + lastSeparator + lastMember
    : lastMember;
}
