import { FieldDataType } from "features/managed-acres";

import {
  validateSplitFieldHasDataEntered,
  validateAcresEnteredIfCropAndPracticeSelected,
  validateAcresEnteredAreNotMoreThanAvailable,
  validateAcresEnteredArePositive,
  validateAcresEnteredIfPlantDateEntered,
  validateAcresEnteredIfPreventPlantIsSelected,
} from "./validations-reportedacres";
import {
  validateForNoCropSelectedWhenAcresEntered,
  validateCropIfPreventPlantIsSelected,
} from "./validations-coverage";
import { validateSplitFieldAcresAreNotMoreThanAvailable } from "./validations-other";
import {
  validatePlantDateEnteredIfAcresEntered,
  validatePlantDateBeforeEarliestPlantDate,
  validatePlantDateEnteredIsNotInFuture,
} from "./validations-plantdate";
import {
  validateIfCropSelectedPracticeTypeIsAlsoSelected,
  validatePracticeIfPreventPlantIsSelected,
  validatePracticeSelectedIfAcresEntered,
} from "./validations-unit";
import {
  ArGridType,
  ArGridValidationType,
} from "features/managed-acres/components/tables/FarmFields/field-validators";

export const validateRows = (
  fieldDataRows: FieldDataType[],
  gridType: ArGridType,
  gridValidationType: ArGridValidationType
): void => {
  // validate each row individually.
  fieldDataRows.forEach((row) => {
    validateRow(row, gridType);
  });

  // some validations require multiple rows to be inspected.
  // when we get the full validation stuff in place eventually,
  // this isn't going to work..  we'll need to collect the list of
  // split parents, and do this once for each group.
  if (gridValidationType === "partial") {
    if (fieldDataRows.length > 1) {
      validateSplitFieldAcresAreNotMoreThanAvailable(fieldDataRows);
    }
  }
};

export const validateRow = (
  record: FieldDataType,
  gridType: ArGridType
): void => {
  // clear prior validation results
  record.plantedAcres.isValid = true;
  record.plantedAcres.validationMessage = "";
  record.plantedAcres.warningMsg = false;

  record.coverageId.isValid = true;
  record.coverageId.validationMessage = "";
  record.coverageId.warningMsg = false;

  record.unitId.isValid = true;
  record.unitId.validationMessage = "";
  record.unitId.warningMsg = false;

  record.plantDate.isValid = true;
  record.plantDate.validationMessage = "";
  record.plantDate.warningMsg = false;

  validateReportedAcres(record, gridType);
  validateCoverage(record, gridType);
  validateUnit(record, gridType);
  validatePlantDate(record, gridType);
};

const validateReportedAcres = (
  record: FieldDataType,
  gridType: ArGridType
): void => {
  if (["farm", "unit"].includes(gridType)) {
    validateSplitFieldHasDataEntered(record);
  }

  if (["farm"].includes(gridType)) {
    validateAcresEnteredIfCropAndPracticeSelected(record);
  }

  if (["farm", "unit"].includes(gridType)) {
    validateAcresEnteredAreNotMoreThanAvailable(record);
  }

  if (["farm"].includes(gridType)) {
    validateAcresEnteredArePositive(record);
  }

  if (["farm", "unit"].includes(gridType)) {
    validateAcresEnteredIfPlantDateEntered(record);
  }

  if (["farm", "unit"].includes(gridType)) {
    validateAcresEnteredIfPreventPlantIsSelected(record);
  }
};

const validateCoverage = (
  record: FieldDataType,
  gridType: ArGridType
): void => {
  if (["farm"].includes(gridType)) {
    validateForNoCropSelectedWhenAcresEntered(record);
  }

  if (["farm"].includes(gridType)) {
    validateCropIfPreventPlantIsSelected(record);
  }
};

const validateUnit = (record: FieldDataType, gridType: ArGridType): void => {
  if (["farm"].includes(gridType)) {
    validateIfCropSelectedPracticeTypeIsAlsoSelected(record);
  }

  if (["farm"].includes(gridType)) {
    validatePracticeIfPreventPlantIsSelected(record);
  }

  if (record.unitId.isValid) {
    if (["farm"].includes(gridType)) {
      validatePracticeSelectedIfAcresEntered(record);
    }
  }
};

const validatePlantDate = (
  record: FieldDataType,
  gridType: ArGridType
): void => {
  if (["farm", "unit"].includes(gridType)) {
    validatePlantDateEnteredIfAcresEntered(record);
  }

  if (["farm", "unit"].includes(gridType)) {
    validatePlantDateBeforeEarliestPlantDate(record);
  }

  if (["farm", "unit"].includes(gridType)) {
    validatePlantDateEnteredIsNotInFuture(record);
  }
};
