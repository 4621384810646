import { httpMethods, contentTypes } from "../constants/http";
import { ApiGateway } from "../types";
import { useApiSend } from "./useApiSend";

export const useApiGateway = (): ApiGateway => {
  const apiSend = useApiSend();

  return {
    get: async function api_get(url: string, appId: string) {
      return await apiSend({
        url,
        method: httpMethods.get,
        headers: { applicationId: appId },
      });
    },

    getFile: async function api_get_file(url: string, appId: string) {
      return await apiSend({
        url,
        method: httpMethods.get,
        headers: {
          contentType: contentTypes.octetStream,
          applicationId: appId,
        },
      });
    },

    post: async function api_post(url: string, appId: string, body: {}) {
      return await apiSend({
        url,
        method: httpMethods.post,
        headers: {
          contentType: contentTypes.json,
          applicationId: appId,
        },
        body: JSON.stringify(body),
      });
    },

    patch: async function api_patch(url: string, appId: string, body: {}) {
      return await apiSend({
        url,
        method: httpMethods.patch,
        headers: {
          contentType: contentTypes.json,
          applicationId: appId,
        },
        body: JSON.stringify(body),
      });
    },

    postFile: async function api_post_file(
      url: string,
      appId: string,
      body: FormData
    ) {
      return await apiSend({
        url,
        method: httpMethods.post,
        headers: {
          contentType: contentTypes.formdata,
          applicationId: appId,
        },
        body: body,
      });
    },

    put: async function api_put(url: string, appId: string, body: {}) {
      return await apiSend({
        url,
        method: httpMethods.put,
        headers: {
          contentType: contentTypes.json,
          applicationId: appId,
        },
        body: JSON.stringify(body),
      });
    },

    delete: async function api_delete(url: string, appId: string) {
      return await apiSend({
        url,
        method: httpMethods.delete,
        headers: { applicationId: appId },
      });
    },
  };
};
