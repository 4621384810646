import { FC, PropsWithChildren } from "react";
import { useMatch } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { routePaths } from "services/routing/routes";
import styles from "./Layout.module.scss";
import Layout_Alternate1 from "./Layout_Alternate1";
import Layout_Main from "./Layout_Main";

const Layout: FC<PropsWithChildren> = (props) => {
  // quick hack to bypass the normal layout behavior (anticipating this is a temporary situation
  // and we'll have some other thing in place for the long term.

  //console.log("pff path", routePaths.producerFarms);
  //console.log("route is producerFarms?", useMatch(routePaths.producerFarms));
  const isOne = useMatch(routePaths.producerFarms);
  const isTwo = useMatch(routePaths.producerUnits); 
  // if (useMatch(routePaths.producerFarms)) {
  if (isOne || isTwo) {
    //console.log("producer farms fields route matched.. using Layout Alternate1");
    return (
      <Layout_Alternate1>
        <div className={styles.gridMain}>{props.children}</div>
      </Layout_Alternate1>
    );
  } else {
    //console.log("no special route matched.. using Layout Main");
    return (
      <Layout_Main>
        <div className={styles.gridMain}>{props.children}</div>
      </Layout_Main>
    );
  }
};

export default Layout;
