import { useQuery } from "@tanstack/react-query";
import { useApiGateway } from "app/apiGateway";
import { httpMethods } from "app/apiGateway/constants/http";
import { handleResponse, send } from "app/apiGateway/utils/apiUtils";
import { multipolygonExample } from "./sampleData";

/* keep for testing as needed
const geojson = {  type: "FeatureCollection",  features: [    {      type: "Feature",      id: 1,      geometry: {        type: "Polygon",        coordinates: [          [            [100.0, 0.0],            [101.0, 0.0],            [101.0, 1.0],            [100.0, 1.0],            [100.0, 0.0],          ],        ],      },      properties: {        prop0: "value0",      },    },  ],};
*/

export const useCountyShapesQuery = (stateName: string, countyFips: string) => {
  // lookup function
  const fetchData = async (
    stateName: string,
    countyFips: string
  ): Promise<string> => {
    const urlTemplate =
      "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Census_Counties/FeatureServer/0/query?where=state_name+%3D%27{{StateName}}%27+AND+county_fips+%3D+%27{{CountyFips}}%27&f=pgeojson";

    const url = urlTemplate
      .replace("{{StateName}}", stateName)
      .replace("{{CountyFips}}", countyFips);

    const request = {
      method: httpMethods.get,
    };

    const response = await fetch(url, request);

    const handledResponse = await handleResponse(response);

    if (handledResponse.isError) {
      return Promise.reject(handledResponse);
    }

    return JSON.stringify(handledResponse.data);
    //return multipolygonExample;
  };

  const { data, isFetching } = useQuery({
    queryKey: ["CountyShapesQuery", stateName, countyFips],
    //enabled: true,
    //staleTime: 100 * 60 * 1000, //100 minutes
    queryFn: async () => await fetchData(stateName, countyFips),
    //refetchOnWindowFocus: false,
    //placeholderData: () => {      return sampleGeoData;    },
  });

  //const queryKey = ["CountyShapesQuery", stateName, countyFips];

  //const { data, isFetching } = useQuery<string>(queryKey, fetchData);

  return {
    countyShapeData: data,
    isCountyShapeDataFetching: isFetching,
  };
};
