import { Sheet } from "@proag/sprout";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import React, { useEffect, useState } from "react";
import {
  IPoliciesFarmUnits,
  IPoliciesUnits,
  PoliciesCropUnitsQueryResult,
  usePoliciesCropUnitsQuery,
  usePoliciesUnitUpdateMutation,
} from "../hooks";
import { ProducersFarmsEditUnitCard } from "./producers-farms-edit-unit-card";
import styles from "./farm-content.module.scss";
import { forEach } from "lodash";
import {
  PoliciesUnitRecord,
  PoliciesUnitUpdateCommand,
} from "../hooks/policies/models/policies-unit-update-command";
import { useQueryClient } from "@tanstack/react-query";
import { ProducersUnitsEditFooter } from "components/ui/producers-units-edit-footer";

interface FarmEditProps {
  farmEditClosed: () => void;
  showFarmEdit: boolean;
}

export const FarmEdit: React.FC<FarmEditProps> = ({
  farmEditClosed,
  showFarmEdit,
}) => {
  const [show, setShow] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const policyId = useAppStateStore((s) => s.policyId);
  const selectedFarmId = useAppStateStore((s) => s.selectedFarmId);
  const { cropUnits, isCropUnitsFetching } = usePoliciesCropUnitsQuery(
    Number(policyId)
  );
  const queryClient = useQueryClient();
  const onSaveSuccess = () => {
    queryClient.invalidateQueries(["policiesCropUnitsQuery", policyId]);
  };
  const { mutateUnitUpdate: mutateUnit } =
    usePoliciesUnitUpdateMutation(onSaveSuccess);
  let modifiedUnits: IPoliciesUnits[] | undefined;

  const filteredfarmUnits: number[] | undefined = cropUnits?.farmUnits
    ?.filter((f: IPoliciesFarmUnits) => f.farmId == selectedFarmId)
    .map((f: IPoliciesFarmUnits) => f.unitId);

  let filteredUnits = farmUnits(cropUnits, filteredfarmUnits);

  function handleClose() {
    setShow(false);
    farmEditClosed();
  }
  function handleChange(changedUnit: IPoliciesUnits, hasValidationError: boolean) {
    if (!modifiedUnits) modifiedUnits = [];

    setIsSaveDisabled(hasValidationError);

    if (!modifiedUnits.find((u) => u.unitId == changedUnit.unitId)) {
      //add new updated unit
      modifiedUnits.push(changedUnit);
    } else {
      var unitIndex = modifiedUnits.findIndex(
        (x) => x.unitId == changedUnit.unitId
      );

      //update values
      modifiedUnits[unitIndex].acreageTypeCode = changedUnit.acreageTypeCode;
      modifiedUnits[unitIndex].mapArea = changedUnit.mapArea;
      modifiedUnits[unitIndex].plantPattern = changedUnit.plantPattern;
      modifiedUnits[unitIndex].skipRowCode = changedUnit.skipRowCode;
      modifiedUnits[unitIndex].rowWidth = changedUnit.rowWidth;
      modifiedUnits[unitIndex].skipRowFactor = changedUnit.skipRowFactor;
    }
  }

  function handleSave() {
    forEach(modifiedUnits, (modifiedUnit) => {
      let policiesUnitRecordObject: PoliciesUnitRecord = {
        unitId: modifiedUnit?.unitId ?? 0,
        acreageTypeCode: modifiedUnit?.acreageTypeCode?.trim() ?? "",
        mapArea: modifiedUnit?.mapArea?.trim() ?? "",
        plantPattern: modifiedUnit?.plantPattern?.trim() ?? "",
        skipRowCode: modifiedUnit?.skipRowCode?.trim() ?? "",
        rowWidth: modifiedUnit?.rowWidth ?? 0,
        skipRowFactor: modifiedUnit?.skipRowFactor ?? 0,
      };

      // now attach the record to the command...
      let policiesUnitUpdateCommandObject: PoliciesUnitUpdateCommand = {
        policyId: policyId,
        unitId: modifiedUnit?.unitId ?? 0,
        record: policiesUnitRecordObject,
      };

      // save stuff
      mutateUnit(policiesUnitUpdateCommandObject);
    });
    setShow(false);
    handleClose();
  }

  function farmUnits(
    cropUnits: PoliciesCropUnitsQueryResult | undefined,
    filteredfarmUnits: number[] | undefined
  ): IPoliciesUnits[] {
    return (
      cropUnits?.units
        ?.filter((u) => filteredfarmUnits?.includes(u.unitId))
        //so the list show up the same each time sore by coverageid and then by unitId
        .sort((a, b) =>
          a.coverageId > b.coverageId
            ? 1
            : a.coverageId < b.coverageId
            ? -1
            : a.unitId > b.unitId
            ? 1
            : a.unitId < b.unitId
            ? -1
            : 0
        ) ?? []
    );
  }
  useEffect(() => {
    if (!showFarmEdit) return;
    setShow(showFarmEdit);
  }, [showFarmEdit]);

  useEffect(() => {
    if (isCropUnitsFetching) return;
    //refresh filtered cropunits after refresh
    filteredUnits = farmUnits(cropUnits, filteredfarmUnits);
  }, [isCropUnitsFetching]);

  return (
    <>
      <Sheet
        setVisible={handleClose}
        title="Edit Farm"
        visible={show}
        footer={
          <ProducersUnitsEditFooter
            onCancel={handleClose}
            onSave={handleSave}
            isSaveDisabled={isSaveDisabled}
          />
        }
      >
        <div className={`${styles.farmEdit} label-md mb-1`}>Unit Details</div>
        {!!filteredUnits &&
          filteredUnits?.map((unit, index) => {
            let coverages =
              cropUnits?.crops?.filter(
                (c) => c.coverageId == unit.coverageId
              ) ?? [];

            let coverage = coverages[0] ?? {};

            return (
              <div key={index}>
                <ProducersFarmsEditUnitCard
                  unit={unit}
                  coverage={coverage}
                  onChange={handleChange}
                />
              </div>
            );
          })}
      </Sheet>
    </>
  );
};
