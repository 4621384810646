import { Typography } from "@proag/sprout";
import { useMemo } from "react";
import { PoliciesFarmsQueryResult } from "features/managed-acres";

interface IFarmNameRendererProps {
  farms: PoliciesFarmsQueryResult[] | undefined;
  farmId: number | undefined;
}

export const FarmNameRenderer: React.FC<IFarmNameRendererProps> = ({
  farms,
  farmId,
}) => {
  const FarmName = useMemo<string>(() => {
    if (farms === undefined) return "Untitled";
    if (farmId === undefined) return "Untitled";
    let farm = farms.find((f) => f.farmId === farmId!);

    if (!farm) return "Untitled";
    if (!farm.name) return "Untitled";
    if (farm.name.trim() === "") return "Untitled";

    return farm.name;
  }, [farms, farmId]);

  return (
    <Typography className="head-sm" style={{ marginBottom: 3 }}>
      {FarmName}
    </Typography>
  );
};
