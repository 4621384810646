import {Button, Flex, Typography} from "@proag/sprout";
import {PrivacyStatementsFooter} from "../../../components/ui/privacy-statements-footer";

interface ProducersFarmsFieldsBodyFooterProps {
  isFieldsSaving: boolean | undefined;
  previousFarmId: number;
  nextFarmId: number;
  doNav: (farmId?: number) => void;
}

export const ProducersFarmsFieldsBodyFooter: React.FC<
  ProducersFarmsFieldsBodyFooterProps
> = ({ isFieldsSaving, previousFarmId, nextFarmId, doNav }) => {
  return (
    <>
      <Flex className="mb-1" justifyContent="space-between">
        <Flex className="align-flex-start" direction="column"></Flex>
        <Flex direction="column" alignItems="flex-end">
          <Flex direction="row" alignItems="flex-end">
            {!isFieldsSaving && previousFarmId > 0 && (
              <Button size="sm"
                variant="neutral-outlined"
                leftIcon="ArrowLeft"
                className="mr-2"
                data-testid="SaveAndPrevious"
                onClick={() => {
                  doNav(previousFarmId);
                }}
              >
                Previous
              </Button>
            )}

            {!isFieldsSaving && nextFarmId > 0 && (
              <Button size="sm"
                variant="neutral-outlined"
                rightIcon="ArrowRight"
                data-testid="SaveAndNext"
                onClick={() => {
                  doNav(nextFarmId);
                }}
              >
                Next
              </Button>
            )}
            {isFieldsSaving && <Button leftIcon="Activity">Saving ...</Button>}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
