import { useToggle } from "@proag/sprout";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import {
  FieldDataType,
  PoliciesCropUnitsQueryResult,
  mapUnitFieldsResultsToFieldDataType,
  usePoliciesCropUnitsQuery,
  usePoliciesFarmsFieldsUpdate,
  usePoliciesFarmsQuery,
  usePoliciesUnitsFieldsQuery,
  useApplicationRolesQuery,
  mapApplicationRolesQueryResults,
} from "features/managed-acres";
import { useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { ProducersUnitsFarmsMap } from "./producers-units-farms-map";
import { ProducersUnitsFieldsBodyFooter } from "./producers-units-fields-footer";
import { ProducersUnitsFieldsHeader } from "./producers-units-fields-header";
import { UnitsFieldsTable } from "./units-fields-table";
import styles from "./producers-units-fields-body.module.scss";
import { GridApi } from "ag-grid-community";
import { BulkEditUnitFields } from "features/managed-acres/components/tables/bulk-edit-unit-fields";

interface ProducersUnitsFieldsBodyProps {
  previousUnitId: number;
  nextUnitId: number;
  selectedUnitId: number | undefined;
  units?: PoliciesCropUnitsQueryResult | undefined;
  setSelectedUnit: any;
}

export const ProducersUnitsFieldsBody: React.FC<
  ProducersUnitsFieldsBodyProps
> = ({
  selectedUnitId,
  units,
  previousUnitId,
  nextUnitId,
  setSelectedUnit,
}) => {
  const policyId = useAppStateStore((s) => s.policyId);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridRowCount, setGridRowCount] = useState<number>(0);
  const [selectedFarmId, setSelectedFarmId] = useState<number[]>([]);
  const [fieldsData, setFieldsData] = useState<FieldDataType[]>([]);
  const [showUnitFarmsMap, toggleShowUnitFarmsMap] = useToggle(false);

  const { farms } = usePoliciesFarmsQuery(policyId);
  const { cropUnits, isCropUnitsFetched, isCropUnitsFetching } =
    usePoliciesCropUnitsQuery(policyId);
  const { unitFields, isUnitFieldsFetching, isUnitFieldsFetched } =
    usePoliciesUnitsFieldsQuery(policyId, selectedUnitId);
  const { isFieldsSaving } = usePoliciesFarmsFieldsUpdate();

  useEffect(() => {
    const selectedFarmsByUnit = units?.farmUnits.filter(
      (fu) => fu.unitId == selectedUnitId
    );
    if (selectedFarmsByUnit && selectedFarmsByUnit.length > 0) {
      setSelectedFarmId(selectedFarmsByUnit.map((m) => m.farmId));
    }
  }, [selectedUnitId]);

  const hasPastAcreageReportingDate = cropUnits?.units?.some(
    (crop) => crop.pastFinalPlantDate
  );

  const { ApplicationRolesData } = useApplicationRolesQuery();
  const { isRoleUnderwriter } = useMemo(
    () => mapApplicationRolesQueryResults(ApplicationRolesData),
    [ApplicationRolesData]
  );

  useEffect(() => {
    if (!isRoleUnderwriter && hasPastAcreageReportingDate) {
      toast.warning(
        "Certain Crop(s) are past acreage reporting date and cannot be selected",
        { toastId: "ARD Toast" }
      );
    }
  }, [isCropUnitsFetched]);

  useEffect(() => {
    if (isUnitFieldsFetching) {
      setFieldsData([]);
    } else if (isUnitFieldsFetched && unitFields) {
      let fieldsDataByUnitId = mapUnitFieldsResultsToFieldDataType(
        unitFields,
        cropUnits?.crops,
        cropUnits?.units,
        cropUnits?.farmUnits,
        farms,
        selectedUnitId
      );

      setFieldsData(fieldsDataByUnitId);
    }
  }, [selectedUnitId, isUnitFieldsFetched, isUnitFieldsFetching]);

  const handleOnGridApiReady = (gridApi: GridApi) => {
    setGridApi(gridApi);
  };

  const handleSelectRowChange = (gridApi: GridApi) => {
    setGridRowCount(gridApi?.getSelectedRows()?.length);
  };

  return (
    <>
      <div data-testid="ProducersUnitsFieldsBody" className="h-100">
        <ProducersUnitsFieldsHeader
          selectedUnit={selectedUnitId}
          units={units}
          showUnitFarmsMap={showUnitFarmsMap}
          toggleShowUnitFarmsMap={toggleShowUnitFarmsMap}
        />
        {showUnitFarmsMap && !!selectedUnitId && selectedUnitId > 0 && (
          <ProducersUnitsFarmsMap
            policyId={policyId}
            unitId={selectedUnitId ?? 0}
          />
        )}
        <div className={styles.tableBodyContent}>
          {gridRowCount > 0 && (
            <BulkEditUnitFields
              gridApi={gridApi}
              unitId={selectedUnitId ?? 0}
              gridCount={gridRowCount}
            />
          )}
          <UnitsFieldsTable
            isLoading={isUnitFieldsFetching || isCropUnitsFetching}
            data={fieldsData}
            onGridApiReady={handleOnGridApiReady}
            onSelectRowChange={handleSelectRowChange}
          />
          <ProducersUnitsFieldsBodyFooter
            isFieldsSaving={isFieldsSaving}
            nextUnitId={nextUnitId}
            previousUnitId={previousUnitId}
            doNav={setSelectedUnit}
          />
        </div>
      </div>
      <Tooltip id="my-tooltip" style={{ zIndex: 201 }} />
    </>
  );
};
