import { CellClassParams } from "ag-grid-community";
import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  MutableRefObject,
  ForwardedRef,
  HTMLInputTypeAttribute,
} from "react";
import { FieldDataType } from "./FieldDataType";

interface fieldEditorParams {
  type: HTMLInputTypeAttribute;
}

export const FieldTextEditor = forwardRef(
  (
    props: CellClassParams<FieldDataType> & fieldEditorParams,
    ref: ForwardedRef<MutableRefObject<HTMLInputElement> | undefined>
  ) => {
    const [value, setValue] = useState(props.value.value);
    const refInput = useRef() as MutableRefObject<HTMLInputElement>;

    useEffect(() => {
      refInput.current.focus();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        current: value,
      };
    });


    return (
      <input
        data-testid="field-text-editor"
        type={props.type ? props.type : "text"}
        ref={refInput}
        value={value ?? ""}
        onChange={(event: any) => setValue(event.target.value)}
        className="w-100 h-100"
      />
    );
  }
);
