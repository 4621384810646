import { GridApi } from "ag-grid-community";
import { FIELDDATA } from "../../../../app/common/constants";
import { FieldDataType } from "../../components";
import {
  PoliciesFarmsFieldRecord,
  PoliciesFarmsFieldBaseRecord,
  PoliciesFarmsFieldCreateCommand,
  PoliciesFarmsFieldDeleteCommand,
  PoliciesFarmsFieldUpdateCommand,
  usePoliciesFarmsFieldCreateMutation,
  usePoliciesFarmsFieldDeleteMutation,
  usePoliciesFarmsFieldUpdateMutation,
} from "../policies";
import { mapPoliciesFarmsFieldRecordFromFieldDataType } from "../../../planted-acres/functions/utility/map-policiesfarmsfieldrecord-from-fielddatatype";
import { generateSubField } from "app/utils/policy-utils";
export const useSplitField = (): {
  SplitField: (
    policyId: number,
    data: FieldDataType | undefined,
    api: GridApi,
    rowIndex: number | null,
    splitOnBackGround?: boolean | null
  ) => void;
} => {
  const { mutateFieldsCreate } = usePoliciesFarmsFieldCreateMutation();
  const { mutateFieldsUpdate: mutateFields } =
    usePoliciesFarmsFieldUpdateMutation();
  const SplitField = (
    policyId: number,
    data: FieldDataType | undefined,
    api: GridApi,
    rowIndex: number | null,
    splitOnBackGround?: boolean | null
  ) => {
    if (!data) return;

    data.isImportedFromPlanetWatchers = false;
    // iterate the nodes, looking for our row index, and what rowGroups show up before we get there.
    let rowGroup = 0;
    if (rowIndex !== null) {
      api.forEachNode((node) => {
        console.log("node", node.rowIndex, node);
        if (node.rowIndex === null) return; // hidden node... don't count
        if (node.rowIndex >= rowIndex) return; // we've reached our node... don't count anymore groups
        if (node.group === true) rowGroup++; //Group found, increment counter
      });
    }

    let record: PoliciesFarmsFieldBaseRecord = {
      cluIdentifier: data.cluIdentifier,
      farmId: data.farm!.farmId,
      splitParentId: calcSplitParentId(data), // we will use the field id from the original field id that was split.
      fieldNumber: data.fieldNumber,
      acreageReportId: data.acreageReportId,
    };

    let command: PoliciesFarmsFieldCreateCommand = {
      policyId: policyId,
      record: record,
    };

    // first, create the empty field, then onSuccess, update with the remaining info
    mutateFieldsCreate(command, {
      onSuccess: (result) => {
        const newId = result.fieldId;
        var newField = getFieldRow(newId, data, splitOnBackGround);
        if (splitOnBackGround && newField.length > 0) {
          newField[0].isVisible = false;
          // setting the validationMessage to empty because
          // the field is created in the background and
          // it should not block navigation between units
          newField[0].coverageId.validationMessage = "";
          newField[0].unitId.validationMessage = "";
          newField[0].plantedAcres.validationMessage = "";
          newField[0].plantDate.validationMessage = "";
        }

        let newIndex = null;
        // if its null, keep that, otherwise, compute the new row index
        if (rowIndex !== null) {
          // Ok, this is weird.  I've encountered two different grid behaviors here
          // and I don't yet understand why one works sometimes, and the other works other times.
          // if we find we need to change this again, lets try to figure out why we need to do it.

          if (false) {
            // based on initial position and accounting for the depth of the rowGroup
            //  add one, because we want it to appear AFTER the field we split from

            // as of 2024-05024, this causes the index to be calculated without the rowGroup
            // making the new element show up too high in the list, somewhat ABOVE the field we split from,
            // instead of immediately following.
            // Previously though, this mess was necessary to get the new element to show up in the right place.
            // ???
            newIndex = !rowIndex ? rowIndex : rowIndex! - rowGroup + 1;
          } else {
            // this method just says to put it immediately after the row we split from,
            // without accounting for rowGroups, and it seems to work (today)
            newIndex = rowIndex + 1;
          }
        }

        let transaction = {
          addIndex: newIndex,
          add: newField,
        };

        let transactionResult = api.applyTransaction(transaction);

        // Adding a row doesn't trigger cellValueChanged events,
        // which we need to to in order to run the validators.
        // Let us try to force the issue...
        const addedRowNode = transactionResult?.add[0];
        addedRowNode?.setDataValue("acres", data.acres);

        if (rowIndex) {
          api.setFocusedCell(rowIndex, FIELDDATA.SUBFIELD);
        }

        // Update Parent field to reset IsImportedFromPlanteWatchers flag
        let policiesFarmsFieldRecordObject: PoliciesFarmsFieldRecord =
          mapPoliciesFarmsFieldRecordFromFieldDataType(data);
        let policiesFarmsFieldUpdateCommandObject: PoliciesFarmsFieldUpdateCommand =
          {
            policyId: policyId,
            farmId: policiesFarmsFieldRecordObject.farmId,
            record: policiesFarmsFieldRecordObject,
          };

        mutateFields(policiesFarmsFieldUpdateCommandObject);
        api.redrawRows();
      },
    });
  };

  return { SplitField: SplitField };
};

export const useDeleteField = (): {
  DeleteField: (
    policyId: number,
    data: FieldDataType | undefined,
    api: GridApi
  ) => void;
} => {
  const { mutateFieldsDelete } = usePoliciesFarmsFieldDeleteMutation();

  const DeleteField = (
    policyId: number,
    data: FieldDataType | undefined,
    api: GridApi
  ) => {
    if (!data) return; // bail out if no data somehow

    const command: PoliciesFarmsFieldDeleteCommand = {
      policyId: policyId,
      farmId: data?.farm?.farmId ?? -1,
      fieldId: data?.fieldId,
      acreageReportId: data?.acreageReportId,
    };

    mutateFieldsDelete(command, {
      onSuccess: () => {
        api.applyTransaction({ remove: [data] });
      },
    });
  };
  return { DeleteField: DeleteField };
};

export const calcSplitParentId = (data: FieldDataType) => {
  if (data.splitParentId > 0) return data.splitParentId; // parent of the field we clicked on

  // otherwise
  return data!.fieldId; // the field we clicked on
};

const getFieldRow = (
  newId: any,
  data: FieldDataType,
  splitOnBackGround?: boolean | null
) => {
  var nextField = { ...data } as FieldDataType;

  return [
    {
      ...nextField,
      acres: 0, // fix this on update later, so we can trigger the onCellValueChanged events.
      splitParentId: calcSplitParentId(data),
      coverageId: {
        value: 0,
        isValid: false,
        warningMsg: false,
        validationMessage: "enter data or delete split line",
      },
      unitId: {
        value: 0,
        isValid: false,
        warningMsg: false,
        validationMessage: "enter data or delete split line",
      },
      uninsuredReasonCode: "",
      subField: {
        value: generateSubField(splitOnBackGround),
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      plantedAcres: {
        value: undefined,
        isValid: false,
        warningMsg: false,
        validationMessage: "enter data or delete split line",
      },
      plantDate: {
        value: undefined,
        isValid: false,
        warningMsg: false,
        validationMessage: "enter data or delete split line",
      },
      share: undefined,
      preventedPlant: {
        value: false,
        isValid: false,
        warningMsg: false,
        validationMessage: "",
      },
      previouslyReported: undefined,
      fieldId: newId,
    },
  ];
};

