import { LoadingSpinner } from "components/common/LoadingSpinner";
import {
  PoliciesAcresQueryResult,
  PoliciesFarmsQueryResult,
  ProducersFarmSidebarCard,
  usePoliciesFarmsQuery,
  usePoliciesProducerQuery,
} from "features/managed-acres";
import React, { useState } from "react";
import styles from "pages/producers-farms-fields/producers-farms-fields.module.scss";
import {
  IProducersFilterData,
  ProducersFilter,
} from "components/search/producers-filter";
import { useAppStateStore } from "app/state-management/use-app-state-store";

interface ProducersFarmSidebarProps {
  acresDetails?: Array<PoliciesAcresQueryResult>;
  onSelectedFarm: (farmId: number) => void;
  onFilterChange: (filterData: IProducersFilterData) => void;
  filteredFarms: Array<PoliciesFarmsQueryResult>;
  onFarmDeleted: (farmId: number) => void;
}

export const ProducersFarmsSidebar: React.FC<ProducersFarmSidebarProps> = ({
  acresDetails,
  onSelectedFarm,
  onFilterChange,
  filteredFarms,
  onFarmDeleted
}) => {
  const policyId = useAppStateStore((s) => s.policyId);
  const selectedFarmId = useAppStateStore((s) => s.selectedFarmId);

  const [sideBarStyle, setSideBarStyle] = useState<any>({
    style: { height: `calc(100vh - ${200}px)` },
  });

  const { producer, isProducerFetching } = usePoliciesProducerQuery(policyId);

  const { farms, isFarmsFetching } = usePoliciesFarmsQuery(
    policyId,
    (farms?: PoliciesFarmsQueryResult[]): void => {
      if (!!farms && farms?.length > 0 && selectedFarmId === 0) {
        // if we have a farms collection, and no farm selected yet..
        // auto select the first one..
        onSelectedFarm(farms[0].farmId);
      }
    }
  );

  const showSpinner = isFarmsFetching;

  return (
    <>    
      {showSpinner ? (
        <LoadingSpinner />
      ) : (
        <div
          className={styles.leftColumnContent}
          style={{
            ...sideBarStyle.style,
          }}
        >
          <ProducersFilter onFilterChange={onFilterChange} showHideEmpty={true}/>
          <div className="px-3 pt-2 pb-3">
            {!!filteredFarms &&
              filteredFarms.map((farm, index) => {
                return (
                  <div key={farm.farmId}>
                    <ProducersFarmSidebarCard
                      //scrollToView={scrollToView}
                      //shouldScroll={index >= filteredFarms.length - 2}
                      key={farm.farmId}
                      producerId={producer?.pawGrowerId!}
                      farmId={farm.farmId}
                      farmName={farm.name}
                      otherLandDescription={farm.otherLandDescription}
                      countyName={farm.countyName}
                      countyCode={farm.countyCode}
                      stateCode={farm.stateCode}
                      section={farm.section}
                      township={farm.township}
                      range={farm.range}
                      fn={farm.farmSerialNumber}
                      tractNumber={farm.tractNumber}
                      plantedAcres={0.0}
                      totalAcres={0}
                      fields={0}
                      isSelected={farm.farmId == selectedFarmId}
                      onSelected={onSelectedFarm}
                      onFarmDeleted={onFarmDeleted}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};
