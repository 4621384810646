import { FallbackProps } from 'react-error-boundary';

export const ErrorFallback = ({error, resetErrorBoundary}: FallbackProps) => {

  let message = '';
  if (error) {
    message =  'Error message: ' + error.message; 
  }
     
  return (
    <div role="alert">
      <p>{ 'An error has occurred with your request. ' }</p>
      <p>{ 'Please try again or contact your sales representative. ' }</p>
      <pre style={{ color: "red" }}>{message}</pre>
    </div>
  );
};