import { FieldDataType } from "../tables/FarmFields";

export function shouldCreateUnassignedField(
  currentField: FieldDataType,
  fields?: FieldDataType[]
): boolean {
  // get all the fields matching current field
  // it can be a parent field or it can be a split field
  var currentFarmFields: FieldDataType[] = [];
  var isParentField = currentField.splitParentId === 0;
  if (!fields || fields.length <= 0) {
    return false;
  }

  if (isParentField) {
    // get all the children
    currentFarmFields = fields.filter(
      (e) => e?.splitParentId === currentField?.fieldId
    );
  } else {
    // Get all the sibling fields
    currentFarmFields = fields.filter(
      (e) => e?.splitParentId === currentField?.splitParentId
    );
    // add parent Field
    var parentField = fields.filter(
      (e) => e.fieldId === currentField.splitParentId
    );
    currentFarmFields.push(parentField[0]);
  }

  // check for already unassigned field
  var unassignedFields = currentFarmFields.filter((e) => !e.unitId.value);
  return unassignedFields.length === 0;
}
