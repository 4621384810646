import { Avatar } from "@proag/sprout";
import styles from "./UserInfo.module.scss";
import { useAuthState } from "app/okta/useAuthState";
import { useOktaAuth } from "@okta/okta-react";
import { useMetricsCreateEventPublish } from "app/analytics";
import { useEffect } from "react";

export const UserInfo = () => {
  const userInfo = useAuthState();
  const { oktaAuth, authState } = useOktaAuth();
  const { mutate: recordMetric } = useMetricsCreateEventPublish();
  useEffect(() => {
    if (authState?.isAuthenticated && userInfo?.email) {
      recordMetric({ name: "user-authenticated", data: {
        category: "user-info",
        action: "Authenticated",
      } });
    }
  }, [userInfo, authState, recordMetric]);

  return (
    <>
      <div>
        {authState?.isAuthenticated &&
          userInfo?.given_name &&
          userInfo?.family_name && (
            <div className={`mr-2 ${styles.user}`}>
              <Avatar
                backgroundColor="orange50"
                kind="text"
                size="md"
                data-testid="user-avatar"
                className="mr-2"
                letter={
                  userInfo.given_name?.charAt(0) +
                    userInfo.family_name?.charAt(0) ?? ""
                }
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.clear();
                  oktaAuth.signOut({
                    postLogoutRedirectUri: (window as any).globalConfig
                      ?.Logout_Url,
                  });
                }}
              />
              <span className={styles.name}>{userInfo.name}</span>
            </div>
          )}
      </div>
    </>
  );
};
