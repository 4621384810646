import {
  CellValueChangedEvent,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  RowDataUpdatedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { GenerateGridOption } from "components/grid/grid-options";
import {
  BooleanValidationField,
  FieldDataType,
  mapApplicationRolesQueryResults,
  useApplicationRolesQuery,
  useDeleteField,
  usePoliciesFarmsFieldUpdateMutation,
  useSplitField,
  getGridValidationErrors,
  NoRowsOverlay,
  getPastFinalPlantDate,
} from "features/managed-acres";
import { FarmFieldsAcreageFooterTable } from "features/managed-acres/components/tables/FarmFields/farm-fields-acerage-footer-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  UnitFieldsTableColumnDefinition_GroupColumnDefinition,
  UnitFieldsTableColumnDefinitions,
} from "./tables/units-fields-table-column-definitions";
import { handleCellValueChangedUnitGridEvent } from "./units-fields-table-event-handlers";
import { FIELDDATA } from "../../../../app/common/constants";
import { isFieldEditable } from "./tables/column-definition-helper";
import "ag-grid-community/styles/ag-grid.css";
import "@proag/sprout/themes/ag-theme-sprout.css";
import { useMetricsCreateEventPublish } from "app/analytics";
import { areValuesSame } from "../../../../app/utils/areValuesSame";
import { shouldCreateUnassignedField } from "features/managed-acres/components/utility/should-create-unassigned-field";
import { GridValidator } from "features/managed-acres/components/tables/FarmFields/field-validators";
import { useIsMutating } from "@tanstack/react-query";

interface UnitsFieldsTableProps {
  isLoading: boolean;
  data?: FieldDataType[];
  onGridApiReady(api: GridApi): any;
  onSelectRowChange(capi: GridApi): any;
}

export const UnitsFieldsTable: React.FC<UnitsFieldsTableProps> = ({
  isLoading,
  data,
  onGridApiReady,
  onSelectRowChange,
}) => {
  const [rowData, setRowData] = useState(data);
  const policyId = useAppStateStore((s) => s.policyId);
  const selectedUnitId = useAppStateStore((s) => s.selectedUnitId);
  const selectedCoverageId = useAppStateStore((s) => s.selectedCoverageId);
  const setIsUnitGridValid = useAppStateStore((s) => s.setIsUnitGridValid);
  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  const { ApplicationRolesData } = useApplicationRolesQuery();
  const { isRoleAgent, isRoleUnderwriter, isRoleProducer } = useMemo(
    () => mapApplicationRolesQueryResults(ApplicationRolesData),
    [ApplicationRolesData]
  );
  const gridOne = useRef<AgGridReact>(null);
  const [acreageFooterData, setAcreageFooterData] = useState(data);
  const { mutateFieldsUpdate: mutateFields } =
    usePoliciesFarmsFieldUpdateMutation();

  const { SplitField } = useSplitField();
  const { DeleteField } = useDeleteField();
  const isMutatingBulkEdit = useIsMutating({ mutationKey: ["fieldsBulkEdit"] });

  if (isLoading || isMutatingBulkEdit) {
    gridOne.current?.api?.showLoadingOverlay();
  }

  if (!isLoading && !isMutatingBulkEdit) {
    showHideOverlay();
  }

  function showHideOverlay() {
    if (gridOne.current?.api?.getDisplayedRowCount()) {
      gridOne.current?.api.hideOverlay();
    } else {
      gridOne.current?.api?.showNoRowsOverlay();
    }
  }

  useEffect(() => {
    setRowData(data);
    setAcreageFooterData(data);
    updateGridOption();
  }, [data, isRoleUnderwriter]);

  const gridOptions = GenerateGridOption(
    isRoleUnderwriter,
    isRoleProducer,
    isRoleAgent
  );

  const onCellValueChangedUnitGridEvent = (
    e: CellValueChangedEvent<FieldDataType>
  ) => {
    const colId = e.column.getColId();
    // set isImportedFromPlanetWatchers to false if user overrides any value
    if (colId != FIELDDATA.SUBFIELD && e.oldValue.value != e.newValue.value) {
      if (!areValuesSame(e.oldValue.value, e.newValue.value)) {
        e.data.isImportedFromPlanetWatchers = false;
        e.node.setData(e.data);
      }
    }

    if (
      colId === FIELDDATA.PLANTEDACRES ||
      colId === FIELDDATA.PREVENTEDPLANT ||
      colId === FIELDDATA.PLANTDATE
    ) {
      if (
        !e.data.plantedAcres.value &&
        !e.data.plantDate.value &&
        !e.data.preventedPlant.value
      ) {
        // user has removed the entered value make this field available for another crop/unit
        e.data.coverageId.value = undefined;
        e.data.unitId.value = undefined;
      } else {
        if (selectedCoverageId) {
          e.data.coverageId.value = selectedCoverageId;
        }

        if (selectedUnitId) {
          e.data.unitId.value = selectedUnitId;
        }
      }
    }

    if (colId === FIELDDATA.PLANTDATE) {
      let unit = e.data?.units?.find(
        (x) => x.unitId === e.data.unitId.value ?? 0
      );

      let unitsFinalPlantDate = unit?.finalPlantDate ? unit.finalPlantDate : "";
      let rowPlantDate = e.data.plantDate.value ? e.data.plantDate.value : "";

      e.data.pastFinalPlantDate = getPastFinalPlantDate(
        unitsFinalPlantDate,
        rowPlantDate
      );

      e.api.refreshCells();
    }

    handleCellValueChangedUnitGridEvent(
      policyId,
      e,
      mutateFields,
      setIsUnitGridValid
    );

    recordMetric({
      name: "producers-unit-field-update",
      data: {
        category: "ProducersUnitsFields",
        label: "Producers Unit Field Update",
        action: "Update",
      },
    });

    const allRowData: FieldDataType[] = [];
    e.api.forEachNode((node) => {
      if (node.data) {
        allRowData.push(node.data);
      }
    });

    if (
      colId === FIELDDATA.PLANTEDACRES ||
      colId === FIELDDATA.PREVENTEDPLANT
    ) {
      setAcreageFooterData(allRowData);
    }

    if (
      colId === FIELDDATA.PLANTEDACRES ||
      colId === FIELDDATA.PREVENTEDPLANT ||
      colId === FIELDDATA.PLANTDATE
    ) {
      if (shouldCreateUnassignedField(e.data, allRowData)) {
        SplitField(policyId, e.data, e.api, null, true);
      }
    }
  };

  // for creating information for "previously reported" fields

  const onRowDataUpdatedUnitGridEvent = (
    event: RowDataUpdatedEvent<FieldDataType>
  ) => {
    validateGrid(event.api);
  };

  const validateGrid = (api: GridApi) => {
    const validationMessages: string[] = getGridValidationErrors(api, true);
    if (validationMessages.length > 0) {
      setIsUnitGridValid(false);
    } else {
      setIsUnitGridValid(true);
    }
  };

  const onCellClicked = (params: any) => {
    if (params.colDef.field == "action" && !params.node.group) {
      params.api.contextMenuFactory.showMenu(
        params.node,
        params.column,
        params.value,
        params.event
      );
    }
  };

  //params: CellKeyDownEvent
  const onCellKeyDown = useCallback((params: any) => {
    if (params.event?.key != "Enter" || params.node.group) {
      return;
    }

    // If enter key is pressed check or uncheck pp
    if (params.colDef.field == FIELDDATA.PREVENTEDPLANT) {
      if (params.data) {
        let newValue: BooleanValidationField = {
          value: !params.data.preventedPlant.value,
          isValid: false,
          warningMsg: false,
          validationMessage: "",
        };
        params.node?.setDataValue(FIELDDATA.PREVENTEDPLANT, newValue);
      }
    }

    if (params.colDef.field == "action") {
      var element = document.getElementById(`${params.rowIndex}-action`);
      // The method showMenu accepts mouse click event as param
      // but we have got the key down event which does not have x, y cordinates
      // so getting the position of the action button and set the
      // x,y cordinates to show the menu at the right place
      var position = element?.getBoundingClientRect();
      if (position) {
        params.event.clientX = position?.x;
        params.event.clientY = position?.y + 20;
      }
      params.api.contextMenuFactory.showMenu(
        params.node,
        params.column,
        params.value,
        params.event,
        element
      );
      params.event?.preventDefault();
    }
  }, []);

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      if (params.column?.getColId() != "action") {
        return [];
      }

      var isEditable: boolean = isFieldEditable(
        params.node?.data,
        params.context
      );
      var rowIndex = params.node?.rowIndex ?? -1;
      var selectedRowData: FieldDataType = params.node?.data;

      let indexDebug = "";
      indexDebug = ` [${rowIndex}]`; // comment out if not debugging

      var menuItems: (string | MenuItemDef)[] = [
        {
          name: `Split Field${indexDebug}`,
          action: () => {
            SplitField(policyId, selectedRowData, params.api, rowIndex);
          },
          disabled: !isEditable,
        },
      ];

      if (
        rowIndex >= 0 &&
        selectedRowData &&
        selectedRowData.splitParentId > 0
      ) {
        var deleteMenu = {
          name: "Delete",
          action: () => {
            if (selectedRowData.splitParentId > 0)
              DeleteField(policyId, selectedRowData, params.api);
          },
          disabled: !isEditable,
        };
        menuItems.push(deleteMenu);
      }

      return menuItems;
    },
    []
  );

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<FieldDataType>): boolean => {
      return node.data?.isVisible ?? true;
    },
    []
  );

  const isExternalFilterPresent = useCallback((): boolean => {
    return true;
  }, []);

  const [gridWrapperStyle, setGridWrapperStyle] = useState<any>({
    style: { height: `calc(100vh - ${500}px)` },
  });

  const updateGridOption = () => {
    const gridOptions = GenerateGridOption(
      isRoleUnderwriter,
      isRoleProducer,
      isRoleAgent
    );
    gridOne?.current?.api?.updateGridOptions(gridOptions);
  };

  return (
    <>
      <div
        className="ag-theme-sprout"
        data-testid="UnitsFieldsTable"
        style={{
          ...gridWrapperStyle.style,
        }}
      >
        <AgGridReact<FieldDataType>
          gridOptions={gridOptions}
          domLayout="normal"
          rowData={isLoading || isMutatingBulkEdit ? undefined : rowData}
          columnDefs={UnitFieldsTableColumnDefinitions}
          rowHeight={50}
          headerHeight={48}
          singleClickEdit={true}
          onGridReady={(params: GridReadyEvent) => {
            onGridApiReady(params.api);
          }}
          onSelectionChanged={(params: GridReadyEvent) => {
            onSelectRowChange(params.api);
          }}
          //
          // Value has changed after editing + cut, paste, cell clear, fill handle, copy range down, undo and redo.
          onCellValueChanged={onCellValueChangedUnitGridEvent}
          onRowDataUpdated={onRowDataUpdatedUnitGridEvent}
          //
          // Allows setting the ID for a particular row node based on the data.
          getRowId={(params: GetRowIdParams<FieldDataType>) =>
            params.data.fieldId.toString()
          }
          ref={gridOne}
          showOpenedGroup={true}
          autoGroupColumnDef={
            UnitFieldsTableColumnDefinition_GroupColumnDefinition
          }
          groupSelectsChildren={true}
          stopEditingWhenCellsLoseFocus={true}
          suppressDragLeaveHidesColumns={true}
          allowContextMenuWithControlKey={false}
          suppressContextMenu={false}
          getContextMenuItems={getContextMenuItems}
          onCellClicked={onCellClicked}
          onCellKeyDown={onCellKeyDown}
          noRowsOverlayComponent={NoRowsOverlay}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          onRowGroupOpened={() =>
            GridValidator("unit", "full", gridOne.current?.api)
          }
          suppressRowVirtualisation={true}
        />
      </div>
      <div style={{ flex: "none", height: "60px" }}>
        <FarmFieldsAcreageFooterTable
          parentGrid={gridOne}
          fieldData={acreageFooterData}
        />
      </div>
    </>
  );
};
