import {Flex, Typography} from "@proag/sprout";


export const PrivacyStatementsFooter = () => {

  return(
    <>
      <Flex direction={'row'} alignItems={'center'} style={{paddingTop: 5}}>
        <Typography className="body-sm" style={{textAlign: 'center'}} data-testid="privacy-statements-text">
          &copy; {new Date().getFullYear()} ProAg. All rights reserved. A member
          of the Tokio Marine HCC group of companies. <br />
          <small>
            ProAg is an equal opportunity provider. <br />
            <a data-testid="privacy-statements-link"
              href="https://www.proag.com/privacy-non-discrimination-statements/"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              Privacy and Non-Discrimination Statements
            </a>
          </small>
        </Typography>
      </Flex>
    </>
  )
}
