import { Button, Flex, Icon, Typography } from "@proag/sprout";
import React from "react";
import { WARNINGMESSAGES, BUTTON_TEXT } from "../../../../app/common/constants";
import styles from "./producers-planted-acres-review-units-sidebar-card.module.scss";
interface ReviewManageUnitStructureCardProps {
  handleShow(): void
}

export const ReviewManageUnitStructureCard: React.FC<ReviewManageUnitStructureCardProps> = ({ handleShow }) => {

  return (
    <>
      <div data-testid="review-manage-unit-structure-card" className={`${"h-100"} ${styles.attentionCard}`}>
        <br /> <br />
        <Flex direction="column" justifyContent="center">
          <Icon iconName="WarningFilled" size="xl" color="#F19F49" />
          <Typography className="title-md">Attention Needed</Typography>
          <br /> <br />
          <div className={styles.attentionWarning}>{WARNINGMESSAGES.MUS_QUALIFICATION_ALERT}</div>
          <br />
        </Flex>

        <Flex justifyContent="center">
          <Button
            variant="warning-solid"
            rightIcon="ChevronRight"
            size="md"
            onClick={handleShow}
          >
            {BUTTON_TEXT.ReviewUnitStructure}
          </Button>
        </Flex>

      </div>

    </>
  );
};
