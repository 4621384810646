import { PoliciesCropUnitsQueryResult, PoliciesFarmsQueryResult } from "features/managed-acres";

export interface IPolicyFarmUnitCoverage {
  farmId: number;
  unitId: number;
  coverageId: number;
  coverage_commodityName: string;
  unit_typeName: string;
  unit_practiceName: string;
  unit_unitNumber: string;
  farmSerialNumber: number;
  tractNumber: number;
  name: string;
  otherLandDescription: string;
  countyName: string;
  countyCode: string;
  stateCode: string;
  section: string;
  township: string;
  range: string;
}

export const reconstructPolicyFarmUnitCoverage = (
  unitDetails: PoliciesCropUnitsQueryResult | undefined,
  farms: Array<PoliciesFarmsQueryResult>
): Array<IPolicyFarmUnitCoverage> => {

  let pfuc: Array<IPolicyFarmUnitCoverage> = [];

  if (unitDetails == undefined) return pfuc;

  const pfus = unitDetails.farmUnits;
  const pus = unitDetails.units;
  const pucs = unitDetails.crops;

  pfus.forEach((pfu) => {
    pus.forEach((pu) => {
      if (pfu.unitId === pu.unitId) {
        pucs.forEach((puc) => {
          if (pu.coverageId === puc.coverageId) {
            let myFarm = farms.find(f=> f.farmId === pfu.farmId)
            pfuc.push({
              farmId: pfu.farmId,
              unitId: pfu.unitId,
              coverageId: pu.coverageId,
              unit_typeName: pu.typeName,
              unit_practiceName: pu.practiceName,
              unit_unitNumber: pu.unitNumber,
              coverage_commodityName: puc.commodityName,
              farmSerialNumber: myFarm?.farmSerialNumber,
              tractNumber: myFarm?.tractNumber,
              section: myFarm?.section,
              township: myFarm?.township,
              range: myFarm?.range,
              name: myFarm?.name
            } as IPolicyFarmUnitCoverage);
          }
        });
      }
    });
  });

  return pfuc;
};