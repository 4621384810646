import {
  CellClickedEvent,
  ColumnFunctionCallbackParams,
  ColDef,
  SuppressKeyboardEventParams,
} from "ag-grid-community";
import {
  CoverageDropdownCellEditor,
  DatePicker,
  UnitPracticeTypeDropdownCellEditor,
} from "components/grid";
import "flatpickr/dist/themes/airbnb.css";
import { BooleanValidationField, FieldDataType } from "./FieldDataType";
import {
  AcresColumn,
  ActionColumn,
  ArTypeColumn,
  CropColumn,
  FieldColumn,
  PracticeTypeColumn,
  PreventedPlantColumn,
} from "./farm-fields-table-columns";
import { FieldCellDisplay } from "./field-cell-display";
import {
  GridValidator,
  isFieldEditable,
  preventedPlantEffect,
  valueSetterEffect,
} from "./field-validators";
import { FieldTextEditor } from "./field-text-editor";
import { IPoliciesFarmUnits } from "features/managed-acres/hooks";
import { FIELDDATA } from "app/common/constants";

const cellStyleRightAlign = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
};

const cellStyleLeftAlign = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

export const FarmFieldsTableColumnDefinitions: Array<ColDef<FieldDataType>> = [
  {
    headerName: "FieldId",
    field: "fieldId",
    hide: true,
    suppressMovable: true,
  },
  {
    headerName: "Field #",
    field: FIELDDATA.FIELDNUMBER,
    suppressAutoSize: false,
    cellRenderer: FieldColumn,
    cellStyle: cellStyleLeftAlign,
    minWidth: 75,
    maxWidth: 110,
    flex: 1,
    suppressMovable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    showDisabledCheckboxes: true,
  },
  {
    headerName: "Sub Field",
    field: FIELDDATA.SUBFIELD,
    headerComponentParams: {
      justify: "justify-content-end",
    },
    cellRenderer: (params: any) => {
      return FieldCellDisplay(
        params.data[FIELDDATA.SUBFIELD],
        <span>{params.data[FIELDDATA.SUBFIELD].value}</span>,
        "flex-end"
      );
    },
    suppressAutoSize: false,
    cellEditor: FieldTextEditor,
    type: "lockoutDisabled",
    cellStyle: cellStyleRightAlign,
    minWidth: 95,
    maxWidth: 125,
    flex: 1,
    suppressMovable: true,
    onCellValueChanged: (newValueParams) =>
      GridValidator("farm", "partial", undefined, newValueParams),
    valueSetter: (params) => {
      params.data.subField = {
        ...params.data.subField,
        value: params.newValue,
      };
      return true;
    },
  },
  {
    headerName: "Field Acres",
    field: FIELDDATA.ACRES,
    headerComponentParams: {
      justify: "justify-content-end",
    },
    suppressAutoSize: false,
    cellRenderer: AcresColumn,
    cellStyle: cellStyleRightAlign,
    minWidth: 110,
    maxWidth: 200,
    flex: 1,
    suppressMovable: true,
  },
  // removed via user story "117493 - View/Update Acreage | Field Details | Remove Prior Insured Column"
  // leaving the column commented out because the column may be added back for 2024 spring crops.
  //{
  //  headerName: "Prior Insured",
  //  field: FIELDDATA.PRIORINSURED,
  //  width: 85,
  //  suppressAutoSize: false,
  //  cellRenderer: PriorInsuredColumn,
  //  cellStyle: cellStyleRightAlign,
  //  //flex: 1,
  //},
  {
    // this column will eventually need to be dynamic (Commodity will determine Crop Types in list)
    headerName: "Crop",
    field: FIELDDATA.COVERAGEID,
    suppressAutoSize: false,
    cellRenderer: (params: any) => {
      return FieldCellDisplay(
        params.data[FIELDDATA.COVERAGEID],
        CropColumn(params)
      );
    },
    cellEditor: CoverageDropdownCellEditor,
    type: "lockoutDisabled",
    cellClassRules: {
      "border-red": (params) =>
        !params.data?.coverageId.isValid && !params.data?.coverageId.warningMsg,
      "border-yellow": (params) =>
        !params.data?.coverageId.isValid &&
        !!params.data?.coverageId.warningMsg,
    },
    valueSetter: (params) => {
      params.data.coverageId = {
        ...params.data.coverageId,
        value: params.newValue,
      };

      if (!params.newValue) {
        params.data.unitId.value = undefined;
        return true;
      }

      const farmUnits = params?.data?.farmUnits
        ?.filter(
          (f: IPoliciesFarmUnits) => f.farmId == params?.data?.farm?.farmId
        )
        .map((f: IPoliciesFarmUnits) => f.unitId);

      // get us only the units matching the currently selected coverage.
      const values = params.data.units?.filter(
        (u) =>
          (u.coverageId === params.newValue ?? 0) &&
          farmUnits?.includes(u.unitId)
      );

      let unit = values?.find((u) => u.unitId === params.data.unitId.value);

      // if after all that filtering, we end up with only 1 unit, lets auto-select that.
      // check if the date has not passed the lockout date
      if (
        values &&
        values.length === 1 &&
        (!values[0].pastFinalPlantDate || params.context?.isRoleUnderwriter)
      ) {
        if (unit?.unitId !== values[0].unitId) {
          // but only if the new unit is different
          params.data.unitId.value = values[0].unitId;
        }
      }
      return true;
    },

    onCellValueChanged: (newValueParams) =>
      GridValidator("farm", "partial", undefined, newValueParams),
    cellStyle: cellStyleLeftAlign,
    flex: 1,
    minWidth: 110,
    suppressMovable: true,
    suppressKeyboardEvent: (
      params: SuppressKeyboardEventParams<FieldDataType>
    ) => {
      return params.event.key === "Enter" && params.editing;
    },
  },
  {
    hide: false,
    headerName: "Practice / Type",
    field: FIELDDATA.UNITID,
    suppressAutoSize: false,
    cellRenderer: (params: any) => {
      return FieldCellDisplay(
        params.data[FIELDDATA.UNITID],
        PracticeTypeColumn(params)
      );
    },
    cellEditor: UnitPracticeTypeDropdownCellEditor,
    type: "lockoutDisabled",
    cellClassRules: {
      "border-red": (params) =>
        !params.data?.unitId.isValid && !params.data?.unitId.warningMsg,
      "border-yellow": (params) =>
        !params.data?.unitId.isValid && !!params.data?.unitId.warningMsg,
    },
    valueSetter: (params) => {
      params.data.unitId = { ...params.data.unitId, value: params.newValue };
      return true;
    },
    onCellValueChanged: (newValueParams) =>
      GridValidator("farm", "partial", undefined, newValueParams),
    cellStyle: cellStyleLeftAlign,
    flex: 1,
    minWidth: 110,
    suppressMovable: true,
    suppressKeyboardEvent: (
      params: SuppressKeyboardEventParams<FieldDataType>
    ) => {
      return params.event.key === "Enter" && params.editing;
    },
  },
  {
    headerName: "Reported Acres",
    field: FIELDDATA.PLANTEDACRES,
    suppressAutoSize: false,
    cellRenderer: (params: any) => {
      return FieldCellDisplay(
        params.data[FIELDDATA.PLANTEDACRES],
        <span>{params.data[FIELDDATA.PLANTEDACRES].value}</span>,
        "flex-end"
      );
    },
    cellEditor: FieldTextEditor,
    type: "lockoutDisabled",
    cellEditorParams: {
      type: "number",
    },
    cellClassRules: {
      "border-red": (params) =>
        !params.data?.plantedAcres.isValid &&
        !params.data?.plantedAcres.warningMsg,
      "border-yellow": (params) =>
        !params.data?.plantedAcres.isValid &&
        !!params.data?.plantedAcres.warningMsg,
    },
    valueSetter: (params) => {
      params.data.plantedAcres = {
        ...params.data.plantedAcres,
        value: params.newValue,
      };
      return true;
    },
    onCellValueChanged: (newValueParams) =>
      GridValidator("farm", "partial", undefined, newValueParams),
    cellStyle: cellStyleRightAlign,
    minWidth: 172,
    maxWidth: 200,
    flex: 1,
    suppressMovable: true,
    suppressKeyboardEvent: (
      params: SuppressKeyboardEventParams<FieldDataType>
    ) => {
      if (params.event.key != "Tab") {
        return false;
      }
      var isPlantDateEditable: boolean;

      if (params?.context.isRoleUnderwriter) {
        isPlantDateEditable = true;
      } else if (params?.data?.pastAcreageReportDate) {
        isPlantDateEditable = !params?.data?.pastAcreageReportDate;
      }
      isPlantDateEditable = !params?.data?.preventedPlant.value;

      if (!isPlantDateEditable) {
        params.api.stopEditing();
      }
      return false;
    },
  },
  {
    headerName: "Plant Date",
    field: FIELDDATA.PLANTDATE,
    suppressAutoSize: false,
    cellRenderer: (params: any) => {
      return FieldCellDisplay(
        params.data[FIELDDATA.PLANTDATE],
        <div className="d-flex">
          {params.data[FIELDDATA.PASTFINALPLANTDATE] && (
            <span style={{ color: "#317399" }} className="mr-1">
              LP
            </span>
          )}
          {params.data[FIELDDATA.PLANTDATE].value}
        </div>
      );
    },
    cellEditor: DatePicker,
    editable: (params: ColumnFunctionCallbackParams<FieldDataType>) => {
      if (params?.context.isRoleUnderwriter) {
        return true;
      }
      if (params?.data?.pastAcreageReportDate) {
        return !params?.data?.pastAcreageReportDate;
      }
      return !params?.data?.preventedPlant.value;
    },
    cellClassRules: {
      "border-red": (params) =>
        !params.data?.plantDate.isValid && !params.data?.plantDate.warningMsg,
      "border-yellow": (params) =>
        !params.data?.plantDate.isValid && !!params.data?.plantDate.warningMsg,
    },
    valueSetter: (params) => {
      params.data.plantDate = {
        ...params.data.plantDate,
        value: params.newValue,
      };
      return true;
    },
    onCellValueChanged: (newValueParams) =>
      GridValidator("farm", "partial", undefined, newValueParams),
    cellStyle: cellStyleRightAlign,
    minWidth: 110,
    maxWidth: 200,
    flex: 1,
    suppressMovable: true,
    suppressKeyboardEvent: (
      params: SuppressKeyboardEventParams<FieldDataType>
    ) => {
      // Tabbing thru focusus only on editable cell and skips non-editable cell
      // Stop editing cell so that tab works on all columns
      if (params.event.key === "Tab") params.api.stopEditing();
      return false;
    },
  },
  {
    headerName: "PP",
    field: FIELDDATA.PREVENTEDPLANT,
    suppressAutoSize: false,
    cellRenderer: PreventedPlantColumn,
    type: "lockoutDisabled",
    width: 60,
    valueSetter: valueSetterEffect(preventedPlantEffect),
    cellStyle: cellStyleLeftAlign,
    editable: false,
    onCellValueChanged: (newValueParams) =>
      GridValidator("farm", "partial", undefined, newValueParams),
    onCellClicked: (
      params: CellClickedEvent<
        FieldDataType,
        FieldDataType[typeof FIELDDATA.PREVENTEDPLANT]
      >
    ) => {
      if (params.data) {
        var isEditable: boolean = isFieldEditable(params.data, params.context);
        if (isEditable) {
          let newValue: BooleanValidationField = {
            value: !params.data.preventedPlant.value,
            isValid: false,
            warningMsg: false,
            validationMessage: "",
          };
          params.node?.setDataValue(FIELDDATA.PREVENTEDPLANT, newValue);
        }
      }
    },
    suppressMovable: true,
  },
  // {
  //   hide: true, //not field level -being re-located to a different location
  //   headerName: "Uninsured",
  //   headerComponent: UninsuredReasonHeader,
  //   field: FIELDDATA.UNINSUREDREASONCODE,
  //   suppressAutoSize: false,
  //   cellRenderer: UninsuredColumn,
  //   cellEditor: UninsuredReasonDropdownCellEditor,
  //   type: "lockoutDisabled",
  // },
  {
    hide: false,
    headerName: "Action",
    field: "action",
    resizable: true,
    cellRenderer: ActionColumn,
    width: 76,
    suppressMovable: true,
  },
  // everyting after here is temporarily hidden/disabled per  https://dev.azure.com/ProAgManagement/ProAg%20Development/_workitems/edit/102682
  {
    hide: true,
    headerName: "AR Type",
    field: FIELDDATA.ARTYPE,
    suppressAutoSize: false,
    cellRenderer: ArTypeColumn,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["", "Planted", "Prevented Planting", "Uninsurable"],
    },
    type: "lockoutDisabled",
  },
];
