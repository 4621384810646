import { useQuery } from "@tanstack/react-query";
import { FieldManagement, useApiGateway } from "app/apiGateway";
import { DocumentEnvelopeResult } from "./models/document-envelope-result";
import { DocumentEnvelopeQuery } from "./models/document-envelope-query";

export const useAcreageReportDocumentEnvelopesQuery = (
  query: DocumentEnvelopeQuery
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = query.policyOid ?? -1;

  const { data, isFetching, refetch, isFetched } = useQuery<
    DocumentEnvelopeResult[]
  >({
    queryKey: ["acreageReportDocumentEnvelopesQuery", queryPolicyId],
    queryFn: async () =>
      await apiGateway.post(
        `policies/${queryPolicyId}/acreage-report/document-envelopes`,
        FieldManagement,
        query
      ),
  });

  return {
    documentEnvelopes: data,
    isDocumentEnvelopesFetching: isFetching,
    fetchDocumentEnvelopes: refetch,
    documentEnvelopesAreFetched: isFetched,
  };
};
