import { Button, Flex } from "@proag/sprout";

interface ManageUnitStructureFooterProps {
  onCancel: () => void;
  onSave : () => void;
}

export const ManageUnitStructureFooter: React.FC<ManageUnitStructureFooterProps> = ({ onCancel, onSave }) => {
  return (
    <>
      <Flex className="mb-1" justifyContent="space-between">
        <Flex className="align-flex-start" direction="column"></Flex>
        <Flex direction="column" alignItems="flex-end">
          <Flex direction="row" alignItems="flex-end">
            <Button size="sm" variant="neutral-outlined" className="mr-2" data-testid="manage-unit-structure-cancel" onClick={() => { onCancel(); }}>
              Cancel
            </Button>
            <Button size="sm" data-testid="manage-unit-structure-save" variant="brand-solid"
              onClick={()=> onSave()}>
              Save
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
