import { Link } from "@proag/sprout";
import { routePaths } from "services/routing/routes";

export function Logo() {
  return (
    <Link to={routePaths.home}>
      <img src="/proag-logo.svg" height="40" className="mr-2" />
    </Link>
  );
}
