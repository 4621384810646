import { IconButton } from "@proag/sprout";
import React from "react";
import { FeatureFarmUnitEditIsActive, useFeatureFlag } from "app/feature-flag";

interface FarmUnitsEditButtonProps {
  setShowFarmUnitEdit: (newValue?: boolean) => void;
}

export const FarmUnitsEditButton: React.FC<FarmUnitsEditButtonProps> = ({
  setShowFarmUnitEdit,
}) => {
  const { featureFlag: flag_FeatureFarmUnitEditIsActive } = useFeatureFlag(
    FeatureFarmUnitEditIsActive
  );

  return (
    <>
      {flag_FeatureFarmUnitEditIsActive && (
        <div className="ml-2">
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-html={"<div>Edit Farm & Unit Details</div>"}
            data-tooltip-place="right"
          >
            <IconButton
              iconName="Edit"
              size="lg"
              data-testid="farm-units-edit-button"
              onClick={() => {
                setShowFarmUnitEdit(true);
              }}
            />
          </a>
        </div>
      )}
    </>
  );
};
