import { useOktaAuth } from "@okta/okta-react";
import { useMutation } from "@tanstack/react-query";
import { CreateEventCommand } from "app/analytics";
import { Analytics, useApiGateway } from "app/apiGateway";
import { useParams } from "react-router-dom";

export const useMetricsCreateEventPublish = () => {
  const { authState } = useOktaAuth();
  const apiGateway = useApiGateway();
  let { pawPolicyOid } = useParams();

  return useMutation({
    mutationKey: ["MetricsCreateEventPublish"],
    mutationFn: async (cmd: CreateEventCommand) => {
      //grab email from the claim. more reliable than userinfo
      cmd.data.userId = authState?.accessToken?.claims?.sub;
      cmd.data.actionDate = new Date().toISOString();
      cmd.data.policyId = +pawPolicyOid!;
      var url = `event/application/acreage-reporting/eventtype/${cmd.name}/events`;
      await apiGateway.post(url, Analytics, JSON.stringify(cmd.data));
    },
  });
};
