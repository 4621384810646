/// this method takes parameters for an API call, a function to check the results, as well as options for the polling
/// and returns a promise to keep checking the "checkFunction"
/// until either the timeout value is hit, or the "otherFunction" is valid
/// options to control the intervals are provided.

export const PollApiUntil = function (
  apiFn: () => any,
  testFn: (r: any) => boolean,
  opts: PollingOptions
) {
  return new Promise((resolve, reject) => {
    const startTime = new Date();

    const timeout = opts.timeout || 10000;
    const interval = opts.interval || 100;
    const timeoutMessage = opts.timeoutMessage || "Timeout!";

    const poll = async function () {
      // 1. call our Api method (what we're polling)
      // this call may take a little time
      var result = await apiFn();

      // 2. verify the results match what we're waiting for
      // ie, the status on a long running process has now "finished" or something...
      const ready = testFn(result);

      // 3. Deal with the outcomes
      if (ready) {
        // 4. All is ready, resolve and lets get on with things!
        resolve(ready);
      } else if (new Date().getTime() - startTime.getTime() > timeout) {
        // 5. We waited too long for the result, and we're giving up!
        reject(new Error(timeoutMessage));
      } else {
        // 6. Not successful, but we're still willing to wait.
        // kick off the whole process again, in a few (specified) ms.
        setTimeout(poll, interval);
      }
    };

    poll();
  });
};

export type PollingOptions = {
  timeout?: number;
  interval?: number;
  timeoutMessage?: string;
};
