import { TabToNextCellParams } from "ag-grid-community";
import { FIELDDATA } from "app/common/constants";
import { FieldDataType } from "features/managed-acres/components";

export const FarmFieldsTabToNextCellHook = (
  params: TabToNextCellParams<FieldDataType>,
  gridOne: any
) => {
  const previousCell = params.previousCellPosition;
  const previousColumn = previousCell.column;
  const lastRowIndex = previousCell.rowIndex;

  let nextRowIndex = lastRowIndex;

  const result2 = {
    rowIndex: nextRowIndex,
    column: previousCell.column,
    rowPinned: previousCell.rowPinned,
  };

  if (gridOne && gridOne.current) {
    let nextColumnName = "";

    switch (previousColumn.getColId()) {
      case FIELDDATA.FIELDNUMBER:
        nextColumnName = params.backwards ? "action" : FIELDDATA.SUBFIELD;
        nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex;
        break;
      case FIELDDATA.SUBFIELD:
        nextColumnName = params.backwards ? "action" : FIELDDATA.COVERAGEID;
        nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex;
        break;
      case FIELDDATA.ACRES:
        nextColumnName = params.backwards
          ? FIELDDATA.SUBFIELD
          : FIELDDATA.COVERAGEID;
        break;
      case FIELDDATA.PRIORINSURED:
        nextColumnName = params.backwards
          ? FIELDDATA.SUBFIELD
          : FIELDDATA.COVERAGEID;
        break;
      case FIELDDATA.COVERAGEID:
        nextColumnName = params.backwards
          ? FIELDDATA.SUBFIELD
          : FIELDDATA.UNITID;
        break;
      case FIELDDATA.UNITID:
        nextColumnName = params.backwards
          ? FIELDDATA.COVERAGEID
          : FIELDDATA.PLANTEDACRES;
        break;
      case FIELDDATA.PLANTEDACRES:
        nextColumnName = params.backwards
          ? FIELDDATA.UNITID
          : FIELDDATA.PLANTDATE;
        break;
      case FIELDDATA.PASTFINALPLANTDATE:
        nextColumnName = params.backwards
          ? FIELDDATA.PLANTEDACRES
          : FIELDDATA.PLANTDATE;
        break;
      case FIELDDATA.PLANTDATE:
        nextColumnName = params.backwards
          ? FIELDDATA.PLANTEDACRES
          : FIELDDATA.PREVENTEDPLANT;
        break;
      case FIELDDATA.PREVENTEDPLANT:
        nextColumnName = params.backwards
          ? FIELDDATA.PLANTDATE
          : FIELDDATA.ACTION;
        break;
      case FIELDDATA.ACTION:
        nextColumnName = params.backwards
          ? FIELDDATA.PREVENTEDPLANT
          : FIELDDATA.SUBFIELD;
        nextRowIndex = params.backwards ? lastRowIndex : lastRowIndex + 1;
        break;
      default:
        nextColumnName = FIELDDATA.FIELDNUMBER;
    }

    let c = params.api.getColumn(nextColumnName);
    if (c) {
      const nextColumn = c;

      const renderedRowCount = gridOne.current.api.getModel().getRowCount();
      if (nextRowIndex < 0) {
        nextRowIndex = -1;
      }

      if (nextRowIndex >= renderedRowCount) {
        return null;
      }
      const result = {
        rowIndex: nextRowIndex,
        column: nextColumn,
        rowPinned: previousCell.rowPinned,
      };

      return result;
    }
  }
  return result2;
};
