import { Button, Flex, Typography, getSproutColor } from "@proag/sprout";
import { GridApi } from "ag-grid-community";
import {
  dateEntryParser,
  mapDateStringToShortIsoFormat,
} from "features/managed-acres/functions";
import { useRef, useState } from "react";
import { FieldDataType } from "./FarmFields";
import Flatpickr from "react-flatpickr";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { mapPoliciesFarmsFieldRecordFromFieldDataType } from "features/planted-acres/functions/utility/map-policiesfarmsfieldrecord-from-fielddatatype";
import { useQueryClient } from "@tanstack/react-query";
import { shouldCreateUnassignedField } from "../utility/should-create-unassigned-field";
import { toast } from "react-toastify";
import { PoliciesFarmsFieldRecord } from "features/managed-acres/hooks/policies/models/policies-farms-field-update-command";
import { useFieldBulkUpdate } from "features/managed-acres/hooks/policies/policiesCommands";
import { calcSplitParentId } from "features/managed-acres/hooks/table/split-fields";
import { SingleValue } from "react-select";
import { generateSubField } from "app/utils/policy-utils";

interface BulkEditUnitFieldsProps {
  gridApi?: GridApi<FieldDataType>;
  gridCount: number;
  unitId: number;
}

export interface BulkUpdate {
  selectedCoverage: SingleValue<option>;
  selectedUnitNumber: SingleValue<option>;
  matchFieldAcres: boolean;
  plantDate: string | null;
  isPreventPlant: boolean;
}

export type option = {
  value: number;
  label: string;
};

export function BulkEditUnitFields({
  gridApi,
  gridCount,
  unitId,
}: BulkEditUnitFieldsProps): JSX.Element {
  const policyId = useAppStateStore((s) => s.policyId);
  const queryClient = useQueryClient();
  const [bulkUpdate, setBulkUpdate] = useState<BulkUpdate>({} as BulkUpdate);
  const selectedCoverageId = useAppStateStore((s) => s.selectedCoverageId);

  const onSuccess = (): void => {
    toast.success(`${gridCount} fields updated`);
    queryClient.invalidateQueries({
      queryKey: ["policiesUnitsFieldsQuery", policyId, unitId],
    });
    gridApi?.deselectAll();
  };
  const { mutate, isLoading } = useFieldBulkUpdate(onSuccess, policyId);

  const inputRef = useRef<any>();
  if (inputRef && inputRef.current && inputRef.current.flatpickr) {
    inputRef.current.flatpickr.altInput.disabled = bulkUpdate.isPreventPlant;
  }

  const saveChanges = () => {
    const fieldsToUpdate = gridApi?.getSelectedRows();
    if (!fieldsToUpdate || fieldsToUpdate.length <= 0) {
      return;
    }

    const nextFields: PoliciesFarmsFieldRecord[] = [];

    const allRowData: FieldDataType[] = [];
    gridApi?.forEachNode((node) => {
      if (node.data) {
        allRowData.push(node.data);
      }
    });

    fieldsToUpdate?.forEach((field) => {
      createUnassignedField(field, allRowData, nextFields);
      const nextField = {
        ...mapPoliciesFarmsFieldRecordFromFieldDataType(field),
      };
      nextField.coverageId = selectedCoverageId ?? null;
      nextField.unitId = unitId;
      if (bulkUpdate?.plantDate) {
        const nextDate = mapDateStringToShortIsoFormat(bulkUpdate.plantDate);
        nextField.plantedDate = nextDate ?? null;
        nextField.preventedPlant = false;
      }

      if (bulkUpdate?.matchFieldAcres) {
        nextField.plantedAcres = nextField.cluCalculatedAcreage;
      }

      if (bulkUpdate?.isPreventPlant) {
        nextField.preventedPlant = true;
        nextField.plantedDate = null;
      }
      nextFields.push(nextField);
    });

    mutate(nextFields);
  };

  const createUnassignedField = (
    field: FieldDataType,
    allFields: (FieldDataType | undefined)[] | undefined,
    nextFields: PoliciesFarmsFieldRecord[]
  ): void => {
    if (shouldCreateUnassignedField(field, allFields as any)) {
      const unassignedField: PoliciesFarmsFieldRecord = {
        cluIdentifier: field.cluIdentifier,
        farmId: field.farm!.farmId,
        splitParentId: calcSplitParentId(field),
        fieldNumber: field.fieldNumber,
        acreageReportId: field.acreageReportId,
        // this is how the new endpoint determines to create a new field
        acreageReportFieldId: 0,
        cluCalculatedAcreage: field.acres,
        subField: generateSubField(true),
        preventedPlant: false,
        coverageId: null,
        unitId: null,
        plantedAcres: null,
        plantedDate: null,
        share: null,
        uninsuredReasonCode: "",
        priorYearInsured: null,
        isImportedFromPlanetWatchers: false,
      };
      nextFields.push(unassignedField);
    }
  };

  return (
    <div className="p-2" style={{ backgroundColor: getSproutColor("green90") }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="m-0">
          <Typography className="body-lg" style={{ margin: 0 }}>
            {`${gridCount} fields selected`}
          </Typography>
        </div>

        <div className="d-flex align-items-center">
          <div style={{ minWidth: "200px" }}>
            <Flex>
              <input
                className="mr-2"
                type="checkbox"
                data-testid="match-acres"
                onChange={(event) => {
                  setBulkUpdate({
                    ...bulkUpdate,
                    matchFieldAcres: event.currentTarget.checked,
                  });
                }}
                checked={!!bulkUpdate?.matchFieldAcres}
                disabled={isLoading}
              ></input>
              <Typography
                className="body-lg"
                style={{ marginBottom: 3, marginRight: 3 }}
              >
                Match field acres
              </Typography>
            </Flex>
          </div>

          <div data-testid="plant-date-col" className="mr-2">
            <Flatpickr
              ref={inputRef}
              disabled={bulkUpdate.isPreventPlant}
              data-testid="plant-date"
              placeholder="Plant Date"
              value={bulkUpdate?.plantDate}
              onClose={(selectedDates: Date[], dateStr: string) => {
                // handle user clicking outside of datePicker input
                if (!dateStr) {
                  setBulkUpdate({ ...bulkUpdate, plantDate: null });
                }
              }}
              onChange={(selectedDates: Date[], dateStr: string) => {
                if (selectedDates.length > 0) {
                  setBulkUpdate({ ...bulkUpdate, plantDate: dateStr });
                } else {
                  setBulkUpdate({ ...bulkUpdate, plantDate: null });
                }
              }}
              options={{
                maxDate: new Date(),
                allowInput: true,
                dateFormat: "m/d/Y",
                altInput: true,
                altFormat: "m/d/Y",
                parseDate: (datestr: string, format: any) =>
                  dateEntryParser(datestr),
              }}
            />
          </div>

          <Flex className="mr-2">
            <input
              className="mr-2"
              type="checkbox"
              data-testid="match-prevent-plant"
              onChange={(event) => {
                setBulkUpdate({
                  ...bulkUpdate,
                  isPreventPlant: event.currentTarget.checked,
                });
              }}
              checked={!!bulkUpdate?.isPreventPlant}
              disabled={isLoading || !!bulkUpdate.plantDate}
            ></input>
            <Typography className="body-lg" style={{ margin: 0 }}>
              PP
            </Typography>
          </Flex>

          <Button
            onClick={saveChanges}
            disabled={isLoading}
            data-testid="apply-updates"
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}
