import { round } from "lodash";
import { format, isValid } from "date-fns";
import { FieldDataType } from "features/managed-acres/components/tables/FarmFields/FieldDataType";
import {
  validateSplitFieldHasDataEntered,
  validateAcresEnteredIfCropAndPracticeSelected,
  validateAcresEnteredAreNotMoreThanAvailable,
  validateAcresEnteredArePositive,
  validateAcresEnteredIfPlantDateEntered,
  validateAcresEnteredIfPreventPlantIsSelected,
} from "./validations-reportedacres";
import {
  hasEnteredAcres,
  hasSelectedCrop,
  hasSelectedUnit,
  hasCheckedPreventPlant,
  hasEnteredPlantedDate,
} from "./validation-helpers";

export const validatePlantDateEnteredIfAcresEntered = (
  record: FieldDataType
): void => {
  if (
    hasEnteredAcres(record) &&
    !hasEnteredPlantedDate(record) &&
    !hasCheckedPreventPlant(record)
  ) {
    record.plantDate = {
      value: record.plantDate.value,
      validationMessage: `Plant Date must be entered if Acres are entered`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.plantDate = {
      value: record.plantDate.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};

export const validatePlantDateBeforeEarliestPlantDate = (
  record: FieldDataType
): void => {
  if (record.plantDate.value) {
    const earlyPlantDate = new Date(record.earlyPlantDate!);
    const dateEntered = new Date(record.plantDate.value);

    if (
      isValid(earlyPlantDate) &&
      isValid(dateEntered) &&
      earlyPlantDate > dateEntered
    ) {
      const displayValue = format(earlyPlantDate, "M/d/y");
      record.plantDate = {
        value: record.plantDate.value,
        validationMessage: `Plant date is prior to the Earliest Plant Date of ${displayValue}.`,
        warningMsg: true,
        isValid: false,
      };
      return;
    }
    return;
  }
};

export const validatePlantDateEnteredIsNotInFuture = (
  record: FieldDataType
): void => {
  if (!record.plantDate.isValid) {
    return;
  }
  if (hasEnteredPlantedDate(record)) {
    const dateEntered = new Date(record.plantDate.value ?? 0);
    const today = new Date();
    if (dateEntered > today) {
      record.plantDate = {
        value: record.plantDate.value,
        validationMessage: `Plant date must be before today.`,
        warningMsg: false,
        isValid: false,
      };
    } else {
      record.plantDate = {
        value: record.plantDate.value,
        validationMessage: ``,
        warningMsg: true,
        isValid: true,
      };
    }
  }
};
