import { Flex, Typography, colors, getSproutColor } from "@proag/sprout";
import { FarmUnitsEditButton } from "components/ui/producers-farm-units-edit-button";
import { PoliciesCropUnitsQueryResult } from "features/managed-acres";
import React, { useState } from "react";
import Switch from "react-switch";
import { UnitEdit } from "./producers-units-edit";

interface ProducersUnitsFieldsHeaderProps {
  selectedUnit: number | undefined;
  units?: PoliciesCropUnitsQueryResult | undefined;
  showUnitFarmsMap: boolean;
  toggleShowUnitFarmsMap: (newValue?: boolean | undefined) => void;
}

export const ProducersUnitsFieldsHeader: React.FC<
  ProducersUnitsFieldsHeaderProps
> = ({ selectedUnit, units, showUnitFarmsMap, toggleShowUnitFarmsMap }) => {
  const [showUnitEdit, setShowUnitEdit] = useState(false);

  const handleUnitEditClosed = () => {
    setShowUnitEdit(false);
  };
  const headerUnit = units?.units.find((r) => r.unitId == selectedUnit);
  const headerCoverage = units?.crops.find(
    (crop) => crop.coverageId == headerUnit?.coverageId
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <Flex
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Typography
            className="head-sm"
            data-testid={"producers-units-fields-header"}
          >
            {headerCoverage?.commodityName} {headerUnit?.unitNumber}
          </Typography>
          <FarmUnitsEditButton
            setShowFarmUnitEdit={() => {
              setShowUnitEdit(true);
            }}
          />
          {showUnitEdit && (
            <UnitEdit
              unitEditClosed={handleUnitEditClosed}
              showUnitEdit={showUnitEdit}
            ></UnitEdit>
          )}
        </Flex>

        {headerUnit && (
          <div className="mt-1">
            <div className="d-flex">
              <div className="mr-5">
                <div
                  className="label-sm mb-2"
                  style={{ color: colors.neutral40 }}
                >
                  Practice
                </div>
                <div className="label-md">{headerUnit.practiceName}</div>
              </div>

              <div className="mr-5">
                <div
                  className="label-sm mb-2"
                  style={{ color: colors.neutral40 }}
                >
                  Type
                </div>
                <div className="label-md">{headerUnit.typeName}</div>
              </div>

              <div className="mr-5">
                <div
                  className="label-sm mb-2"
                  style={{ color: colors.neutral40 }}
                >
                  Share
                </div>
                <div className="label-md">
                  {Math.round(headerUnit.share * 100)}%
                </div>
              </div>
            </div>
            <div>
              <Flex
                className="mt-3"
                style={{ paddingBottom: 5 }}
                justifyContent="flex-end"
              >
                <div className="mr-1">Map</div>
                <Switch
                  data-testid="pu-map-switch"
                  width={36}
                  height={20}
                  onChange={toggleShowUnitFarmsMap}
                  checked={showUnitFarmsMap}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor={getSproutColor("brandGreen")}
                />
              </Flex>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
