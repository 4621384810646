import { Alert, Flex, getSproutColor, useToggle } from "@proag/sprout";
import { useQueryClient } from "@tanstack/react-query";
import {
  AcresSummary,
  mapApplicationRolesQueryResults,
  useApplicationRolesQuery,
  usePoliciesCropUnitsQuery,
  usePoliciesPlanetWatchersConfidenceQuery,
  usePolicyMutationImportFromACRSI,
  usePolicyMutationImportFromPlanetWatchers,
} from "features/managed-acres";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ProducersFieldsHeaderCard, ProducersNamePolicyNumber } from ".";
import { useMetricsCreateEventPublish } from "app/analytics";
import { ImportAcres } from "../../features/managed-acres/components/import-acres";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { PlantingSeason } from "features/managed-acres/components/producers-farms-fields-planting-season";
import { CropTotalsToggle } from "../../features/managed-acres/components/producers-farms-fields-crop-totals";
import {
  FeaturePlanetWatchersIsActive,
  useFeatureFlag,
} from "app/feature-flag";

interface ProducersPolicyHeaderProps {
  acresSummaryData: AcresSummary | undefined;
  acresSummaryDataIsFetching: boolean;
}

export const ProducersPolicyHeader: React.FC<ProducersPolicyHeaderProps> = ({
  acresSummaryData,
}) => {
  const policyId = useAppStateStore((s) => s.policyId);

  const [acrsiImportAlertIsVisible, setAcrsiImportAlertIsVisible] =
    useState(false);
  const [
    planetWatchersImportAlertIsVisible,
    setPlanetWatchersImportAlertIsVisible,
  ] = useState(false);

  const queryClient = useQueryClient();

  const { cropUnits } = usePoliciesCropUnitsQuery(policyId);

  const { planetWatchersConfidence } =
    usePoliciesPlanetWatchersConfidenceQuery(policyId);

  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  const { featureFlag: flag_FeaturePlanetWatchersIsActive } = useFeatureFlag(
    FeaturePlanetWatchersIsActive
  );

  const hasPastFinalPlantDateFields = cropUnits?.units?.some(
    (crop) => crop.pastFinalPlantDate
  );

  function callImportFromAcrsi() {
    setAcrsiImportAlertIsVisible(false);
    importFromACRSI.mutate();
    recordMetric({
      name: "import-acrsi",
      data: {
        category: "ProducersFarmsFields",
        label: "Import From ACRSI",
        action: "Import",
      },
    });
  }

  function callImportFromPlanetWatchers() {
    setPlanetWatchersImportAlertIsVisible(false);
    importFromPlanetWatchers.mutate();
    recordMetric({
      name: "import-planetWatchers",
      data: {
        category: "ProducersFarmsFields",
        label: "Import From PlanetWatchers",
        action: "Import",
      },
    });
  }

  const onImportSuccess = () => {
    queryClient.invalidateQueries(["policiesFarmsFieldsQuery", policyId]);
    toast.success("Import success!");
  };

  const onImportFail = () => {
    queryClient.invalidateQueries(["policiesFarmsFieldsQuery", policyId]);
    toast.error("Import Failed, data refreshed.");
  };

  const importFromACRSI = usePolicyMutationImportFromACRSI(
    policyId,
    onImportSuccess,
    onImportFail
  );

  const importFromPlanetWatchers = usePolicyMutationImportFromPlanetWatchers(
    policyId,
    onImportSuccess,
    onImportFail
  );

  const { ApplicationRolesData } = useApplicationRolesQuery();
  const { isRoleUnderwriter } = useMemo(
    () => mapApplicationRolesQueryResults(ApplicationRolesData),
    [ApplicationRolesData]
  );

  const [showCropTotals, toggleShowCropTotals] = useToggle(false);

  return (
    <>
      <Alert
        data-testid="acrsi"
        className="sprout__alert-docs__yqsSX"
        onAction={callImportFromAcrsi}
        position="top-center"
        onClose={() => setAcrsiImportAlertIsVisible(false)}
        titleText="Manually added information may be removed. Do you want to proceed?"
        visible={acrsiImportAlertIsVisible}
        variant="warning-solid"
        actionText={"Submit Request"}
      />
      <Alert
        className="sprout__alert-docs__yqsSX"
        onAction={callImportFromPlanetWatchers}
        position="top-center"
        onClose={() => setPlanetWatchersImportAlertIsVisible(false)}
        titleText="Manually added information may be removed. Do you want to proceed?"
        visible={planetWatchersImportAlertIsVisible}
        variant="warning-solid"
        actionText={"Submit Request"}
      />

      <div className="d-flex justify-content-between">
        <ProducersNamePolicyNumber />
        <Flex direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Flex
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <CropTotalsToggle
              showCropTotals={showCropTotals}
              toggleShowCropTotals={toggleShowCropTotals}
            />
          </Flex>
          <Flex
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <PlantingSeason />
          </Flex>
          <Flex
            className="ml-2 mb-1"
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <ImportAcres
              importDisabled={hasPastFinalPlantDateFields && !isRoleUnderwriter}
              importPlanetWatchersDisabled={
                !flag_FeaturePlanetWatchersIsActive ||
                planetWatchersConfidence?.hasConfidence === false
              }
              setAcrsiImportAlertIsVisible={setAcrsiImportAlertIsVisible}
              setPlanetWatchersImportAlertIsVisible={
                setPlanetWatchersImportAlertIsVisible
              }
            />
          </Flex>
        </Flex>
      </div>

      {showCropTotals && (
        <Flex
          data-testid="crop-totals-flex"
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className="mb-3 mt-1"
        >
          <ProducersFieldsHeaderCard
            key="Total Planted"
            title={"Total Planted"}
            amount={acresSummaryData?.plantedAcres}
            total={acresSummaryData?.calculatedAcres}
            countyTotals={acresSummaryData?.countyTotals}
          />
          <ProducersFieldsHeaderCard
            key="PP"
            title={"PP"}
            amount={acresSummaryData?.preventedAcres}
            countyTotals={acresSummaryData?.preventedCountyTotals}
          />

          {acresSummaryData?.cropTotals.map((crop) => {
            if (crop.cropName !== "")
              return (
                <ProducersFieldsHeaderCard
                  key={crop.cropName}
                  title={crop.cropName
                    .split(" ")
                    .map(
                      (e) =>
                        e.substring(0, 1).toLocaleUpperCase() +
                        e.substring(1).toLocaleLowerCase()
                    )
                    .join(" ")}
                  amount={crop.plantedAcres}
                  countyTotals={crop.countyTotals}
                />
              );
          })}
        </Flex>
      )}
    </>
  );
};
