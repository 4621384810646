import { Flex } from "@proag/sprout";
import { getPawURL } from "app/apiGateway/utils/apiUtils";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./sidebar-display-tabs.module.scss";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { toast } from "react-toastify";

interface SideBarDisplayTabsProps {}

export const SideBarDisplayTabs: React.FC<SideBarDisplayTabsProps> = ({}) => {
  const policyOid = useAppStateStore((s) => s.policyId);
  const isUnitGridValid = useAppStateStore((s) => s.isUnitGridValid);
  const isFarmGridValid = useAppStateStore((s) => s.isFarmGridValid);

  const navigate = useNavigate();
  let { unit } = useParams();

  const onNavigateTo = (navigateTo: string) => {
    if (navigateTo == "unit") {
      if (isFarmGridValid) {
        navigate(`/policy/${policyOid}/unit`);
      } else {
        showToastMsg(
          "Please resolve errors in your data before switching to farms."
        );
      }
    } else {
      if (isUnitGridValid) {
        navigate(`/policy/${policyOid}`);
      } else {
        showToastMsg(
          "Please resolve errors in your data before switching to units."
        );
      }
    }
  };

  const showToastMsg = (msg: string) => {
    toast.error(msg, {
      toastId: "SelectedTabNavigateGridValidCheck",
    });
  };

  return (
    <div className={styles.ere}>
      <Flex direction="row" justifyContent="flex-start" alignItems="stretch">
        <div
          className={`title-sm ${unit ? styles.notSelected : styles.Selected}`}
          onClick={() => onNavigateTo("farm")}
          data-testid={"farms-sidebar-tabs"}
        >
          Farms
        </div>
        <div
          className={`title-sm ${
            unit == "unit" ? styles.Selected : styles.notSelected
          }`}
          onClick={() => onNavigateTo("unit")}
          data-testid={"units-sidebar-tabs"}
        >
          Units
        </div>
      </Flex>
    </div>
  );
};
