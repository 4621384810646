import { Flex, Icon, NumericInput, getSproutColor } from "@proag/sprout";
import { PoliciesCoveragesUnitStructureQueryResult, overrideSelection } from "features/managed-acres/hooks/policies/models/policies-coverages-unitstructure-query-result";
import { useEffect, useState } from "react";
import { INFO_MESSAGES, UNIT_OPTION_PRACTICE_CODES, WARNINGMESSAGES, NUMERIC_INPUT } from "../../../../app/common/constants";
import styles from "./producers-planted-acres-review-units-sidebar-card.module.scss";


interface ManageUnitStructureSidebarProps {
  isSelected: boolean;
  coverageUnitStructure?: PoliciesCoveragesUnitStructureQueryResult;
  CoverageUnitStructureToUpdate(cov: PoliciesCoveragesUnitStructureQueryResult): void;
  pastAcreageReportingDate: boolean | undefined;

}

export const ManageUnitStructureSidebar: React.FC<ManageUnitStructureSidebarProps> = ({ isSelected, coverageUnitStructure, CoverageUnitStructureToUpdate, pastAcreageReportingDate }) => {

  const radioCheckValue = coverageUnitStructure?.coverageUnitStructureOptionOverride?.overrideSelection.find(
    o => o.practice?.id == UNIT_OPTION_PRACTICE_CODES.ORG || o.practice?.id == UNIT_OPTION_PRACTICE_CODES.NONORG) ? "A"
    : coverageUnitStructure?.coverageUnitStructureOptionOverride?.overrideSelection.find(
      o => o.practice?.id == UNIT_OPTION_PRACTICE_CODES.BOTH) ? "B" : "";

  const [radioCheck, setRadioCheck] = useState(radioCheckValue);

  const getUnitStructureOptionCode = (practiceId: number): any => {
    var unitStructureOptionCode: string | null = "";
    var data = coverageUnitStructure?.coverageUnitStructureOptionOverride?.overrideSelection.find(
      o => o.practice?.id == practiceId);
    if (data) {
      unitStructureOptionCode = data.unitStructureOptionCode;
    }
    return unitStructureOptionCode;
  }

  const getOverrideQualifyingSectionCount = (practiceId: number): any => {
    var overrideQualifyingSectionCount: number | null | undefined = undefined;
    var data = coverageUnitStructure?.coverageUnitStructureOptionOverride?.overrideSelection.find(
      o => o.practice?.id == practiceId)
    if (data) {
      overrideQualifyingSectionCount = data.overrideQualifyingSectionCount;
    }
    return overrideQualifyingSectionCount ?? 0;
  }

  const [orgButtonSelected, setOrgButtonSelected] = useState(getUnitStructureOptionCode(UNIT_OPTION_PRACTICE_CODES.ORG));

  const [norgButtonSelected, setNOrgButtonSelected] = useState(getUnitStructureOptionCode(UNIT_OPTION_PRACTICE_CODES.NONORG));

  const [cButtonSelected, setCButtonSelected] = useState(getUnitStructureOptionCode(UNIT_OPTION_PRACTICE_CODES.BOTH));

  const [showSelectUnitStructure, setShowSelectUnitStructure] = useState(coverageUnitStructure?.practice?.id == UNIT_OPTION_PRACTICE_CODES.BOTH);

  const [orgSectionOverride, setOrgSectionOverride] = useState(getOverrideQualifyingSectionCount(UNIT_OPTION_PRACTICE_CODES.ORG));

  const [nOrgSectionOverride, setNOrgSectionOverride] = useState(getOverrideQualifyingSectionCount(UNIT_OPTION_PRACTICE_CODES.NONORG))

  const [bothSectionOverride, setBothSectionOverride] = useState(getOverrideQualifyingSectionCount(UNIT_OPTION_PRACTICE_CODES.BOTH));

  const [showWarningIcon, setShowWarningIcon] = useState<boolean | undefined>();
  const isPracticeOrg = coverageUnitStructure?.practice?.id == UNIT_OPTION_PRACTICE_CODES.ORG;
  const isPracticeNonOrg = coverageUnitStructure?.practice?.id == UNIT_OPTION_PRACTICE_CODES.NONORG

  useEffect(() => {
    setOrgButtonSelected(getUnitStructureOptionCode(UNIT_OPTION_PRACTICE_CODES.ORG));
    setNOrgButtonSelected(getUnitStructureOptionCode(UNIT_OPTION_PRACTICE_CODES.NONORG));
    setCButtonSelected(getUnitStructureOptionCode(UNIT_OPTION_PRACTICE_CODES.BOTH));
    setShowSelectUnitStructure(coverageUnitStructure?.practice?.id == UNIT_OPTION_PRACTICE_CODES.BOTH);
    setOrgSectionOverride(getOverrideQualifyingSectionCount(UNIT_OPTION_PRACTICE_CODES.ORG));
    setNOrgSectionOverride(getOverrideQualifyingSectionCount(UNIT_OPTION_PRACTICE_CODES.NONORG));
    setBothSectionOverride(getOverrideQualifyingSectionCount(UNIT_OPTION_PRACTICE_CODES.BOTH));
    if(coverageUnitStructure?.isLocallyChanged == undefined)
      {
        const showWarningValue = coverageUnitStructure && coverageUnitStructure.unitOptionCode != null && coverageUnitStructure.systemUnitStructureOptionCode != coverageUnitStructure.unitOptionCode.code &&
        !(coverageUnitStructure.coverageUnitStructureOptionOverride?.overrideSelection && coverageUnitStructure.coverageUnitStructureOptionOverride?.overrideSelection.length > 0);
        setShowWarningIcon(showWarningValue);
      }
  }, [coverageUnitStructure]);

  useEffect(() => {
    var updated = coverageUnitStructure;
    if (updated?.coverageUnitStructureOptionOverride?.overrideSelection) {

      if (orgButtonSelected != "" || orgSectionOverride) {
        let overrideSelectionOrg = coverageUnitStructure?.coverageUnitStructureOptionOverride?.overrideSelection.find(p => p.practice != null && p.practice.id == UNIT_OPTION_PRACTICE_CODES.ORG)
        if (overrideSelectionOrg && overrideSelectionOrg?.overrideQualifyingSectionCount != orgSectionOverride || overrideSelectionOrg?.unitStructureOptionCode != orgButtonSelected) {
          var u = overrideSelectionOrg
          if (orgButtonSelected == "EO" && orgSectionOverride < NUMERIC_INPUT.MIN) {
            setOrgSectionOverride(NUMERIC_INPUT.MIN)
          }

          if (u) {
            u.overrideQualifyingSectionCount = orgSectionOverride;
            u.unitStructureOptionCode = orgButtonSelected;
          }
          else {
            var a: overrideSelection;
            a = {
              practice: {
                id: UNIT_OPTION_PRACTICE_CODES.ORG,
                code: "Org"
              }, qualifyingSectionCount: 0, overrideQualifyingSectionCount: orgSectionOverride,
              unitStructureOptionCode: orgButtonSelected
            }
            updated.coverageUnitStructureOptionOverride.overrideSelection.push(a);
          }
          if (updated) {
            updated.coverageUnitStructureOptionOverride.overrideSelection = updated.coverageUnitStructureOptionOverride.overrideSelection.filter(o => o.unitStructureOptionCode != undefined)
            updated.isLocallyChanged = true;
            CoverageUnitStructureToUpdate(updated);
          }

        }
      }
    }
  }, [orgSectionOverride, orgButtonSelected]);

  useEffect(() => {
    var updated = coverageUnitStructure;
    if (updated?.coverageUnitStructureOptionOverride?.overrideSelection) {
      if (norgButtonSelected != "" || nOrgSectionOverride) {
        let overrideSelectionnOrg = coverageUnitStructure?.coverageUnitStructureOptionOverride?.overrideSelection.find(p => p.practice != null && p.practice.id == UNIT_OPTION_PRACTICE_CODES.NONORG)
        if (overrideSelectionnOrg && overrideSelectionnOrg?.overrideQualifyingSectionCount != nOrgSectionOverride || overrideSelectionnOrg?.unitStructureOptionCode != norgButtonSelected) {
          var u = overrideSelectionnOrg

          if (norgButtonSelected == "EO" && nOrgSectionOverride < NUMERIC_INPUT.MIN) {
            setNOrgSectionOverride(NUMERIC_INPUT.MIN)
          }

          if (u) {
            u.overrideQualifyingSectionCount = nOrgSectionOverride;
            u.unitStructureOptionCode = norgButtonSelected;
          }
          else {
            var a: overrideSelection;
            a = {
              practice: {
                id: UNIT_OPTION_PRACTICE_CODES.NONORG,
                code: "NonOrg"
              }, qualifyingSectionCount: 0, overrideQualifyingSectionCount: nOrgSectionOverride,
              unitStructureOptionCode: norgButtonSelected
            }
            updated.coverageUnitStructureOptionOverride.overrideSelection.push(a);
          }
          if (updated)
            updated.coverageUnitStructureOptionOverride.overrideSelection = updated.coverageUnitStructureOptionOverride.overrideSelection.filter(o => o.unitStructureOptionCode != undefined)
          updated.isLocallyChanged = true;
          CoverageUnitStructureToUpdate(updated);
        }
      }

    }
  }, [nOrgSectionOverride, norgButtonSelected]);

  useEffect(() => {
    var updated = coverageUnitStructure;
    if (updated?.coverageUnitStructureOptionOverride?.overrideSelection) {
      if ((cButtonSelected != "") || bothSectionOverride) {
        let overrideSelectionBoth = coverageUnitStructure?.coverageUnitStructureOptionOverride?.overrideSelection.find(p => p.practice != null && p.practice.id == UNIT_OPTION_PRACTICE_CODES.BOTH)
        if (overrideSelectionBoth && overrideSelectionBoth?.overrideQualifyingSectionCount != bothSectionOverride || overrideSelectionBoth?.unitStructureOptionCode != cButtonSelected) {
          var u = overrideSelectionBoth

          if (cButtonSelected == "EU" && bothSectionOverride < NUMERIC_INPUT.MIN) {
            setBothSectionOverride(NUMERIC_INPUT.MIN)
          }
          if (u) {
            u.overrideQualifyingSectionCount = bothSectionOverride ?? 0;
            u.unitStructureOptionCode = cButtonSelected;
          }
          else {
            var a: overrideSelection;
            a = {
              practice: {
                id: UNIT_OPTION_PRACTICE_CODES.BOTH,
                code: "Both"
              }, qualifyingSectionCount: 0, overrideQualifyingSectionCount: bothSectionOverride ?? 0,
              unitStructureOptionCode: cButtonSelected
            }
            updated.coverageUnitStructureOptionOverride.overrideSelection.push(a);
          }
          if (updated) {
            updated.coverageUnitStructureOptionOverride.overrideSelection = updated.coverageUnitStructureOptionOverride.overrideSelection.filter(o => o.unitStructureOptionCode != undefined)
            updated.isLocallyChanged = true;
            CoverageUnitStructureToUpdate(updated);
          }

        }
      }
    }
  }, [cButtonSelected, bothSectionOverride]);

  const currentSectionCount = (practice: string): string => {
    var sectionCount = "0";
    if (coverageUnitStructure && coverageUnitStructure?.qualifyingSectionCount != null && coverageUnitStructure?.qualifyingSectionCount.trim() != "") {
      let sectionCountArr: string[] = ("0,0").split(',')

      if (coverageUnitStructure.qualifyingSectionCount.includes(',')) {
        sectionCountArr = coverageUnitStructure.qualifyingSectionCount.split(',')
      }
      else {
        sectionCountArr[0] = coverageUnitStructure.qualifyingSectionCount
      }
      if (practice == "Org") {
        return coverageUnitStructure.systemUnitStructureOptionCode != "BU/OU"
          && sectionCountArr[0].trim() != ""
          ? sectionCountArr[0]
          : "0"
      }
      if (practice == "Non-Org") {
        return coverageUnitStructure.systemUnitStructureOptionCode != "BU/OU"
          && sectionCountArr[1].trim() != ""
          ? sectionCountArr[1]
          : "0"
      }

    }

    return sectionCount;
  }

  const handleRadioButton = (radio: any) => {
    if (radio == "radioA") {
      setRadioCheck("A");
      setCButtonSelected(undefined);
    } else {
      setRadioCheck("B");
      setOrgButtonSelected(undefined);
      setNOrgButtonSelected(undefined);
    }
  };

  return (
    <>
      <div data-testid={"manage-unit-structure-sidebar-card-" + coverageUnitStructure?.coverageID.toString()} className={`${pastAcreageReportingDate ? styles.disable_buttons : ""}`}>
        { showWarningIcon &&
          <Flex direction="row" justifyContent="flex-start">
            <p className={`${styles.musiconp1}`}><Icon iconName="WarningFilled" size="sm" color="#F19F49" /></p>
            <p className={`${styles.muswrnp1}`}>{WARNINGMESSAGES.MUS_EOQUALIFICATION} </p>
          </Flex>
        }
        {/*region radio A*/}

        <div data-testid="radio-button-A" className={`${styles.radioGroupCardA} ${radioCheck == "A" ? styles.selectedRadioButton : ""}`}
          onClick={() => handleRadioButton("radioA")}>
          Override Qualification for EO
          {radioCheck == "A" ?
            <Icon iconName="CheckmarkFilled" id="radioA" size="sm" className={`${styles.divRadioA}`} /> :
            <Icon iconName="RadioButton" id="radioA" size="sm" className={`${styles.divRadioA}`} />
          }

          {/*Organic Practice*/}

          <Flex direction="column" className={`${styles.flexorg}`}>
            <div style={{ color: getSproutColor("neutral30") }}>Organic Practice</div>

            <Flex className={`${isPracticeNonOrg ? styles.disable_buttons : ""}`}>
              <div data-testid="org-BU" id="org-BU" className={`${styles.option_button} ${orgButtonSelected == "BU" ? styles.green_background : ""}`}
                onClick={(e) => { setOrgButtonSelected("BU"); setOrgSectionOverride(0) }}> BU </div>
              <div data-testid="org-OU" id="org-OU" className={`${styles.option_button} ${orgButtonSelected == "OU" ? styles.green_background : ""}`}
                onClick={(e) => { setOrgButtonSelected("OU"); setOrgSectionOverride(0) }}> OU </div>

              <div>or</div>

              <div data-testid="org-EO" id="org-EO" className={`${styles.option_button_override} ${orgButtonSelected == "EO" ? styles.green_background : ""}`}
                onClick={(e) => setOrgButtonSelected("EO")}> Override for EO </div>
            </Flex>

            <p data-testid={"current-section-org1" + coverageUnitStructure?.coverageID.toString()} className={`${styles.currentSectionText}`}>Current Sections: {currentSectionCount("Org")}</p>
            <div style={{ color: '#4c4d4d' }}>Organic Section Override</div>

            <Flex className={`${isPracticeNonOrg ? styles.disable_buttons : ""}`}>
              <NumericInput data-test-id="o-numeric-input" id="o-numeric-input" btnVariabnt="neutral-outlined" className="fixed-num-input" onChange={(e) => { setOrgSectionOverride(e.target.value != null ? parseInt(e.target.value) : undefined) }}
                onValueChange={(newVal: number) => { setOrgSectionOverride(newVal) }}
                value={orgSectionOverride} maxValue={NUMERIC_INPUT.MAX} minValue={NUMERIC_INPUT.MIN} style={{ backgroundColor: "white" }} disabled={orgButtonSelected != "EO"} />
            </Flex>

          </Flex>

          {/*Non-Organic Practice*/}

          <Flex direction="column" className={`${styles.flexorg}`}>
            <div style={{ color: getSproutColor("neutral30") }}>Non-Organic Practice</div>

            <Flex className={`${isPracticeOrg ? styles.disable_buttons : ""}`}>
              <div data-testid="norg-BU" id="norg-BU" className={`${styles.option_button} ${norgButtonSelected == "BU" ? styles.green_background : ""}`}
                onClick={(e) => { setNOrgButtonSelected("BU"); setNOrgSectionOverride(0) }}> BU </div>
              <div data-testid="norg-OU" id="norg-OU" className={`${styles.option_button} ${norgButtonSelected == "OU" ? styles.green_background : ""}`}
                style={{ marginRight: '5px' }} onClick={(e) => { setNOrgButtonSelected("OU"); setNOrgSectionOverride(0) }}> OU </div>
              <div>or</div>

              <div data-testid="norg-EO" id="norg-EO" className={`${styles.option_button_override} ${norgButtonSelected == "EO" ? styles.green_background : ""}`}
                onClick={(e) => setNOrgButtonSelected("EO")}> Override for EO </div>
            </Flex>

            <p data-testid={"current-section-nonorg1" + coverageUnitStructure?.coverageID.toString()} className={`${styles.currentSectionText}`}>Current Sections: {currentSectionCount("Non-Org")}</p>
            <div style={{ color: '#4c4d4d' }}>Non-Organic Section Override</div>
            <Flex className={`${isPracticeOrg ? styles.disable_buttons : ""}`}>
              <NumericInput data-testid="n-numeric-input" id="n-numeric-input" btnVariabnt="neutral-outlined" className="fixed-num-input" onChange={(e) => setNOrgSectionOverride(e.target.value != null ? parseInt(e.target.value) : undefined)}
                onValueChange={(newVal: number) => { setNOrgSectionOverride(newVal) }}
                value={nOrgSectionOverride} maxValue={NUMERIC_INPUT.MAX} minValue={NUMERIC_INPUT.MIN} style={{ backgroundColor: "white" }} disabled={norgButtonSelected != "EO"} />
            </Flex>

          </Flex>

        </div>

        {/*End region radioA*/}

        <br />
        {/*region radio B*/}
        {showSelectUnitStructure &&


          <div data-testid="radio-button-B" className={`${styles.radioGroupCardB} ${radioCheck == "B" ? styles.selectedRadioButton : ""}`}
            onClick={() => handleRadioButton("radioB")}>
            Select unit structure

            {radioCheck == "B" ?
              <Icon iconName="CheckmarkFilled" id="radioB" size="sm" className={`${styles.divRadioB}`} /> :
              <Icon iconName="RadioButton" id="radioB" size="sm" className={`${styles.divRadioB}`} />
            }
            <p className={`${styles.muspara}`}>{INFO_MESSAGES.MUS_EOREMOVE}</p>

            <Flex direction="row">
              <Flex direction="column" className={`${styles.flexc}`}>
                <Flex className={`${styles.muspara}`} style={{ marginBottom: "3px" }}>
                  Unit Structure
                </Flex>
                <Flex>
                  <div data-testid="c-BU" id="c-BU" className={`${styles.option_button} ${cButtonSelected == "BU" ? styles.green_background : ""}`}
                    onClick={(e) => { setCButtonSelected("BU"); setBothSectionOverride(0) }}> BU </div>
                  <div data-testid="c-EU" id="c-EU" className={`${styles.option_button} ${cButtonSelected == "EU" ? styles.green_background : ""}`}
                    onClick={(e) => setCButtonSelected("EU")}> EU </div>
                  <div data-testid="c-OU" id="c-OU" className={`${styles.option_button} ${cButtonSelected == "OU" ? styles.green_background : ""}`}
                    onClick={(e) => { setCButtonSelected("OU"); setBothSectionOverride(0) }}> OU </div>
                </Flex>
              </Flex>

              <Flex direction="column" className={`${styles.flexc}`}>
                <Flex>
                  <NumericInput data-testid="c-numeric-input" id="c-numeric-input" btnVariabnt="neutral-outlined" className="fixed-num-input" onChange={(e) => setBothSectionOverride(e.target.value != null ? parseInt(e.target.value) : undefined)}
                    value={bothSectionOverride} maxValue={NUMERIC_INPUT.MAX} minValue={NUMERIC_INPUT.MIN} labelText="Section Override"
                    style={{ backgroundColor: "white" }} disabled={cButtonSelected != "EU"}
                  />
                </Flex>
              </Flex>
            </Flex>
            <p data-testid={"current-section-org2" + coverageUnitStructure?.coverageID.toString()} className={`${styles.currentSectionText} ${styles.currentSectionOrgNonOrg}`}>Current Sections Org: {currentSectionCount("Org")}</p>
            <p data-testid={"current-section-nonorg2" + coverageUnitStructure?.coverageID.toString()} className={`${styles.currentSectionText} ${styles.currentSectionOrgNonOrg}`}>Current Sections Non-Org: {currentSectionCount("Non-Org")}</p>
          </div>
        }

        {/*End region radio B*/}

        <br />
      </div>
    </>
  );
};
