import { Typography } from "@proag/sprout";
import { AgChartsReact } from "ag-charts-react";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import {
  AcresSummary,
  mapAcresResultsToAcresSummary,
} from "features/managed-acres/functions/mappings/mapAcresResultsToAcresSummary";
import { usePoliciesAcresQuery } from "features/managed-acres/hooks/policies/policiesQueries";
import { useEffect, useState } from "react";
import styles from "./producers-planted-acres-review-planting-summary.module.scss";
import { DefaultChartOptions } from "features/planted-acres/review/components/charts/chartOptions";

interface ProducersReviewPlantingSummary {}

export const ProducersReviewPlantingSummary: React.FC<
  ProducersReviewPlantingSummary
> = () => {
  const policyId = useAppStateStore((s) => s.policyId);
  const { acres } = usePoliciesAcresQuery(policyId);

  const [reviewAcresSummary, setAcresSummary] = useState<AcresSummary>({
    calculatedAcres: 0,
    plantedAcres: 0,
    preventedAcres: 0,
    unreportedAcres: 0,
  } as AcresSummary);

  useEffect(() => {
    if (acres) {
      setAcresSummary(mapAcresResultsToAcresSummary(acres));
    }
  }, [acres]);

  const options = {
    ...DefaultChartOptions,
    data: [
      {
        SliceName: "Reported Acres",
        amount: reviewAcresSummary.plantedAcres,
        fill: "lightgreen",
      },
      {
        SliceName: "PreventedPlant",
        amount: reviewAcresSummary.preventedAcres,
        fill: "orange",
      },
      {
        SliceName: "UnReported",
        amount: reviewAcresSummary.unreportedAcres,
        fill: "grey",
      },
    ],
  };

  return (
    <div data-testid="planting-summary">
      <div className="row">
        <Typography className="title-md">Planting Summary</Typography>
      </div>
      <div className="row">
        <div className="col-6">
          <div>Reported Acres</div>
          <span data-testid="planting-summary-reported-acres" className="bold">
            {(
              reviewAcresSummary.plantedAcres +
              reviewAcresSummary.preventedAcres
            ).toFixed(2)}
          </span>
          /{reviewAcresSummary.calculatedAcres.toFixed(2)}
        </div>
        <div className="col-6">
          <div>Unreported Acres</div>
          <span className="bold">
            {reviewAcresSummary.unreportedAcres.toFixed(2)}
          </span>
        </div>
      </div>
      <div className={`row`}>
        <div
          className="col-6 p-0"
          style={{
            height: "200px",
          }}
        >
          <AgChartsReact options={options} />
        </div>
        <div className={`${styles.parent} col-6`}>
          <div className="bold">Reported Acres</div>
          <div className="p-1">
            <div className={`${styles.planted}`}>
              Planted Acres ({reviewAcresSummary.plantedAcres.toFixed(2)} Acres)
            </div>
          </div>
          <div className="p-1">
            <div className={`${styles.preventedPlant}`}>
              Prevent Plant ({reviewAcresSummary.preventedAcres.toFixed(2)}{" "}
              Acres)
            </div>
          </div>
          <div className="p-1">
            <div className="bold">Unreported Acres </div>
            <div className={`${styles.unreportedAcres}`}>
              Unreported Acres ({reviewAcresSummary.unreportedAcres.toFixed(2)}{" "}
              Acres)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
