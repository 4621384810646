import { Flex, colors } from "@proag/sprout";
import styles from "./producers-planted-acres-review-farms-sidebar-card.module.scss";
import { PoliciesAcresQueryResult } from "features/managed-acres/hooks/policies/models/policies-acres-query-result";

interface ProducersReviewFarmSidebarCardProps {
  farmId: number;
  farmName: string;
  otherLandDescription: string;
  countyName: string;
  countyCode: string;
  stateCode: string;
  section: string;
  township: string;
  range: string;
  fn: number;
  tractNumber: number;
  totalAcres: number;
  plantedAcres: number;
  isSelected: boolean;
}

export const ProducersReviewFarmSidebarCard: React.FC<
  ProducersReviewFarmSidebarCardProps
> = ({
  farmId,
  farmName,
  otherLandDescription,
  countyName,
  countyCode,
  stateCode,
  section,
  township,
  range,
  fn,
  tractNumber,
  totalAcres,
  plantedAcres,
  isSelected,
}) => {
  const DisplayFarmName = farmName == "" ? "Untitled" : farmName;
  const DisplayFarmNumber = fn === 0 ? "" : `FN ${fn}`;
  const DisplayTract = tractNumber === 0 ? "" : `  |  Tract ${tractNumber}`;
  const DisplayCounty = `${countyName} County`;
  const DisplayLegals = !section.trim()
    ? ""
    : "  |  (" + section + ") " + township + " " + range;
  const DisplayOtherID = !otherLandDescription.trim()
    ? ""
    : otherLandDescription.trim();

  return (
    <>
      <div
        data-testid="producers-review-farms-sidebar-card"
        className={`${styles.hoverClick} mb-2 ${
          isSelected ? styles.selected : ""
        }`}
      >
        <Flex
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Flex>
            <div className="pt-3 pl-3 pb-3">
              <div className="title-sm">{DisplayFarmName}</div>
              <div className="label-sm" style={{ color: colors.neutral40 }}>
                {DisplayFarmNumber + DisplayTract}
              </div>
              <div className="label-sm" style={{ color: colors.neutral40 }}>
                {DisplayCounty + DisplayLegals}
              </div>
              <div className="label-sm" style={{ color: colors.neutral40 }}>
                {DisplayOtherID}
              </div>
            </div>
          </Flex>
          <Flex
            direction="column"
            alignItems="flex-start"
            className={"p-3 mr-2 mb-2 ml-2"}
            style={{
              backgroundColor: colors.neutral98,
            }}
          >
            <Flex direction="row" alignItems="baseline">
              <div className="label-sm" style={{ color: colors.neutral40 }}>
                Reported Acres
              </div>
            </Flex>
            <Flex
              direction="row"
              alignItems="baseline"
              justifyContent="flex-start"
            >
              <div className="label-md mr-1">{plantedAcres.toFixed(2)}</div>
              <div className="label-sm" style={{ color: colors.neutral40 }}>
                / {totalAcres.toFixed(2)}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
};
