import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { FieldManagement, useApiGateway } from "app/apiGateway";
import { PoliciesUnitFarmsShapesQueryResult } from "./models/policies-unit-farms-shapes-query-result";


export const usePoliciesUnitFarmsShapesQuery = (
  policyId: number | undefined,
  unitId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? -1;
  const queryUnitId = unitId ?? -1;

  const { data, isFetching } = useQuery<PoliciesUnitFarmsShapesQueryResult[]>({
    queryKey: ["policiesUnitFarmsShapesQuery", queryPolicyId, queryUnitId],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${queryPolicyId}/unit/${queryUnitId}/shapes`,
        FieldManagement
      ),
    staleTime: 300 * 1000, //300 seconds...5minutes
  });

  return {
    farmsShapes: data,
    isFarmsShapesFetching: isFetching,
  };
};
