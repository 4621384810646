import { Flex, colors, Icon } from "@proag/sprout";
import {
  PoliciesCoveragesUnitStructureQueryResult,
} from "features/managed-acres";
import _lodash from "lodash";
import { useEffect, useState } from "react";
import { ManageUnitStructureSidebar } from "./manage-unit-structure-sidebar";
import styles from "./producers-planted-acres-review-units-sidebar-card.module.scss";

interface UnitStructureReviewSidebarCoverageCardProps {
  isSelected: boolean;
  coverageUnitStructure: PoliciesCoveragesUnitStructureQueryResult;
  isCollapse?: boolean;
  CoverageUnitStructureToUpdate(cov: PoliciesCoveragesUnitStructureQueryResult): void;
  pastAcreageReportingDate : boolean | undefined;
}

export const UnitStructureReviewSidebarCoverageCard: React.FC<UnitStructureReviewSidebarCoverageCardProps> =
  ({ isSelected, coverageUnitStructure, isCollapse, CoverageUnitStructureToUpdate, pastAcreageReportingDate }) => {

    const [show, setShow] = useState(false);
    const [showWarningIcon, setShowWarningIcon] = useState<boolean | undefined>();

    useEffect(() => {
      if (isCollapse) {
        setShow(false);
      }
      if (isSelected) {
        setShow(true)
      }
    }, [isCollapse]);

    useEffect(() => {
    if(coverageUnitStructure?.isLocallyChanged == undefined)
      {
        const showWarningValue = coverageUnitStructure && coverageUnitStructure.unitOptionCode != null && coverageUnitStructure.systemUnitStructureOptionCode != coverageUnitStructure.unitOptionCode.code &&
        !(coverageUnitStructure.coverageUnitStructureOptionOverride?.overrideSelection && coverageUnitStructure.coverageUnitStructureOptionOverride?.overrideSelection.length > 0);
        setShowWarningIcon(showWarningValue);
      }
  }, [coverageUnitStructure]);
    return (
      <>
        <div data-testid={"unit-structure-review-sidebar-coverage-card"}
          className={`${styles.hoverClick} w-100 mb-2 `}>
          <div>
            <div>
              <div onClick={() => setShow(!show)} data-testid={"unit-structure-review-sidebar-" + coverageUnitStructure?.coverageID.toString()}>
                <Flex direction="row" alignItems="flex-end" justifyContent="space-between">
                  <Flex>
                    <div className="pt-3 pl-3 pb-3">
                      <div className="title-sm">{coverageUnitStructure.commodityName}
                        { showWarningIcon &&
                          <Icon iconName="WarningFilled" size="xs" color="#F19F49" style={{ margin: "0px 0px 2px 5px" }} />
                        }
                      </div>
                      <div className="label-sm" style={{ color: colors.neutral40 }}>
                        {coverageUnitStructure?.countyName}
                      </div>
                    </div>
                  </Flex>
                  <Flex>
                    <Flex direction="row" alignItems="flex-end" justifyContent="space-between">
                      <Flex direction="column" alignItems="flex-end" justifyContent="space-between" className={"mr-2 mb-4 ml-1"}>
                        <Icon data-testid={"unit-structure-review-sidebar-card-button-" + coverageUnitStructure?.coverageID.toString()}
                          className="right-align" iconName={show ? "ChevronUp" : "ChevronDown"} />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
              {show && coverageUnitStructure != null &&
                <ManageUnitStructureSidebar key={coverageUnitStructure.coverageID} isSelected={false} coverageUnitStructure={coverageUnitStructure}
                  CoverageUnitStructureToUpdate={CoverageUnitStructureToUpdate} pastAcreageReportingDate={pastAcreageReportingDate}></ManageUnitStructureSidebar>
              }
            </div>
          </div>
        </div>
      </>
    );
  };
