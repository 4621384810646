import { useQuery } from "@tanstack/react-query";
import { useApiGateway } from "app/apiGateway";
import { Party } from "app/apiGateway/constants/ApplicationIdValues";

export type ApplicationRolesQueryResultItem = {
  applicationName: string;
  roleName: string;
};

export const useApplicationRolesQuery = () => {
  const apiGateway = useApiGateway();
  const queryApplicationName = "SimplifiedAR";

  const { data, isFetching } = useQuery<ApplicationRolesQueryResultItem[]>({
    queryKey: ["ApplicationRolesQueryResult"],
    queryFn: async () =>
      await apiGateway.get(`applications/${queryApplicationName}/roles`, Party),
    staleTime: 60 * 1000 * 10, //10 minutes
  });

  return {
    ApplicationRolesData: data,
    isApplicationRolesFetching: isFetching,
  };
};
