import Header from "./Header";
import { FC, PropsWithChildren } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Flex } from "@proag/sprout";

const Layout_Alternate1: FC<PropsWithChildren> = (props) => {
  return (
    <>
      <Header />

      {/* Page Body*/}
      <div>{props.children}</div>

      {/* Page Footer*/}
      {/* MISC */}
      <ToastContainer
        position="top-center"
        hideProgressBar
        autoClose={3000}
        theme="colored"
        draggable={false}
      />
    </>
  );
};

export default Layout_Alternate1;
