import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { isEmpty, startCase } from "lodash";
import { GridReadyEvent } from "ag-grid-community";
import { FieldDataType } from "./FieldDataType";
import { useParams } from "react-router";
import { usePoliciesCropUnitsQuery } from "features/managed-acres/hooks/policies/policiesQueries";
import {
  AcreageSummary,
  calculateAcreageTotals,
  setColumns,
} from "./farm-fields-acreage-utils";
import styles from "./farm-fields-style.module.scss";

interface FarmFieldsAcreageFooterTableProps {
  parentGrid: RefObject<AgGridReact<any>>;
  fieldData?: FieldDataType[];
}

export function FarmFieldsAcreageFooterTable({
  parentGrid,
  fieldData,
}: FarmFieldsAcreageFooterTableProps): JSX.Element {
  const { pawPolicyOid } = useParams();
  const gridRef = useRef<AgGridReact>(null);
  const { cropUnits } = usePoliciesCropUnitsQuery(Number(pawPolicyOid));
  const [acreageTableData, setAcreageTableData] = useState<
    AcreageSummary[] | null
  >(null);

  const acreageData = useMemo(
    () => calculateAcreageTotals(fieldData),
    [fieldData]
  );
  const averageDataColumnDef = useMemo(
    () => setColumns(acreageData),
    [acreageData]
  );

  useEffect(() => {
    if (
      cropUnits &&
      cropUnits.crops &&
      cropUnits.crops.length > 0 &&
      !isEmpty(acreageData)
    ) {
      const nextAverageData = { ...acreageData };
      for (let [key, value] of Object.entries(nextAverageData)) {
        const nextValue = Math.round(value * 100) / 100;
        const crop = cropUnits?.crops.find(
          (c) => c.coverageId.toString() === key
        );
        if (crop) {
          nextAverageData[key] = `${crop.commodityName}: ${nextValue}`;
        } else {
          nextAverageData[key] = `${startCase(key)}: ${nextValue}`;
        }
      }
      setAcreageTableData([nextAverageData]);
    } else {
      const nextData: AcreageSummary = {
        totalAcres: 0,
        PPAcres: 0,
        reportedAcres: 0,
      };

      for (let [key, value] of Object.entries(nextData)) {
        nextData[key] = `${startCase(key)}: 0`;
      }

      setAcreageTableData([nextData]);
    }
  }, [cropUnits?.crops, acreageData]);

  return (
    <div className={styles.acreageFooterTable} data-testid="agrid-farms-fields">
      <AgGridReact
        alignedGrids={parentGrid.current ? [parentGrid.current] : undefined}
        rowData={acreageTableData}
        columnDefs={averageDataColumnDef}
        headerHeight={0}
        rowStyle={{ fontWeight: "600", fontSize: "17px" }}
        ref={gridRef}
        alwaysShowHorizontalScroll={true}
        defaultColDef={{ resizable: true }}
        domLayout="autoHeight"
        suppressCellFocus={true}
        rowHeight={50}
        onGridReady={(event: GridReadyEvent) => {
          event.api.hideOverlay();
        }}
        suppressHorizontalScroll={true}
      />
    </div>
  );
}
