import { colors, Dialog, IconButton } from "@proag/sprout";
import styles from "./producers-farms-sidebar-card.module.scss";
import { useRef, useState } from "react";
import { useDeleteFarm } from "../hooks/policies/policiesQueries";
import { useAppStateStore } from "../../../app/state-management/use-app-state-store";
import { toast } from "react-toastify";

interface ProducersFarmSidebarCardProps {
  producerId: number;
  farmId: number;
  farmName: string;
  otherLandDescription: string;
  countyName: string;
  countyCode: string;
  stateCode: string;
  section: string;
  township: string;
  range: string;
  fn: number;
  tractNumber: number;
  plantedAcres: number;
  totalAcres: number;
  fields: number;
  isSelected: boolean;
  onSelected: (farmId: number) => void;
  onFarmDeleted: (farmId: number) => void;
}

export const ProducersFarmSidebarCard: React.FC<
  ProducersFarmSidebarCardProps
> = ({
  producerId,
  farmId,
  farmName,
  otherLandDescription,
  countyName,
  countyCode,
  stateCode,
  section,
  township,
  range,
  fn,
  tractNumber,
  plantedAcres,
  totalAcres,
  fields,
  isSelected,
  onSelected,
  onFarmDeleted,
}) => {
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const policyId = useAppStateStore((s) => s.policyId);
  const DisplayFarmName = farmName == "" ? "Untitled" : farmName;
  const DisplayFarmNumber = fn === 0 ? "" : `FN ${fn}`;
  const DisplayTract = tractNumber === 0 ? "" : `  |  Tract ${tractNumber}`;
  const DisplayCounty = `${countyName} County`;
  const DisplayLegals = !section.trim()
    ? ""
    : "  |  (" + section + ") " + township + " " + range;
  const DisplayOtherID = !otherLandDescription.trim()
    ? ""
    : "  |  " + otherLandDescription.trim();

  const cardRef = useRef<any>();

  const onDeleteSuccess = () => {
    onFarmDeleted(farmId);
    toast.success("Farm successfully removed");
  };
  const onDeleteFail = () => {
    toast.error("Error while deleting the farm.");
  };
  const { deleteFarm } = useDeleteFarm(onDeleteSuccess, onDeleteFail);
  const onDeleteFarm = () => {
    setIsConfirmationVisible(true);  
  }
  function onModelClose() {
    setIsConfirmationVisible(false);
  }
  function onConfirmation() {
    setIsConfirmationVisible(false);
    deleteFarm(policyId, farmId); 
  }
  return (
    <>
      <Dialog
        visible={isConfirmationVisible }
        onClose={()=>onModelClose()}
        onPrimaryButtonClick={() => onConfirmation()}
        onSecondaryButtonClick={() => onModelClose()}
        primaryButtonText="OK"
        secondaryButtonText="Cancel"
        titleText="Remove Farm"
      >
        <p>
          Are you sure you want to remove this farm from the list? Removing the farm will delete any reported acres on the farm.
        </p>
      </Dialog>  
    <div
        ref={cardRef}
        data-testid="producers-farms-sidebar-card"
        className={`${styles.hoverClick} w-100 mb-2 ${isSelected ? styles.selected : ""
          }`}
        onClick={() => onSelected(farmId)}
      >
        <div>         
          <div className="p-3">
            <div className={styles.farmDeleteIcon} >
              <IconButton
                id={`${farmId}-farm-delete-icon`}
                data-testid={`${farmId}-farm-delete-icon`}
                iconName="TrashCan"
                onClick={(e) => { e.stopPropagation(); onDeleteFarm() }}
                style={{ float: 'right' }}
              />
            </div>
            <div className="title-sm">{DisplayFarmName}</div>
            <div className="label-sm" style={{ color: colors.neutral40 }}>
              {DisplayFarmNumber + DisplayTract}
            </div>
            <div className="label-sm" style={{ color: colors.neutral40 }}>
              {DisplayCounty + DisplayLegals + DisplayOtherID}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
