import { IconButton } from "@proag/sprout";
import {
  CustomToggle,
  IPoliciesCrops,
  IPoliciesUnits,
} from "features/managed-acres";
import { useState } from "react";
import _lodash from "lodash";
import { colors } from "@proag/sprout";
import { Dropdown } from "react-bootstrap";
import { ProducersFarmUnitsEditUnitCardDetails } from "components/ui/producers-farm-units-edit-unit-card-details";

interface ProducersFarmsEditUnitCardProps {
  coverage: IPoliciesCrops | undefined;
  unit: IPoliciesUnits;
  onChange: (unit: IPoliciesUnits, hasValidationError: boolean) => void;
}

export const ProducersFarmsEditUnitCard: React.FC<
  ProducersFarmsEditUnitCardProps
> = ({ coverage, unit, onChange }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <div
        data-testid={"producers-farms-edit-unit-card"}
        className={"mb-1 surface-card border-round shadow-3"}
      >
        <div>
          <div
            data-testid={
              "producers-farms-edit-unit-card-unit-" + unit?.unitId.toString()
            }
            className={`p-3 px-3 pt-2 pb-3 w-100`}
          >
            <div
              className="d-flex justify-content-between"
              onClick={() => setShowDetails(!showDetails)}
            >
              <div>
                <div className="title-sm bold">{unit.unitNumber}</div>
                {coverage ? (
                  <div className="label-sm" style={{ color: colors.neutral40 }}>
                    <div>
                      {coverage.commodityName +
                        " " +
                        coverage.insurancePlanAbbrev +
                        ` - ${Math.round(
                          coverage.coverageLevel * 100
                        )}% - 100%`}
                    </div>
                    <div>
                      {unit.practiceName +
                        " - " +
                        unit.typeName +
                        " - Share " +
                        Math.round(unit.share * 100) +
                        "%"}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <IconButton
                data-testid={
                  "producers-farms-edit-unit-card-button-" +
                  coverage?.coverageId.toString()
                }
                className="right-align"
                iconName={showDetails ? "ChevronUp" : "ChevronDown"}
              />
            </div>
            {showDetails && (
              <ProducersFarmUnitsEditUnitCardDetails
                unit={unit}
                onChange={onChange}
              />
            )}
          </div>
        </div>
        <div className="mb-1"></div>
      </div>
    </>
  );
};
