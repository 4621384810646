import { IconButton, IconName } from "@proag/sprout";
import { IHeaderParams } from "ag-grid-community";
import { FieldDataType } from "features/managed-acres/components/tables/FarmFields/FieldDataType";
import { useCallback } from "react";

export interface ICustomHeaderParams extends IHeaderParams<FieldDataType> {
  menuIcon: IconName | undefined;
  displayName: string;
  iconToolTip?: string;
  justify:
    | "justify-content-start"
    | "justify-content-center"
    | "justify-content-end";
}
export const CustomHeader = ({
  menuIcon,
  displayName,
  api,
  iconToolTip,
  justify = "justify-content-start",
}: ICustomHeaderParams) => {
  const returnIcon = () => {
    if (menuIcon) {
      if (iconToolTip && iconToolTip.length > 0) {
        return (
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-html={iconToolTip}
            data-testid={"custom-header-icon-toolTip"}
          >
            <IconButton
              data-testid="icon-button"
              iconName={menuIcon}
              size="sm"
              variant="brand-outlined"
            />
          </a>
        );
      } else {
        return (
          <>
            <IconButton
              data-testid="icon-button"
              iconName={menuIcon}
              size="sm"
              variant="brand-outlined"
            />
          </>
        );
      }
    }
  };

  const returnHeaderData = () => {
    if (menuIcon == undefined) {
      return <>{displayName}</>;
    }
    return (
      <>
        <span>{displayName}</span>
        <>&nbsp;&nbsp;&nbsp;&nbsp;</>
        <span>{returnIcon()}</span>
      </>
    );
  };

  return (
    <div className={`w-100 d-flex ${justify}`}>
      <div
        className={`d-flex align-items-center`}
        data-testid={"customheader-" + displayName}
      >
        {returnHeaderData()}
      </div>
    </div>
  );
};
