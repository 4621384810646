import { useAppStateStore } from "app/state-management/use-app-state-store";
import { LoadingSpinner } from "components/common/LoadingSpinner";
import {
  CustomToggle,
  IPoliciesUnits,
  usePoliciesCoveragesAcreageTypesQuery,
  usePoliciesCoveragesUnitsAreaClassesQuery,
  usePoliciesCoveragesUnitsSkipRowRulesQuery,
} from "features/managed-acres";
import _lodash from "lodash";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { PoliciesCoveragesAcreageTypesResult } from "features/managed-acres/hooks/policies/models/policies-coverages-acreage-types-result";
import { PoliciesCoveragesUnitsAreaClassesResult } from "features/managed-acres/hooks/policies/models/policies-coverages-area-classes-result";
import { TextInput } from "@proag/sprout";
import { SkipRowOption } from "features/managed-acres/hooks/policies/models/policies-coverages-skip-row-rules-result";
import { isNumeric } from "app/utils";
import {
  FeatureFarmUnitSkipRowEditIsActive,
  useFeatureFlag,
} from "app/feature-flag";

interface ProducersFarmUnitsEditUnitCardDetailsProps {
  unit: IPoliciesUnits;
  onChange: (unit: IPoliciesUnits, hasValidationError: boolean) => void;
}

const placeholder = "Select";
export const ProducersFarmUnitsEditUnitCardDetails: React.FC<
  ProducersFarmUnitsEditUnitCardDetailsProps
> = ({ unit, onChange }) => {
  const [acreageTypeDisplay, setAcreageTypeDisplay] =
    useState<string>(placeholder);
  const [hasErrors, setHasErrors] = useState(false);
  const [modifiedUnit, setModifiedUnit] = useState<IPoliciesUnits>({ ...unit });
  const [areaClassDisplay, setAreaClassDisplay] = useState<string>(placeholder);
  const [skipRowRuleDisplay, setSkipRowRuleDisplay] =
    useState<string>(placeholder);
  const [skipRowWidth, setSkipRowWidth] = useState<number>(0);
  const [skipRowWidthErrorDisplay, setSkipRowWidthErrorDisplay] =
    useState<string>("");
  const [skipRowFactor, setSkipRowFactor] = useState<string>("");
  const policyId = useAppStateStore((s) => s.policyId);
  const { coverageAcreageTypes, isCoveragesAcreageTypesFetching } =
    usePoliciesCoveragesAcreageTypesQuery(Number(policyId), unit?.coverageId);

  const { coverageUnitsAreaClasses, isCoveragesUnitsAreaClassesFetching } =
    usePoliciesCoveragesUnitsAreaClassesQuery(
      Number(policyId),
      unit?.coverageId,
      unit?.unitId
    );

  const { coverageUnitsSkipRowRules, isCoveragesUnitsSkipRowRulesFetching } =
    usePoliciesCoveragesUnitsSkipRowRulesQuery(
      Number(policyId),
      unit?.coverageId,
      unit?.unitId
    );
  const { featureFlag: flag_FeatureFarmUnitSkipRowEditIsActive } =
    useFeatureFlag(FeatureFarmUnitSkipRowEditIsActive);

  useEffect(() => {
    setModifiedUnit({ ...unit });
    setSelectedAcreageType();
    setSelectedAreaClass();
    setSkipRowRuleDisplay(
      modifiedUnit?.plantPattern == null || modifiedUnit?.plantPattern === ""
        ? placeholder
        : modifiedUnit?.plantPattern
    );
    setSkipRowWidth(modifiedUnit?.rowWidth ?? 0);
    setSkipRowFactor(modifiedUnit?.skipRowFactor?.toString() ?? "");
  }, [unit]);

  useEffect(() => {
    if (isCoveragesAcreageTypesFetching) return;
    setSelectedAcreageType();
  }, [isCoveragesAcreageTypesFetching]);

  useEffect(() => {
    if (isCoveragesUnitsAreaClassesFetching) return;
    setSelectedAreaClass();
  }, [isCoveragesUnitsAreaClassesFetching]);

  function handleAcreageTypeChange(
    value: PoliciesCoveragesAcreageTypesResult | undefined
  ) {
    modifiedUnit.acreageTypeCode = value?.acreageTypeCode ?? "";
    onChange(modifiedUnit, hasErrors);
    setAcreageTypeDisplay(formatAcreageType(value));
  }
  //
  function handleAreaClassChange(
    value: PoliciesCoveragesUnitsAreaClassesResult | undefined
  ) {
    modifiedUnit.mapArea = value?.subCountyCode ?? "";
    onChange(modifiedUnit, hasErrors);
    setAreaClassDisplay(value?.subCountyCode ?? placeholder);
  }

  function handleSkipRowRuleChange(value: SkipRowOption | undefined) {
    modifiedUnit.plantPattern = value?.pattern ?? "";
    modifiedUnit.skipRowCode = value?.patternCode ?? "";

    if (isNumeric(value?.minRowWidth)) {
      modifiedUnit.rowWidth = Number(value?.minRowWidth) ?? 0;
      setSkipRowWidth(modifiedUnit.rowWidth);
    }
    modifiedUnit.skipRowFactor = value?.skipRowFactorDecimal ?? 0;
    setSkipRowFactor(modifiedUnit.skipRowFactor?.toString());
    onChange(modifiedUnit, hasErrors);
    setSkipRowRuleDisplay(value?.pattern ?? placeholder);
  }

  function handleSkipRowWidthChange(value: number | undefined) {
    modifiedUnit.rowWidth = value ?? 0;
    setSkipRowWidth(value ?? 0);
    let selectedSkipRowRule = coverageUnitsSkipRowRules?.skipRowOptions?.filter(
      (u) => u.pattern?.trim() == modifiedUnit.plantPattern?.trim()
    )[0];

    if (selectedSkipRowRule) {
      if (selectedSkipRowRule.maxRowWidth < modifiedUnit.rowWidth) {
        setHasErrors(true);
        setSkipRowWidthErrorDisplay(
          "Cannot exceed max row width of " +
            selectedSkipRowRule.maxRowWidth +
            "."
        );
        onChange(modifiedUnit, true);
      } else {
        setHasErrors(false);
        setSkipRowWidthErrorDisplay("");
        onChange(modifiedUnit, false);
      }
    }
  }

  function handleSkipRowFactorChange(value: string) {
    const newValue = value;

    // Validate that the input is a valid number or decimal
    if (newValue === "" || /^[0-9]*\.?[0-9]*$/.test(newValue)) {
      setSkipRowFactor(newValue);
    }

    modifiedUnit.skipRowFactor = Number(newValue);
    onChange(modifiedUnit, hasErrors);
  }

  function formatAcreageType(
    value: PoliciesCoveragesAcreageTypesResult | undefined
  ) {
    if (!value) return placeholder;
    return value?.acreageTypeCode + " - " + value?.acreageTypeDescription;
  }

  function setSelectedAreaClass() {
    let selectedAreaClass = coverageUnitsAreaClasses?.filter(
      (u) => u.subCountyCode.trim() == unit.mapArea.trim()
    )[0];
    setAreaClassDisplay(selectedAreaClass?.subCountyCode ?? placeholder);
  }

  function setSelectedAcreageType() {
    let selectedAcreageType = coverageAcreageTypes?.filter(
      (u) => u.acreageTypeCode?.trim() == unit.acreageTypeCode?.trim()
    )[0];
    setAcreageTypeDisplay(formatAcreageType(selectedAcreageType));
  }

  return (
    <>
      {isCoveragesAcreageTypesFetching ||
      isCoveragesUnitsAreaClassesFetching ||
      isCoveragesUnitsSkipRowRulesFetching ? (
        <LoadingSpinner />
      ) : (
        <div
          data-testid={"producers-farm-units-edit-unit-card-details"}
          className={"mb-1 surface-card border-round shadow-3"}
        >
          <div className={`p-3 px-3 pt-2 pb-3 w-100`}>
            <div>
              <div className="flex-grow-1">
                <div>
                  <div className="label-sm mb-1 mt-3">Acreage Type</div>
                  <Dropdown data-testid="acreage-type-drop-down">
                    <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
                      <div className="single-truncate">
                        {acreageTypeDisplay}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        key={""}
                        onClick={() => handleAcreageTypeChange(undefined)}
                        data-testid="acreage-type-empty-selection"
                      >
                        {placeholder}
                      </Dropdown.Item>
                      {coverageAcreageTypes?.map((acreageType) => {
                        return (
                          <Dropdown.Item
                            key={acreageType.acreageTypeCode}
                            onClick={() => handleAcreageTypeChange(acreageType)}
                            className="single-truncate"
                          >
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-html={
                                '<div style="width: 250px">' +
                                acreageType.acreageTypeDescription +
                                "</div>"
                              }
                              data-tooltip-place="left"
                              data-tooltip-delay-show={300}
                            >
                              {formatAcreageType(acreageType)}
                            </a>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {coverageUnitsAreaClasses &&
                coverageUnitsAreaClasses.length > 0 && (
                  <div className="flex-grow-1">
                    <div>
                      <div className="label-sm mb-1 mt-3">Area Class</div>
                      <Dropdown data-testid="area-class-drop-down">
                        <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
                          {areaClassDisplay}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            key={""}
                            onClick={() => handleAreaClassChange(undefined)}
                            data-testid="area-class-empty-selection"
                          >
                            {placeholder}
                          </Dropdown.Item>
                          {coverageUnitsAreaClasses?.map((areaClass) => {
                            return (
                              <Dropdown.Item
                                key={areaClass.subCountyCode}
                                onClick={() => handleAreaClassChange(areaClass)}
                              >
                                {areaClass.subCountyCode}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                )}
              {flag_FeatureFarmUnitSkipRowEditIsActive &&
                coverageUnitsSkipRowRules &&
                coverageUnitsSkipRowRules.isSkipRowAvailable && (
                  <div className="flex-grow-1">
                    <div>
                      <div className="label-md mb-1 mt-3">Skip Row Details</div>
                      <div className="label-sm mb-1 mt-3">Pattern</div>
                      <Dropdown data-testid="skip-row-pattern-drop-down">
                        <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
                          {skipRowRuleDisplay}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            key={""}
                            onClick={() => handleSkipRowRuleChange(undefined)}
                            data-testid="skip-row-pattern-empty-selection"
                          >
                            {placeholder}
                          </Dropdown.Item>
                          {coverageUnitsSkipRowRules?.skipRowOptions?.map(
                            (skipRowOption) => {
                              return (
                                <Dropdown.Item
                                  key={skipRowOption.pattern}
                                  onClick={() =>
                                    handleSkipRowRuleChange(skipRowOption)
                                  }
                                >
                                  {skipRowOption.pattern}
                                </Dropdown.Item>
                              );
                            }
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="label-sm mb-1 mt-3">Row Width</div>
                      <div className={`d-flex w-100`}>
                        <TextInput
                          type="number"
                          name="skip-row-width"
                          data-testid="skip-row-width"
                          disabled={
                            modifiedUnit?.plantPattern === "Solid"
                              ? true
                              : false
                          }
                          value={skipRowWidth}
                          errorText={skipRowWidthErrorDisplay}
                          onValueChange={(event) =>
                            handleSkipRowWidthChange(event)
                          }
                          maxLength={2}
                        ></TextInput>
                      </div>
                      <div className="label-sm mb-1 mt-3">Factor</div>
                      <TextInput
                        type="text"
                        name="skip-row-factor"
                        data-testid="skip-row-factor"
                        disabled={
                          modifiedUnit?.plantPattern === "Other" ? false : true
                        }
                        value={skipRowFactor?.toString()}
                        onValueChange={(value) =>
                          handleSkipRowFactorChange(value)
                        }
                      ></TextInput>
                    </div>
                  </div>
                )}
            </div>
            <div className="mb-1"></div>
          </div>
        </div>
      )}
    </>
  );
};
