import { ICellEditorParams } from "ag-grid-community";
import {
  IPoliciesUnits,
  UnitOptions,
} from "features/managed-acres";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  memo,
  ReactNode,
} from "react";
import Select, {
  ActionMeta,
  SingleValue,
} from "react-select";
import "./dropdownCellEditor.scss";

export const UnitPracticeTypeDropdownCellEditor = memo(
  forwardRef((props: ICellEditorParams, ref) => {
    const [selection, setSelection] = useState<number | undefined>(
      !props.value.value ? 0 : parseInt(props.value.value)
    );
    const selectRef = useRef<any>(null);

    const options = [{ value: 0, label: "Select", isDisabled: false }].concat(
      UnitOptions(
        props.data.coverageId?.value ?? 0,
        props?.data?.farm.farmId,
        props?.data?.units,
        props?.data?.farmUnits,
        props?.context.isRoleUnderwriter
      )
    );

    const activeOption = options.find((o) => o.value === selection);

    useEffect(() => {
      // focus on the input
      selectRef.current?.focus();
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return selection;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const onChangeHandler = (
      newValue: SingleValue<{ value: number; label: string }>,
      actionMeta: ActionMeta<{ value: number; label: string }>
    ) => {
      let newSelectionValue = newValue?.value;
      setSelection(newSelectionValue);
    };

    const createToolTip = (toolTipTexts?: string[]): string => {
      if (!toolTipTexts || toolTipTexts?.length == 0) {
        return "";
      }
      var myString = ``;
      toolTipTexts.forEach((item) => (myString += `<div>${item}</div>`));
      return myString;
    };

    const formatLabelOption = (data: {
      value: number;
      label: string;
    }): ReactNode => {
      const toolTipText = props.data.units.find(
        (v: IPoliciesUnits) => v.unitId === data.value
      );

      return (
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-html={createToolTip(toolTipText?.toolTipText)}
          data-tooltip-place="right"
          data-tooltip-delay-show={500}
        >
          <div data-testid="unit-type-practice-dropdown-option">
            {data.label}
          </div>
        </a>
      );
    };

    return (
      <div className="w-100 h-100" data-testid="unit-type-practice-dropdown">
        <Select
          className="h-100"
          ref={selectRef}
          formatOptionLabel={formatLabelOption}
          options={options}
          defaultValue={activeOption ?? options[0]}
          menuPortalTarget={document.body}
          onChange={onChangeHandler}
          styles={{
            menu: (base) => ({ ...base, marginTop: 0 }),
          }}
        />
      </div>
    );
  })
);
