import { Typography } from "@proag/sprout";
import { FC, PropsWithChildren } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import styles from "./Layout.module.scss";
import { Sidebar } from "./Sidebar";

const Layout_Main: FC<PropsWithChildren> = (props) => {
  return (
    <>
      <div className={styles.grid}>
        <Header />
        <div className={styles.gridSidebar}>
          <Sidebar />
        </div>
        <div className={styles.gridMain}>{props.children}</div>
      </div>
      <footer className={styles.gridFooter}>
        <Typography className="body-sm">
          &copy; {new Date().getFullYear()} ProAg. All rights reserved. A member
          of the Tokio Marine HCC group of companies. <br />
          ProAg is an equal opportunity provider. <br />
          <a
            href="https://www.proag.com/privacy-non-discrimination-statements/"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            Privacy and Non-Discrimination Statements
          </a>
        </Typography>
      </footer>
      <ToastContainer
        position="top-center"
        hideProgressBar
        autoClose={3000}
        theme="colored"
        draggable={false}
      />
    </>
  );
};

export default Layout_Main;
