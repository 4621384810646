import { useQuery } from "@tanstack/react-query";
import { ApiGateway, FieldManagement, useApiGateway } from "app/apiGateway";

export type RmaAdmStatesResponse = RmaAdmState[];

export type RmaAdmState = {
  createdBy: string;
  createdDate: Date;
  deletedDate: Date;
  lastReleasedDate: Date;
  modifiedBy: string;
  modifiedDate: Date;
  recordCategoryCode: string;
  recordTypeCode: string;
  regionalOfficeCode: string;
  regionalOfficeName: string;
  reinsuranceYear: number;
  releasedDate: Date;
  stateAbbreviation: string;
  stateCode: string;
  stateId: number;
  stateName: string;
};

const getRmaAdmStates = async (apiGateway: ApiGateway) => {
  let response = await apiGateway.get(`rma-adm/states`, FieldManagement);

  let statesResponse: RmaAdmStatesResponse = response as RmaAdmStatesResponse;
  //return response as RmaAdmStatesResponse;
  return statesResponse;
};

/*
    Purpose of this query is to provide a cached result
    set of the RmaAdm states dataset.  This will back a
    lookup function to allow easy access to various bits
    of states related data.
*/
export const useRmaAdmStatesQuery = () => {
  const apiGateway = useApiGateway();

  const { data, isFetching, refetch } = useQuery<RmaAdmStatesResponse>({
    queryKey: ["RmaAdmStates"],
    staleTime: 100 * 60 * 1000, //100 minutes
    queryFn: async () => await getRmaAdmStates(apiGateway),
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: () => console.log("rmaadm states success"),
  });

  return {
    rmaAdmStates: data,
    isRmaAdmStatesFetching: isFetching,
  };
};

export const rmaAdmStatesQueryExportedForTesting = {
  getRmaAdmStates,
};
