import { IconButton, Typography } from "@proag/sprout";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CustomToggle } from "features/managed-acres/functions/filters/custom-toggle";
import styles from "./producers-filter.module.scss";

export interface ProducersFilterProps {
  onFilterChange: (filterData: IProducersFilterData) => void;
  showHideEmpty: boolean;
}

export interface IProducersFilterData {
  filterType: string;
  filterValue: string;
  hideEmpty: boolean;
}

export const ProducersFilter: React.FC<ProducersFilterProps> = ({onFilterChange, showHideEmpty}) => {
  const [filterType, setFilterType] = useState<string>("All");
  const [filterValue, setFilterValue] = useState<string>("");
  const [hideEmpty, setHideEmpty] = useState<boolean>(false);
  const filterTypeArray = [
    "All",
    "Unit",
    "Name",
    "Number",
    "Tract",
    "Section",
    "Township",
    "Range",
    "Crop",
    "Practice",
    "Type",
  ];

  useEffect(() => {
    const filterData = {
      filterType: filterType,
      filterValue: filterValue,
      hideEmpty: hideEmpty,
    } as IProducersFilterData;

    onFilterChange(filterData);
  }, [filterType, filterValue, hideEmpty]);

  return (
    <>
      <div className={`px-3 pt-3 ${styles.sideNavHeader}`}>
        <div className={`d-flex w-100`}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
              {filterType}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {filterTypeArray.map((type) => {
                return (
                  <Dropdown.Item key={type} onClick={() => setFilterType(type)}>
                    {type}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <input
            className={`pl-1`}
            placeholder="Search"
            type="text"
            name="search"
            style={{ width: "100%" }}
            data-testid="filter-value"
            onChange={(event) => setFilterValue(event.currentTarget.value)}
          ></input>
          <IconButton
            variant="brand-tonal"
            iconName="Search"
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopRightRadius: "1px",
              borderBottomRightRadius: "1px",
              width: "60px",
              borderLeft: "1px solid #B1B2B2",
            }}
          ></IconButton>
        </div>
        <div
          style={{
            alignContent: "space-around",
          }}
        >
          <div className="d-flex my-2">
            {showHideEmpty && (
              <>
                <Typography className="body-sm" style={{ marginBottom: 3 }}>
                  Hide Empty:
                </Typography>
                <input
                  type="checkbox"
                  data-testid="farm-hideempty-filter"
                  name="farm-hideempty-filter"
                  style={{ width: "115px" }}
                  onChange={(event) =>
                    setHideEmpty(event.currentTarget.checked)
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
