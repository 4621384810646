const {
    REACT_APP_OKTA_CLIENT_ID,
    REACT_APP_OKTA_ISSUER,
    REACT_APP_OKTA_REDIRECT_URL,
    REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK,
  } = (window as any).globalConfig.oktaAuth;
  
  const BASENAME = 'http://localhost:11460';
  
  export const config = {
    oidc: {
      clientId: REACT_APP_OKTA_CLIENT_ID || 'UNKNOWN',
      issuer: REACT_APP_OKTA_ISSUER || 'UNKNOWN',
      redirectUri: REACT_APP_OKTA_REDIRECT_URL || 'UNKNOWN',
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
      disableHttpsCheck: REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || false,
    },
    resourceServer: {
      messagesUrl: 'http://localhost:11460/api/messages',
    },
    app: {
      basename: BASENAME,
    },
  };
  