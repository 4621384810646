import { useToggle } from "@proag/sprout";
import { GridApi } from "ag-grid-community";
import { useApiGateway } from "app/apiGateway";
import {
  FarmFieldsTable,
  isPageLoadApisCompleted,
  mapApplicationRolesQueryResults,
  mapFieldResultsToFieldDataType,
  useApplicationRolesQuery,
  usePoliciesCropUnitsQuery,
  usePoliciesFarmsFieldsQuery,
  usePoliciesFarmsFieldsUpdate,
  usePoliciesFarmsQuery,
} from "features/managed-acres";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import styles from "pages/producers-farms-fields/producers-farms-fields.module.scss";
import { ProducersFarmsFieldsBodyFooter } from "./producers-farms-fields-body-footer";
import { ProducersFarmsFieldsBodyHeader } from "./producers-farms-fields-body-header";
import { ProducersFarmsFieldsHeader } from "./producers-farms-fields-header";
import { ProducersFarmsFieldsMap } from "./producers-farms-fields-map";
import { FieldDataType } from "./tables/FarmFields/FieldDataType";
import { NavUtil } from "./utility/gridApiNavUtility";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { useQueryClient } from "@tanstack/react-query";
import { BulkEditFarmFields } from "./tables/bulk-edit-farm-fields";

interface ProducersFarmsFieldsBodyProps {
  nextFarmId: number;
  previousFarmId: number;
  onSelectedFarm: (farmId: number, isDirty?: boolean) => void;
  setPageLoadEndDateTime: (endDateTime: string) => void;
}

export const ProducersFarmsFieldsBody: React.FC<
  ProducersFarmsFieldsBodyProps
> = ({
  nextFarmId,
  previousFarmId,
  onSelectedFarm,
  setPageLoadEndDateTime,
}) => {
  const apiGateway = useApiGateway();

  const [gridApi, setGridApi] = useState<GridApi>();

  const [gridRowCount, setGridRowCount] = useState<number>(0);

  const policyId = useAppStateStore((s) => s.policyId);
  const selectedFarmId = useAppStateStore((s) => s.selectedFarmId);

  const { farms, isFarmsFetching } = usePoliciesFarmsQuery(policyId);

  const farm = farms?.find((f) => f.farmId === selectedFarmId);

  const { farmsFields, isFarmsFieldsFetching } = usePoliciesFarmsFieldsQuery(
    Number(policyId),
    selectedFarmId
  );

  const { cropUnits, isCropUnitsFetching } = usePoliciesCropUnitsQuery(
    Number(policyId)
  );

  const [showFarmMap, toggleshowFarmMap] = useToggle(false);

  const fieldsData = useMemo<FieldDataType[]>(() => {
    return mapFieldResultsToFieldDataType(
      farmsFields,
      cropUnits?.crops,
      cropUnits?.units,
      cropUnits?.farmUnits,
      farms
    );
  }, [farmsFields, cropUnits]);

  const { ApplicationRolesData } = useApplicationRolesQuery();
  const { isRoleUnderwriter } = useMemo(
    () => mapApplicationRolesQueryResults(ApplicationRolesData),
    [ApplicationRolesData]
  );

  const hasPastFinalPlantDateFields = cropUnits?.units?.some(
    (crop) => crop.pastFinalPlantDate
  );

  useEffect(() => {
    if (!isRoleUnderwriter && hasPastFinalPlantDateFields) {
      toast.warning(
        "Certain Crop(s) are past acreage reporting date and cannot be selected",
        { toastId: "ARD Toast" }
      );
    }
  }, [isCropUnitsFetching]);

  const queryClient = useQueryClient();

  useEffect(() => {
    let checkInterval = setInterval(() => {
      if (!!queryClient) {
        const { pageLoadedEndTime, isPageLoaded } =
          isPageLoadApisCompleted(queryClient);
        if (isPageLoaded) {
          setPageLoadEndDateTime(pageLoadedEndTime);
          clearInterval(checkInterval);
        }
      }
    }, 1000); // a second.

    return () => {
      clearInterval(checkInterval);
    };
  }, [queryClient]);

  const { isFieldsSaving } = usePoliciesFarmsFieldsUpdate();

  const doNav = (farmId?: number) => {
    NavUtil(gridApi, onSelectedFarm, farmId);
  };

  const handleOnGridApiReady = (gridApi: GridApi) => {
    setGridApi(gridApi);
  };

  const handleSelectRowChange = (gridApi: GridApi) => {
    setGridRowCount(gridApi?.getSelectedRows()?.length);
  };

  /*document.addEventListener('keydown', function(event) {
  const key = event.key;
  if (key === "Tab") {
    const focusedElement:any = document.activeElement;
    const bounding = focusedElement.getBoundingClientRect();

    if (bounding.bottom >= window.innerHeight) {
      window.scrollTo(0, bounding.bottom);
    }
  }
});*/

  return (
    <>
      <div data-testid="ProducersFarmsFieldsBody" className="h-100">
        <ProducersFarmsFieldsHeader
          farms={farms}
          showFarmMap={showFarmMap}
          toggleshowFarmMap={toggleshowFarmMap}
        />
        {showFarmMap && !!farm && (
          <ProducersFarmsFieldsMap
            countyCode={farm.countyCode}
            stateCode={farm.stateCode}
          />
        )}
        <div className={styles.tableBodyContent}>
          {gridRowCount > 0 && (
            <BulkEditFarmFields
              gridApi={gridApi}
              coverages={cropUnits?.crops}
              units={cropUnits?.units}
              farmUnits={cropUnits?.farmUnits}
              farm={farm}
              selectedFarmId={selectedFarmId}
              gridCount={gridRowCount}
            />
          )}
          <FarmFieldsTable
            isLoading={isFarmsFieldsFetching || isCropUnitsFetching}
            data={fieldsData}
            onGridApiReady={handleOnGridApiReady}
            onSelectRowChange={handleSelectRowChange}
          />
          <ProducersFarmsFieldsBodyFooter
            isFieldsSaving={isFieldsSaving}
            previousFarmId={previousFarmId}
            nextFarmId={nextFarmId}
            doNav={doNav}
          />
        </div>
      </div>

      {/* This tooltip bit is needed to get the tips working on the grid.
           For some reason, it doesn't work when included THERE, but here is ok
      */}
      <Tooltip id="my-tooltip" style={{ zIndex: 201 }} />
    </>
  );
};
