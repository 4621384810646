import { Flex } from "@proag/sprout";
import styles from "./producers-planted-acres-review-sidebar-tabs.module.scss";

interface ProducersReviewFarmsSideBarTabsProps {
  showUnits: boolean;
  setShowUnits: (isValid: boolean) => void;
}

export const ProducersReviewFarmsSideBarTabs: React.FC<
  ProducersReviewFarmsSideBarTabsProps
> = ({ showUnits, setShowUnits }) => {
  return (
    <div className={styles.tabsWrapper}>
      <Flex direction="row" justifyContent="flex-start" alignItems="stretch">
        <div
          className={`title-sm ${
            showUnits ? styles.selected : styles.notSelected
          }`}
          onClick={() => {
            setShowUnits(true);
          }}
        >
          Units
        </div>
        <div
          data-testid="producers-review-sidebar-farms-tab"
          className={`title-sm ${
            showUnits === true ? styles.notSelected : styles.selected
          }`}
          onClick={() => {
            setShowUnits(false);
          }}
        >
          Farms
        </div>
      </Flex>
    </div>
  );
};
