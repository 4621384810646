import { reconstructPolicyFarmUnitCoverage } from "components/functions/filterConstructor";
import { IProducersFilterData } from "components/search/producers-filter";
import {
  PoliciesAcresQueryResult,
  PoliciesCropUnitsQueryResult,
  PoliciesFarmsQueryResult
} from "features/managed-acres";
import _ from "lodash";

const shouldLog = false;

export const filterFarms = (
  farmsFilterData: IProducersFilterData,
  farms: Array<PoliciesFarmsQueryResult>,
  acresDetails?: Array<PoliciesAcresQueryResult>,
  unitDetails?: PoliciesCropUnitsQueryResult
): Array<PoliciesFarmsQueryResult> => {
  let filtered: Array<PoliciesFarmsQueryResult> = [];

  // filter out farms without crops for a season
  if(unitDetails?.farmUnits.length === 0) {
    return filtered;
  }

  if (farms === undefined) {
    // stick with the defaults empty array
  } else if (farms.length === 0) {
    // stick with the defaults empty array
  } else {
    // make a copy of the farms array.
    //filtered = Array.from(farms);
  }

  const farmUnitCoverage = reconstructPolicyFarmUnitCoverage(unitDetails, farms);

  shouldLog && console.log("Top of filterFarms");
  let filteredResults: Array<PoliciesFarmsQueryResult> = [];

  // for the "All" filter, we're going to need a different way of doing things, because we have to run ALL the individual filters
  // and then the result is the composite of all filters.

  shouldLog &&
    console.log("farmsFilterData.filterType", farmsFilterData.filterType);
  shouldLog &&
    console.log("farmsFilterData.filterValue", farmsFilterData.filterValue);

  if (farmsFilterData.filterValue === "") {
    // if we have no filter... we'll just return the entire farms collection
    filteredResults = Array.from(farms);
    shouldLog &&
      console.log("filteredResults after no filterValue", filteredResults);
  } else {
    // Otherwise, we attempt to apply the filter.

    if (["Unit", "All"].includes(farmsFilterData.filterType)) {
      // unit is different.. we'll need to consult the Units details

      const matchunits = farmUnitCoverage.filter((f) => {
        return f.unit_unitNumber
          .toString()
          .toLowerCase()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });

      matchunits.forEach((u) => {
        let farmsByUnit: Array<PoliciesFarmsQueryResult> = farms.filter((f) => {
          return f.farmId === u.farmId;
        });
        shouldLog &&
          console.log("adding farms to filtered by Unit", farmsByUnit.length);
        filteredResults.push(...farmsByUnit);
      });
    }
    shouldLog && console.log("filteredResults after Unit", filteredResults);

    if (["Name", "All"].includes(farmsFilterData.filterType)) {
      let farmsByName: Array<PoliciesFarmsQueryResult> = farms.filter((f) => {
        return f.name
          .toString()
          .toLowerCase()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });
      shouldLog &&
        console.log("adding farms to filtered by Name", farmsByName.length);
      filteredResults.push(...farmsByName);
      shouldLog && console.log("filteredResults after Name", filteredResults);
    }

    if (["Number", "All"].includes(farmsFilterData.filterType)) {
      let farmsByFsn: Array<PoliciesFarmsQueryResult> = farms.filter((f) => {
        return f.farmSerialNumber
          .toString()
          .toLowerCase()
          .startsWith(farmsFilterData.filterValue.toLowerCase());
      });
      shouldLog &&
        console.log("adding farms to filtered by Number", farmsByFsn.length);
      filteredResults.push(...farmsByFsn);
      shouldLog && console.log("filteredResults after Number", filteredResults);
    }

    if (["Tract", "All"].includes(farmsFilterData.filterType)) {
      let farmsByTract: Array<PoliciesFarmsQueryResult> = farms.filter((f) => {
        return f.tractNumber
          .toString()
          .toLowerCase()
          .startsWith(farmsFilterData.filterValue.toLowerCase());
      });
      shouldLog &&
        console.log("adding farms to filtered by Tract", farmsByTract.length);
      filteredResults.push(...farmsByTract);
      shouldLog && console.log("filteredResults after Tract", filteredResults);
    }

    if (["Section", "All"].includes(farmsFilterData.filterType)) {
      let farmsBySection: Array<PoliciesFarmsQueryResult> = farms.filter(
        (f) => {
          return f.section
            .toString()
            .toLowerCase()
            .startsWith(farmsFilterData.filterValue.toLowerCase());
        }
      );
      shouldLog &&
        console.log(
          "adding farms to filtered by Section",
          farmsBySection.length
        );
      filteredResults.push(...farmsBySection);
      shouldLog &&
        console.log("filteredResults after Section", filteredResults);
    }

    if (["Township", "All"].includes(farmsFilterData.filterType)) {
      let farmsByTownship: Array<PoliciesFarmsQueryResult> = farms.filter(
        (f) => {
          return f.township
            .toString()
            .toLowerCase()
            .startsWith(farmsFilterData.filterValue.toLowerCase());
        }
      );
      shouldLog &&
        console.log(
          "adding farms to filtered by Township",
          farmsByTownship.length
        );
      filteredResults.push(...farmsByTownship);
      shouldLog &&
        console.log("filteredResults after Township", filteredResults);
    }

    if (["Range", "All"].includes(farmsFilterData.filterType)) {
      let farmsByRange: Array<PoliciesFarmsQueryResult> = farms.filter((f) => {
        return f.range
          .toString()
          .toLowerCase()
          .startsWith(farmsFilterData.filterValue.toLowerCase());
      });
      shouldLog &&
        console.log("adding farms to filtered by Range", farmsByRange.length);
      filteredResults.push(...farmsByRange);
      shouldLog && console.log("filteredResults after Range", filteredResults);
    }

    if (["Crop", "All"].includes(farmsFilterData.filterType)) {
      // Crop is different.. we'll need to consult the Units details

      const matchCrops = farmUnitCoverage.filter((f) => {
        return f.coverage_commodityName
          .toString()
          .toLowerCase()
          .trim()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });

      matchCrops.forEach((u) => {
        let farmsByCrops: Array<PoliciesFarmsQueryResult> = farms.filter(
          (f) => {
            return f.farmId === u.farmId;
          }
        );
        shouldLog &&
          console.log("adding farms to filtered by crop", farmsByCrops.length);
        filteredResults.push(...farmsByCrops);
      });
    }
    shouldLog && console.log("filteredResults after Crops", filteredResults);

    if (["Practice", "All"].includes(farmsFilterData.filterType)) {
      // Practice is different.. we'll need to consult the Units details

      const matchPractice = farmUnitCoverage.filter((f) => {
        return f.unit_practiceName
          .toString()
          .toLowerCase()
          .trim()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });

      shouldLog && console.log("matchPractice ", matchPractice);
      matchPractice.forEach((u) => {
        let farmsByPractice: Array<PoliciesFarmsQueryResult> = farms.filter(
          (f) => {
            return f.farmId === u.farmId;
          }
        );
        shouldLog &&
          console.log(
            "adding farms to filtered by practice",
            farmsByPractice.length
          );
        filteredResults.push(...farmsByPractice);
      });
    }
    shouldLog && console.log("filteredResults after practice", filteredResults);

    if (["Type", "All"].includes(farmsFilterData.filterType)) {
      // Type is different.. we'll need to consult the Units details

      const matchType = farmUnitCoverage.filter((f) => {
        return f.unit_typeName
          .toString()
          .toLowerCase()
          .trim()
          .includes(farmsFilterData.filterValue.toLowerCase());
      });

      shouldLog && console.log("matchType ", matchType);
      matchType.forEach((u) => {
        let farmsByType: Array<PoliciesFarmsQueryResult> = farms.filter((f) => {
          return f.farmId === u.farmId;
        });
        shouldLog &&
          console.log("adding farms to filtered by type", farmsByType.length);
        filteredResults.push(...farmsByType);
      });
    }
    shouldLog && console.log("filteredResults after type", filteredResults);

    //all the various adds above (considering "All" behavior)
    //will possibly add duplicate farms... make the array unique before we continue.
    filteredResults = _.uniq(filteredResults);
  }

  if (farmsFilterData.hideEmpty) {
    if (acresDetails) {
      filteredResults = filteredResults.filter((f) => {
        let foundAcre = acresDetails.findIndex((a) => {
          return a.farmId === f.farmId;
        });

        return foundAcre >= 0;
      });
    }
  }

  return filteredResults;
};
