import { Flex, Icon, IconButton, getSproutColor } from "@proag/sprout";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { FarmUnitsEditButton } from "components/ui/producers-farm-units-edit-button";

import {
  FarmLegalsRenderer,
  FarmNameRenderer,
  PoliciesFarmsQueryResult,
} from "features/managed-acres";
import React, { useState } from "react";
import Switch from "react-switch";
import { FarmEdit } from "./producers-farms-edit";

interface ProducersFarmsFieldsHeaderProps {
  farms: PoliciesFarmsQueryResult[] | undefined;
  showFarmMap: boolean;
  toggleshowFarmMap: (newValue?: boolean | undefined) => void;
}

export const ProducersFarmsFieldsHeader: React.FC<
  ProducersFarmsFieldsHeaderProps
> = ({ farms, showFarmMap, toggleshowFarmMap }) => {
  const selectedFarmId = useAppStateStore((s) => s.selectedFarmId);
  const [showFarmEdit, setShowFarmEdit] = useState(false);

  const handleFarmEditClosed = () => {
    setShowFarmEdit(false);
  };
  return (
    <>
      {selectedFarmId > 0 && (
        <Flex
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          style={{ paddingBottom: 5 }}
        >
          <Flex
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            style={{ paddingBottom: 5 }}
          >
            <Flex
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <FarmNameRenderer farms={farms} farmId={selectedFarmId} />
              <FarmUnitsEditButton
                setShowFarmUnitEdit={() => {
                  setShowFarmEdit(true);
                }}
              />
              {
                showFarmEdit && (
                <FarmEdit
                  farmEditClosed={handleFarmEditClosed}
                  showFarmEdit={showFarmEdit}
                ></FarmEdit>
              )}
            </Flex>
            <FarmLegalsRenderer farms={farms} farmId={selectedFarmId} />
          </Flex>
          <Flex direction="row" alignItems="center">
            <Flex
              direction="row"
              justifyContent="space-between"
              style={{ paddingLeft: 5 }}
            >
              <div>
                <Flex
                  className="mt-3"
                  style={{ paddingBottom: 5 }}
                  justifyContent="space-between"
                >
                  <div className="mr-2">Map</div>
                  <Switch
                    data-testid="pff-map-switch"
                    width={36}
                    height={20}
                    onChange={toggleshowFarmMap}
                    checked={showFarmMap}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor={getSproutColor("brandGreen")}
                  />
                </Flex>
              </div>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};
