import { Query, QueryClient, QueryOptions } from "@tanstack/react-query";

export const isPageLoadApisCompleted = (queryClient: QueryClient) => {
  let loaded: boolean | undefined = undefined;
  let endDateTime: string = "";

  const cache = queryClient.getQueryCache();
  let unfilteredQueries = cache.getAll();

  // filter out all the queries that are disabled.
  let filteredQueries = unfilteredQueries.filter((item) => {
    const itemOptions: any = item.options;
    // consider it true if it doesn't have the "enabled" property,
    // otherwise, use the value of the "enabled" property.
    return itemOptions.hasOwnProperty("enabled") ? itemOptions.enabled : true;
  });

  // now filter out some specific queries we don't want to consider.
  filteredQueries = filteredQueries.filter((item: Query) => {
    let key = item.options.queryKey;
    if (!!key && key.includes("policiesPlanetWatchersConfidenceQuery")) {
      return false;
    }

    return true;
  });

  if (filteredQueries) {
    console.groupCollapsed("📘", "checking queries", new Date().toISOString());

    filteredQueries.forEach((item: Query) => {
      const hash = item.options.queryHash;
      const status = item.state.status;

      if (item.state.status == "success") {
        if (loaded != false) {
          loaded = true;
        }
      } else {
        console.log("📕", "key / status: ", hash, status);
        loaded = false;
      }
    });

    if (loaded) {
      endDateTime = new Date().toUTCString();
    }
    console.groupEnd();
  }

  return {
    pageLoadedEndTime: endDateTime,
    isPageLoaded: loaded,
  };
};
