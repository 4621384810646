import { FieldDataType } from "features/managed-acres/components";
import _ from "lodash";
import {
  hasEnteredAcres,
  hasEnteredPlantedDate,
  hasSelectedCrop,
  hasSelectedUnit,
  isSplitField,
  hasCheckedPreventPlant,
} from "./validation-helpers";

const isValid = (r: FieldDataType): boolean => {
  return r.plantedAcres.isValid;
};

export const validateSplitFieldHasDataEntered = (
  record: FieldDataType
): void => {
  if (!isValid(record)) return; // bail out early if already invalid

  if (
    !hasEnteredAcres(record) &&
    !hasEnteredPlantedDate(record) &&
    !hasSelectedCrop(record) &&
    !hasSelectedUnit(record) &&
    isSplitField(record)
  ) {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: `No information exists on split line. Please delete line.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};

export const validateAcresEnteredIfCropAndPracticeSelected = (
  record: FieldDataType
): void => {
  if (!isValid(record)) return; // bail out early if already invalid

  if (
    hasSelectedCrop(record) &&
    hasSelectedUnit(record) &&
    !hasEnteredAcres(record)
  ) {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: `Acres must be reported when Crop and Practice/Type are selected.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};

export const validateAcresEnteredAreNotMoreThanAvailable = (
  record: FieldDataType
): void => {
  if (!isValid(record)) return; // bail out early if already invalid

  if (
    hasEnteredAcres(record) &&
    record.plantedAcres.value &&
    Number(record.plantedAcres.value) > record.acres
  ) {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: `reported acres must be less than or equal to ${record.acres}`,
      warningMsg: true,
      isValid: false,
    };
  } else {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};

export const validateAcresEnteredArePositive = (
  record: FieldDataType
): void => {
  if (!isValid(record)) return; // bail out early if already invalid

  if (
    record.plantedAcres.value !== undefined &&
    record.plantedAcres.value?.toString() !== "" &&
    record.plantedAcres.value <= 0 &&
    (hasSelectedCrop(record) ||
      hasSelectedUnit(record) ||
      hasEnteredPlantedDate(record))
  ) {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: `Acres entered must be greater than 0.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};

export const validateAcresEnteredIfPlantDateEntered = (
  record: FieldDataType
): void => {
  if (!isValid(record)) return; // bail out early if already invalid

  if (hasEnteredPlantedDate(record) && !hasEnteredAcres(record)) {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: `Plant date is entered. Acres must be reported.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};

export const validateAcresEnteredIfPreventPlantIsSelected = (
  record: FieldDataType
): void => {
  if (!isValid(record)) return; // bail out early if already invalid

  if (hasCheckedPreventPlant(record) && !hasEnteredAcres(record)) {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: `Prevent Plant is checked. Acres must be reported.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.plantedAcres = {
      value: record.plantedAcres.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};
