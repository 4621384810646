import {
  PoliciesFarmsFieldsQueryResult,
  PoliciesFarmsQueryResult,
  IPoliciesCrops,
  IPoliciesUnits,
  IPoliciesFarmUnits,
  dateEntryParser,
  PoliciesUnitFarmsFieldsQueryResult,
  PreviouslyReported,
} from "features/managed-acres";
import {
  FieldDataType,
  PreviouslyReportedField,
} from "features/managed-acres/components/tables/FarmFields/FieldDataType";
import { format, isValid } from "date-fns";
import { map } from "lodash";
import { SUBFIELDPREFIX } from "app/common/constants";

export const getPastFinalPlantDate = (
  finalPlantDate: string,
  plantDate: string
): boolean => {
  let finalPlantDate_ = new Date(finalPlantDate ? finalPlantDate : "");
  let plantDate_ = new Date(plantDate ? plantDate : "");

  if (
    isValid(finalPlantDate_) &&
    isValid(plantDate_) &&
    plantDate_ > finalPlantDate_
  ) {
    return true;
  } else {
    return false;
  }
};

export const mapUnitFieldsResultsToFieldDataType = (
  farmFieldsResults: PoliciesUnitFarmsFieldsQueryResult[] | undefined,
  coverageData: IPoliciesCrops[] | undefined,
  unitData: IPoliciesUnits[] | undefined,
  farmUnitData: IPoliciesFarmUnits[] | undefined,
  farmData: PoliciesFarmsQueryResult[] | undefined,
  selectedUnitId: number | undefined
): FieldDataType[] => {
  let list: FieldDataType[] = [];
  if (farmFieldsResults == undefined) return list;

  // filter out farms/fields without crops for a season
  if (farmUnitData?.length === 0) {
    return list;
  }

  map(farmFieldsResults, (x) => {
    let pd = undefined;
    var selectedUnit = unitData?.find((e) => e.unitId == selectedUnitId);
    if (x.plantedDate !== "") {
      pd = dateEntryParser(x.plantedDate ?? "");
      if (!!pd) {
        x.plantedDate = format(pd, "MM/dd/yyyy");
      }
    }

    let fieldDataType: FieldDataType = {
      fieldId: x.fieldId,
      fieldNumber: x.fieldNumber,
      acres: x.cluCalculatedAcreage ?? 0,
      cluIdentifier: x.cluId ?? "",
      priorInsured: x.priorYearInsured,
      plantedAcres: { value: x.plantedAcres, isValid: true, warningMsg: false },
      pastFinalPlantDate: getPastFinalPlantDate(
        x.finalPlantDate ?? "",
        x.plantedDate ?? ""
      ),
      plantDate: {
        value: x.plantedDate,
        isValid: true,
        warningMsg: false,
      },
      earlyPlantDate: x.earlyPlantDate,
      share: x.share,
      subField: {
        value: x.subField?.startsWith(SUBFIELDPREFIX.AUTOGENERATED)
          ? x.subField?.substring(4)
          : x.subField,
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      preventedPlant: {
        value: !!x.preventedPlant,
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      coverageId: {
        value: x.coverageId ?? 0,
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      unitId: {
        value: x.unitId ?? 0,
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      uninsuredReasonCode: x.uninsuredReasonCode ?? "",
      splitParentId: x.splitParentId ?? 0,
      acreageReportId: x.acreageReportId,
      pastAcreageReportDate: selectedUnitId
        ? selectedUnit
          ? selectedUnit.pastFinalPlantDate
          : x.pastAcreageReportDate
        : x.pastAcreageReportDate,

      arType: "", // not fully implemented yet.
      practice: "", // not fully implemented yet.
      type: "", // not fully implemented yet.
      unitNumber: "", // not fully implemented yet.

      coverages: coverageData,
      units: unitData,
      farmUnits: farmUnitData,
      farm: farmData?.find((f) => f.farmId === x.farmId),
      previouslyReported: populatePreviouslyReportedColumn(
        coverageData,
        unitData,
        x.previouslyReported
      ),
      isVisible: x.isVisible,
      isImportedFromPlanetWatchers:x.isImportedFromPlanetWatchers,
    };

    if (fieldDataType.farm) {
      //fsnTract is currently only used for the GroupHeader.
      //We need to append another element to this, so rather than create a new property
      // that does exactly what the old one did, we'll just re-use the current one.
      let fsn = fieldDataType.farm.farmSerialNumber;
      let tract = fieldDataType.farm.tractNumber;
      let name = fieldDataType.farm.name;
      let section = fieldDataType.farm.section;
      name = !!name ? name : fieldDataType.farm.otherLandDescription;
      name = !!name ? name : "Unknown";

      let computedFarmName = `${fsn}|${tract}|${name}|${section}`; // consumers of this will split the "array" to access the components

      fieldDataType.fsnTract = computedFarmName;
    }

    list.push(fieldDataType);
  });

  //return a list sorted
  // first by FarmSerialNumber
  // then by tractNumber
  // finally by fieldNumber
  list = list.sort((a, b) => {
    const a_fsn = a.farm?.farmSerialNumber ?? 0;
    const b_fsn = b.farm?.farmSerialNumber ?? 0;

    const a_tn = a.farm?.tractNumber ?? 0;
    const b_tn = a.farm?.tractNumber ?? 0;

    if (a_fsn < b_fsn) {
      return -1;
    } else if (a_fsn > b_fsn) {
      return 1;
    } else {
      // EQUAL -- check the next level

      if (a_tn < b_tn) {
        return -1;
      } else if (a_tn > b_tn) {
        return 1;
      } else {
        // EQUAL

        if (a.fieldNumber < b.fieldNumber) {
          return -1;
        } else if (a.fieldNumber > b.fieldNumber) {
          return 1;
        } else {
          // EQUAL
          return 0;
        }
      }
    }
  });

  return list;
};

const populatePreviouslyReportedColumn = (
  coverageData: IPoliciesCrops[] | undefined,
  unitData: IPoliciesUnits[] | undefined,
  previouslyReported?: PreviouslyReported[]
): PreviouslyReportedField[] | undefined => {
  if (!previouslyReported || previouslyReported.length == 0) {
    return undefined;
  }

  var previouslyReportedUnitField: PreviouslyReportedField[] = [];
  previouslyReported?.forEach((prField) => {
    var coverage = coverageData?.find(
      (e) => e.coverageId == prField.coverageId
    );
    var unit = unitData?.find((e) => e.unitId == prField.unitId);
    let previouslyReportedField = getPreviouslyReported(
      unit,
      coverage,
      prField
    );
    previouslyReportedUnitField.push(previouslyReportedField);
  });

  return previouslyReportedUnitField;
};

const getPreviouslyReported = (
  unit: IPoliciesUnits | undefined,
  coverage: IPoliciesCrops | undefined,
  field: PreviouslyReported
): PreviouslyReportedField => {
  var unitField: PreviouslyReportedField = {
    unit: {
      id: unit?.unitId ?? 0,
      value: unit?.unitNumber ?? "",
    },
    coverage: {
      id: coverage?.coverageId ?? 0,
      value: coverage?.commodityName ?? "",
    },
    acres: field.acres,
    plantedDate: formatDate(field.plantedDate),
    preventedPlant: field.preventPlant,
  };
  return unitField;
};

const formatDate = (dateField?: string | undefined): string => {
  var value: string = "";
  if (dateField) {
    var dateValue = dateEntryParser(dateField);
    if (!!dateValue) {
      value = format(dateValue, "MM/dd/yyyy");
    }
  }
  return value;
};

export const mapFieldResultsToFieldDataType = (
  farmFieldsResults: PoliciesFarmsFieldsQueryResult[] | undefined,
  coverageData: IPoliciesCrops[] | undefined,
  unitData: IPoliciesUnits[] | undefined,
  farmUnitData: IPoliciesFarmUnits[] | undefined,
  farmData: PoliciesFarmsQueryResult[] | undefined
): FieldDataType[] => {
  let list: FieldDataType[] = [];
  if (farmFieldsResults == undefined) return list;

  // filter out farms/fields without crops for a season
  if (farmUnitData?.length === 0) {
    return list;
  }

  map(farmFieldsResults, (x) => {
    let pd = undefined;
    if (x.plantedDate !== "") {
      pd = dateEntryParser(x.plantedDate);
      if (!!pd) {
        x.plantedDate = format(pd, "MM/dd/yyyy");
      }
    }

    let fieldDataType: FieldDataType = {
      fieldId: x.fieldId,
      fieldNumber: x.fieldNumber,
      acres: x.cluCalculatedAcreage,
      cluIdentifier: x.cluId,
      priorInsured: x.priorYearInsured,
      plantedAcres: { value: x.plantedAcres, isValid: true, warningMsg: false },
      pastFinalPlantDate: getPastFinalPlantDate(
        x.finalPlantDate,
        x.plantedDate
      ),
      plantDate: {
        value: x.plantedDate,
        isValid: true,
        warningMsg: false,
      },
      earlyPlantDate: x.earlyPlantDate,
      share: x.share,
      subField: {
        value: x.subField,
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      preventedPlant: {
        value: !!x.preventedPlant,
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      coverageId: {
        value: x.coverageId ?? 0,
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      unitId: {
        value: x.unitId ?? 0,
        isValid: true,
        warningMsg: false,
        validationMessage: "",
      },
      uninsuredReasonCode: x.uninsuredReasonCode,
      splitParentId: x.splitParentId,
      acreageReportId: x.acreageReportId,
      pastAcreageReportDate: x.pastAcreageReportDate,

      arType: "", // not fully implemented yet.
      practice: "", // not fully implemented yet.
      type: "", // not fully implemented yet.
      unitNumber: "", // not fully implemented yet.

      coverages: coverageData,
      units: unitData,
      farmUnits: farmUnitData,
      farm: farmData?.find((f) => f.farmId === x.farmId),
      isVisible: true,
      isImportedFromPlanetWatchers: x.isImportedFromPlanetWatchers,
    };
    if (fieldDataType.farm) {
      fieldDataType.fsnTract = fieldDataType.farm.farmSerialNumber.toString();
      if (fieldDataType.farm.tractNumber)
        fieldDataType.fsnTract =
          fieldDataType.fsnTract +
          "/" +
          fieldDataType.farm.tractNumber.toString();
    }
    if (!fieldDataType.subField?.value?.startsWith(SUBFIELDPREFIX.AUTOGENERATED)) {
      list.push(fieldDataType);
    }
  });

  return list.sort((a, b) => a.fieldNumber - b.fieldNumber);
};
