import { Typography } from "@proag/sprout";

import { toast } from "react-toastify";
import { GridApi } from "ag-grid-community";
import {
  hasEnteredAcres,
  hasEnteredPlantedDate,
  hasSelectedCrop,
  hasSelectedUnit,
} from "features/managed-acres/functions/validations/validation-helpers";
import { FieldDataType, EditableField } from "../tables";

// do some things navigation related things.
export function NavUtil(
  gridApi: GridApi<any> | undefined,
  onSelectedFarm: any,
  farmId?: number
) {
  // we're gonna have problems if we have no api to work with!
  if (!gridApi) {
    return;
  }

  const validationMessages: string[] = getGridValidationErrors(gridApi);

  // report any validation errors we may have discovered.
  if (validationMessages.length > 0) {
    toast.error(
      <>
        <Typography className="body-lg">
          Please fix all errors prior to leaving.
        </Typography>
        <ul>
          {validationMessages.map((e, index) => {
            return (
              <Typography key={index} className="body-sm">
                <li>{e}</li>
              </Typography>
            );
          })}
        </ul>
      </>,

      { toastId: "fatal validations x202311071517" }
    );

    return; // halt / abort / abandon ship / look on my works ye mighty and despair!
  }

  // if we had no validation problems
  // and we were passed a farmId
  // go ahead and update our farm (navigate to the new one)
  if (farmId) {
    onSelectedFarm(farmId);
  }
}

export const getGridValidationErrors = (
  gridApi: GridApi<any>,
  isUnitGrid: boolean = false
): string[] => {
  // get a copy of the grid data to work with easily.
  const allRowData: FieldDataType[] = [];

  gridApi.forEachNode((n) => {
    if (!!n.data) {
      allRowData.push(n.data);
    }
  });

  // loop over each row, and collect all the various validation messages that we might
  // have on our cells, including a few extras we might think of along the way.
  const validationMessages: string[] = [];

  allRowData.forEach((row) => {
    //
    // TODO: this should be moved to the validation CHECK code..
    // should not be in the SeeIfWeFoundErrorsWhenWeChecked code... :D
    if (
      !isUnitGrid &&
      row?.splitParentId &&
      !hasEnteredAcres(row) &&
      !hasEnteredPlantedDate(row) &&
      !hasSelectedCrop(row) &&
      !hasSelectedUnit(row)
    ) {
      validationMessages.push(
        "No information exists on split line. Please delete line."
      );
    }

    // this is the main and expected logic of this method
    for (let key in row) {
      if (row.hasOwnProperty(key)) {
        const field = row[key] as EditableField;
        if (
          // its an object with a validationMessage, flagged as invalid, but not as a warning
          // and we've not already collected it...

          typeof field === "object" &&
          field?.validationMessage &&
          !field.isValid &&
          !field.warningMsg &&
          !validationMessages.includes(field.validationMessage)
        ) {
          validationMessages.push(field.validationMessage);
        }
      }
    }

    //
    //some "cross fields" validation
    // TODO: this should be moved to the validation CHECK code..
    // should not be in the SeeIfWeFoundErrorsWhenWeChecked code... :D
    if (!hasValidSubFieldLabels(allRowData)) {
      validationMessages.push("Unique Subfield Required");
    }
  });

  return validationMessages;
};

function hasValidSubFieldLabels(fields: FieldDataType[]) {
  let unique: FieldDataType[] = new Array();
  let isValid: boolean = true;

  fields.forEach((f) => {
    if (f.subField) {
      let foundSubFields: FieldDataType[] = unique.filter((u) => {
        return u.fieldNumber == f.fieldNumber && u.subField == f.subField;
      });
      if (foundSubFields.length == 0) {
        unique.push(f);
      } else {
        isValid = false;
      }
    }
  });
  return isValid;
}
