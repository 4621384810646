import { useOktaAuth } from "@okta/okta-react";
import { ApiRequestInfo, ApiResponse, ApiResponseBody } from "../types";
import { send } from "../utils/apiUtils";
import { OktaAuth } from "@okta/okta-auth-js";
import { httpStatusCodes } from "../constants/http";

export function checkAuthResponse(response: ApiResponse, oktaAuth: OktaAuth) {
  if (
    response.status !== httpStatusCodes.unauthorized &&
    response.status !== httpStatusCodes.badGateway
  ) {
    return true;
  }

  oktaAuth.signOut({
    postLogoutRedirectUri: (window as any).globalConfig?.Logout_Url,
  });

  return false;
}

export const useApiSend = () => {
  const { oktaAuth } = useOktaAuth();

  async function apiSend(request: ApiRequestInfo): Promise<ApiResponseBody> {
    request.headers.authorization = `Bearer ${oktaAuth.getAccessToken() ?? ""}`;

    return await send(request).then((response) => {
      checkAuthResponse(response, oktaAuth);

      if (response.isError) {
        return Promise.reject(response);
      }

      // TODO: refactor apis to accept the whole response
      //       to make headers, status, and errors available
      return response.data;
    });
  }

  return apiSend;
};
