import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useNavigate } from "react-router";

export const useRestoreOriginalUri = (oktaAuth: OktaAuth) => {
  const navigate = useNavigate();

  const captureLogin = (oktaAuth: OktaAuth) => {
    //TODO: GA4 to capture login metric
  };

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
    captureLogin(_oktaAuth);
  };

  return restoreOriginalUri;
};
