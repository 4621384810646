import { Flex, colors } from "@proag/sprout";
import {
  IPoliciesCrops,
  IPoliciesUnits,
} from "features/managed-acres/hooks/policies/models/policies-crop-unit-query-result";
import styles from "./producers-planted-acres-review-units-sidebar-card.module.scss";

interface ProducersReviewUnitSidebarUnitProps {
  isSelected: boolean;
  coverage: IPoliciesCrops;
  unit: IPoliciesUnits;
}

export const ProducersReviewUnitsSidebarUnit: React.FC<
  ProducersReviewUnitSidebarUnitProps
> = ({ coverage, unit, isSelected }) => {
  return (
    <>
      <div
        data-testid={
          "producers-review-units-sidebar-card-unit-" + unit?.unitId.toString()
        }
        className={`p-2 ${
          styles.hoverClick
        }${isSelected ? styles.selected: ""}`}
      >
        <Flex
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Flex>
            <div className="pt-3 pl-3 pb-3">
              <div className="title-sm">{unit.unitNumber}</div>

              {coverage ? (
                <div>
                  <div className="label-sm" style={{ color: colors.neutral40 }}>
                    {coverage.commodityName +
                      " " +
                      coverage.insurancePlanAbbrev +
                      ` - ${Math.round(coverage.coverageLevel * 100)}% - 100%`}
                  </div>
                  <div className="label-sm" style={{ color: colors.neutral40 }}>
                    {unit.practiceName +
                      " - " +
                      unit.typeName +
                      " - Share " +
                      Math.round(unit.share * 100) +
                      "%"}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Flex>
          <Flex
            direction="column"
            alignItems="flex-end"
            justifyContent="space-between"
            style={{
              backgroundColor: colors.neutral98,
            }}
            className={"p-3 mr-2 mb-2 ml-2"}
          >
            <Flex direction="row" alignItems="baseline">
              <div
                className="label-sm  mb-1"
                style={{ color: colors.neutral40 }}
              >
                {unit.reportedAcres > 0 ? "" : "No"} Reported Acres
              </div>
            </Flex>
            <Flex
              direction="row"
              alignItems="baseline"
              justifyContent="flex-start"
            >
              {unit.reportedAcres > 0 && (
                <>
                  <div className="label-md mr-1">
                    {unit.reportedAcres.toFixed(2)}
                  </div>
                  <div className="label-sm" style={{ color: colors.neutral40 }}>
                    / {unit.totalAcres.toFixed(2)}
                  </div>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
};
