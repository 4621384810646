import { useAppStateStore } from "app/state-management/use-app-state-store";
import { LoadingSpinner } from "components/common/LoadingSpinner";
import {
  IProducersFilterData,
  ProducersFilter,
} from "components/search/producers-filter";
import { usePoliciesFarmsQuery } from "features/managed-acres";
import { filterUnits } from "features/planted-acres/units/functions/filters/filter-Units-By-Many";
import { useState } from "react";
import { ProducersReviewUnitsSidebarCoverageCard } from "./producers-planted-acres-review-units-sidebar-coverage-card";
import styles from "./producers-planted-acres-review-sidebar.module.scss";
import { usePoliciesCropUnitsReviewQuery } from "../hooks/policies/policies-queries";
import {usePoliciesCoveragesUnitStructureQuery} from "../../../../features/managed-acres";
import { Feature147540, useFeatureFlag } from "../../../../app/feature-flag/useFeatureFlag";
import  { OptionCodeUpdate } from "features/managed-acres/hooks/policies/models/policies-coverages-unitstructure-override-query-result";
import { IPoliciesCrops} from "features/managed-acres";

interface ProducersReviewUnitsSidebarProps {
  onReviewButtonClick(coverageId : number) : void
  updatedUnitOptioncollection:OptionCodeUpdate[]
}

export const ProducersReviewUnitsSidebar: React.FC<ProducersReviewUnitsSidebarProps> = ({onReviewButtonClick,updatedUnitOptioncollection}) => {
  const policyId = useAppStateStore((s) => s.policyId);
  const {coveragesUnitStructure} = usePoliciesCoveragesUnitStructureQuery(policyId);
  
  const { cropUnits, isCropUnitsFetching } =
    usePoliciesCropUnitsReviewQuery(policyId);
  
  let updatedCropUnits = cropUnits?.units;
  
  if (updatedUnitOptioncollection && updatedUnitOptioncollection.length > 0 && updatedCropUnits) {
    updatedCropUnits && updatedCropUnits?.map(p => {
      let coverageEle = updatedUnitOptioncollection.find(x => x.pawCoverageId == p.pawCoverageId);
      if (coverageEle) {
        let optionCode = coverageEle.records.find(r => r.pawPremiumLineId == p.pawPremiumLineId)?.optionCode;
        if (optionCode && p.unitNumber) {
          p.unitNumber = p.unitNumber.slice(0, -2) + optionCode;
        }
      }
    });
    
  }
  const [farmsFilterData, setFarmsFilterData] = useState({
    filterType: "",
    filterValue: "All",
    hideEmpty: false,
  } as IProducersFilterData);

  const { farms, isFarmsFetching } = usePoliciesFarmsQuery(policyId!);

  const filteredUnits = filterUnits(
    farmsFilterData,
    farms || [],
    [],
    cropUnits
  );

  return (
    <>
      {isCropUnitsFetching ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.leftColumnContent}>
          <div className={`${styles.rightSidebarContent}`}>
            <ProducersFilter
              onFilterChange={setFarmsFilterData}
              showHideEmpty={false}
            />
            <div className="px-3 pt-2 pb-3">
              {!!filteredUnits?.crops &&
                filteredUnits?.crops?.map((crop, index) => {
                  return (
                    <div key={index}>
                      <ProducersReviewUnitsSidebarCoverageCard
                        isSelected={false}
                        units={
                          filteredUnits?.units
                            ? filteredUnits?.units
                                .filter(
                                  (unit) => unit.coverageId == crop.coverageId
                                )
                                .sort((a, b) =>
                                  a.unitNumber > b.unitNumber ? 1 : -1
                                )
                            : []
                        }
                        coverage={crop}
                        coverageUnitStructure={coveragesUnitStructure?.find(c=>c.pawCoverageId==crop.pawCoverageID)}
                        onReviewButtonClick={onReviewButtonClick}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
