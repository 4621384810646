export function isNumeric(val: any) {
  const numVal = parseFloat(val);
  if (Number.isNaN(numVal)) {
    return false;
  }

  if (!Number.isFinite(numVal)) {
    return false;
  }

  return true;
}
