import { Typography, Button, colors, Flex, Sheet } from "@proag/sprout";
import styles from "./producers-planted-acres-review-acre-observation.module.scss";
import { useEffect, useState } from "react";
import { LoadingSpinner } from "components/common/LoadingSpinner";
import { ProducersReviewAcreObservationDiscrepancies } from "./producers-planted-acres-review-acre-observation-discrepancies";
import { usePoliciesAcresObserveQuery } from "../hooks/policies/policies-queries";
import { ProducersReviewAcreObservationNoDiscrepancies } from "./producers-planted-acres-review-acre-observation-no-discrepancies";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { PLANTINGSEASON } from "app/common/constants";
import {
  FeaturePlanetWatchersIsActive,
  useFeatureFlag,
} from "app/feature-flag";
import { useMetricsCreateEventPublish } from "app/analytics";
import { useQueryClient } from "@tanstack/react-query";
import { refreshQueries } from "app/utils/policy-utils";

interface ProducersReviewAcreObservation {}

export const ProducersReviewAcreObservation: React.FC<
  ProducersReviewAcreObservation
> = () => {
  const queryClient = useQueryClient();
  const { acresObserved, isAcresObserveFetching, refetchAcresObserve } =
    usePoliciesAcresObserveQuery();
  const [discrepancyCount, setDiscrepancyCount] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [show, setShow] = useState(false);
  const isPlantingSeasonSpring =
    useAppStateStore((s) => s.plantingSeason) == PLANTINGSEASON.SPRING;

  const { featureFlag: flag_FeaturePlanetWatchersIsActive } = useFeatureFlag(
    FeaturePlanetWatchersIsActive
  );
  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  function updateDiscrepancyCount(hideDiscrepancyCount: number) {
    setDiscrepancyCount(discrepancyCount - hideDiscrepancyCount);
  }

  function handleClose() {
    if (hasChanges) {
      refetchAcresObserve();
      refreshQueries(queryClient);
      setHasChanges(false);
    }
    return setShow(false);
  }

  useEffect(() => {
    setDiscrepancyCount(acresObserved?.discrepancyCount ?? 0);
  }, [isAcresObserveFetching]);

  const handleShow = () => {
    setShow(true);

    recordMetric({
      name: "producers-planted-acres-review-acres",
      data: {
        category: "ProducersPlantedAcresReview",
        label: "Producers Planted Acres Review Acres Click",
        action: "Review",
      },
    });
  };

  return (
    <>
      {isAcresObserveFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="h-100 d-flex flex-column"
            data-testid="acre-observation"
          >
            <div className="row">
              <Typography className="title-md">Acre Observation</Typography>
            </div>
            <div className="row">
              <div className="label-sm" style={{ color: colors.neutral40 }}>
                Use earth observation technology to find any discrepancies
                between data entered and data observed
              </div>
            </div>
            <div className="row flex-grow-1 align-items-center">
              <img
                className={`${styles.earthImage} img-responsive`}
                src="/acre-observation.svg"
              />
            </div>
            <div className="row">
              <Flex className="w-100 align-items-center">
                <Button
                  width="100%"
                  size="sm"
                  variant="brand-outlined"
                  data-testid="review-acres"
                  onClick={handleShow}
                >
                  Review Acres
                </Button>
              </Flex>
            </div>
          </div>
          <Sheet
            setVisible={handleClose}
            title="Acre Observations"
            visible={show}
          >
            {flag_FeaturePlanetWatchersIsActive &&
            isPlantingSeasonSpring &&
            acresObserved?.hasAcresObservedData ? (
              <ProducersReviewAcreObservationDiscrepancies
                acreObservationDiscrepancies={acresObserved}
                setHasChanges={setHasChanges}
                discrepancyCount={discrepancyCount}
                updateDiscrepancyCount={updateDiscrepancyCount}
              />
            ) : (
              <ProducersReviewAcreObservationNoDiscrepancies />
            )}
          </Sheet>
        </>
      )}
    </>
  );
};
