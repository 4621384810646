import { IPoliciesFarmUnits, IPoliciesUnits } from "features/managed-acres";

export const UnitOptions = (
  coverageId: number,
  selectedFarmId: number,
  units?: IPoliciesUnits[],
  farmUnits?: IPoliciesFarmUnits[],
  isRoleUnderwriter?: boolean
): Array<{ value: number; label: string; isDisabled: boolean }> => {
  const filteredfarmUnits: number[] | undefined = farmUnits
    ?.filter((f: IPoliciesFarmUnits) => f.farmId == selectedFarmId)
    .map((f: IPoliciesFarmUnits) => f.unitId);

  let values =
    units?.filter(
      (u) =>
        u.coverageId === coverageId && filteredfarmUnits?.includes(u.unitId)
    ) ?? [];

  let unitOptions = [
    ...values.map((v) => ({
      value: v.unitId,
      label: `${v.practiceName} | ${v.typeName} | ${
        v.unitNumber
      } | ${Math.round(v.share * 100)}%`,
      isDisabled: isDisabled(v.unitId, units, isRoleUnderwriter),
    })),
  ];
  return unitOptions;
};

const isDisabled = (
  unitId: number,
  units?: IPoliciesUnits[],
  isRoleUnderwriter?: boolean
): boolean => {
  var unit = units?.find((e) => e.unitId == unitId);
  if (unit) {
    if (unit.pastFinalPlantDate) {
      return !isRoleUnderwriter;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
