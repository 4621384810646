import { ICellEditorParams } from "ag-grid-community";
import {
  IPoliciesCrops,
  FieldDataType,
  useApplicationRolesQuery,
  mapApplicationRolesQueryResults,
  CropOptions,
} from "features/managed-acres";
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  memo,
  useMemo,
} from "react";
import Select, { SingleValue } from "react-select";
import "./dropdownCellEditor.scss";

export const CoverageDropdownCellEditor = memo(
  forwardRef((props: ICellEditorParams<FieldDataType>, ref) => {
    const selectRef = useRef<any>(null);
    const [selection, setSelection] = useState<number | undefined>(
      !props.value.value ? undefined : parseInt(props.value.value)
    );

    useEffect(() => {
      selectRef.current.focus();
    }, []);

    const { ApplicationRolesData } = useApplicationRolesQuery();
    const { isRoleUnderwriter } = useMemo(
      () => mapApplicationRolesQueryResults(ApplicationRolesData),
      [ApplicationRolesData]
    );

    const options = [{ value: 0, label: "Select", isDisabled: false }].concat(
      CropOptions(
        props.data?.farm?.farmId ?? 0,
        props.data?.coverages,
        props.data?.units,
        props.data?.farmUnits,
        props.data?.farm?.countyCode,
        isRoleUnderwriter
      )
    );

    const activeOption = options.find((o) => o.value === selection);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return selection;
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const onChangeHandler = (
      newValue: SingleValue<{ value: number; label: string }>
    ) => {
      let newSelectionValue = newValue?.value;
      setSelection(newSelectionValue);
    };

    return (
      <div className="w-100 h-100" data-testid="coverage-dropdown">
        <Select
          className="h-100"
          ref={selectRef}
          options={options}
          defaultValue={activeOption ?? options[0]}
          menuPortalTarget={document.body}
          onChange={onChangeHandler}
          styles={{
            menu: (base) => ({ ...base, marginTop: 0 }),
          }}
        />
      </div>
    );
  })
);
