import { Spinner } from "react-bootstrap";

export const LoadingSpinner = () => {
  return (
    <div
      data-testid="loading-spinner"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner variant="primary" animation="border" />
    </div>
  );
};
