import { Sheet } from "@proag/sprout";
import React, { useEffect, useState } from "react";
import { ProducersUnitsEditFooter } from "../../../../components/ui/producers-units-edit-footer";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import styles from "./unit-content.module.scss";
import {
  IPoliciesUnits,
  usePoliciesCropUnitsQuery,
  usePoliciesUnitUpdateMutation,
} from "features/managed-acres";
import {
  PoliciesUnitRecord,
  PoliciesUnitUpdateCommand,
} from "features/managed-acres/hooks/policies/models/policies-unit-update-command";
import { ProducersFarmUnitsEditUnitCardDetails } from "components/ui/producers-farm-units-edit-unit-card-details";
import { useQueryClient } from "@tanstack/react-query";

interface UnitEditProps {
  unitEditClosed: () => void;
  showUnitEdit: boolean;
}

export const UnitEdit: React.FC<UnitEditProps> = ({
  unitEditClosed,
  showUnitEdit,
}) => {
  const [show, setShow] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const policyId = useAppStateStore((s) => s.policyId);
  const selectedUnitId = useAppStateStore((s) => s.selectedUnitId);
  const { cropUnits, isCropUnitsFetching } = usePoliciesCropUnitsQuery(
    Number(policyId)
  );
  const queryClient = useQueryClient();

  let unit = cropUnits?.units?.filter((u) => u.unitId == selectedUnitId)[0];
  let modifiedUnit: IPoliciesUnits | undefined;

  function handleClose() {
    setShow(false);
    unitEditClosed();
  }

  function handleChange(changedUnit: IPoliciesUnits, hasValidationError: boolean) {
    setIsSaveDisabled(hasValidationError);
    modifiedUnit = changedUnit;
  }

  const onSaveSuccess = () => {
    queryClient.invalidateQueries(["policiesCropUnitsQuery", policyId]);
  };
  const { mutateUnitUpdate: mutateUnit } =
    usePoliciesUnitUpdateMutation(onSaveSuccess);

  function handleSave() {
    //check if any changes have been made
    if (!modifiedUnit) return;

    let policiesUnitRecordObject: PoliciesUnitRecord = {
      unitId: modifiedUnit?.unitId ?? 0,
      acreageTypeCode: modifiedUnit?.acreageTypeCode?.trim() ?? "",
      mapArea: modifiedUnit?.mapArea?.trim() ?? "",
      plantPattern: modifiedUnit?.plantPattern?.trim() ?? "",
      skipRowCode: modifiedUnit?.skipRowCode?.trim() ?? "",
      rowWidth: modifiedUnit?.rowWidth ?? 0,
      skipRowFactor: modifiedUnit?.skipRowFactor ?? 0,
    };

    // now attach the record to the command...
    let policiesUnitUpdateCommandObject: PoliciesUnitUpdateCommand = {
      policyId: policyId,
      unitId: modifiedUnit?.unitId ?? 0,
      record: policiesUnitRecordObject,
    };

    // save stuff
    mutateUnit(policiesUnitUpdateCommandObject);
    setShow(false);
    unitEditClosed();
  }
  useEffect(() => {
    if (!showUnitEdit) return;
    setShow(showUnitEdit);
  }, [showUnitEdit]);

  useEffect(() => {
    unit = cropUnits?.units?.filter((u) => u.unitId == selectedUnitId)[0];
  }, [selectedUnitId]);

  return (
    <>
      <Sheet
        setVisible={handleClose}
        title="Edit Unit"
        visible={show}
        footer={
          <ProducersUnitsEditFooter
            onSave={handleSave}
            onCancel={handleClose}
            isSaveDisabled={isSaveDisabled}
          />
        }
      >
        <div className={`${styles.unitEdit} label-md mb-1`}>Unit Details</div>
        {unit && (
          <ProducersFarmUnitsEditUnitCardDetails
            unit={unit}
            onChange={handleChange}
          />
        )}
      </Sheet>
    </>
  );
};
