import { round } from "lodash";
import { format, isValid } from "date-fns";
import { FieldDataType } from "features/managed-acres/components/tables/FarmFields/FieldDataType";
import {
  validateSplitFieldHasDataEntered,
  validateAcresEnteredIfCropAndPracticeSelected,
  validateAcresEnteredAreNotMoreThanAvailable,
  validateAcresEnteredArePositive,
  validateAcresEnteredIfPlantDateEntered,
  validateAcresEnteredIfPreventPlantIsSelected,
} from "./validations-reportedacres";
import {
  hasEnteredAcres,
  hasSelectedCrop,
  hasSelectedUnit,
  hasCheckedPreventPlant,
  hasEnteredPlantedDate,
} from "./validation-helpers";

export const validateForNoCropSelectedWhenAcresEntered = (
  record: FieldDataType
): void => {
  let nextRecord = {
    ...record.coverageId,
    validationMessage: "",
    isValid: true,
  };
  if (hasEnteredAcres(record) && !hasSelectedCrop(record)) {
    nextRecord.validationMessage = `Crop must be selected when acres are reported.`;
    nextRecord.isValid = false;
  }
  record.coverageId = nextRecord;
};

export const validateCropIfPreventPlantIsSelected = (
  record: FieldDataType
): void => {
  if (!record.coverageId.isValid) {
    return;
  }
  if (hasCheckedPreventPlant(record) && !hasSelectedCrop(record)) {
    record.coverageId = {
      value: record.coverageId.value,
      validationMessage: `Crop must be selected when prevent plant is checked.`,
      warningMsg: false,
      isValid: false,
    };
  } else {
    record.coverageId = {
      value: record.coverageId.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  }
};
