/// this method is a wrapper for the file-saver module
/// to make it easier to test.
/// there are a few variants and options that we might
/// pass.  They are not implemented here, we'd need to expand
/// our wrapper to take it to the next level, should we desire.

import { saveAs } from "file-saver";

export const FileSaveAs = (data: string | Blob, fileName: string): void => {
  saveAs(data, fileName);
};
