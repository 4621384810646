export const dateEntryParser = (datestr: string): Date | undefined => {
  // via story 105869
  //
  //On the date, the most common is mm/dd/yy.
  //If user types 071323 is should accept it and format it as 07/13/2023.
  //If they type 07132023 it should take that and format it as 07/13/2023.
  //If they type 07/13/23  (with forward slashes included) it should accept the value.
  let month: number = NaN;
  let day: number = NaN;
  let year: number = NaN;

  if (!datestr) {
    return undefined;
  }

  if (datestr.includes("/")) {
    const [MM, DD, YYYY] = datestr.split("/");
    month = Number(MM);
    day = Number(DD);
    year = Number(YYYY);
  } else if (datestr.includes("-")) {
    if (datestr.split("-")[0].length === 4) {
      // special handling for ISO8601 dates... if they use the picker control instead of entering
      // manually, it'll end up here.  Same with Db returned values
      const [YYYY, MM, DD] = datestr.split("T")[0].split("-");
      month = Number(MM);
      day = Number(DD);
      year = Number(YYYY);
    } else {
      const [MM, DD, YYYY] = datestr.split("-");
      month = Number(MM);
      day = Number(DD);
      year = Number(YYYY);
    }
  } else if ([6, 8].includes(datestr.length)) {
    month = Number(datestr.substring(0, 2));
    day = Number(datestr.substring(2, 4));
    year = Number(datestr.substring(4));
  } else {
    // not able to parse date string return stub
    return undefined;
  }

  if (Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year)) {
    // something else went wrong...
    return undefined;
  }

  if (year < 100) year += 2000;

  var mydate = new Date(year, month - 1, day);

  return mydate;
};
