import { Flex, Typography } from "@proag/sprout";
import { UserInfo } from "./UserInfo";
import { Logo } from "./Logo";

const Header = () => {
  return (
    <>
      <Flex
        justifyContent="space-between"
        id="acreage-reporting-header"
        alignItems="center"
        style={{
          height: "72px",
          borderBottom: "1px solid #E3E5E5",
          position: "sticky",
          top: "0px",
          backgroundColor: "white",
          zIndex: 3,
        }}
        className="px-2"
      >
        <Flex>
          <Logo />
          <Typography className="title-md" style={{ margin: "0" }}>
            Simplified AR
          </Typography>
        </Flex>
        <UserInfo />
      </Flex>
    </>
  );
};

export default Header;
