import { Button } from "@proag/sprout";
import React from "react";

export const CustomToggle = React.forwardRef(
  ({ children, onClick }: any, ref) => (
    <Button
      variant="neutral-outlined"
      rightIcon="CaretDown"
      ref={ref as any}
      data-testid="filter-type-select"
      width="100%"
      style={{
        borderTopLeftRadius: "1px",
        borderBottomLeftRadius: "1px",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        borderRight: "1px solid #B1B2B2",
        backgroundColor: "white",
      }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  )
);
