import { FC, useEffect, useState } from "react";
import { GeoJsonMap } from "components/maps/geo-json-map";
import { usePoliciesUnitFarmsShapesQuery } from "features/planted-acres/units/hooks";
import { UpdateMapEffect } from "components/maps/mapUtils";

export interface ProducersUnitsFarmsMapProps {
  policyId: number;
  unitId: number;
}

export const ProducersUnitsFarmsMap: FC<ProducersUnitsFarmsMapProps> = ({
  policyId,
  unitId
}) => {
  const [geoJsonCluShapes, setGeoJsonCluShapes] = useState("");
  const { farmsShapes, isFarmsShapesFetching } = usePoliciesUnitFarmsShapesQuery(
    policyId,
    unitId
  );

  useEffect(() => {
    UpdateMapEffect(farmsShapes, setGeoJsonCluShapes);
  }, [farmsShapes]);

  return (
    <>
      <div
        style={{ width: "100%", height: "480px" }}
        data-testid="pu-farms-map"
      >
        {!isFarmsShapesFetching && <GeoJsonMap geoData={geoJsonCluShapes} />}
      </div>
    </>
  );
};
