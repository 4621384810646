interface RoutePaths {
  login: string;
  home: string;
  contact: string;
  producerFarms: string;
  producerUnits: string;
  producerPlantedAcresReview: string;
}

export const routePaths: RoutePaths = {
  login: "/login/callback",
  home: "/",
  contact: "/contact",
  producerFarms: "/policy/:pawPolicyOid",
  producerUnits: "/policy/:pawPolicyOid/:unit",
  producerPlantedAcresReview: "/policy/:pawPolicyOid/review",
};
