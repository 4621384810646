import { round } from "lodash";
import { format, isValid } from "date-fns";
import { FieldDataType } from "features/managed-acres/components/tables/FarmFields/FieldDataType";
import {
  validateSplitFieldHasDataEntered,
  validateAcresEnteredIfCropAndPracticeSelected,
  validateAcresEnteredAreNotMoreThanAvailable,
  validateAcresEnteredArePositive,
  validateAcresEnteredIfPlantDateEntered,
  validateAcresEnteredIfPreventPlantIsSelected,
} from "./validations-reportedacres";
import {
  hasEnteredAcres,
  hasSelectedCrop,
  hasSelectedUnit,
  hasCheckedPreventPlant,
  hasEnteredPlantedDate,
} from "./validation-helpers";

export const validateSplitFieldAcresAreNotMoreThanAvailable = (
  records: FieldDataType[]
): void => {
  const parentField = records.find((row) => !row.splitParentId);
  if (parentField) {
    const totalReportedAcres = records.reduce((total, partial) => {
      if (partial.plantedAcres.value) {
        return total + (Number(partial.plantedAcres.value) ?? 0);
      }
      return total;
    }, 0);
    if (
      round(totalReportedAcres, 2) > round(Number(parentField.acres) ?? 0, 2)
    ) {
      records.forEach((row) => {
        row.plantedAcres = {
          value: row.plantedAcres.value,
          validationMessage: `A field (and all its split fields) must not have more than ${parentField.acres}.  Reduce acres before saving.`,
          warningMsg: true,
          isValid: false,
        };
      });
      return;
    }
    return;
  }
  records.forEach((row) => {
    row.plantedAcres = {
      value: row.plantedAcres.value,
      validationMessage: ``,
      warningMsg: false,
      isValid: true,
    };
  });
};
