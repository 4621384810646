import { AgChartOptions } from "ag-charts-community";

const theme = {
  palette: {
    fills: ["lightgreen", "orange", "grey"],
    strokes: ["grey"],
  },
};

export const DefaultChartOptions: AgChartOptions = {
    data: [
      {
        SliceName: "Reported Acres",
        amount: 0,
      },
      {
        SliceName: "PreventedPlant",
        amount: 0,
      },
      {
        SliceName: "UnReported",
        amount: 0,
      },
    ],
    series: [
      {
        type: "pie",
        calloutLabelKey: "SliceName",
        angleKey: "amount",
        innerRadiusRatio: 0.75,
        calloutLabel: { enabled: false },
      },
    ],
    legend: {
      enabled: false,
      position: "right",
    },
    background: {
      fill: "transparent",
    },
    theme: theme,
  };
