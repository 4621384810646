import { Flex, colors, Typography } from "@proag/sprout";
import { CountyTotalsSummary } from "features/managed-acres";
import { ProducersCountyHeaderCard } from ".";

interface ProducersFieldsHeaderCardProps {
    title: string;
    amount: number | undefined;
    total?: number;
    countyTotals?: Array<CountyTotalsSummary>;
  }
  export const ProducersFieldsHeaderCard: React.FC<
    ProducersFieldsHeaderCardProps
  > = ({ title, amount, total, countyTotals }) => {
    return (
      <Flex
        direction="column"
        alignItems="flex-start"
        className={"pr-4 pl-4 pt-3 pb-3"}
        style={{
          marginLeft: 0,
          marginRight: ".5rem",
          backgroundColor: "white",
          minWidth: "175px",
          borderRadius: "2px",
          boxShadow:
            "0 0 2px rgba(0, 0, 0, 14%), 0 2px 4px rgba(0, 0, 0, 10%), 0 1px 6px rgba(0, 0, 0, 10%)",
        }}
      >
        <Flex
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Typography
            className="label-md"
            style={{ marginBottom: "0", color: colors.neutral40 }}
          >
            {title} Acres
          </Typography>
        </Flex>
  
        <Flex direction="row" alignItems="baseline" justifyContent="flex-start">
          <Typography className="head-md" style={{ marginBottom: "0" }}>
            {amount?.toFixed(2)}
          </Typography>
          {!!total && (
            <>
              <Typography className="label-sm" style={{ marginBottom: "0" }}>
                {"/"}
                {total.toFixed(2)}
              </Typography>
            </>
          )}
        </Flex>
        {countyTotals?.map((county) => {
          if (county.countyName !== "" || county.plantedAcres > 0)
            return (
              <ProducersCountyHeaderCard
                key={
                  title +
                  " " +
                  (county.countyName.length > 0 ? county.countyName : "unknown")
                }
                title={(county.countyName.length > 0
                  ? county.countyName
                  : "unknown"
                )
                  .split(" ")
                  .map(
                    (e) =>
                      e.substring(0, 1).toLocaleUpperCase() +
                      e.substring(1).toLocaleLowerCase()
                  )
                  .join(" ")}
                amount={county.plantedAcres}
              />
            );
        })}
      </Flex>
    );
  };