import { Flex, Button, colors } from "@proag/sprout";
import { Dropdown } from "react-bootstrap";
import React from "react";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { PLANTINGSEASON } from "app/common/constants";
import { useQueryClient } from "@tanstack/react-query";
import { usePoliciesQuery } from "features/managed-acres/hooks/policies/policiesQueries";
import { refreshQueries } from "app/utils/policy-utils";

interface PlantingSeasonProps {}

export const PlantingSeason: React.FC<PlantingSeasonProps> = ({}) => {
  const queryClient = useQueryClient();
  const setPlantingSeason = useAppStateStore((s) => s.setPlantingSeason);
  const setSelectedFarmId = useAppStateStore((s) => s.setSelectedFarmId);
  const policyId = useAppStateStore((s) => s.policyId);
  const { policy } = usePoliciesQuery(policyId);

  const springDisplay = `${policy?.reinsuranceYear ?? ""} Spring`;
  const fallDisplay = `${policy?.reinsuranceYear ?? ""} Fall`;

  function changePlantingSeason(season: string) {
    setPlantingSeason(season);
    setSelectedFarmId(0);
    refreshQueries(queryClient);
  }
  return (
    <>
      <Flex className="mb-1" justifyContent="space-between">
        <Flex direction="column" alignItems="flex-end">
          <div className="label-sm mb-1" style={{ color: colors.neutral40 }}>
            Acreage Reporting Date
          </div>
          <Flex direction="row" alignItems="flex-end">
            <Dropdown className="flex-start">
              <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
                {useAppStateStore((s) => s.plantingSeason) ==
                PLANTINGSEASON.SPRING
                  ? springDisplay
                  : fallDisplay}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    changePlantingSeason(PLANTINGSEASON.SPRING);
                  }}
                >
                  {springDisplay}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    changePlantingSeason(PLANTINGSEASON.FALL);
                  }}
                >
                  {fallDisplay}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }: any, ref) => (
  <Button
    data-testid="producers-farms-fields-planting-season-button"
    rightIcon="CaretDown"
    variant="neutral-outlined"
    ref={ref as any}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));
