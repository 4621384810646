import {
  GridApi,
  IRowNode,
  NewValueParams,
  ValueSetterFunc,
  ValueSetterParams,
} from "ag-grid-community";
import { EditableField, FieldDataType } from "./FieldDataType";
import { concat } from "lodash";
import { validateRows } from "features/managed-acres/functions/validations/rows-validator";

const syncValidator = (
  params: ValueSetterParams<FieldDataType>,
  validateFn: ValueSetterFunc<FieldDataType>
) => {
  const isValid = validateFn(params);

  let rowData = params.data;
  let fieldName = params.colDef.field;

  if (fieldName) {
    const nextField = rowData[fieldName] as EditableField;
    if (nextField && typeof nextField === "object") {
      rowData[fieldName] = {
        ...nextField,
        isValid: isValid,
        validationMessage: isValid ? "" : nextField.validationMessage,
      };
    }

    params.node?.setData(rowData);
  }
};

export const valueSetterEffect =
  (validateFn: (params: ValueSetterParams<FieldDataType>) => boolean) =>
  (params: ValueSetterParams<FieldDataType>) => {
    syncValidator(params, validateFn);

    // This determines whether the cell should refresh but we are handling
    // that in the validateFn function with the setData function
    return true;
  };

export const preventedPlantEffect = (
  params: ValueSetterParams<FieldDataType>
) => {
  params.data.plantDate = {
    value: undefined,
    isValid: true,
    validationMessage: "",
    warningMsg: false,
  };
  params.data.pastFinalPlantDate = undefined;
  params.data.preventedPlant = {
    ...params.data.preventedPlant,
    value: params.newValue.value,
  };
  return true;
};

const didValueChange = (oldValue: any, newValue: any): boolean => {
  if (oldValue === newValue) {
    return false;
  } else if (!oldValue && !newValue) {
    return false;
  } else {
    return true;
  }
};

export type ArGridType = "farm" | "unit";
export type ArGridValidationType = "full" | "partial";

export const GridValidator = (
  gridType: ArGridType,
  gridValidationType: ArGridValidationType = "partial",
  gridApi?: GridApi<FieldDataType>,
  event?: NewValueParams<FieldDataType, any>
) => {
  // because there are so many conditions and behaviors of how the validations
  // should fire, we'll try just validating the entire grid as needed.
  // it might be too expensive, but I think it will be okay.

  const api = event ? event.api : gridApi;

  if (!api) {
    return;
  }

  let nodeList = api.getRenderedNodes();
  let validatingRowsData: FieldDataType[] = [];

  if (gridValidationType === "full") {
    validatingRowsData = getAllRowsForValidation(nodeList);
  } else if (event && gridValidationType === "partial") {
    if (!didValueChange(event.oldValue.value, event.newValue.value)) {
      return;
    }

    validatingRowsData = getPartialRowsForValidation(nodeList, event.data);
  } else {
    return;
  }
  validateRows(validatingRowsData, gridType, gridValidationType);
  api.applyTransaction({ update: validatingRowsData });
};

function getAllRowsForValidation(
  nodeList: IRowNode<FieldDataType>[]
): FieldDataType[] {
  // remove grouped rows
  return nodeList.reduce((acc: FieldDataType[], node) => {
    if (!node.group) {
      acc.push(node!.data!);
    }
    return acc;
  }, []); // I promise both "n" and "data" will exist!
}

function getPartialRowsForValidation(
  nodeList: IRowNode<FieldDataType>[],
  field: FieldDataType
): FieldDataType[] {
  let additionalRowsData: FieldDataType[] = [];
  let currentRowsData: FieldDataType[] = [field]; // add our edited row, regardless
  // The follow block effectively reduces our validations to only check
  // nodes that are related to the row we touched.
  nodeList.forEach((row: any) => {
    // if edited row data has been split from parent
    if (field.splitParentId) {
      // ignore row that happens to be the edited row.
      if (row.data?.fieldId !== field.fieldId) {
        if (
          row.data?.fieldId === field.splitParentId ||
          row.data?.splitParentId === field.splitParentId
        ) {
          // and the current row either IS edited rows parent or is a sibling of the edited row(has same parent)
          additionalRowsData.push(row.data);
        }
      }
    } else {
      // edited row is not a split from a parent
      if (row.data?.splitParentId === field.fieldId) {
        // and current row has parent of edited row.
        additionalRowsData.push(row.data);
      }
    }
  });

  return concat(currentRowsData, additionalRowsData);
}

export const isFieldEditable = (
  data: FieldDataType | undefined,
  context: any
): boolean => {
  var isEditable: boolean = true;
  if (data?.pastAcreageReportDate) {
    isEditable = !!context.isRoleUnderwriter;
  } else {
    isEditable = true;
  }
  return isEditable;
};
