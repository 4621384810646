import { Flex } from "@proag/sprout";

interface InputCheckBoxProps {
  onChange: any;
  testId: string;
  label: string;
}

export const InputCheckBox: React.FC<InputCheckBoxProps> = ({
  onChange,
  label,
  testId,
}) => {
  return (
    <Flex justifyContent="start">
      <input
        type="checkbox"
        data-testid={testId}
        name={`input-checkbox`}
        onChange={onChange}
      />
      <div className="body-bold p-1 bold ml-2">{label}</div>
    </Flex>
  );
};
