import { AwsRum } from "aws-rum-web";

let awsRum: AwsRum | null = null;
try {
  awsRum = new AwsRum(
    (window as any).globalConfig.awsRum.REACT_APP_AWSRUM_APPLICATION_ID,
    (window as any).globalConfig.awsRum.REACT_APP_AWSRUM_APPLICATION_VERSION,
    (window as any).globalConfig.awsRum.REACT_APP_AWSRUM_APPLICATION_REGION,
    (window as any).globalConfig.awsRumConfig
  );
} catch (error) {
  // errors during initialization are ignored
}

export function getAwsRum() {
  return awsRum;
}
