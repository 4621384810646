import { IPoliciesCrops, IPoliciesUnits } from "features/managed-acres";

export function CompareCoverageByCountyThenCommodityName(a: IPoliciesCrops, b: IPoliciesCrops) {
    if (a.countyName < b.countyName) {
        return -1;
    }
    if (a.countyName > b.countyName) {
        return 1;
    }
    if (a.commodityName < b.commodityName) {
        return -1;
    }
    if (a.commodityName > b.commodityName) {
        return 1;
    }
    return 0;
}

export function ComparePremiumLineByDescription(a: IPoliciesUnits, b: IPoliciesUnits) {
    const unitA = a.unitNumber.substring(0, 4) + a.unitNumber.substring(5, 9);
    const unitB = b.unitNumber.substring(0, 4) + b.unitNumber.substring(5, 9);
    if (unitA < unitB) {
        return -1;
    }
    if (unitA > unitB) {
        return 1;
    }
    return 0;
}

export function ComparePremiumLineByCoverageThenPremiumLine(a: IPoliciesUnits, b: IPoliciesUnits, sortOrder: Map<number, number>) {
    let aindex = sortOrder.get(a.coverageId) || 0;
    let bindex = sortOrder.get(b.coverageId) || 0;
    if (aindex < bindex) {
        return -1;
    }
    if (aindex > bindex) {
        return 1;
    }
    return ComparePremiumLineByDescription(a, b)
}