import { colors } from "@proag/sprout";
import { ACREAGEREPORTTYPES } from "app/common/constants";
import { CustomToggle } from "features/managed-acres/functions/filters/custom-toggle";
import { Dropdown } from "react-bootstrap";

interface ReportTypeProps {
  onChange: any;
  value: number;
  label: string;
}

export const ReportType: React.FC<ReportTypeProps> = ({
  onChange,
  value,
  label,
}) => {
  return (
     <>
      <div className="label-sm mb-1" style={{ color: colors.neutral40 }}>
            {label}
          </div>
          <Dropdown
            data-testid="generate-report-drop-down"
            onSelect={onChange}
          >
            <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
              {value == 0
                ? ACREAGEREPORTTYPES.FARMBASED
                : ACREAGEREPORTTYPES.UNITBASED}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Item
                data-testid="report-farmbased"
                eventKey={ACREAGEREPORTTYPES.FARMBASED}
              >
                {ACREAGEREPORTTYPES.FARMBASED}
              </Dropdown.Item>
              <Dropdown.Item
                data-testid="report-unitbased"
                eventKey={ACREAGEREPORTTYPES.UNITBASED}
              >
                {ACREAGEREPORTTYPES.UNITBASED}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
     </>
  );
};
