import {
  CellClickedEvent,
  ColumnFunctionCallbackParams,
  ValueSetterParams,
} from "ag-grid-community";
import {
  BooleanValidationField,
  FieldDataType,
  StringValidationField,
  NumberValidationField,
} from "features/managed-acres";

export const cellStyleRightAlign = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
};

export const cellStyleLeftAlign = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

export const SubFieldValueSetter = (
  params: ValueSetterParams<FieldDataType>
) => {
  var hasFieldChanged = hasValueChanged(params.newValue, params.data.subField);
  params.data.subField = {
    ...params.data.subField,
    value: params.newValue,
  };
  return hasFieldChanged;
};

export const PlantedAcresCellClassRules = {
  "border-red": (params: any) =>
    !!params.data?.plantedAcres &&
    !params.data.plantedAcres.isValid &&
    !params.data?.plantedAcres.warningMsg,
  "border-yellow": (params: any) =>
    !params.data?.plantedAcres.isValid &&
    !!params.data?.plantedAcres.warningMsg,
};

export const PlantedAcresValueSetter = (
  params: ValueSetterParams<FieldDataType>
) => {
  var hasFieldChanged = hasValueChanged(
    params.newValue,
    params.data.plantedAcres
  );
  params.data.plantedAcres = {
    ...params.data.plantedAcres,
    value: params.newValue,
  };
  return hasFieldChanged;
};

export const PlantDateEditable = (
  params: ColumnFunctionCallbackParams<FieldDataType>
) => {
  if (params?.context.isRoleUnderwriter) {
    return true;
  }
  if (params?.data?.pastAcreageReportDate) {
    return !params?.data?.pastAcreageReportDate;
  }
  return !params?.data?.preventedPlant.value;
};

export const PlantDateValueSetter = (
  params: ValueSetterParams<FieldDataType>
) => {
  var hasFieldChanged = hasValueChanged(params.newValue, params.data.plantDate);
  params.data.plantDate = {
    ...params.data.plantDate,
    value: params.newValue,
  };
  return hasFieldChanged;
};

export const PlantDateCellClassRules = {
  "border-red": (params: any) =>
    !!params.data?.plantDate &&
    !params.data?.plantDate.isValid &&
    !params.data?.plantDate.warningMsg,
  "border-yellow": (params: any) =>
    !params.data?.plantDate.isValid && !!params.data?.plantDate.warningMsg,
};

export const PreventPlantOnCellClicked = (
  params: CellClickedEvent<FieldDataType, FieldDataType["preventedPlant"]>
) => {
  if (params.data) {
    var isEditable: boolean = isFieldEditable(params.data, params.context);
    if (isEditable) {
      let newValue: BooleanValidationField = {
        value: !params.data.preventedPlant.value,
        isValid: false,
        warningMsg: false,
        validationMessage: "",
      };
      params.node?.setDataValue("preventedPlant", newValue);
    }
  }
};

const hasValueChanged = (
  newValue: any,
  oldValue:
    | StringValidationField
    | BooleanValidationField
    | NumberValidationField
): boolean => {
  if (newValue == oldValue.value) {
    return false;
  } else {
    return true;
  }
};

export const isFieldEditable = (
  data: FieldDataType | undefined,
  context: any
): boolean => {
  var isEditable: boolean = true;
  if (data?.pastAcreageReportDate) {
    isEditable = !!context.isRoleUnderwriter;
  } else {
    isEditable = true;
  }
  return isEditable;
};
