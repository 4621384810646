import { Icon } from "@proag/sprout";
import { NumberValidationField, StringValidationField } from "./FieldDataType";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const FieldCellDisplay = (
  { value, isValid, validationMessage, warningMsg }: StringValidationField | NumberValidationField,
  content?: JSX.Element,
  justifyContent?: "flex-start" | "flex-end"
) => {
  let errorIcon = (
    <Icon
      data-testid="error-icon"
      iconName="WarningFilled"
      size={"sm"}
      style={{ color: warningMsg ? "#d6822a" : "#9b3434" }}
    ></Icon>
  );

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {validationMessage}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={isValid ? <></> : renderTooltip}
    >
      <div
        style={{
          display: "flex",
          justifyContent: isValid
            ? justifyContent ?? "flex-start"
            : "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div data-testid="cell-value">{content}</div>
        <span>{isValid ? "" : errorIcon}</span>
      </div>
    </OverlayTrigger>
  );
};
