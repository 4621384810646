import {
  IPoliciesCrops,
  IPoliciesFarmUnits,
  IPoliciesUnits,
} from "features/managed-acres";

const calcLevel = (coverage: IPoliciesCrops): string => {
  let level = `${Math.round(coverage.coverageLevel * 100)}%`;
  if (coverage.coverageByPracticeChecked === true) {
    /* prettier-ignore */
    level = `IRR ${Math.round(coverage.irrCoverageLevel * 100)}% - NON IRR ${Math.round(coverage.coverageLevel * 100)}%`;
  }

  return level;
};

export const CropOptions = (
  selectedFarmId: number,
  coverages?: IPoliciesCrops[],
  units?: IPoliciesUnits[],
  farmUnits?: IPoliciesFarmUnits[],
  countyCode?: string,
  isRoleUnderwriter?: boolean
): Array<{ value: number; label: string; isDisabled: boolean }> => {
  let lookupCoverages = coverages ?? [];

  const availableUnits = farmUnits
    ?.filter((f: IPoliciesFarmUnits) => f.farmId == selectedFarmId)
    .map((f: IPoliciesFarmUnits) => f.unitId);

  const availableCoverages = units
    ?.filter((f: IPoliciesUnits) => availableUnits?.includes(f.unitId))
    .map((f: IPoliciesUnits) => f.coverageId);

  // reduce to show only coverages that match farms county.
  lookupCoverages = lookupCoverages?.filter(
    (c) =>
      c.countyCode === countyCode && availableCoverages?.includes(c.coverageId)
  );

  let cropOptions = [
    ...lookupCoverages.map((c) => ({
      value: c.coverageId,
      label: `${c.commodityName} | ${c.insurancePlanAbbrev} | ${calcLevel(
        c
      )}  | ${Math.round(c.priceElectionFactor * 100)}% ${
        c.allUnitsPastAcreageReportingDate ? "(Past ARD)" : ""
      }`,
      isDisabled: isDisabled(c.coverageId, units, isRoleUnderwriter),
    })),
  ];

  return cropOptions;
};

const isDisabled = (
  coverageId: number,
  units?: IPoliciesUnits[],
  isRoleUnderwriter?: boolean
): boolean => {
  if (isRoleUnderwriter) {
    return false;
  }
  var coverageUnits = units?.filter((e) => e.coverageId == coverageId);
  if (coverageUnits) {
    if (coverageUnits.length == 0) {
      return true;
    } else {
      var isAllUnitsPastAcreageReportingDate = coverageUnits?.every(
        (e) => e.pastAcreageReportingDate == true
      );
      return isAllUnitsPastAcreageReportingDate;
    }
  } else {
    return true;
  }
};
