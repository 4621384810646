import {
  FieldDataType,
  PoliciesFarmsFieldUpdateCommand,
  getGridValidationErrors,
  PoliciesFarmsFieldRecord,
} from "features/managed-acres";

import { CellValueChangedEvent, RowDataUpdatedEvent } from "ag-grid-community";
import { mapPoliciesFarmsFieldRecordFromFieldDataType } from "features/planted-acres/functions/utility/map-policiesfarmsfieldrecord-from-fielddatatype";

export const handleCellValueChangedUnitGridEvent = (
  policyId: number,
  event: CellValueChangedEvent<FieldDataType>,
  mutateFields: (cmd: PoliciesFarmsFieldUpdateCommand) => void,
  setIsUnitGridValid: (valid: boolean) => void
) => {
  // handle all sorts of things that should happen after the grid state has changed.
  // save ... update footer ... etc

  let policiesFarmsFieldRecordObject: PoliciesFarmsFieldRecord =
    mapPoliciesFarmsFieldRecordFromFieldDataType(event.data);

  // now attach the record to the command...
  let policiesFarmsFieldUpdateCommandObject: PoliciesFarmsFieldUpdateCommand = {
    policyId: policyId,
    farmId: event.data.farm!.farmId,
    record: policiesFarmsFieldRecordObject,
  };

  mutateFields(policiesFarmsFieldUpdateCommandObject);

  // now we inspect the grid to see if we have validation issues.
  const validationMessages: string[] = getGridValidationErrors(event.api, true);
  if (validationMessages.length > 0) {
    setIsUnitGridValid(false);
  } else {
    setIsUnitGridValid(true);
  }
};

export const handleRowDataUpdatedUnitGridEvent = (
  event: RowDataUpdatedEvent<FieldDataType>
) => {
  // not sure if we need anything here, or if we can handle everyting in the the "cellValueChanged" part
  // keeping this here for now, until we're completely done and know we won't need it.
  //console.log("handleRowDataUpdatedUnitGridEvent .", event);
};
