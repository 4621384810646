import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styles from "./producers-base-footer.module.scss";
import { Button, Flex } from "@proag/sprout";
import { usePoliciesAcreageReportPublish } from "features/managed-acres";
import React from "react";
import { toast } from "react-toastify";
import { useMetricsCreateEventPublish } from "app/analytics";
import { refreshQueries, routeToPaw } from "app/utils/policy-utils";
import { useAppStateStore } from "../../app/state-management/use-app-state-store";
import { useQueryClient } from "@tanstack/react-query";

export interface ProducersBaseFooterProps {}

export const ProducersBaseFooter: React.FC<ProducersBaseFooterProps> = ({}) => {
  const queryClient = useQueryClient();
  const isFarmGridValid = useAppStateStore((s) => s.isFarmGridValid);
  const isUnitGridValid = useAppStateStore((s) => s.isUnitGridValid);
  const navigate = useNavigate();
  const { mutate: recordMetric } = useMetricsCreateEventPublish();

  const { pawPolicyOid } = useParams();
  let policyId = !!pawPolicyOid ? +pawPolicyOid : -1;

  const acreageReportPublish = usePoliciesAcreageReportPublish(
    Number(pawPolicyOid)
  );

  function callAcreageReportPublish() {
    acreageReportPublish.mutate();
  }

  const completeReport = () => {
    if (!isFarmGridValid || !isUnitGridValid) {
      toast.error(
        "Please resolve errors in your data before finishing reporting.",
        {
          toastId: "FinishReportingNavigateGridValidCheck",
        }
      );
    } else {
      callAcreageReportPublish();
      recordMetric({
        name: "finish-reporting",
        data: {
          category: "ProducersFarmsFields",
          label: "Finish Reporting",
          action: "Finish",
        },
      });
      refreshQueries(queryClient);
      navigate(`/policy/${policyId}/review`);
    }
  };

  return (
    <>
      <footer className={styles.footer} id="page-footer">
        <Button
          size={"sm"}
          data-testid="producers-farms-fields-button-detail-lines"
          variant="neutral-open"
          leftIcon="ArrowLeft"
          onClick={() => {
            if (!isFarmGridValid || !isUnitGridValid) {
              toast.error(
                "Please resolve errors in your data before navigating back to Detail Lines.",
                {
                  toastId: "DetailLinesNavigateGridValidCheck",
                }
              );
            } else {
              routeToPaw(policyId);
            }
          }}
        >
          Back to Detail Lines
        </Button>
        <Flex direction={"row"} justifyContent="flex-end" alignItems="flex-end">
          <Flex
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              size={"sm"}
              data-testid="producers-farms-fields-button-complete"
              rightIcon="ArrowRight"
              onClick={completeReport}
            >
              Report Summary
            </Button>
          </Flex>
        </Flex>
      </footer>
    </>
  );
};
