import { LoadingSpinner } from "components/common/LoadingSpinner";
import React, { useEffect, useState } from "react";
import styles from "./producers-planted-acres-review-sidebar.module.scss";
import { usePoliciesFarmsAcreageQuery } from "../hooks/policies/policies-queries";
import { ProducersReviewFarmSidebarCard } from "./producers-planted-acres-review-farms-sidebar-card";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { filterFarms } from "features/managed-acres/functions/filters/filter-Farms-By-Many";
import {
  usePoliciesCropUnitsQuery,
} from "features/managed-acres/hooks/policies/policiesQueries";
import { PoliciesFarmsAcreageQueryResult } from "../hooks/policies/models/policies-farms-acreage-query-result";
import {
  IProducersFilterData,
  ProducersFilter,
} from "components/search/producers-filter";

interface ProducersReviewFarmsSidebarProps {}

export const ProducersReviewFarmsSidebar: React.FC<
  ProducersReviewFarmsSidebarProps
> = ({}) => {
  const policyId = useAppStateStore((s) => s.policyId);
  const { farms, isFarmsAcreageFetching } =
    usePoliciesFarmsAcreageQuery(policyId);

  const [farmsList, setFarmsList] =
    useState<PoliciesFarmsAcreageQueryResult[]>();

  const [farmsFilterData, setFarmsFilterData] = useState({
    filterType: "",
    filterValue: "All",
    hideEmpty: false,
  } as IProducersFilterData);

  const { cropUnits } = usePoliciesCropUnitsQuery(Number(policyId));

  const filteredFarms = filterFarms(
    farmsFilterData,
    farms || [],
    [],
    cropUnits
  );

  useEffect(() => {
    if (farms) {
      const filteredItems = farms?.filter(
        (e) =>
          filteredFarms &&
          filteredFarms.findIndex((r) => r.farmId == e.farmId) != -1
      );
      if (filteredItems && filteredItems.length > 0) {
        setFarmsList(filteredItems);
      } else {
        setFarmsList([]);
      }
    }
  }, [farmsFilterData]);

  return (
    <>
      {isFarmsAcreageFetching ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.leftColumnContent}>
          <div className={`${styles.rightSidebarContent}`}>
            <ProducersFilter onFilterChange={setFarmsFilterData} showHideEmpty={false} />
            <div className="px-3 pt-2 pb-3">
              {!!farmsList &&
                farmsList.map((farm) => {
                  return (
                    <div key={farm.farmId}>
                      <ProducersReviewFarmSidebarCard
                        farmId={farm.farmId}
                        farmName={farm.name}
                        otherLandDescription={farm.otherLandDescription}
                        countyName={farm.countyName}
                        countyCode={farm.countyCode}
                        stateCode={farm.stateCode}
                        section={farm.section}
                        township={farm.township}
                        range={farm.range}
                        fn={farm.farmSerialNumber}
                        tractNumber={farm.tractNumber}
                        plantedAcres={farm.plantedAcres}
                        totalAcres={farm.reportedAcres}
                        isSelected={false}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
